import "firebase/auth";
import { httpsCallable} from "firebase/functions";
import { doc, getDoc } from "@firebase/firestore";
import { db, functions } from '../../firebaseutil';
import * as Sentry from "@sentry/nextjs";

const REDEEM = "redeem";

export default function useRedeem() {

  const redeem = async ({code, email, name}: {code: string, email?: string, name?: string}, params?: any) => {
    try {
    const redeemCallable = httpsCallable(functions, 'user-redeem');
      const result = await redeemCallable({code, name, email, params});
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error while redeeming",err);
      throw(err);
    }
  }

  const hasRedeemed = async (uid: string, code: string): Promise<boolean> => {
    if(!code)
      return false;
    const redeemRef = doc(db, `${REDEEM}/${code.toLowerCase()}/claim/${uid}`);
    // console.log("checking redeem status at", `${REDEEM}/${code.toLowerCase()}/claim/${uid}`);
    const redeemSnapshot = await getDoc(redeemRef);
    // console.log("redeemsnapshot", redeemSnapshot?.data(), redeemSnapshot.exists());
    return redeemSnapshot.exists();
  }

  return {redeem, hasRedeemed};
}