import { useState } from "react";
import { Receipt } from "../../models/blockchain";
import useInterval from "../helper/useInterval";

export default function useProgress(setPaymentProgressPercent: any) {
  const [stepsFinished, setStepsFinished] = useState(0);
  const [started, setStarted] = useState(false);
  const [stepCount, setStepCount] = useState(7);
  const stepSize = Math.floor(100 / stepCount);

  useInterval(() => setPaymentProgressPercent(prev => incrementalProgressPercent(prev, stepsFinished + 1, stepSize)), !started || stepsFinished === stepCount ? null : 200);

  const incrementalProgressPercent = (prevPercent: number, wipStep: number, stepSize: number) => {
    const prevStep = wipStep - 1;
    // console.log("increment", { start: started, stepsFinished, stepCount, prevPercent, wipStep, stepSize });
    if (prevPercent < prevStep * stepSize) {
      // console.log(`${stepsFinished}/${stepCount} from ${prevPercent} to ${prevStep * stepSize + stepSize * 0.04} wip: ${wipStep} ${stepSize}`)
      return prevStep * stepSize + stepSize * 0.04;
    } else if(prevPercent > wipStep * stepSize) {
      //step count was probably reset
      // console.log(`${stepsFinished}/${stepCount} from ${prevPercent} to ${prevStep * stepSize + stepSize * 0.04} wip: ${wipStep} ${stepSize}`)
      return prevStep * stepSize + stepSize * 0.04;
    } else {
      // console.log(`${stepsFinished}/${stepCount} from ${prevPercent} to ${prevPercent + (wipStep * stepSize - prevPercent) * 0.2} ${prevPercent} ${wipStep} ${stepSize}`)
      return prevPercent + (wipStep * stepSize - prevPercent) * 0.2;
    }
  }

  const start = (count: number) => {
    setStarted(true);
    setStepCount(count);
    setStepsFinished(prev => 0);
    setPaymentProgressPercent(0);
  }

  const changeStepCount = (count: number) => {
    setStepCount(count);
    setPaymentProgressPercent(prev => (stepsFinished + 1) * Math.floor(100 / count));
  } 

  const fail = () => {
    setStepsFinished(0);
    setStarted(false);
  }

  const increment = () => {
    console.log("Incrementing steps finished.", stepsFinished);
    setStepsFinished(prev => prev + 1);
  }


  const forceSetStepsFinished = (count: number) => {
    console.log("Forcing steps finished.", stepsFinished, count);
    setStepsFinished(prev => count);
  }

  const finish = () => {
    setStarted(false);
    setPaymentProgressPercent(100);
  }

  return { start, increment, fail, finish, changeStepCount, forceSetStepsFinished };
}
