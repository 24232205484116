import { ethers, Signer } from "ethers";
import FleatoNFT from "../../smartcontracts/FleatoNFT.json";
import { fleatoConfig } from "../../fleato-config";
import { gasFee, gasFeeOptions } from "../../util/smartcontracts";
import { EthersTxn, Nonce } from "../../models/wallet";
import useFleatoPool from "./useFleatoPool";
import { WalletType } from "../wallet/models";
import * as Sentry from "@sentry/nextjs";

export default function useFleatoNFT({walletType, account, getSigner, getProvider} : {walletType: WalletType, account?: string, getProvider?: () => Promise<any>, getSigner?: () => Promise<Signer>}) {

  const {getNonce, submitTransaction} = useFleatoPool();
  //getProvider will be sent for connected wallets
  //getSigner will be sent for fleato wallets

  //Web3 Provider for connected providers, default provider for fleato wallet.
  const networkProvider = async (): Promise<any> => {
    if(walletType == WalletType.ConnectedWallet)
      return new ethers.providers.Web3Provider(await getProvider());
    else
      return ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);
  }

  const nftContractInstance = async () => {
    if(walletType == WalletType.FleatoWallet) {
      const c = new ethers.Contract(fleatoConfig.contractFleatoNFT, FleatoNFT.abi, await networkProvider());
      const signer = await getSigner();
      console.log("Signer available in useFleatoNFT ", !!signer);
      return c.connect(signer);
    } else {
      const c = new ethers.Contract(fleatoConfig.contractFleatoNFT, FleatoNFT.abi, (await networkProvider()).getSigner());
      return c;
    }
  }
  
  const isApproved = async (grantForContractAddress: string): Promise<boolean> => {
    const contract = await nftContractInstance();
    console.log({account});
    if(account)
      return await contract.isApprovedForAll(account, grantForContractAddress);
    else
      return false;
  }


  const approveAll = async (grantForContractAddress: string): Promise<EthersTxn | Nonce> => {
    console.log("Approving transfers", {grantForContractAddress});
    const contract = await nftContractInstance();
    if(walletType !== WalletType.FleatoWallet) {
        const gasEstimate = await gasFee();
        console.log("gasEstimate", gasEstimate);
        const tx = await contract.setApprovalForAll(grantForContractAddress, true, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      const poolNonce = await getNonce(await contract.signer.getAddress());
      const chainNonce = await contract.provider.getTransactionCount(await contract.signer.getAddress(), "latest");
      console.log("poolNonce", poolNonce, "chainNonce", chainNonce);

      const nonce = Math.max(poolNonce?.pooled ?? 0, chainNonce);
      const gasEstimate = await gasFeeOptions();
      console.log("gasEstimate", gasEstimate, "grantForContractAddress", grantForContractAddress);
      const slowTxn = await contract.populateTransaction.setApprovalForAll(grantForContractAddress, true, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await contract.populateTransaction.setApprovalForAll(grantForContractAddress, true, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await contract.populateTransaction.setApprovalForAll(grantForContractAddress, true, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      if(!contract.signer) {
        Sentry.captureException(new Error(`useFleatoNFT - attempting to approvalAll before wallet is loaded for ${walletType} account ${account}`));
        throw new Error("Wallet is not loaded yet. Please try again.");
      }
      const signedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastestTxn), nonce});
      console.log("slowTxn", slowTxn, "signedTx", signedTx);
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  return {isApproved, approveAll};
}
