import { useContext, useEffect, useState } from 'react';
import "firebase/auth";
import {getAuth} from "firebase/auth";
import {getFunctions, httpsCallable} from "firebase/functions";
import { getFirestore, doc, getDoc, onSnapshot, query, collection, where } from "firebase/firestore";
import { CustomizationChoice, GuestBid, GuestBidWithCache, PrivateBid, PrivateBidWithCache, Product, ProductStats, PublicProfile } from "../../models/product";
import useBuyNow from './useBuyNow';
import { db, auth, functions } from '../../firebaseutil';
import * as Sentry from "@sentry/nextjs";
import { PrintfulData } from '../../models/order';
import useLocalStorage from '../helper/useLocalStorage';
import { uuidv4 } from '../../util/util';
import { UserContext } from '../../contexts/UserContext';

const PRODUCT = "product";
const PRODUCT_PUBLIC = "public";
const PRODUCT_STATS = "stats";

enum RequestType {
  subscribeToNewsletter = "subscribeToNewsletter",
  publicProfile = "publicProfile",
  updateProfile = "updateProfile",
  addWallet = "addWallet",
  registerDevice = "registerDevice",
  walletBalance = "walletBalance",
  deleteProduct = "deleteProduct",
  listProduct = "listProduct",
  writeReview = "writeReview",
  placeBid = "placeBid",
  placeGuestBid = "placeGuestBid",
  markAsSoldByChannel = "markAsSoldByChannel",
}

export default function useProduct(productId?: string) {
  const {buyNow: buyNowInner, fetchOrderNumber: fetchOrderNumberInner} = useBuyNow();
  const [product, setProduct] = useState<Product>();
  const {dbUser} = useContext(UserContext);
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyBuying, setIsBusyBuying] = useState(false);
  const [stats, setStats] = useState<ProductStats>();
  const [profiles, setProfiles] = useState<PublicProfile[]>([]);
  const [orderNumber, setOrderNumber] = useState<string>();
  const [sellerProducts, setSellerProducts] = useState<Product[]>([]);
  const [markingAsSoldByChannel, setmMarkingAsSoldByChannel] = useState(false);
  const [bidError, setBidError] = useState("");
  const [userCacheId, saveUserCacheId] = useLocalStorage<string | undefined>("cacheId", undefined);


  useEffect(()=> {
  }, [userCacheId])

  useEffect(()=> {
    if(!productId)
      return;
    fetchProduct(productId).then(product => setProduct(product));
  }, [productId])

  useEffect(()=> {
    if(!product?.id)
      return;
    const d = doc(db, PRODUCT, product.id, PRODUCT_PUBLIC, PRODUCT_STATS);
    const unsubscribe: any = onSnapshot(d, (snapshot) => {
      const s = snapshot.data() as ProductStats;
      setStats(s);
      fetchProfiles(s?.bidders ?? []);
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
  }, [product?.id])

  useEffect(() => {
    if(product?.status == "bought") {
      fetchOrderNumber(product.id);
    }
  }, [product?.status])

  const getCacheId = () => {
    if(!userCacheId || userCacheId?.length === 0) {
      const cacheId = uuidv4();
      saveUserCacheId(cacheId);
      return cacheId;
    } else {
      return userCacheId;
    }

  }

  const fetchOrderNumber = async (productId: any): Promise<string | undefined> => {
    setIsBusy(true);
    const response = await fetchOrderNumberInner(productId);
    setIsBusy(false);
    return response;    
}


  const bid = async ({productId, bidAmount, maxBid}: {productId: string, bidAmount: number, maxBid: number}) => {
    setIsBusy(true);
    const bid: PrivateBidWithCache = {user: auth?.currentUser?.uid ?? '', bid: bidAmount, maxBid, bidAt: new Date().toISOString(), productId, userCacheId: "", name: dbUser?.name };
    try {
    const placeBid = httpsCallable(functions, 'product-productCall');
    const result = await placeBid({requestType: RequestType.placeBid, input: bid});
    setIsBusy(false);
    return result?.data as unknown as string;
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error while bidding",err);
      setIsBusy(false);
      return "bid not submitted";
    }
  }

  const guestBid = async ({phoneNumber, productId, bidAmount, name, email, maxBid}: {productId: string,name: string, email: string, phoneNumber: string, bidAmount: number, maxBid: number}) => {
    setIsBusy(true);
    const bid: GuestBidWithCache = {user: auth?.currentUser?.uid ?? '', bid: bidAmount, maxBid, bidAt: new Date().toISOString(), productId, name, email, phoneNumber, userCacheId: "" };
    try {      
      const guestBid = httpsCallable(functions, 'product-productCall');
      const result = await guestBid({requestType: RequestType.placeGuestBid, input: bid});
      setIsBusy(false);      
      return result?.data as string;
    } catch(err) {
      setBidError((err as Error).message ?? err?.toString());
      Sentry.captureException(err);
      console.log("Error while bidding",err);
      setIsBusy(false);
      throw err;
    }
  }

  const buyNow = async ({productId, quantity, variationSku, variationOptionSku, customizationOption, createNewOrderheader, printfulData}
      : {productId: string | undefined, quantity: number, variationSku?: string, variationOptionSku?: string, customizationOption?: CustomizationChoice[], createNewOrderheader?: boolean, printfulData?: PrintfulData}) => {
    setIsBusyBuying(true);
    const response = await buyNowInner({productId, quantity, variationSku, variationOptionSku, customizationOption, createNewOrderheader, printfulData});
    setIsBusyBuying(false);
    return response;
  }

  const fetchProfiles = (bidders: string[]) => {
    try{
    const publicProfile = httpsCallable(functions, 'user-userCall');
    bidders?.filter(b => !profiles.find(p => p.id === b)).forEach(async (b) => {
      const sellerProfile = (await publicProfile({requestType: RequestType.publicProfile, input: {uid: b}})).data as PublicProfile;
      if(sellerProfile.id === auth.currentUser?.uid) {
        sellerProfile.handle = "You";
      }
      setProfiles(prev => [...prev, sellerProfile]);
    })
  } catch(err) {
      Sentry.captureException(err);
      console.log("Error while bidding",err);
    }
  }

  const fetchProduct = async (productId: string): Promise<Product> => {
    setIsBusy(true);
    const ref = doc(db, `${PRODUCT}/${productId}`);
    const product = (await getDoc(ref)).data() as Product;
    setIsBusy(false);
    return product;
  }

  const deleteProduct = async (productId: string) => {
    try{
    const ref = httpsCallable(functions, 'product-productCall');
    const response = await ref({requestType: RequestType.deleteProduct, input: {productId: productId}});
    return response;
    }
   catch(err) {
    Sentry.captureException(err);
    console.log("Error while bidding",err);
  }
  }

  const markAsSoldByChannel = async(productId: string) => {
    setmMarkingAsSoldByChannel(true);    
    try{
    const ref = httpsCallable(functions, 'product-productCall');
    const response = await ref({requestType: RequestType.markAsSoldByChannel, input: {productId: productId}});
    setmMarkingAsSoldByChannel(false);    
    return response;
    }
   catch(err) {
    Sentry.captureException(err);
    console.log("Error while bidding",err);
  }
  }

  const fetchSellerProducts = async (userId: string) => {
    const productsQuery = query(collection(db, `${PRODUCT}`), where('seller.id', '==', `${userId}`));
    const unsubscribe = onSnapshot(productsQuery, (documentSnapshot) => {
            const returnArray: any[] = [];
            documentSnapshot.forEach(item => {
                returnArray.push(item.data());
            });
            setSellerProducts(returnArray.sort((a,b) => b.createdAt - a.createdAt));
        }, err => {
          Sentry.captureException(err);
            console.log(err);
        });
}

  return {liveStats: stats, product, bid, isBusy, profiles, uid: auth.currentUser?.uid, orderNumber, fetchOrderNumber, buyNow, isBusyBuying, fetchProduct, deleteProduct, fetchSellerProducts, sellerProducts, fetchProfiles, markAsSoldByChannel, markingAsSoldByChannel, guestBid, bidError };
}