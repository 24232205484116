import { ethers } from "ethers";
import { fleatoConfig } from "../fleato-config";
import { Receipt } from "../models/blockchain";
import { GasFeeResponse } from "../models/wallet";
import ERC20 from "../smartcontracts/IERC20.json";

export const formatReceipt = (r: any): Receipt => {
  const receipt: Receipt = {
    blockHash: r.blockHash.toString(),
    blockNumber: r.blockNumber,
    confirmations: r.confirmations,
    contractAddress: r.contractAddress,
    cumulativeGasUsed: r.cumulativeGasUsed.toString(),
    from: r.from,
    gasUsed: r.gasUsed.toString(),
    logsBloom: r.logsBloom,
    status: r.status,
    to: r.to,
    transactionHash: r.transactionHash.toString(),
    transactionIndex: r.transactionIndex,
    type: r.type,
    logs: r.logs.map((l: any) => (
      {removed: l.removed ?? false,
        transactionLogIndex: l.transactionLogIndex,
        address: l.address,
        data: l.data.toString(),
        topics: l.topics?.map((t: any) => t.toString()),
        logIndex: l.logIndex})),
  };
  return receipt;
};


const networkProvider = () => ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);

export const tokenContract = async (address: string) => {
  const tokenContract = new ethers.Contract(address, (ERC20 as any).abi, networkProvider());
  return tokenContract;
};

const toInt = (n: string) => Math.ceil(parseFloat(n));

export const gasFee = async (): Promise<ethers.providers.FeeData> => {
  const response = await fetch(`https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=${fleatoConfig.polygonScanApiKey}`, {headers: {
    Accept: "application/json",
    'Content-Type': "application/json",
    'User-Agent': "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36"
  }});
  let responseJson: GasFeeResponse, responseText: string = "";
  try {
    responseText = await response.text();
    console.log("Response from api.polygonscan.com:", responseText, `https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=${fleatoConfig.polygonScanApiKey}`);
    responseJson = JSON.parse(responseText);
  } catch (err) {
    throw new Error("Unable to estimate gas fee: " + responseText);
  }
  const fee = responseJson.result;
  if(responseJson.status !== "1")
    throw new Error("Unable to estimate gas fee: " + responseJson.message);
  const result = {
    lastBaseFeePerGas: ethers.BigNumber.from(toInt(fee.suggestBaseFee)).mul("1000000000"), 
    maxFeePerGas: ethers.BigNumber.from(toInt(fee.FastGasPrice)).mul("1000000000"),
    maxPriorityFeePerGas: ethers.BigNumber.from(toInt(fee.FastGasPrice)).sub(Math.floor(parseFloat(fee.BaseFee ?? fee.suggestBaseFee ?? "0.00"))).mul("1000000000"),
    gasPrice: ethers.BigNumber.from(toInt(fee.FastGasPrice)).mul("1000000000")
  };
  console.log("gas fee", result);
  return result;
}

export const gasFeeOptions = async () => {
  const fee = await gasFee();
  return {
    slow: fee,
    fast: {maxPriorityFeePerGas: fee.maxPriorityFeePerGas!.mul(2), maxFeePerGas: fee.maxFeePerGas?.add(fee.maxPriorityFeePerGas!), gasPrice: fee.maxFeePerGas?.add(fee.maxPriorityFeePerGas!) },
    fastest: {maxPriorityFeePerGas: fee.maxPriorityFeePerGas!.mul(3), maxFeePerGas: fee.maxFeePerGas?.add(fee.maxPriorityFeePerGas!.mul(2)), gasPrice: fee.maxFeePerGas?.add(fee.maxPriorityFeePerGas!.mul(2)) },
  };
};