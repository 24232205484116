import { Dispatch, useState } from 'react';
import "firebase/auth";
import { e164PhoneNumber, errorMessage, isValidPhoneNumber, uniqueName } from '../../util/util';
import { ConfirmationResult, getAuth, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential, linkWithCredential } from 'firebase/auth';
import { MESSAGES } from '../../util/messages';
import * as Sentry from '@sentry/nextjs'

export default function useLogin() {
  const auth = getAuth();
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [verificationCodeResent, setVerificationCodeResent] = useState(false);
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
  const [signedIn, setSignedIn] = useState(false);

  const ERRORS = {
    ACCOUNT_ALREADY_EXISTS : "account-exists-with-different-credential",
    INVALID_VERIFICATION_CODE: "invalid-verification-code"
  }

  const signInSendPhoneNumber = async (phoneNumber: string, appVerifier: any) => {
    try {
      setVerificationCodeError("");
      const result = await signInWithPhoneNumber(auth, e164PhoneNumber(phoneNumber), appVerifier);
      setConfirmationResult(result);
      setVerificationCodeSent(true);
      return true;
    } catch (err) {
      Sentry.captureException(err);
      //If this is uncommented, recapta box will not hide after a failed verification code attempt.
      //It ends up showing two recaptchas.
      //setVerificationCodeSent(false); 
      setVerificationCodeError(err.toString());
      console.error("Error while signing in", err);
      return false;
    }
  }

  const signInSendVerificationCode = async (verificationCode: string): Promise<boolean> => {
    try {
      var credential = PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);
      // const { user } = await confirmationResult.confirm(verificationCode);
      if(auth.currentUser) {
        const { user } = await linkWithCredential(auth.currentUser, credential);
      } else {
        const {user} = await signInWithCredential(auth, credential);
      }
      setSignedIn(true);
      setVerificationCodeError("");
      return true;
    } catch (err) {
      Sentry.captureException(err);
      const message: string = errorMessage(err);
      if(message?.includes(ERRORS.ACCOUNT_ALREADY_EXISTS)) {
        setVerificationCodeError(MESSAGES.LOGIN.ACCOUNT_ALREADY_EXISTS);
      } else if(message?.includes(ERRORS.INVALID_VERIFICATION_CODE)) {
        setVerificationCodeError(MESSAGES.LOGIN.INVALID_VERIFICATION_CODE);
      } else {
        setVerificationCodeError("Error: " + message);
      }
      console.error("invalid code", err);
      return false;
    }
  }

  const resendVerificationCode = async (phoneNumber: string, appVerifier: any) => {
    setVerificationCodeResent(false);
    const result = await signInSendPhoneNumber(phoneNumber, appVerifier);
    if(result)
      setVerificationCodeResent(true);
    return result;
  }

  const clearVerificationError = () => setVerificationCodeError("");

  return { signedIn, signInSendPhoneNumber, signInSendVerificationCode, resendVerificationCode, verificationCodeSent, verificationCodeError, clearVerificationError, verificationCodeResent };
}