import { createContext, useState } from 'react'
import { Opportunity, TypesenseOpportunity, UpdateOpportunityStatus } from '../models/user';
import useOpportunity from '../hooks/user/useOpportunity';
interface Filter {
    startDate: string;
    endDate: string;
}
interface IOpportunityContext {
    opportunitys: Opportunity[];
    selectedOpportunity: Opportunity | TypesenseOpportunity;
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    setSelectedOpportunity: (opportunity: Opportunity | TypesenseOpportunity) => void;
    upsertOpportunity: (add: Opportunity, startDate?: string, endDate?: string) => Promise<boolean>;
    fetchOpportunity: (id: string) => Promise<Opportunity>;
    updateOpportunityStatus: (opportunityStatus: UpdateOpportunityStatus) => Promise<boolean>;
    deleteOpportunity: (id: string) => Promise<boolean>;
    setFilter: (filter: Filter) => void;
    filter: Filter;
}

export const OpportunityContext = createContext<IOpportunityContext>({} as IOpportunityContext);

export const OpportunityProvider = ({ children }) => {
    const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity | TypesenseOpportunity>();
    const [isModalOpen, setIsModalOpen] = useState(false);


    const { opportunitys, upsertOpportunity, fetchOpportunity, deleteOpportunity, updateOpportunityStatus, setFilter, filter } = useOpportunity();

    return (
        <OpportunityContext.Provider value={{
            opportunitys, upsertOpportunity, fetchOpportunity, updateOpportunityStatus, deleteOpportunity, setFilter, setSelectedOpportunity, selectedOpportunity, isModalOpen, setIsModalOpen, filter
        }}>
            {children}
        </OpportunityContext.Provider>
    )
}