import Link from "next/link";
import React, { useEffect, useState } from "react";
import { FaCopy, FaExternalLinkAlt, FaRegCopy } from "react-icons/fa";
import { fleatoConfig } from "../../fleato-config";
import { shortHex } from "../../util/util";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Wallet = ({ address, showFull }: { address: string, showFull?: boolean }) => {
  const [copied, setCopied] = useState(false);
  return (
    <div>
      {address && <>
        {showFull ? address : shortHex(address)} &nbsp;
        <CopyToClipboard text={address}
          onCopy={() => setCopied(true)}>
          <span className="font-sm ps-2 text-blue link" style={{ cursor: "pointer" }} >{!copied && <FaRegCopy style={{ height: 24 }} />}{copied ? "Copied!" : null}</span>

        </CopyToClipboard>
        <Link href={`${fleatoConfig.blockExplorer}/address/${address}`} passHref><a className="font-sm ps-2 text-blue link" target="_blank" rel="noreferrer">
          <FaExternalLinkAlt />{" "}
        </a></Link>
      </>}
      {!address && <></>}
    </div>
  );
};

export default Wallet;