import { LatLngTuple } from "leaflet";
import { SocialHandles } from "./dao";
import { SilaKycStatus } from "./sila";
import { Venue } from "./venue";
import { Ticket } from "./ticket";

export interface Address {
  id: string;
  fullName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phoneNumber: string;
  isDefault: boolean;
  lastUpdated: unknown;
}

export interface SupportModal {
  name:string,
  email?:string,
  comment:string,
  social?: string,
  contact?: string;
  image?:string[]
}

export enum WalletSource {
  fleato = "fleato",
  injected = "injected",
  walletConnect = "walletConnect",
  coinbase = "coinbase",
  ledger = "ledger",
  trezor = "trezor"
}

export interface ConnectedWallet {
  address: string;
  chainId: number;
  handle?: string;
  source?: WalletSource;
}

export interface SelfCustodyWallet {
  address: string;
  source?: WalletSource;
  handle?: string;
  googleAccount?: string;
  chainId: number;
  fileId?: string;
}

export interface DbUser {
  id: string;
  email?: string;
  name?: string;
  displayName?: string;
  photoUrl?: string; // dont use this and take from logged user instead, as user may change it?
  handle?: string;
  joinedOn: string;
  emailVerified: boolean;
  location?: Location;
  phoneNumber?: string;
  phoneVerified: boolean;
  signInProviders: string[];
  wallets?: string[];
  connectedWallets?: ConnectedWallet[];
  fleatoWallets?: SelfCustodyWallet[];
  devices?: string[];
  lastUpdated: string;
  lastLogin: string;
  isActive: boolean;
  silaHandle?: string;
  silaKycStatus?: SilaKycStatus;
  referralCode?: string;
  maxReferrals?: number;
}

export interface GapiUser {
  id: string;
  name: string;
  givenName: string;
  familyName: string;
  imageUrl: string;
  email: string;
}

export interface ProfileUpdateRequest {
  email?: string;
  name?: string;
  displayName?: string;
  photoUrl?: string; 
  handle?: string;
  phoneNumber?: string;
  consentForEmailUpdates?: boolean;
  referralCode?: string;
  agencyId?:string;
}
export interface FeaturedWork{
  description?:string;
  image?:string;
  name?:string;
  order?:number;
  productId?: string;
}

export interface DisplaySettings {
  showCollectionsButton?: boolean;
  artistStoreTabName?:string;
  commissionTabName?:string;
  commissionFormTitle?:string;
  HeadfontSize: number,
  bigFont: number,
  extraFontColor: string,
  fontFamily: string,
  headfontColor: string,
  mainBgColor: string,
  subHeadfontSize: number,
  subfontColor: string,
  tabsBgColor:string
  bannerOffsetYAt1920?: number;
}

export interface ArtistProfileUpdateRequest {
  id:string;
  userId:string;
  name: string;
  storeName?: string;
  tagLines?: string[];
  image?:string;
  video?:string;
  avatarImage?: string;
  bannerImage?: string;
  categories?: string[];
  description: string;
  featuredWork?:FeaturedWork[]; 
  locationLatLng?: LatLngTuple; //Same as number[], but ensures that the array contains exactly 2 items
  locationName?: string;
  region?: string;
  collections:string[];
  stake: number;
  displaySettings?: DisplaySettings;
  commission?: Commission;
  locationDescription?:string
}


export interface Commission {
  productId: string;
  tagLine: string;
}
export interface StakeInfo{
  artistId:string;
  stake:number;
  agencyId:string;
}
export interface ArtistProfile {
  id:string;
  userId:string;
  sysHandle: string;
  name?: string;
  storeName?: string;
  email?:string;
  tagLines?: string[];
  image?:string;
  video?:string;
  avatarImage?: string;
  bannerImage?: string;
  featuredBannerImage?: string;
  categories?: string[];
  description?: string;
  featuredWork?:FeaturedWork[]; 
  locationLatLng?: LatLngTuple; //Same as number[], but ensures that the array contains exactly 2 items
  locationName?: string;
  region?: string;
  collections?:string[];
  stake?: number;
  displaySettings?: DisplaySettings;
  commission?: Commission;
  locationDescription?:string;
  productId?: string;
  logo?: string;
  artistBannerImage?:string;
  bioImage?:string;
  bankAccountDetails?:BankDetails;
  socialHandles?: SocialHandles;
  phoneNumber?:string;
  shortDescription?: string;
  memberships?: ArtistMembership[];
  categoryHierarchy?: CategoryHierarchy[];
  artistTags?: string[];
  followers?: number;
  gender?:string;
  age?:string;
  raceEthnicity?:string;
  education?:string;
  income?:string;
  workHours?:string;
  termsAcknowledged?:boolean;
  agencyIds:string[];
}

export interface ResourceItem {
  name: string;
  url: string;
}
export interface Resource {
  id: string;
  name: string;
  description: string;
  coverImage: string;
  category:string;
  agencyId: string;
  resources: ResourceItem[];
}

export interface Opportunity{
  id:string;
  email:string;
  showEmail:string;
  name:string;
  date:string;
  time:string;
  discipline:string[];
  opportunityType:string;
  description:string;
  fee:string;
  link:string;
  contactInfo:string;
  sendCopy:boolean;
  status:string;
  agencyId:string;
  image?:string;
  reason?:string;
  createdAt?:string;
}
export interface TypesenseOpportunity{
  id:string;
  email:string;
  showEmail:string;
  name:string;
  date:number;
  time:string;
  discipline:string[];
  opportunityType:string;
  description:string;
  fee:string;
  link:string;
  contactInfo:string;
  sendCopy:boolean;
  status:string;
  agencyId:string;
  image?:string;
  reason?: string;
  lastUpdated: number;
  createdAt?:string;
}
export interface Event {
  id?: string;
  title: string;
  organizer: string;
  description: string;
  eventType: string;
  eventTimeType: string;
  eventCategory: string;
  image: string[];
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  locationType: string;
  venue: Venue;
  tags: string[];
  tickets: Ticket
}
export interface UpdateOpportunityStatus{
  id:string;
  status:string;
  agencyId:string;
  reason?:string;
}
export interface DownloadDetails {
  id: string;
  name?: string;
  email?: string;
  zipcode?: string;
  createdAt: string;
}
export interface CategoryHierarchy {
  lvl0?: string,
  lvl1?: string;
  lvl2?: string;
}

export interface ArtistMembership {
  agencyId: string;
  level: string;
  stake?: number;
}

export interface SellerDetails{
  id:string;
  sysHandle: string;
  userId:string;
  name?:string;
  storeName?: string;
  email?:string;
  categories?: string[];
  description?: string;
  region?: string;
  locationLatLng?: LatLngTuple;
  locationName?: string;
  artistTags?: string[];
  gender?:string;
  age?:string;
  raceEthnicity?:string;
  education?:string;
  income?:string;
  workHours?:string;
  termsAcknowledged?:boolean;
  agencyIds:string[];
  memberships:ArtistMembership[];
}
export interface SellerDetails{
  id:string;
  userId:string;
  storeName?: string;
  email?:string;
  categories?: string[];
  description?: string;
  region?: string;
  locationLatLng?: LatLngTuple;
  locationName?: string;
}

export interface BankAccountDetails{
  id:string;
  sysHandle: string;
  userId:string;
  agencyIds: string[];
  bankAccountDetails:{
  accountHolderName?:string;
  accountType?:string;
  accountNumber?:string;
  ifscCode?:string;
  routingNumber?:string;
  isIFSC?:boolean;
}
  };
  export interface BankDetails{
   
    accountHolderName?:string;
    accountType?:string;
    accountNumber?:string;
    ifscCode?:string;
    routingNumber?:string;
    isIFSC?:boolean;
  
    };

export interface SellerHomeStyle{
  id:string;
  sysHandle: string;
  userId:string;
  tagLines?: string[];
  agencyIds: string[];
  logo?: string;
  image?:string;
  video?:string;
  avatarImage?: string;
  bannerImage?: string;
}
export type CategoriesType = {
  name?: string,
  order?: number,
  description?: string,
  image?: string,
}

export interface TypeSenseUser {
  objectID: string;
  name: string;
  handle: string;
  sysHandle: string;
  wallets: string[];
  fleatoWallet: string;
  photoUrl: string;
  id: string;
}

export interface ArtistSocialHandleRequest {
  id: string;
  socialHandles: SocialHandles;
}

export interface UserUnverified {
  handle: string;
  name: string;
  email: string;
  phoneNumber: string;
  tempUserId?: string;
  claimCode?: string;
}

export interface TypesenseArtist {
  objectID: string;
  id: string;
  userId: string;
  handle: string;
  image: string;
  name: string;
  storeName: string;
  email: string;
  tagLines: string[];
  video: string;
  avatarImage: string;
  bannerImage: string;
  categories: string[];
  description: string;
  location: number[];
  region: string;
  collections: string[];
  stake: number;
  locationDescription: string;
  logo: string;
  artistBannerImage: string;
  bioImage: string;
  shortDescription: string;
  "categoryHierarchy.lvl0"?: string;
  'categoryHierarchy.lvl1'?: string;
  "categoryHierarchy.lvl2"?: string;
  tags: string[];
  agencyIds: string[];
  phoneNumber: string;
  locationName: string;
}