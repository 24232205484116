import { CustomizationChoice } from "../models/product";
import * as gtag from './analytics';

export const tagSellClicked = (userId: string) => {
}

export const tagViewItem = (listId: string, listName: string, productId: string, productName: string, price: number, categories: string[]) => {
  gtag.event("view_item", {
    currency: 'USD',
    items: [{id: productId, category: categories.filter(c => c !== "just-in")?.[0], name: productName, price: price, quantity: 1}]
  })
}

export const tagRemoveFromCart = (productId: string, productName: string, price: number, categories: string[]) => {
  gtag.event("remove_from_cart", {
    currency: 'USD', value: price,
    items: [{id: productId, category: categories.filter(c => c !== "just-in")?.[0], name: productName, price: price, quantity: 1}]
  })
}

export const tagBeginCheckout = (productId: string, productName: string, price: number, categories: string[]) => {
  gtag.event('begin_checkout', {
    currency: 'USD', value: price,
    items: [{id: productId, category: categories.filter(c => c !== "just-in")?.[0], name: productName, price: price, quantity: 1}]
  })
}

export const tagSpendVirtualCurrency = (currency: string, paymentAmount: number, productId: string) => {
  gtag.customEvent('spend_virtual_currency', {
    currency, value: paymentAmount,
    item_name: productId
  })
}

export const tagAddPaymentInfo = (paymentType: string, currency: string, paymentAmount: number, productId: string, productName: string, price: number, categories: string[]) => {
  gtag.customEvent('add_payment_info', {
    currency, value: paymentAmount, payment_type: paymentType,
    items: [{id: productId, category: categories.filter(c => c !== "just-in")?.[0], name: productName, price: price, quantity: 1}]
  })
}

export const tagViewStore = (store: string) => {
  gtag.customEvent('view_store', { item_name: store })
}

export const tagReadFAQ = (question: string) => {
  gtag.customEvent('read_faq', { item_name: question })
}

export const tagViewShortUrl = (code: string) => {
  gtag.customEvent('view_shorturl', { item_name: code })
}

export const tagPurchase = (orderNumber: string, value: number, shipping: number, tax: number, price: number, productId: string, productName: string, categories: string[]) => {
  gtag.event('purchase', {
    currency: 'USD', transaction_id: orderNumber, value, shipping, tax,
    items: [{id: productId, category: categories.filter(c => c !== "just-in")?.[0], name: productName, price, quantity: 1}]
  })
}

export const tagSearch = (query: string) => gtag.event("search", {search_term: query})

export const tagSignUp = () => gtag.event("sign_up", {});

export const tagLogin = () => gtag.event("login", {});

export const tagVisitSignUp = (referralCode: string) => {
  gtag.customEvent('visit_signup', { referralCode: referralCode })
}

export const tagVisitLogin = (referralCode: string) => {
  gtag.customEvent('visit_login', { referralCode })
}

export const tagBrowseMerch = (artist: string) => {
  gtag.customEvent('browse_merch', { artist })
}

export const tagExplore = () => {
  gtag.customEvent('explore', {  })
}

export const tagLearnMore = () => {
  gtag.customEvent('learn_more', { })
}

export const tagAbout = () => {
  gtag.customEvent('about', { })
}

export const tagCreateWallet = (wallet_type: 'fleato' | 'connected' | 'nowallet', chain_id: 137 | 80001) => {
  gtag.customEvent("create_Wallet", {
    wallet_type, chain_id
  })
}

export const tagWalletSignIn = (wallet_type: 'fleato' | 'connected' | 'nowallet', account: string, chain_id: 137 | 80001) => {
  gtag.customEvent("wallet_signin", {
    wallet_type, chain_id, account
  })
}

export const tagWalletSignOut = (wallet_type: 'fleato' | 'connected' | 'nowallet', account: string, chain_id: 137 | 80001) => {
  gtag.customEvent("wallet_signout", {
    wallet_type, chain_id, account
  })
}

export const tagConnectWallet = (account: string, wallet_type: 'fleato' | 'connected' | 'nowallet', chain_id: 137 | 80001) => {
  gtag.customEvent("connect_wallet", {
    wallet_type, chain_id, account
  })
}


export const tagPartnerCatalog = (agency: string, artist?: string) => {
  gtag.customEvent("partner_catalog", {
    agency, artist
  })
}


export const tagChooseWallet = (account: string, wallet_type: 'fleato' | 'connected' | 'nowallet', chain_id: 137 | 80001) => {
  gtag.customEvent("choose_wallet", {
    wallet_type, chain_id, account
  })
}

export const tagBuildBlueTile = () => {
  gtag.customEvent("bluetile_build", {})
}

export const tagFinalizeBlueTile = (item_name: string, success: boolean) => {
  gtag.customEvent("bluetile_finalize", {
    item_name, success
  })
}

export const tagMintBlueTile = (item_name: string, account: string, wallet_type: 'fleato' | 'connected' | 'nowallet', chain_id: 137 | 80001) => {
  gtag.customEvent("bluetile_mint", {
    item_name, wallet_type, chain_id, account
  })
}
