import { createContext } from 'react'
import useDaos from '../hooks/dao/useDaos';
import { Dao, DaoMember } from '../models/dao';

interface IDaoContext {
  daos: Dao[];
  featuredDaos: Dao[];
  daosIManage: Dao[];
  daosIJoined: Dao[];
  userAsMember: DaoMember[];
  getDaoMembers: (sysHandle: string) => Promise<DaoMember[]>;
}

export const DaoContext = createContext<IDaoContext>({} as IDaoContext);

export const DaoProvider = ({ children }) => {
  const { daos, featuredDaos, getDaoMembers, daosIManage, daosIJoined, userAsMember } = useDaos();
  return (
    <DaoContext.Provider value={{ daos, featuredDaos, getDaoMembers, daosIManage, daosIJoined, userAsMember }}>
      {children}
    </DaoContext.Provider>
  )
}