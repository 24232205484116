import { useContext, useEffect, useState } from 'react';
import { CampaignData, OrderConfirmationMessage, PublicProfile, PublicProfileByHandle, ShortUrlDoc } from '../models/product';
import { httpsCallable } from '@firebase/functions';
import { db, auth, functions } from '../firebaseutil';
import { BlockchainTransaction } from '../models/wallet';
import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { UserContext } from '../contexts/UserContext';
import { sequenceId } from '../util/util';
import * as Sentry from '@sentry/nextjs'


enum RequestType {
    subscribeToNewsletter = "subscribeToNewsletter",
    publicProfile = "publicProfile",
    updateProfile = "updateProfile",
    addWallet = "addWallet",
    registerDevice = "registerDevice",
    walletBalance = "walletBalance",
    getPublicProfile = "getPublicProfile",
  }
   
  const TXN_RECEIPTS = "txn-receipts";
  const USER = "user";
  const CAMPAIGN = "campaign";
  const Z_CONFIRMATION_MESSAGE = "z-confirmation-message";
  const URL_SHORNER = 'url-shortner';

export default function usePublicProfile(uid?: string) {
    const {dbUser} = useContext(UserContext);
    const [publicProfile, setPublicProfile] = useState<PublicProfile>();
    const [userTransactions, setUserTransactions] = useState<BlockchainTransaction[]>([]);
    const [campaignData, setCampaignData] = useState<CampaignData>(null);
    const [generalCampaign, setGeneralCampaign] = useState<CampaignData>({
        utm_campaign: "share", utm_content: "share", utm_id: "1", utm_source: "guest", utm_term: "share", handle: "guest", userId: "guest"
    });

    const [currentShortUrlItem, setCurrentShortUrlItem] = useState<ShortUrlDoc>(null);

    useEffect(() => {
        if(!!!auth?.currentUser?.uid){
          setPublicProfile(undefined);
          setUserTransactions([]);
        }
      },[auth?.currentUser?.uid]);
    
    useEffect(() => {
        if(!uid || publicProfile){
            return;
        }else{
            fetchPublicProfile(uid);
        }
    }, [uid]);

    useEffect(() => {
        if(!dbUser){
            return;
        }else{
            setGeneralCampaign({
                utm_campaign: "share", utm_content: "share", utm_id: "1", utm_source: dbUser?.handle, utm_term: "share", handle: dbUser?.handle, userId: uid
            });
        }
    }, [dbUser]);


    useEffect(() => {
        if(!uid || userTransactions?.length > 0){
            return;
        }else{
            getUserTxn(uid);
        }
    }, [uid]);

    useEffect(() => {
        if(!!auth?.currentUser?.uid && !!dbUser?.handle) {
            (async() => {
                try{
                    const q = await query(collection(db, CAMPAIGN), where("userId", "==", auth?.currentUser?.uid ?? ''));
                    const userCampaignData1: CampaignData[] = [] ;
                    const querySnapshot = await getDocs(q);
                    await querySnapshot.forEach(async(doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        await setCampaignData(doc.data() as CampaignData);
                        await userCampaignData1.push(doc.data() as CampaignData);
                    });                    
                    if(userCampaignData1?.length === 0) {
                        await setUserCampaignData();
                    }
                }catch(e) {                    
                    // console.log("getCampaignData", e);
                }        
            })()
        }
    }, [auth?.currentUser?.uid, dbUser?.handle])

    const fetchPublicProfile = async (uid?: string): Promise<PublicProfile> => {
        if(!uid)
            return {id: ""};

        try{
        const publicProfile = httpsCallable(functions, 'user-userCall');
        const userProfile = (await publicProfile({requestType: RequestType.publicProfile, input: {uid} })).data as PublicProfile;
        setPublicProfile(userProfile);
        return userProfile;
        }
        catch(err){
                Sentry.captureException(err);
                console.log(err);
        }
    }

    const fetchPublicProfileByHandle = async (handle?: string): Promise<PublicProfileByHandle> => {
        if(!handle)
            return {handle: ""};
        try{
        const publicProfile = httpsCallable(functions, 'user-userCall');
        const userProfile = (await publicProfile({requestType: RequestType.getPublicProfile, input: {handle} })).data as PublicProfileByHandle;
        return userProfile;
        }
        catch(err){
            Sentry.captureException(err);
            console.log(err);
    }
    }


    const getUserTxn = async (userId: string): Promise<BlockchainTransaction[]> => {
        const querySnapshot = await query(collection(db, USER, userId, TXN_RECEIPTS));
        const returnArray: BlockchainTransaction[] = [];
        await onSnapshot(querySnapshot, (snap) => {
            snap.forEach(item => {
                // console.log({item});
                // if(item.data().description.includes("listing")){
                //     setUserTransactions(prev => [...prev, item.data() as BlockchainTransaction])
                // }
                setUserTransactions(prev => [...prev, item.data() as BlockchainTransaction])
                returnArray.push(item.data() as BlockchainTransaction);
            });
        },(err)=>{Sentry.captureException(err);console.log(err)});
        return returnArray;        
      };      

    const setUserCampaignData = async() => {
        try{
            const id = sequenceId();
            await setCampaignData({
                handle: dbUser?.handle,
                userId: auth?.currentUser?.uid,
                utm_campaign: generalCampaign?.utm_campaign,
                utm_content: generalCampaign?.utm_content,
                utm_id: id,
                utm_source: generalCampaign?.utm_source,
                utm_term: generalCampaign?.utm_term              
            })    
            const ref = await doc(db, `${CAMPAIGN}/${id}`);
            await setDoc(ref, {
                handle: dbUser?.handle,
                userId: auth?.currentUser?.uid,
                utm_campaign: generalCampaign?.utm_campaign,
                utm_content: generalCampaign?.utm_content,
                utm_id: id,
                utm_source: generalCampaign?.utm_source,
                utm_term: generalCampaign?.utm_term,              
            }, {merge: true});
        }catch(err) {
            // console.log({setCampaignData: e});            
            Sentry.captureException(err);console.log(err)
        }
    }



    const getShortUrlByUserId = async({productId} : {productId: string}) => {
        if(!auth?.currentUser?.uid)
            return undefined;
        try{
            const q = await query(collection(db, URL_SHORNER), where('productId', '==', productId), where('userId', '==', auth?.currentUser?.uid));
            let shortUrlItem: ShortUrlDoc[] = [] ;
            const querySnapshot = await getDocs(q);
            await querySnapshot.forEach(async(doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log("hii6", " => ", doc.data());
                await setCurrentShortUrlItem(doc.data() as ShortUrlDoc);
                await shortUrlItem.push(doc.data() as ShortUrlDoc);
            });
            
            return shortUrlItem?.[0];
        }catch(e) {
            // console.log({getShortUrlByUrlHandleError: e});
            return {productId: "none"};
        }
    }

    const setShortUrlData = async(
        {productId, handle, urlEmail, shortUrl, urlFacebook, urlQrCode, urlTwitter} : 
        {productId: string, handle: string, urlQrCode: string, shortUrl: string, urlEmail, urlFacebook, urlTwitter}) => {
        try{
            // console.log({handle, shortUrl}, "hii5");
            await setCurrentShortUrlItem({
                urlEmail: urlEmail,
                shortUrl: shortUrl,
                productId: productId,
                handle: handle,
                urlFacebook: urlFacebook,
                urlQrCode: urlQrCode,
                urlTwitter: urlTwitter,
                userId: auth?.currentUser?.uid
            });    
            const ref = doc(db, `${URL_SHORNER}/${sequenceId()}`);
            const setDocs = await setDoc(ref, {
                urlEmail: urlEmail,
                shortUrl: shortUrl,
                productId: productId,
                handle: handle,
                urlFacebook: urlFacebook,
                urlQrCode: urlQrCode,
                urlTwitter: urlTwitter,
                userId: auth?.currentUser?.uid
            }, {merge: true});     
        }catch(err) {
            // console.log({setShortUrlData: e});      
            Sentry.captureException(err);console.log(err)      
        }
    }

    const getShortUrlData = async({shortUrl} : {shortUrl: string}) => {
        try{
            const q = await query(collection(db, URL_SHORNER), where('shortUrl', '==', shortUrl));
            let shortUrlItem: ShortUrlDoc ;
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setCurrentShortUrlItem(doc.data() as ShortUrlDoc);
                shortUrlItem = doc.data() as ShortUrlDoc;
            });
            return shortUrlItem;
        }catch(e) {
            console.log({getShortUrlByUrlHandleError: e});
            return undefined;
        }
    }

    const getOrderConfirmationMessage = async(id: string) => {
        if(id){
            const docRef = doc(db, Z_CONFIRMATION_MESSAGE, id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return docSnap?.data() as OrderConfirmationMessage;
              } else {
                return undefined;
              }    
        }else {
            return undefined;
        }
    }

    return {
        publicProfile, 
        fetchPublicProfile, 
        fetchPublicProfileByHandle, 
        getUserTxn, 
        userTransactions,
        campaignData,
        generalCampaign,
        setShortUrlData,
        getShortUrlByUserId,
        currentShortUrlItem,
        getShortUrlData,
        getOrderConfirmationMessage,
    }
}
