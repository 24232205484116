import "firebase/auth";
import {getFunctions, httpsCallable} from "firebase/functions";
import { AuctionWelcomeEmail, Order, OrderHeader, PrintfulData } from '../../models/order';
import { db, auth, functions } from '../../firebaseutil';
import * as Sentry from "@sentry/nextjs";
import { ItemInCart } from "../../models/event";
import { CustomizationChoice, Variation } from "../../models/product";

enum RequestTypes {
  orderForProduct = "orderForProduct",
  buyNow = "buyNow",
  buyNowMultipleItems = "buyNowMultipleItems",
  buyBluetileMerch = "buyBluetileMerch",
}

export default function useBuyNow() {

  const fetchOrderNumber = async (productId: any): Promise<string | undefined> => {
    try {
    const orderForProduct = httpsCallable(functions, 'payment-paymentCall1');
      const order = (await orderForProduct({requestType: RequestTypes.orderForProduct, productId})).data as Order;
      return order?.orderNumber;
    } catch(err) {
      Sentry.captureException(err);
      console.log(err);
      return undefined;
    }

}

  const buyNow = async ({productId, quantity, variationSku, variationOptionSku, customizationOption, createNewOrderheader, printfulData}
    : {productId: string | undefined, quantity: number, variationSku?: string, variationOptionSku?: string, customizationOption?: CustomizationChoice[], createNewOrderheader?: boolean, printfulData?: PrintfulData}): Promise<string> => {
    try {
    const buyNowCallable = httpsCallable(functions, 'payment-paymentCall1');
      // console.log("calling buyNow api with", {requestType: RequestTypes.buyNow, productId, variationSku, variationOptionSku, customizationOption, printfulData})
    const result = await buyNowCallable({requestType: RequestTypes.buyNow, productId, quantity, variationSku, variationOptionSku, customizationOption, createNewOrderheader, printfulData});
    return result.data as string;
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error while buying",err);
      return "";
    }
  }

  const buyNowMultipleItems = async (data: ItemInCart[]) => {
    try {
      const buyNowCallable = httpsCallable(functions, 'payment-paymentCall1');
        // console.log("calling buyNow api with", {requestType: RequestTypes.buyNowMultipleItems, itemsInCart: data})
      const result = await buyNowCallable({requestType: RequestTypes.buyNowMultipleItems, itemsInCart: data});
      return result.data as string;
      } catch(err) {
        Sentry.captureException(err);
        console.log("Error while buying",err);
        return "";
      }  
  }

  const buyBluetileMerch = async({productId, quantity, variationProductSku, variationSku, variationOptionSku} : {productId: string; quantity: number, variationProductSku: string; variationSku: string; variationOptionSku: string; }) => {
    try {
      const buyNowCallable = httpsCallable(functions, 'payment-paymentCall1');
        // console.log("calling buyBluetileMerch api with", {requestType: RequestTypes.buyBluetileMerch, productId, variationProductSku, variationSku, variationOptionSku})
      const result = await buyNowCallable({requestType: RequestTypes.buyBluetileMerch, productId, quantity, variationProductSku, variationSku, variationOptionSku});
      return result.data as string;
      } catch(err) {
        Sentry.captureException(err);
        console.log("Error while buying",err);
        return "";
      }  
  }

  const createDraftOrderFromInvoice = async (invoiceHeader: OrderHeader) => {
    try{
    const a = httpsCallable(functions, 'product-productCall');
    const result = await a({requestType: 'createDraftOrderFromInvoice', input: invoiceHeader});
    return result?.data as OrderHeader;
    }catch(err){
      Sentry.captureException(err);
      console.log(err);
      return null;
    }
  }

  const sendAuctionWelcomeEmail = async (data: AuctionWelcomeEmail) => {
    try{
      const a = httpsCallable(functions, 'product-productCall');
      await a({requestType: 'sendAuctionWelcomeEmail', input: data});
      }catch(err){
        Sentry.captureException(err);
        console.log(err);
        return null;
      }  
  }

  return {fetchOrderNumber, buyNow, buyBluetileMerch, buyNowMultipleItems, createDraftOrderFromInvoice, sendAuctionWelcomeEmail};
}