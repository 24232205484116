import React, { useContext, useEffect, useState, useRef } from "react";
import "react-chat-elements/dist/main.css";
import {
  MessageList,
  Input,
} from "react-chat-elements";
import { GrClose } from "react-icons/gr";
import { ChatWrapper } from "./chat-elements-style";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleChatSelector,
} from "../../store/selectors/common.selectors";
import {
  toggleChatAction,
} from "../../store/slices/common.slice";
import useChatMessages from "../../hooks/chat/useChatMessages";
import { UserContext } from "../../contexts/UserContext";
import { ChatContext } from "../../contexts/ChatContext";
import usePublicProfile from "../../hooks/usePublicProfile";

export default function ChatElements() {
  const dispatch = useDispatch();
  const toggleChat = useSelector(toggleChatSelector);
  const hideChatHandler = () => {
    dispatch(toggleChatAction(!toggleChat));
  };
  const { user, publicProfile } = useContext(UserContext);
  const { selectedProduct } = useContext(ChatContext);
  const { publicProfile: otherProfile } = usePublicProfile(selectedProduct?.seller?.id);
  const { messages, send } = useChatMessages({ userId: user?.uid, buyerId: user?.uid, sellerId: selectedProduct?.seller?.id, productId: selectedProduct?.id });

  const [reply, setReply] = useState({ id: "", text: "" });
  const inputRef = useRef(null);
  const replyHandler = (e) => {
    let value = e.target.value;
    setReply({
      id: user?.uid,
      text: value,
    });

  };
  const onSend = () => {
    if (reply?.text) {
      console.log("Sending", {
        msg: [reply],
        prevCount: messages?.length,
        buyerName: publicProfile?.handle,
        sellerName: selectedProduct?.seller?.handle,
        productImageUrl: selectedProduct?.image,
        productName: selectedProduct?.name,
        partyImageUrls: [publicProfile?.photoUrl ?? "", otherProfile?.photoUrl ?? ""]
      });
      send({
        msg: [reply],
        prevCount: messages?.length,
        buyerName: publicProfile?.handle,
        sellerName: selectedProduct?.seller?.handle,
        productImageUrl: selectedProduct?.image,
        productName: selectedProduct?.name,
        partyImageUrls: [publicProfile?.photoUrl ?? "", otherProfile?.photoUrl ?? ""]
      });
      inputRef?.current.clear();
    }
  };
  return (
    <ChatWrapper className={`${toggleChat ? "visible" : ""}`}>
      <div className="close">
        <GrClose
          onClick={hideChatHandler}
          color="#757575"
          size={18}
          className="cursor-pointer close"
        />
      </div>
      <h1 className="text-center text-blue p-4">Chat with seller</h1>
      <div className="overflow-auto">
        <div className="px-4 py-5">
          <MessageList
            className="message-list"
            lockable={true}
            toBottomHeight={"100%"}
            dataSource={messages.map((item) => ({
              ...item,
              title: item?.id === user?.uid ? "You" : selectedProduct.seller?.handle,
              position: item?.id === user?.uid ? "left" : "right",
              type: "text",
              date: item.createdAt,
            }))
              .sort((a, b) => (a.date > b.date ? 1 : -1))}
          />
        </div>
      </div>
      <div className="pb-4">
        <Input
          ref={inputRef}
          onChange={(e) => replyHandler(e)}
          placeholder="Type here..."
          rightButtons={
            <button
              className="btn blue-bg text-white"
              onClick={onSend}
            >
              Send
            </button>
          }
        />
      </div>
    </ChatWrapper>
  );
}
