import React from "react";
import Blockies from "react-blockies";

const Blockie = ({ address, scale }: { address: string, scale: number }) => {
  return (
    <>
    {!!address ? 
    <Blockies seed={address} size={5} scale={scale} color={`#${address?.substring(2, 8)}`} bgColor={`#${address?.substring(9, 15)}`} spotColor={`#${address?.substring(16, 22)}`}  className="identicon" /> :
    <Blockies seed={"qqq"} size={5} scale={scale} color={`#540`} bgColor={`#ABC`} spotColor={`#396`}  className="identicon" />
    }
    </>
  );
};

export default Blockie;