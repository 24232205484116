import { useState, useEffect } from "react";
import { SushiswapPair, SushiswapPairSettings, TradeContext, ChainId, SushiswapPairFactory } from 'simple-sushiswap-sdk';
import { TOKEN_CONTRACTS } from "../../smartcontracts/constants";
import {fleatoConfig} from "../../fleato-config";
import pRetry from 'p-retry';

export default function useSushiSwapTrader({ account }: { account?: string}) {
  const [trade, setTrade] = useState<TradeContext>();
  const contractAddress = fleatoConfig.contractSushV2Router02;
  useEffect(() => {
    if (trade) {
      //getting live prices results in rate limiting by Alchemy.
      // trade.quoteChanged$.subscribe((value: TradeContext) => setTrade(value));
      return () => trade.destroy();
    }
  }, [trade])

  const dummyAccount = "0x3B7d9c88E7705589016404B0753c71Bb7671e92F";

  const quoteTokenForToken = async (fromSymbol: string, toSymbol: string, fromAmount: number) => internalSwapTokensForToken(fromSymbol, toSymbol, fromAmount, dummyAccount);

  const swapTokensForToken = async (fromSymbol: string, toSymbol: string, fromAmount: number) => {
    if(account) return internalSwapTokensForToken(fromSymbol, toSymbol, fromAmount, account);
    else throw new Error("swapTokenForToken - recipient ethereum address required for swap");
  }

  const internalSwapTokensForToken = async (fromSymbol: string, toSymbol: string, fromAmount: number, ethereumAddress: string) => {
    try {
      // console.log("swapTokensForToken called with ", {fromSymbol, toSymbol, fromAmount});
      const sushiswapPair = new SushiswapPair({
        // the contract address of the token you want to convert FROM
        fromTokenContractAddress: TOKEN_CONTRACTS[fromSymbol as keyof typeof TOKEN_CONTRACTS],
        // the contract address of the token you want to convert TO
        toTokenContractAddress: TOKEN_CONTRACTS[toSymbol as keyof typeof TOKEN_CONTRACTS],
        // the ethereum address of the user using this part of the dApp
        ethereumAddress,
        chainId: fleatoConfig.maticChainId,
        // you can pass in the provider url as well if you want
        providerUrl: fleatoConfig.polygonRpcEndpoint,
        settings: new SushiswapPairSettings({
          // if not supplied it will use `0.005` which is 0.5%
          // please pass it in as a full number decimal so 0.7%
          // would be 0.007
          slippage: 0.02,
          // if not supplied it will use 20 a deadline minutes
          deadlineMinutes: 20,
          // if not supplied it will try to use multihops
          // if this is true it will require swaps to direct
          // pairs
          disableMultihops: false,
        }),
      });

      const sushiswapPairFactory = await sushiswapPair.createFactory();
      // console.log("calling trade with amount", fromAmount.toFixed(8))
      // const trade = await sushiswapPairFactory.trade(fromAmount.toFixed(8));

      const trade = await pRetry(() => tradeWithRetry(sushiswapPairFactory, fromAmount.toFixed(8)), {retries: 2, onFailedAttempt: async error => {
        console.log("Attempt", error.attemptNumber, "failed. There are", error.retriesLeft, "retries left.", error.message, error.stack);
      }})
  
      setTrade(trade);
      // console.log("trade", trade);
      return trade;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  const tradeWithRetry = async (sushiswapPairFactory: SushiswapPairFactory, amount: string) => {
    const trade = await sushiswapPairFactory.trade(amount);
    return trade;
  }
  

  return {sushiSwapContractAddress: contractAddress, trade, swapTokensForToken, quoteTokenForToken };
}
