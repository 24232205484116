import styled  from 'styled-components';

export const FixedFooterWrapper = styled.div`
background:#FAFAFA;
border:1px solid #EAEAEA;
padding:15px 0;
position: fixed;
bottom: 0;
right: 0;
width: 100%;
z-index: 10000;
.logo svg{max-width:80%}

`

export const FloatingFooterWrapper = styled.div`
margin-top:30px;
background:#FAFAFA;
border:1px solid #EAEAEA;
padding:15px 0;
.logo svg{max-width:80%}

`