import { constants, ethers, Signer } from "ethers";
import PhysicalSwap from "../../smartcontracts/IPhysicalNFTSwap.json";
import { TOKEN_CONTRACTS } from "../../smartcontracts/constants";
import { gasFee, tokenContract, gasFeeOptions } from "../../util/smartcontracts";
import { fleatoConfig } from "../../fleato-config";
import useSubmitToFleatoPool from "./useFleatoPool";
import { EthersTxn, Nonce } from "../../models/wallet";
import { WalletType } from "../wallet/models";
import * as Sentry from "@sentry/nextjs";
import { SwapContractApprovedState, SwapContractApprovedStates } from "../../models/blockchain";


export default function usePhysicalSwap({walletType, account, getSigner, getProvider} : {walletType: WalletType, account?: string, getProvider?: () => Promise<any>, getSigner?: () => Promise<Signer>}) {

  const {getNonce, submitTransaction} = useSubmitToFleatoPool();

  //Web3 Provider for connected providers, default provider for fleato wallet.
  const networkProvider = async (): Promise<any> => {
    if(walletType == WalletType.ConnectedWallet)
      return new ethers.providers.Web3Provider(await getProvider());
    else
      return ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);
  }

  const physicalSwapContract = async () => {
    const contractAddress = fleatoConfig.contractPhysicalSwap;
    if(walletType == WalletType.FleatoWallet) {
      const c = new ethers.Contract(contractAddress, PhysicalSwap.abi, await networkProvider());
      return c.connect(await getSigner());
    } else {
      const c = new ethers.Contract(contractAddress, PhysicalSwap.abi, (await networkProvider()).getSigner());
      return c;
    }
  }

  const readOnlyContract = async () => {
    const contractAddress = fleatoConfig.contractPhysicalSwap;
    const c = new ethers.Contract(contractAddress, PhysicalSwap.abi, await networkProvider());
    return c;
  }

  const physicalSwapContractAddress = fleatoConfig.contractPhysicalSwap;
  let feeTokenContractAddress = TOKEN_CONTRACTS.fleato;

  const nftStatus = async (chargeCode: string) => {
    const contract = await readOnlyContract();
    const status = await contract.getNFTStatus(chargeCode);
    const {nftContract, nftTokenId, nftInCustody, nftRefunded, nftWithdrawn, nftScavenged} : {nftContract: string, nftTokenId: string, nftInCustody: boolean, nftRefunded: boolean, nftWithdrawn: boolean, nftScavenged: boolean} = status;

    return {nftContract, nftTokenId, nftInCustody, nftRefunded, nftWithdrawn, nftScavenged};
  }

  const isDeposited = async (chargeCode: string): Promise<boolean> => {
    const status = await nftStatus(chargeCode);
    return status.nftInCustody || status.nftRefunded || status.nftScavenged || status.nftWithdrawn;
  }

  const chargeStatus = async (chargeCode: string) => {
    const c = await readOnlyContract();
    const chargeStatus = await c.getChargeStatus(chargeCode);
    const s = {adjudicator: chargeStatus.adjudicator,
      seller: chargeStatus.seller,
      buyer: chargeStatus.buyer,
      productCode: chargeStatus.productCode,
      chargeCode,
      approvedState: SwapContractApprovedStates[chargeStatus.approvedState]
    };
    console.log("charge status for charge code", chargeCode, s);
    return s;
  }

  const depositNFT = async (chargeCode: string): Promise<EthersTxn | Nonce> => {
    console.log("Depositing NFT to charge", {chargeCode});
    const contract = await physicalSwapContract();
    if(walletType !== WalletType.FleatoWallet) {
        const gasEstimate = await gasFee();
        console.log("gasEstimate", gasEstimate);
        const tx = await contract.depositNFT(chargeCode, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      const poolNonce = await getNonce(account);
      const chainNonce = await contract.provider.getTransactionCount(await contract.signer.getAddress(), "latest");
      console.log("poolNonce", poolNonce, "chainNonce", chainNonce);

      const nonce = Math.max(poolNonce?.pooled ?? 0, chainNonce);
      const gasEstimate = await gasFeeOptions();
      console.log("gasEstimate", gasEstimate, "chargeCode", chargeCode);
      const slowTxn = await contract.populateTransaction.depositNFT(chargeCode, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await contract.populateTransaction.depositNFT(chargeCode, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await contract.populateTransaction.depositNFT(chargeCode, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      if(!contract.signer) {
        Sentry.captureException(new Error(`usePhysicalSwap - attempting to depositNFT before wallet is loaded for ${walletType} account ${account}`));
        throw new Error("Wallet is not loaded yet. Please try again.");
      }
      const signedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastestTxn), nonce});
      console.log("slowTxn", slowTxn, "signedTx", signedTx);
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const pay = async (input: {chargeCode: string, productCode: string, receiver: string, paymentTokenContract: string, paymentNetFeeAndWithholdingAmount: number, feeAndWithholdingAmount: number, nftContract: string, nftTokenId: string}): Promise<EthersTxn | Nonce> => {
    console.log("in physicalSwap pay", input);
    //Server should monitor the contract event, and update order status.
    const {chargeCode, productCode, receiver, paymentTokenContract, paymentNetFeeAndWithholdingAmount, feeAndWithholdingAmount} = input;
    let {nftContract, nftTokenId} = input;
    //Get contract address, charge code from charge
    if(!nftContract) nftContract = constants.AddressZero;
    if(!nftTokenId) nftTokenId = "0x00";

    const contract = await physicalSwapContract();
    const buyerAddress = account ?? await contract.signer.getAddress();
    console.log("buyer", buyerAddress);


    let tx;
    try {
      if(walletType !== WalletType.FleatoWallet) {
        const gasEstimate = await gasFee();
        console.log("gasEstimate", gasEstimate);
        //Fee always paid in Fleato, and decimals is 18, so parseEther is fine
        tx = await contract.pay({chargeCode: chargeCode, productCode, seller: receiver, buyer: buyerAddress, paymentTokenContract: paymentTokenContract, paymentAmount: ethers.utils.parseUnits(paymentNetFeeAndWithholdingAmount.toFixed(2), await (await tokenContract(paymentTokenContract)).decimals()), withholdingTokenContract: fleatoConfig.contractFleatoToken, withholdingAmount: ethers.utils.parseEther(feeAndWithholdingAmount.toFixed(2)), adjudicator: fleatoConfig.addressAdjudicator, nftContract, nftTokenId}, {from: buyerAddress, maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas, gasLimit: 500000});
        return tx;
      } else {
        const nonce = Math.max((await getNonce(account))?.pooled ?? 0, await contract.provider.getTransactionCount(account, "latest"));
        const gasEstimate = await gasFeeOptions();
        console.log("gasEstimate, nonce", gasEstimate, nonce)
        const slowTxn = await contract.populateTransaction.pay({chargeCode: chargeCode, productCode, seller: receiver, buyer: buyerAddress, paymentTokenContract: paymentTokenContract, paymentAmount: ethers.utils.parseUnits(paymentNetFeeAndWithholdingAmount.toFixed(2), await (await tokenContract(paymentTokenContract)).decimals()), withholdingTokenContract: fleatoConfig.contractFleatoToken, withholdingAmount: ethers.utils.parseEther(feeAndWithholdingAmount.toFixed(2)), adjudicator: fleatoConfig.addressAdjudicator, nftContract, nftTokenId}, {from: buyerAddress, maxFeePerGas: gasEstimate.slow.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas, gasLimit: 500000});
        const fastTxn = await contract.populateTransaction.pay({chargeCode: chargeCode, productCode, seller: receiver, buyer: buyerAddress, paymentTokenContract: paymentTokenContract, paymentAmount: ethers.utils.parseUnits(paymentNetFeeAndWithholdingAmount.toFixed(2), await (await tokenContract(paymentTokenContract)).decimals()), withholdingTokenContract: fleatoConfig.contractFleatoToken, withholdingAmount: ethers.utils.parseEther(feeAndWithholdingAmount.toFixed(2)), adjudicator: fleatoConfig.addressAdjudicator, nftContract, nftTokenId}, {from: buyerAddress, maxFeePerGas: gasEstimate.fast.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas, gasLimit: 500000});
        const fastestTxn = await contract.populateTransaction.pay({chargeCode: chargeCode, productCode, seller: receiver, buyer: buyerAddress, paymentTokenContract: paymentTokenContract, paymentAmount: ethers.utils.parseUnits(paymentNetFeeAndWithholdingAmount.toFixed(2), await (await tokenContract(paymentTokenContract)).decimals()), withholdingTokenContract: fleatoConfig.contractFleatoToken, withholdingAmount: ethers.utils.parseEther(feeAndWithholdingAmount.toFixed(2)), adjudicator: fleatoConfig.addressAdjudicator, nftContract, nftTokenId}, {from: buyerAddress, maxFeePerGas: gasEstimate.fastest.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas, gasLimit: 500000});
        if(!contract.signer) {
          Sentry.captureException(new Error(`usePhysicalSwap - attempting to pay before wallet is loaded for ${walletType} account ${account}`));
          throw new Error("Wallet is not loaded yet. Please try again.");
        }
        const signedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(slowTxn), nonce});
        const fastFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastTxn), nonce});
        const fastestFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastestTxn), nonce});
        await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
        return await getNonce(account);
      }
    } catch(err) {
      console.log("Error in pay", err);
      if((err as any).data?.message)
        throw new Error((err as any).data.message);
      else if((err as any).message)
        throw new Error((err as any).message);
      else
        throw err;
    }
  }

  return {physicalSwapContractAddress, feeTokenContractAddress, pay, nftStatus, isDeposited, depositNFT, chargeStatus};
}
