import { createContext } from 'react'
import { Address } from '../models/user';
import useAddress from '../hooks/user/useAddress';

interface IAddressContext {
  addresses: Address[];
  defaultAddress: Address;
  isBusySavingAddress: boolean;
  upsertAddress: (add: Address) => Promise<Address>;
}

export const AddressContext = createContext<IAddressContext>({} as IAddressContext);

export const AddressProvider = ({ children }) => {

  const {addresses, defaultAddress, isBusySavingAddress, upsertAddress} = useAddress();

  return (
    <AddressContext.Provider value={{ 
      addresses, defaultAddress, isBusySavingAddress, upsertAddress }}>
      {children}
    </AddressContext.Provider>
  )
}