import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import moment from "moment";
import { CryptoPayments } from "../models/payment";
import { Listing, TypesenseProduct } from "../models/product";
import { Address } from "../models/user";
import { RecentBalance } from "../models/wallet";
import {
  uniqueNamesGenerator,
  NumberDictionary,
  animals,
} from "unique-names-generator";
import slug from "slug";
import { cities } from "./dictionaries";
import { fleatoConfig } from "../fleato-config";
import { v4 as uuid } from "uuid";
import STOP_WORDS from "./stop_words.json";
import { isMobileOnly } from "react-device-detect";

export const isValidPhoneNumber = (number: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedPhoneNumber = phoneUtil.parse(number);
    return phoneUtil.isValidNumber(parsedPhoneNumber);
  } catch (err) {
    return false;
  }
};

export const shuffle = (arr) => {
  const newArr = arr.slice();
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
};

export const isValidEmailAddress = (email: string) => {
  const specialCharactersAndNumbers = /[!@#$%^&*(),.?":{}|<>0-9]/;
  if (!email || specialCharactersAndNumbers.test(email.slice(-1))) return false;
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    email
  );
};

export const e164PhoneNumber = (number: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedPhoneNumber = phoneUtil.parse(number, "US");
    //This can still return partial/incomplete numbers, just formatted not validated
    return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);
  } catch (err) {
    return "";
  }
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const snapshotToArray = (snapshot: any) => {
  var returnArr: unknown[] = [];

  snapshot.docs.forEach((childSnapshot: any) => {
    var item = childSnapshot.data();
    item.id = childSnapshot.id;

    returnArr.push(item);
  });
  return returnArr;
};

export const formatAmount = (amount: number) => {
  if (parseFloat(amount.toFixed(0)) == amount) return amount.toFixed(0);
  else return amount.toFixed(2);
};

export const shorten = (str?: string, maxLen: number = 3) => {
  return str
    ? str.length > maxLen
      ? str.substring(0, maxLen) + "..."
      : str
    : "";
};

export const smartShorten = (str?: string) => {
  //return first 50 chars, and any non-space characters immediately following it until next space is reached
  return str
    ? str.length > 100
      ? str.replace(/^(.{100}[^\s]*).*/, "$1") + "..."
      : str
    : "";
};

export const smartShorten50 = (str?: string) => {
  //return first 50 chars, and any non-space characters immediately following it until next space is reached
  return str
    ? str.length > 50
      ? str.replace(/^(.{50}[^\s]*).*/, "$1") + "..."
      : str
    : "";
};

export const smartShorten25 = (str?: string) => {
  //return first 50 chars, and any non-space characters immediately following it until next space is reached
  return str
    ? str.length > 25
      ? str.replace(/^(.{25}[^\s]*).*/, "$1") + "..."
      : str
    : "";
};

//https://firebasestorage.googleapis.com/v0/b/fleato-listing/o/211020%2F1634779595570-13829-pens4-jpg?alt=media&token=b870dcc5-87b8-459e-a70c-b5d8842cec09
export const friendlyFileNameFromImageUrl = (url: string): string => {
  const relativeUrl = url.split("/").pop();
  const noQueryParams = relativeUrl?.split("?")[0];
  const noExcapedSlash = noQueryParams?.split("%2F").pop();
  const noTimestampPrefix = noExcapedSlash.split("-").slice(1).join("-");
  const decoded = decodeURI(noTimestampPrefix ?? "");
  const extn = decoded.split("-").pop();
  const baseName = decoded.split("-").slice(0, -1).join("-");
  return `${baseName}.${extn}`;
};

export const clone = <T>(obj: T) => {
  return JSON.parse(JSON.stringify(obj)) as T;
};

export const longDateTime = (date: string) =>
  moment(date).format("ddd MM/DD/YYYY hh:mm A");

export const zeroDecimals = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? ""
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(0)
    : amt.toFixed(0);

export const zeroOrMoreDecimals = (amt: number | string | undefined) =>
  parseFloat(zeroDecimalsFixed(amt)) > 100
    ? zeroDecimalsFixed(amt)
    : twoDecimalsFixed(amt);

export const zeroDecimalsFixed = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? "0"
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(0)
    : amt.toFixed(0);

export const twoDecimals = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? ""
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(2)
    : amt.toFixed(2);

export const fourDecimals = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? ""
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(4)
    : amt.toFixed(4);

export const sixDecimals = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? ""
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(6)
    : amt.toFixed(6);

export const eightDecimals = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? ""
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(8)
    : amt.toFixed(8);

export const twoDecimalsFixed = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? "0.00"
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(2)
    : amt.toFixed(2);

export const threeDecimalsFixed = (amt: number | string | undefined) =>
  amt === undefined || amt === "NaN"
    ? "0.00"
    : typeof amt == "string"
    ? parseFloat(amt).toFixed(3)
    : amt.toFixed(3);

export const onelineAddress = (address?: Address) =>
  address
    ? `${address.fullName}, ${address.address1} ${
        address.address2 ? ", " + address.address2 : ""
      }, ${address.city}, ${address.state} ${address.zip}`
    : "";

export const productDetailsPrice = (amt: number | string | undefined) =>
  amt === null || amt === undefined || amt === "NaN"
    ? ""
    : typeof amt == "string"
    ? parseFloat(amt) === Math.floor(parseFloat(amt))
      ? parseFloat(amt)
      : parseFloat(amt).toFixed(2)
    : amt === Math.floor(amt)
    ? amt
    : amt.toFixed(2);

export const recentFleatoWallet = (wallets?: string[]): string | undefined => {
  if (hasFleatoWallet(wallets)) {
    const last = wallets!.length - 1;
    return wallets![last];
  } else {
    return undefined;
  }
};

export const hasFleatoWallet = (wallets?: string[] | undefined): boolean =>
  (wallets?.length ?? 0) > 0;

export const recentEntry = (array: any[]): any => {
  if (!array?.length) return undefined;
  const last = array!.length - 1;
  return array![last];
};

export const shortHex = (hex: string) => {
  return typeof hex === "string" && hex.length > 0
    ? `${hex.slice(0, 6)}...${hex.slice(-4)}`
    : "";
};

export const num = (n: string | number | undefined) =>
  n === undefined ? 0 : typeof n == "string" ? parseFloat(n) : n;

export const str = (s: string | number | undefined) =>
  s === undefined ? "" : typeof s == "string" ? s : s.toString();

export const maxBalanceInSingleToken = (balance: RecentBalance | undefined) =>
  Math.max(
    num(balance?.bitcoin?.v),
    num(balance?.ethereum?.v),
    num(balance?.fleato.v),
    num(balance?.usdc?.v)
  );

export const cryptoPaymentSummaryString = (payment: CryptoPayments) => {
  if (!payment) return undefined;
  const b = `${
    payment?.fleato?.v
      ? `fleato $${zeroOrMoreDecimals(payment.fleato.v)}, `
      : ""
  }${
    payment?.usdc?.v ? `usd coin $${zeroOrMoreDecimals(payment.usdc.v)}, ` : ""
  }${
    payment?.bitcoin?.v
      ? `bitcoin $${zeroOrMoreDecimals(payment.bitcoin.v)}, `
      : ""
  }${
    payment?.ethereum?.v
      ? `ethereum $${zeroOrMoreDecimals(payment.ethereum.v)}, `
      : ""
  }${
    (payment?.matic?.v ?? 0) > 1
      ? `polygon $${zeroOrMoreDecimals(payment?.matic?.v)}, `
      : ""
  }`;
  return b ? b.slice(0, -2) : b;
};

export const cryptoPaymentTokensString = (payment: CryptoPayments) => {
  if (!payment) return undefined;
  const b = `${payment?.fleato?.v ? `fleato, ` : ""}${
    payment?.usdc?.v ? `usd coin, ` : ""
  }${payment?.bitcoin?.v ? `bitcoin, ` : ""}${
    payment?.ethereum?.v ? `ethereum, ` : ""
  }${
    (payment?.matic?.v ?? 0) > 1
      ? `polygon $${zeroOrMoreDecimals(payment?.matic?.v)}, `
      : ""
  }`;
  return b ? b.slice(0, -2) : b;
};

export function titleCase(str: string) {
  if (!str) return "";
  return str
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function errorMessage(err: any) {
  return (err?.message ?? err?.toString() ?? "Unknown Error").replace(
    "FirebaseError: ",
    ""
  );
}

export const availableQuantity = (listing: Listing) =>
  listing.quantity - (listing.soldQuantity ?? 0); // - (listing.reservedQuantity ?? 0);

export const bankersRound = (n: number, d: number = 2) => {
  var x = n * Math.pow(10, d);
  var r = Math.round(x);
  var br = (x > 0 ? x : -x) % 1 === 0.5 ? (0 === r % 2 ? r : r - 1) : r;
  return br / Math.pow(10, d);
};

export const uniqueName = () => {
  const numberDictionary = NumberDictionary.generate({ min: 10, max: 99 });
  const shortName = uniqueNamesGenerator({
    dictionaries: [cities, numberDictionary], // colors can be omitted here as not used
    separator: "",
    style: "capital",
    length: 2,
  });
  return slug(shortName);
};

export const randomShortUrl = () => {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 20; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const numberKey = (orderId: number) => String(orderId).padStart(9, "0");

export const sequenceId = () => {
  const start = new Date("2021-01-01");
  const now = new Date();
  const elapsed = Math.floor((now.getTime() - start.getTime()) / 1000);
  return numberKey(elapsed);
};

export const initials = (name: string) => {
  let returnName = "";
  for (let i = 0; i < name?.length; i++) {
    if (i > 0 && name[i - 1] === " " && name[i] != " ") {
      returnName = returnName + name[i];
    } else if (i === 0 && name[i] != " ") {
      returnName = returnName + name[i];
    }
  }
  const length = 2;

  var trimmedName =
    returnName.length > length ? returnName.substring(0, 2) : returnName;

  return trimmedName.toUpperCase();
};

export const productUrl = (image: string, size: number = 680) => {
  if (!image) return image;
  if (image.includes("680") && image.includes("thumbnails")) return image;
  else if (image.includes("token=")) return image; //specific permissions, suffixing to point to a new file wont work
  const unescaped = image?.split("%2F")?.join("/");
  if (
    fleatoConfig.imageOptimizedUrls.some((item) => unescaped?.includes(item))
  ) {
    const path = unescaped.split("/").slice(0, -1).join("/");
    const file = unescaped.split("/").pop();
    let path1 = path;
    if (path?.split("/").pop()?.length === 36) {
      path1 = unescaped.split("/").slice(0, -2).join("/");
    }
    let newName;

    if (file?.includes("."))
      newName =
        file.split(".").slice(0, -1).join(".") +
        `_${size ?? 680}x${size ?? 680}.` +
        file.split(".").pop();
    else if (file?.includes("?"))
      newName =
        file.split("?").slice(0, -1).join("?") +
        `_${size ?? 680}x${size ?? 680}?` +
        file.split("?").pop();
    else newName = file + `_${size ?? 680}x${size ?? 680}`;
    return `${path1}/thumbnails/${newName}`;
  } else return image;
};

export const FLEATO_ENVIRONMENTS = {
  DEV: "development",
  PROD: "production",
};

export const getSysHandle = (handle: string) => {
  return handle?.replace(" ", "_");
};

export const productDetailUrl = (product: TypesenseProduct) => {
  // console.log("getting url for product", product);
  return product?.isMerchandise
    ? isMobileOnly && !product?.isCustomizable
      ? `/sv/${product?.productSku}/${product?.sku}`
      : `/p/${product?.productSku}/${product?.sku}`
    : isMobileOnly && !product?.isCustomizable
    ? `/sv/${product?.id}`
    : `/p/${product?.id}${product?.isCustomizable ? "?customize=true" : ""}`;
};

export const uniqueId = () => uuid();

export function queryWithoutStopWords(query) {
  const words = query.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").split(" ");
  return words
    .map((word) => {
      if (
        STOP_WORDS.includes(word.toLowerCase()) ||
        word.toLowerCase()?.length <= 2
      ) {
        return null;
      } else {
        return word;
      }
    })
    .filter((w) => w)
    .join(" ")
    .trim();
}

export const primitives = (source: any) => {
  var target = {};
  if (source) {
    for (var key in source) {
      var prop = source[key],
        type = typeof prop;
      if (type === "string" || type === "number" || type === "boolean") {
        target[key] = prop;
      }
    }
  }
  return target;
};

export const objectArrayToCSV = (array: any[]) => {
  let result;
  if (!array?.length) return "";
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += Array.isArray(item[key]) ? item[key].length : item[key];
      // eslint-disable-next-line no-plusplus
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};
