import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import "firebase/auth";
import { collection, query, onSnapshot, doc, getDoc, getDocs, limit, where, setDoc, orderBy } from "firebase/firestore";
import { ArtistProfile, ArtistProfileUpdateRequest, ArtistSocialHandleRequest, BankAccountDetails, SellerDetails, SellerHomeStyle, StakeInfo} from '../../models/user';
import { db, auth, functions } from '../../firebaseutil';
import { fleatoConfig } from '../../fleato-config';
import { httpsCallable } from 'firebase/functions';
import { User } from 'firebase/auth';
import * as Sentry from '@sentry/nextjs'

export const ARTISTS = "artists";
export const UPSERTARTISTPROFILE = "upsertArtistProfile";
export const UPSERTARTISTSTAKE='upsertArtistStake';
export const UPSERTARTISTSOCIALHANDLES = "upsertArtistSocialHandles";

export const profileTemplate: ArtistProfile = {
  id: "template",
  sysHandle: "template",
  name: "",
  description: "",
  stake: 0,
  email:"",
  userId: auth?.currentUser?.uid,
  bannerImage: `${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-banner.jpg`,
  avatarImage: `${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-avatar.jpg`,
  image: `${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-avatar.jpg`,
  tagLines: [],
  collections: [],
  categories: [],
  storeName: "",
  locationName: "",
  region: "",
  featuredWork: [],
  bioImage:'',
  artistBannerImage:'',
  logo:`${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-avatar.jpg`,
  agencyIds:[],
  memberships:[]
}

export default function useArtists(user: User | null) {
  const [allArtists, setAllArtists] = useState<ArtistProfile[]>([]);
  const [featuredArtists, setFeaturedArtists] = useState<ArtistProfile[]>([]);
  const [isBusy,setIsBusy]=useState(false);

  const appendAddAllArtist = (cat: ArtistProfile) => {
    if(cat.image !== "https://cdn.fleato.org/artist%2Ftemplate-avatar.jpg")
      setAllArtists(prev => [...prev, cat]) 
  }

  const appendUpdateAllArtist = (cat: ArtistProfile) => {
    if(cat.image !== "https://cdn.fleato.org/artist%2Ftemplate-avatar.jpg")
      setAllArtists(prev => [...prev.filter(pk => pk.id !== cat.id), cat]) 
  }

  useEffect(()=> {
    if(allArtists?.length>0)
      setFeaturedArtists([...allArtists].filter(a => (a.stake ?? 0) > 10).sort((a,b) => (b.stake ?? 0) - (a.stake ?? 0))?.slice(0, 10));
  }, [allArtists])
  

  useEffect(() => {
      const q = query(collection(db, `${ARTISTS}`), where("stake", ">", 0), orderBy("stake", "desc"), limit(100));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const localMessages = [];
        snapshot.docChanges().forEach((change) => {
          if(change.type == "added") {
            // console.log("artist added", change.doc.id);
            appendAddAllArtist(change.doc.data() as ArtistProfile);
          } else if(change.type == "modified") {
            // console.log("artist modified", change.doc.id);
            appendUpdateAllArtist(change.doc.data() as ArtistProfile);
          } else if(change.type == "removed") {
            // console.log("artist removed", change.doc.id);
            setAllArtists(prev => prev.filter(p => p.userId !== change.doc.data().userId))
          }
        })
      },(err)=>{Sentry.captureException(err);console.log(err)});
      return unsubscribe;
  }, [user])

  const fetchArtist = async (id: string): Promise<ArtistProfile> => {
    // return allArtists?.find(value => value.id===id);
    const artistRef = doc(db, `${ARTISTS}/${id}`);
    const shortUrlSnapshot = await getDoc(artistRef);
    return shortUrlSnapshot.data() as ArtistProfile;
  }

  const updateSellerDetails=async(sellerDetails:SellerDetails)=>{
    await updateArtistDetails(sellerDetails);
  }
  const updateArtistStakeOrder=async({artistId,stake,agencyId}:StakeInfo)=>{
    await updateArtistStake(artistId,stake,agencyId);
  }
  const updateSellerHomeStyle= async(sellerHomeStyle:SellerHomeStyle)=>{
    await updateArtistDetails(sellerHomeStyle);
  }
  const updateBankDetails=async(bankDetails:BankAccountDetails)=>{
    await updateArtistDetails(bankDetails);
  }
  
  const updateArtistDetails = async ( artistDetails: ArtistProfile): Promise<ArtistProfile> => {
    
    if(artistDetails.userId) {
      try{
      const userCallable = httpsCallable(functions, 'user-userCall');
      const result = await userCallable({requestType: UPSERTARTISTPROFILE, input: artistDetails});
      const response = result.data as ArtistProfile;
      return response;
      }
      catch(err){
        Sentry.captureException(err);
        console.log(err);
        return undefined;
      }
    }
    return artistDetails;
  }
  const updateArtistStake = async ( artistId: String,stake:number,agencyId:String)=> {
    if(artistId) {
      try{
      const userCallable = httpsCallable(functions, 'user-userCall');
      const result = await userCallable({requestType: UPSERTARTISTSTAKE, input: artistId,stake,agencyId});
      }
      catch(err){
        Sentry.captureException(err);
        console.log(err);
        return undefined;
      }
    }
  }
 
  const updateArtistSocialHandles = async(input: ArtistSocialHandleRequest) => {
    try{
    const userCallable = httpsCallable(functions, 'user-userCall');
    const result = await userCallable({requestType: UPSERTARTISTSOCIALHANDLES, input: input});
    const response = result.data as any;
    return response;
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  
  return {
    allArtists, featuredArtists,
    fetchArtist, updateArtistDetails,updateSellerDetails,updateArtistStake: updateArtistStakeOrder,
    updateSellerHomeStyle,updateBankDetails, updateArtistSocialHandles};
}