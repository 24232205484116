import styled from "styled-components";

export const RedeemModalWrapper = styled.div`
background: #fff;
h3 {
}
border-radius: 0px;
padding: 20px 40px;
.close {
  right: 10px;
  cursor: pointer;
  top: 10px;
}
table {
  border-color: #707070;
}
`;