import {useState} from "react";
import {httpsCallable} from "firebase/functions";
import { functions } from '../../firebaseutil';
import { Nonce, SignedTxnRequest } from "../../models/wallet";
import * as Sentry from '@sentry/nextjs'

enum RequestTypes {
  submitSignedTxn = "submitSignedTxn",
  getNonce = "getNonce"
}

export default function useSubmitToFleatoPool() {
  const [isLoading, setIsLoading] = useState(false);
  const submitTransaction = async (request: SignedTxnRequest) => {
    try {
      const userCallable = httpsCallable(functions, 'user-userCall');
      setIsLoading(true);
      const result = await userCallable({requestType: RequestTypes.submitSignedTxn, input: request});
      setIsLoading(false);
      return;
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error submitting transaction to fleato pool",err);
      throw(err);
    }
  }

  const getNonce = async (address: string): Promise<Nonce> => {
    try {
    const userCallable = httpsCallable(functions, 'user-userCall');
      setIsLoading(true);
      const result = await userCallable({requestType: RequestTypes.getNonce, input: {address}});
      const nonce = result.data as Nonce;

      setIsLoading(false);
      return nonce;
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error submitting transaction to fleato pool",err);
      throw(err);
    }
  }

  return {isLoading, submitTransaction, getNonce};
}
