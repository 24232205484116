import React, { useContext, useEffect, useState, useRef } from "react";
import "react-chat-elements/dist/main.css";
import { GrClose } from "react-icons/gr";
import { ChatWrapper } from "./topics-chat-elements-style";
import { useDispatch, useSelector } from "react-redux";
import { toggleChatTopicsSelector } from "../../store/selectors/common.selectors";
import { toggleChatTopicsAction } from "../../store/slices/common.slice";
import useChatTopics from "../../hooks/chat/useChatTopics";
import { UserContext } from "../../contexts/UserContext";
import { ChatContext } from "../../contexts/ChatContext";
import { IoIosArrowRoundBack } from "react-icons/io";
import useProduct from "../../hooks/product/useProduct";
import logo from "../../public/images/logo.svg";
import moment from "moment";
import Image from "next/image";
import MessageListView from "./MessageListView";
import { useMedia } from "../../hooks/helper/useMedia";
import { shorten } from "../../util/util";
import { ChatTopic } from "../../models/chat";

export default function ChatElements() {
  const dispatch = useDispatch();
  const [chatGroup, setchatGroup] = useState(true);
  const [chat, setChat] = useState(false);
  const toggleChatTopics = useSelector(toggleChatTopicsSelector);
  const { user } = useContext(UserContext);
  const { setSelectedProduct, selectedTopic, setSelectedTopic, selectedOrder } = useContext(ChatContext);
  const { topics } = useChatTopics(user?.uid);
  const { fetchProduct } = useProduct();
  const hideChatTopicHandler = () => {
    dispatch(toggleChatTopicsAction(!toggleChatTopics));
  };
  const desktop = useMedia("(min-width:992px)");
  const topicHandler = async (item: ChatTopic) => {
    setSelectedTopic(item);
    const product = await fetchProduct(item.productId);
    setSelectedProduct(product);
    chatHandler();
  };

  useEffect(() => {
    if (topics?.length) {
      (async () => {
        if (selectedOrder)
          setSelectedTopic(topics.find(t => t.productId == selectedOrder.productId && t.partyIds[0] == selectedOrder.buyerId && t.partyIds[1] == selectedOrder.sellerId) ?? topics[0]);
        else
          setSelectedTopic(topics[0]);
        const product = await fetchProduct(topics[0].productId);
        setSelectedProduct(product);
      })();
    }
  }, [topics, selectedOrder]);

  const chatHandler = () => {
    setChat(true);
    setchatGroup(false);
  };
  const ChatGroupView = () => {
    return (
      <div className={`${desktop ? 'w-1' : ''} col-md-12 col-lg-4  position-relative`}>
        <div className="overflow-auto left chat-topics">
          <div className="px-2 py-2">
            <ul className="list-unstyled">
              {topics?.map((item) => (
                <li
                  key={`${item?.productId}-${item?.partyIds.join("-")}`}
                  className={`pb-2 cursor-pointer `}
                  onClick={() => topicHandler(item)}
                >
                  <div className="row">
                    <div className="col-8 col-md-8 col-8 col-md-8 d-flex align-items-center py-2">
                      <h3
                        className={`
                ${selectedTopic?.productId?.toString() ===
                            item?.productId?.toString()
                            ? "text-blue"
                            : "text-grey"
                          } mb-0 `}
                      >
                        {shorten(item?.productName, 80)}
                      </h3>
                      <span
                        className="ps-3  d-block d-lg-none text-blue"
                        onClick={chatHandler}
                      >
                        Detail
                      </span>
                    </div>
                    <div className="col-4 col-md-4 py-2 text-end">
                      {moment(item.lastMessageAt).fromNow()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8 col-md-9">
                      <p className="mb-1" onClick={chatHandler}><span className="pe-2 font-bold">{item?.lastMessageFrom === user?.uid ? "You :" : item?.lastMessageFrom === item?.partyIds[0] ? `${item.partyNames[0]} :` : `${item.partyNames[1]} :`}
                      </span>{item?.lastMessage} </p>
                    </div>
                    <div className="col-4 col-md-3 text-end pe-4">
                      {!item?.productImageUrl && <Image src={logo} alt="logo" />}
                      {!!item?.productImageUrl && <Image src={item?.productImageUrl} alt="product-image" width={50} height={50} />}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ChatWrapper className={`${toggleChatTopics ? "visible" : ""} `}>
      <div className="close">
        <GrClose
          onClick={hideChatTopicHandler}
          color="#757575"
          size={18}
          className="cursor-pointer close"
        />
      </div>
      <h1 className="text-center p-3 white-bg mb-0">
        {!desktop && chat && (
          <IoIosArrowRoundBack
            onClick={() => {
              setChat(false);
              setchatGroup(true);
            }}
          />
        )}
        Conversations
      </h1>
      <div className="row main-wrap m-0" style={{ backgroundColor: "white !important" }}>
        {!desktop && chatGroup ? (
          <ChatGroupView />
        ) : !desktop && chat ? (
          <div className="col-md-12   position-relative">
            <MessageListView selectedTopic={selectedTopic} />
          </div>
        ) : (
          ""
        )}

        {desktop && <ChatGroupView />}
        {desktop && (
          <div className=" d-none d-lg-block col-lg-8 position-relative white-bg w-4">
            <MessageListView selectedTopic={selectedTopic} />
          </div>
        )}
      </div>
    </ChatWrapper>
  );
}
