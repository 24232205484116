import { createContext } from 'react'
import useAgencies from '../hooks/agency/useAgencies';
import { Agency, AgencyCreateRequest, AgencyUpdateRequest, AuctionUpdateRequest, AuctionCreateRequest, ChangeAuctionThemeRequest, AddArtistToAuctionRequest, AddProductToAuctionRequest } from '../models/agency';

interface IAgenciesContext {
  agencies: Agency[];
  createAgency: (a: AgencyCreateRequest) => Promise<string>;
  createAuction: (a: AuctionCreateRequest) => Promise<string>;
  updateAgency: (a: AgencyUpdateRequest) => Promise<string>; 
  updateAuction: (a: AuctionUpdateRequest) => Promise<string>;
  changeAuctionTheme: (a: ChangeAuctionThemeRequest) => Promise<string>;
  addArtistsToAuction: (a: AddArtistToAuctionRequest[]) => Promise<string>;
  removeArtistsFromAuction: (a: AddArtistToAuctionRequest[]) => Promise<string>;
  addProductsToAuction: (a: AddProductToAuctionRequest[]) => Promise<string>;
  removeProductsFromAuction: (a: AddProductToAuctionRequest[]) => Promise<string>;
}

export const AgenciesContext = createContext<IAgenciesContext>({} as IAgenciesContext);

export const AgenciesProvider = ({ children }) => {  
  const { agencies, createAgency, createAuction, 
    updateAgency, updateAuction, changeAuctionTheme,
    addArtistsToAuction, addProductsToAuction, removeArtistsFromAuction, 
    removeProductsFromAuction } = useAgencies();
  
  return (
    <AgenciesContext.Provider value={{ 
      agencies , createAgency, createAuction, 
      updateAgency, updateAuction, changeAuctionTheme, 
      addArtistsToAuction, addProductsToAuction, removeArtistsFromAuction,
      removeProductsFromAuction,
    }}>
      {children}
    </AgenciesContext.Provider>
  )
}