import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import logo from "../../../public/images/blacklogo.svg";
import { tagAbout, tagSellClicked } from "../../../lib/events";
import { DropdownMenu } from "../../../components/menu/dropdown/dropdownMenu";
import { DropdownMenuWrapper } from "../../../components/menu/dropdown/dropdown-style";
import { UserContext } from "../../../contexts/UserContext";
import { ArtistContext } from "../../../contexts/ArtistContext";

const BADGE_ID = 'e5cca14d0f580a5a';
let ALCHEMY_URL = `https://alchemyapi.io/?r=badge:${BADGE_ID}`;
const ALCHEMY_ANALYTICS_URL = `https://analytics.alchemyapi.io/analytics`

function logBadgeClick() {
	    fetch(`${ALCHEMY_ANALYTICS_URL}/badge-click`, {
    method: 'POST',
	    	headers: {
	        	'Content-Type': 'application/json',
	      	},
		    body: JSON.stringify({
			    badge_id: BADGE_ID,
    }),
  });
	    window.open(ALCHEMY_URL, '_blank').focus();
}

function logBadgeView() {
  try {
	    fetch(`${ALCHEMY_ANALYTICS_URL}/badge-view`, {
    method: 'POST',
	    	headers: {
	        	'Content-Type': 'application/json',
	      	},
		    body: JSON.stringify({
			    badge_id: BADGE_ID,
    }),
  });
  } catch(e) {
    console.log("unable to log badge view");
  }
}

function isBadgeInViewpoint(bounding) {
	    return (
	        bounding.top >= 0
	        && bounding.left >= 0
	        && bounding.bottom <= (window.innerHeight || window.document.documentElement.clientHeight)
	        && bounding.right <= (window.innerWidth || window.document.documentElement.clientWidth)
	    );
}

export const Footer = (props: any) => {
  const { user, isVerifiedUser } = useContext(UserContext);
  const { isSeller } = useContext(ArtistContext);
  const [current, setcurrent] = useState("");
  const { openRedeemModal } = props;
  const [localUser, setLocalUser] = useState({
    userName: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    var intervalId = setInterval(() => {
      const badge = document.getElementById('badge-button');
      if (badge && isBadgeInViewpoint(badge.getBoundingClientRect())) {
        logBadgeView();
        clearInterval(intervalId);
      }
    }, 2000);
    return () => clearInterval(intervalId);
  })

  const [toggleDropDown, setToggleDropDown] = useState(false);

  const signInSignUpProfileUpdate = (isSigningUp: boolean) => {
    if (user === null && (!localUser?.phone || localUser?.phone?.length === 0))
      props.openModal(isSigningUp);
    else {
      toggleDropDownMenu();
    }
  }

  const toggleDropDownMenu = () => {
    setToggleDropDown(!toggleDropDown);
  }

  const signInSignUp = (isSigningUp: boolean) => {
    props.openModal(undefined, isSigningUp);
  }

  useEffect(() => {
    (async () => {
      const userName = await localStorage.getItem("userName");
      const email = await localStorage.getItem("email");
      const phone = await localStorage.getItem("phone");
      await setLocalUser({
        userName,
        email,
        phone
      })
    })()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 d-flex align-items-center justify-content-center justify-content-md-start">
          <ul className="list-unstyled d-flex  m-0 font-md">

            <li className="p-1 pe-md-2 ">
              <Link href="/categories/all" passHref><a className="bold">Explore</a></Link>
            </li>

            <li className="p-1 pe-md-2">
              <Link href="https://flywith.fleato.com" passHref>
                {/* <Link href="/sell/new"> */}
                <a className="bold">
                  <span onClick={() => tagSellClicked(user?.uid ?? "guest")} >Sell</span></a></Link>
            </li>
            <li className="p-1 pe-md-2 cursor-pointer" onClick={() => signInSignUpProfileUpdate(false)}>
              {toggleDropDown && <DropdownMenuWrapper
                className={current === "profile" ? "visible" : "visible"}
              >
                {
                  <DropdownMenu openRedeemModal={openRedeemModal} isVerifiedUser={isVerifiedUser} isSeller={isSeller} signInSignUp={signInSignUp} />
                }
              </DropdownMenuWrapper>}
              <>
                {user === null && (localUser?.phone?.length === 0 || localUser?.phone) &&
                  <> <Link href="/" passHref >
                    <a onClick={() => signInSignUpProfileUpdate(false)}>Login</a>
                  </Link>
                    <span className="mx-2">|</span>
                    <Link href="/" passHref>
                      <a onClick={() => signInSignUpProfileUpdate(true)}>Sign up</a>
                    </Link></>
                }

              </>
            </li>
          </ul>
        </div>
        <div className="col-md-4 text-center d-flex justify-content-center logo" style={{ marginBottom: 0 }}>
          {/* <span>
            <Link href="/" passHref>
              <a className="d-flex align-items-center" style={{ justifyContent: 'center' }}>

                <ReactSVG src={logo.src} alt="Logo" />

                <p className="mb-0 mx-2 bold" style={{ fontSize: 22 }}>Fleato</p>
              </a>
            </Link>
          </span> */}

            <span className="btn">
              {/* <a href="#">
                  <img onClick={() => logBadgeClick()} id="badge-button" style={{width:"240px", height: "53px"}} src="https://static.alchemyapi.io/images/marketing/badgeLight.png" alt="Alchemy Supercharged" />
              </a>               */}
              <span className="font-md-bold" style={{ fontSize: '0.8rem' }}>
                Powered by
              </span>
              <span className="bold"> Fleato Labs</span>
            </span>
        </div>
        <div className="col-md-4 justify-content-center justify-content-md-evenly d-flex align-items-center font-md">
          <p className="m-0  text-black text-center">
            <span className="bold">
              <Link href="https://flywith.fleato.com/about" passHref>
                <a style={{ fontSize: '0.8rem !important' }}
                  target="_blank"
                  onClick={() => tagAbout()}
                >
                  About us
                </a>
              </Link>
              <span className="mx-2">| </span>

              <Link href="/support" passHref>
                <a style={{ fontSize: '0.8rem !important' }}
                  target="_blank"
                >
                  Customer Support
                </a>
              </Link>
              <span className="mx-2">| </span>

              <Link href="/privacy" passHref>
                <a style={{ fontSize: '0.8rem !important' }} >Privacy</a>
              </Link>
              <span className="mx-2">| </span>

              <Link href="/terms" passHref>
                <a style={{ fontSize: '0.8rem !important' }}>Terms</a>
              </Link>


            </span><br></br>
            All Rights Reserved © 2022

          </p>
        </div>
      </div>
    </div>
  );
};
