import { Tile } from "./bluetile";
import { Delivery } from "./delivery";
import { Event } from "./event";
import { CryptoPayments, PayPalPayments, SilaPayments, ChargeStatus, CreditsPayments } from "./payment";
import { CustomizationChoice, EventBenefits, ShippingMethod } from "./product";
import { Address } from "./user";

export interface OrderBase {
  orderNumber: string;
  buyerId: string;
  sellerId: string;
  productId: string;
  stakeCode: string;
  productSku: string;
  subtotal: number;
  taxRate: number;
  couponCode?: string;
  couponMessage?: string;
  discount?: number;
  currency: string;
  status: OrderStatus;
  productName: string;
  productDescription: string;
  productImage: string;
  productPrice: number;
  nftContract: string;
  nftTokenId: string;
  orderDate: string;
  lastUpdated: string;
  customizationOptions?:CustomizationChoice[];
  chargeCode?: string;
  auctionId?: string;
}

export interface Order extends OrderBase {
  amount: number;
  shippingMethod: ShippingMethod;
  shippingPrice: number;
  originZip?: string;
  tax: number;
  buyerRewardAmount?: string;
  quantity?: number;
  orderHeaderId?: string;

  phoneNumber?: string;
  shippingAddress?: Address;
  billingAddress?: Address;
  email?: string;

  chargeCodesCoinbase?: string[];
  chargeCodesFleato?: string[];
  chargeCodesCredits?: string[];
  chargeCodesPayPal?: string[];
  chargeCodesCheck?: string[];
  chargeCodesSila?: string[];
  agencyIds?: string[];
  attendance?: Attendance;
  productType?: "product" | "event" | "ticket";
  printfulData?: PrintfulData;
  platform?: string;
}

export interface PrintfulData {
  image: string;
  width?: number;
  quantity: number;
  merchImage?:string;
  tiles: Tile[];
  tileWords: string[];
  selectedTileType: string;
}

export interface Attendance {
  event: Event;
  benefits?: EventBenefits;
  agencyIds?: string[];
}

export enum OrderStatus {
  draft = "draft",
  processing = "processing",
  paid = "paid",
  complete = "complete"
}

// export const ORDER_STATUS = {
//   DRAFT: "draft",
//   BOUGHT: "bought",
//   PAYMENT_INPROGRESS: "payment-inprogress",
//   PAID: "paid",
//   PROCESSING: "processing",
//   SHIPPED: "shipped",
//   DELIVERED: "delivered",
//   COMPLETE: "complete",
//   CANCELLED: "cancelled",
//   ABANDONED: "abandoned",
// };

export interface InprogressPayment {
  chargeType: "fleato" | "coinbase" | "paypal" | "sila";
  chargeCode: string;
  paymentAmount: number;
}

export interface OrderWithPayment extends Order {
  cryptoPayment?: CryptoPayments;
  payPalPayment?: PayPalPayments;
  creditsPayment?: CreditsPayments;
  silaPayment?: SilaPayments;
  inprogressPayments?: InprogressPayment[];
}

export interface OrderWithDelivery extends OrderWithPayment {
  delivery?: Delivery;
}

export interface ChargeSummary {
  s: "fleato" | "credits" | "coinbase" | "paypal";
  id: string;
  amount: number;
  status: ChargeStatus;
  code?: string;
  expiresAt?: string;
}
export interface PaymentSummary {
  unpaidAmount: number;
  paidAmount: number;
  capturingAmount: number;
  processingAmount: number;
  charges: ChargeSummary[];
}

export interface OrderRequest {
  orderNumber: string;
  shippingMethod?: ShippingMethod;
  phoneNumber: string;
  shippingAddress: Address;
  billingAddress: Address;
  email: string;
  buyerRewardAmount?: string;
  userType?: string;
}

export interface OrderHeader {
  orderHeaderId: string;
  orders: [{
    orderNumber: string;
    status: string;
    productId: string;
  }];
  buyerId: string;
  status: string;
  payPalPayment?: PayPalPayments;
  chargeCodesPayPal?: string[];
  silaPayment?: SilaPayments;
  chargeCodesCheck?: string[];
  chargeCodesSila?: string[];
  setPaymentError?: (paymentError: string) => void;
  allowedPaymentMethods?: {
    check?: boolean;
    paypal?: boolean;
    fleatoWallet?: boolean;
    fleatoCredits?: boolean;
    cryptoWallet?: boolean;
  };
  invoiceUid?: string;
  disableQuantity?: boolean;
  disablePromoCode?: boolean;
  auctionId?: string;
  agencyIds?: string[];
}

export const PLATFORM_FEE = 0.05;
export const SELLER_FLEATO_REWARD = 0.03;
export const BUYER_FLEATO_REWARD = 0.09;

export interface AuctionWelcomeEmail {
  name: string;
  email: string;
  phoneNumber: string;
  auctionId: string;
  agencyId: string;
}