import React from "react";
import { MobileNavWrapper } from "../../styles/global-style";
import { toggleMenuAction } from "../../store/slices/common.slice";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenuSelector } from "../../store/selectors/common.selectors";
import { AiOutlineClose } from "react-icons/ai";

export default function MobileNavigation() {
  const dispatch = useDispatch();
  const toggleMenu = useSelector(toggleMenuSelector);
  const hideMenu = () => {
    dispatch(toggleMenuAction(!toggleMenu));
  };
  return (
    <MobileNavWrapper className={`${
        toggleMenu ? "visible" : ""
      } d-lg-none`}>
      <div className="pdt-wrapper  pt-4 px-3 pr-5">
          <AiOutlineClose
          size={24}
          color="#fff"
          onClick={hideMenu}
          className="close cursor-pointer"
        />
        {/* <h3>Categories</h3>
          <ul className="list-unstyled m-0 "> */}
        {/* {categories?.map((cat) => (
          <li key={cat.name} className="d-flex align-items-baseline"><AiOutlinePlus size={14} className="me-1"/>
            <Link href="/">
              <a>{cat.name}</a>
            </Link>
          </li>
        ))} */}
            {/* <LinkRefinement attribute="categories" limit={100} />
          </ul> */}
        </div>
    
    </MobileNavWrapper>
  );
}


export async function getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  }
}