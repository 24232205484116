import { useEffect, useState } from "react";
import { FLEATO_PAY_LIMIT, Nonce, RecentBalance, TOKENS } from "../../models/wallet";
import { ethers, Signer, BigNumber, utils } from "ethers";
import { TOKEN_CONTRACTS } from "../../smartcontracts/constants";
import { doc, getDoc } from "@firebase/firestore";
import { FleatoCharge } from "../../models/payment";
import useERC20 from "./useERC20";
import usePhysicalSwap from "./usePhysicalSwap";
import useProgress from "../helper/useProgress";
import useSushiSwapTrader from "./useSushiSwapTrader";
import useRawTransaction from "./useRawTransaction";
import { db } from '../../firebaseutil';
import { formatReceipt } from "../../util/smartcontracts";
import { Receipt } from "../../models/blockchain";
import { httpsCallable } from "firebase/functions";
import { functions } from '../../firebaseutil';
import { EthersTxn, SignedTxnRequest } from "../../models/wallet";


import { SWAP_FLEATO_TOL_FACTOR, SWAP_FLEATO_TOL_ROUNDUP, SWAP_USDC_TOL_FACTOR, SWAP_USDC_TOL_ROUNDUP, PAY_FLEATO_TOL_FACTOR, PAY_FLEATO_TOL_ROUNDUP, PAY_USDC_TOL_FACTOR, PAY_USDC_TOL_ROUNDUP, SAFE_BALANCE_TOL_FACTOR } from "../../models/wallet"
import * as Sentry from "@sentry/nextjs";
import { fleatoConfig } from "../../fleato-config";
import { createStep, Step, StepCallbacks } from "../../components/progressbar/multi-step-process";
import { fourDecimals, smartShorten25, twoDecimals, twoDecimalsFixed } from "../../util/util";
import { WalletType } from "../wallet/models";

const CHARGE_FLEATO = "charge-fleato";

interface ERC20Contract {
  approve: (grantForContractAddress: string, amountWei: BigNumber) => Promise<EthersTxn | Nonce>;
  allowance: (spender: string) => Promise<number>;
  decimals: () => Promise<number>;
}

enum RequestTypes {
  paymentSubmitted = "paymentSubmitted",
}

export interface Hold {
    fleato?: number;
    usdc?: number;
    bitcoin?: number;
    ethereum?: number;
    matic?: number;
    totalUSD: number;
}

export interface PayExtraInfo {
  isMultiItemCheckout: boolean;
  hold: Hold;
}


export default function usePay({walletType, account, getSigner, getProvider, fetchTokenBalances }: {walletType: WalletType, account?: string, getProvider?: () => Promise<any>, getSigner?: () => Promise<Signer>, fetchTokenBalances: () => Promise<RecentBalance> }) {
  const fleatoContract = useERC20({walletType, account, symbol: TOKENS.fleato, getProvider, getSigner });
  const usdcContract = useERC20({walletType, account, symbol: TOKENS.usdc, getProvider, getSigner });
  const bitcoinContract = useERC20({walletType, account, symbol: TOKENS.bitcoin, getProvider, getSigner });
  const ethereumContract = useERC20({walletType, account, symbol: TOKENS.ethereum, getProvider, getSigner });
  const maticContract = useERC20({walletType, account, symbol: TOKENS.matic, getProvider, getSigner });
  const { physicalSwapContractAddress, pay, isDeposited, depositNFT } = usePhysicalSwap({walletType, account, getProvider, getSigner });
  const { sendTransactionNoWait } = useRawTransaction({walletType, getProvider, getSigner });
  const { swapTokensForToken: swapFleato, trade: fleatoTrade } = useSushiSwapTrader({ account });
  const { swapTokensForToken: swapUsdc, trade: usdcTrade } = useSushiSwapTrader({ account });
  const { sushiSwapContractAddress, quoteTokenForToken: swapQuote } = useSushiSwapTrader({ account });
  const [paymentProgressPercent, setPaymentProgressPercent] = useState(0.0);
  const { start, increment, fail, finish, changeStepCount } = useProgress(setPaymentProgressPercent);
  // const setDescription = (value: string) => setPaymentSteps(prev => ({ ...prev, description: value }));
  // const { execute } = useExecute({ start, increment, fail, finish, setDescription });




  // useEffect(() => {
  //   if (fleatoTrade?.routeText)
  //     setPaymentSteps(prev => ({ ...prev, fleatoTradePath: fleatoTrade.routeText }));
  // }, [fleatoTrade])

  // useEffect(() => {
  //   if (usdcTrade?.routeText)
  //     setPaymentSteps(prev => ({ ...prev, usdcTradePath: usdcTrade.routeText }));
  // }, [usdcTrade])

  const contracts: Record<string, ERC20Contract> = {
    fleato: fleatoContract,
    usdc: usdcContract,
    bitcoin: bitcoinContract,
    ethereum: ethereumContract,
    matic: maticContract
  }


  const fetchCharge = async (chargeId: string): Promise<FleatoCharge> => {
    const ref = doc(db, `${CHARGE_FLEATO}/${chargeId}`);
    const charge = (await getDoc(ref)).data() as FleatoCharge;
    return charge;
  }

  const networkProvider = async (): Promise<any> => {
    if (walletType == WalletType.ConnectedWallet)
      return new ethers.providers.Web3Provider(await getProvider());
    else
      return ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);
  }

  const tokenWithSufficientBalance = async (usdAmount: number) => {
    const balance = await fetchTokenBalances();
    const safeUsdAmount = usdAmount * SAFE_BALANCE_TOL_FACTOR;
    if ((balance?.fleato.v ?? 0) > (safeUsdAmount - 0.01)) return TOKENS.fleato;
    if ((balance?.usdc?.v ?? 0) > (safeUsdAmount - 0.01)) return TOKENS.usdc;
    if ((balance?.bitcoin?.v ?? 0) > (safeUsdAmount - 0.01)) return TOKENS.bitcoin;
    if ((balance?.ethereum?.v ?? 0) > (safeUsdAmount - 0.01)) return TOKENS.ethereum;
    if ((balance?.matic?.v ?? 0) > (safeUsdAmount - 0.01)) return TOKENS.matic;
    if ((balance?.total.v ?? 0) > (safeUsdAmount - 0.01)) {
      throw new Error("Insufficient Balance in any single token. Please consolidate your balances to any one Token such as Fleato for USDC to pay.");
    } else if ((balance?.total.v ?? 0) <= safeUsdAmount - 0.01) {
      throw new Error("Insufficient Balance, $" + (balance?.total.v ?? "0") + " available," + "needed atleast $" + twoDecimals(safeUsdAmount) + " in wrapped bitcoin, ethereum or usdc. Please try again.");
    }
  }

  const submitInprogressPayment = async (orderHeaderId: string, orderNumber: string, chargeCode: string, paymentAmount: number) => {
    try {
    const submitPayment = httpsCallable(functions, 'payment-paymentCall1');
      //If payment is at order level, send order number. If it is at header level (one paypal payment for everything), send header id
      if(orderNumber)
        await submitPayment({ requestType: RequestTypes.paymentSubmitted, orderNumber, chargeCode, chargeType: "fleato", paymentAmount });
      else
        await submitPayment({ requestType: RequestTypes.paymentSubmitted, orderHeaderId, chargeCode, chargeType: "fleato", paymentAmount });
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error while updating payment info", err);
    }
  }

  const depositNFTSteps = async (chargeCode: string, isDeposited: (chargeCode: string) => Promise<boolean>, depositNFT: (chargeCode: string) => Promise<EthersTxn | Nonce>, onComplete: () => Promise<void>, callbacks: StepCallbacks): Promise<Step[]> => {
    const steps: Step[] = [];
    const alreadyDeposited = await isDeposited(chargeCode);
    if(alreadyDeposited) {
      console.log("NFT already deposited");
      return steps;
    }
    const depositStep = createStep(callbacks);
    depositStep.name = `Deposit NFT`;
    depositStep.promptMessage = `Deposit NFT with Fleato Platform`;
    depositStep.execute = async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => {
      callbacks.markInError(stepId, "");
      callbacks.markProgress(stepId, 20, `Depositing NFT with Fleato Platform...`);
      try {
        const tx = await depositNFT(chargeCode);
          if (tx && "wait" in tx) {
            //Connected wallet transaction
            if (callbacks?.setTransactionHash) callbacks.setTransactionHash(stepId, tx.hash);
            console.log("tx", tx);
            const response = await tx.wait();
            const receipt = formatReceipt(response);
            console.log("receipt", receipt);
            if (receipt == null) {
              callbacks.markInError(stepId, "Transaction not mined");
              return false;
            } else if (receipt.status == 0) {
              callbacks.markInError(stepId, "Transaction failed");
              return false;
            } else {
              console.log("txn successful");
            }
            callbacks.markProgress(stepId, 100, `NFTs deposited with Flato Platform!`);
          } else {
            //Fleato wallet transaction
            console.log("pooled nonce", tx);
            //Submit to backend
            console.log("txn submitted");
            callbacks.markProgress(stepId, 100, `NFT deposit initiated! We'll take it from here.`);
          }
        await onComplete();
        return true;
      } catch (err: any) {
        const keyMessage = err?.error?.reason ?? err.error ?? err.toString();
        console.log("Error", err);
        Sentry.captureException(err);
        callbacks.markInError(stepId, keyMessage);
        return false;
      };
    }
    steps.push(depositStep);
    return steps;
  }

  const coin = (name: string) => name !== "bitcoin" ? "coins" : ""; 

  const payForOrder = async (prefix: string, chargeId: string, paymentAmount: number, nftContract: string, nftTokenId: string, royaltyBasisPoints: number, extraInfo: PayExtraInfo, callbacks: StepCallbacks): Promise<Step[]> => {
    //Payment Amount can be less than order total, in case of split payments.
    //Each payment will proportionately pay the fee.
    //construct transaction, submit with payment.
    prefix = smartShorten25(prefix);
    const suffix = extraInfo.isMultiItemCheckout ? ` for "${prefix}"` : "";
    const charge = await fetchCharge(chargeId);
    console.log("Charge", charge);
    const balance = await fetchTokenBalances();

    const steps: Step[] = [];
    let step: Step;
    const stepInfo: { name: string, description: string, params: any }[] = [];

    //Determine how payment will be made
    let holdingSymbol = "";
    try {
      holdingSymbol = await tokenWithSufficientBalance(extraInfo.isMultiItemCheckout ? extraInfo.hold.totalUSD : paymentAmount) ?? "";
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }

    let feeRate = (charge.fee_price?.amount ?? 0) / (charge.local_price.amount ?? 0.0001);
    let withholdingRate = charge.withholding_price.amount / (charge.local_price.amount ?? 0.0001);
    let withholdingAmount = paymentAmount * withholdingRate;
    let feeAmount = paymentAmount * feeRate;
    let paymentNetFeeAndWithholdingAmountInPaymentTokens = 0.0, paymentNetFeeAndWithholdingAmountInUsd = 0.0;
    let feeAndWithholdingAmountInFleato = 0.0;
    let buyFleato = false, buyUsdc = false, usdcNeeded = 0, paymentToken = TOKENS.fleato, fleatoNeeded = 0;
    let holdingTokenNeededforSwap = 0.0, holdingAmountNeededForSwap = 0.0;
    let holdingTokenNeededForFleatoSwap = "0.0", holdingAmountNeededForFleatoSwap = 0.0;
    let holdingTokenNeededForUsdcSwap = "0.0", holdingAmountNeededForUsdcSwap = 0.0;

    const holdingUom = holdingSymbol === TOKENS.bitcoin ? 0.00001 : holdingSymbol === TOKENS.ethereum ? 0.0001 : 1

    let holding2FleatoRate = 1;
    console.log("holding symbol is", holdingSymbol, "is different from fleato", holdingSymbol !== TOKENS.fleato);
    if (holdingSymbol !== TOKENS.fleato) {
      const holdingFleatoTrade = await swapQuote(holdingSymbol, TOKENS.fleato, 1 * holdingUom);
      holding2FleatoRate = parseFloat(holdingFleatoTrade.expectedConvertQuote) / holdingUom;
    }

    let holding2UsdcRate = 1;
    if (holdingSymbol !== TOKENS.usdc) {
      const holdingUsdcTrade = await swapQuote(holdingSymbol, TOKENS.usdc, 1 * holdingUom);
      holding2UsdcRate = parseFloat(holdingUsdcTrade.expectedConvertQuote) / holdingUom;
    }


    if (paymentAmount <= FLEATO_PAY_LIMIT) {
      fleatoNeeded = paymentAmount / charge.exchangeRate.fleato.usd;
      paymentNetFeeAndWithholdingAmountInPaymentTokens = (paymentAmount - feeAmount - withholdingAmount) / charge.exchangeRate.fleato.usd;
      paymentNetFeeAndWithholdingAmountInUsd = (paymentAmount - feeAmount - withholdingAmount);
      feeAndWithholdingAmountInFleato = (feeAmount + withholdingAmount) / charge.exchangeRate.fleato.usd;
      usdcNeeded = 0;
      paymentToken = TOKENS.fleato;
    } else {
      fleatoNeeded = (feeAmount + withholdingAmount) / charge.exchangeRate.fleato.usd;
      usdcNeeded = paymentAmount - feeAmount - withholdingAmount;
      paymentNetFeeAndWithholdingAmountInPaymentTokens = usdcNeeded;
      paymentNetFeeAndWithholdingAmountInUsd = usdcNeeded;
      feeAndWithholdingAmountInFleato = fleatoNeeded;
      paymentToken = TOKENS.usdc;
    }

    const usdcBalanceUsd = balance?.usdc?.v ?? 0.0;
    console.log("fleato needed", fleatoNeeded, "available fleato balance", balance?.fleato.u, balance);
    if ((balance?.fleato.u ?? 0) < fleatoNeeded || (fleatoNeeded > 0 && holdingSymbol !== "fleato" && extraInfo.isMultiItemCheckout)) {
      buyFleato = true;
      const fleatoBuyUnits = (fleatoNeeded - (balance?.fleato.u ?? 0) + SWAP_FLEATO_TOL_ROUNDUP) * SWAP_FLEATO_TOL_FACTOR;
      holdingTokenNeededforSwap += fleatoBuyUnits / holding2FleatoRate;
      holdingAmountNeededForSwap += fleatoBuyUnits * charge.exchangeRate.fleato.usd;
      holdingAmountNeededForFleatoSwap = fleatoBuyUnits * charge.exchangeRate.fleato.usd;
      holdingTokenNeededForFleatoSwap = (fleatoBuyUnits / holding2FleatoRate).toFixed(8);
    }

    if (usdcBalanceUsd < usdcNeeded || (usdcNeeded > 0 && holdingSymbol !== "usdc" && extraInfo.isMultiItemCheckout)) {
      buyUsdc = true;
      const usdAmount = (usdcNeeded - usdcBalanceUsd + SWAP_USDC_TOL_ROUNDUP) * SWAP_USDC_TOL_FACTOR;
      holdingTokenNeededforSwap += usdAmount / holding2UsdcRate;
      holdingAmountNeededForSwap += usdAmount;
      holdingAmountNeededForUsdcSwap = usdAmount;
      holdingTokenNeededForUsdcSwap = (usdAmount / holding2UsdcRate).toFixed(8);
    }

    let holdingTokenSwapAllowance = 0, safeHoldingTokenSwapAllowance = 0, usdcSettleAllowance = 0, fleatoSettleAllowance = 0;
    try {
      holdingTokenSwapAllowance = await contracts[holdingSymbol].allowance(sushiSwapContractAddress);
      safeHoldingTokenSwapAllowance = holdingTokenNeededforSwap;

      usdcSettleAllowance = await contracts.usdc.allowance(physicalSwapContractAddress);
      fleatoSettleAllowance = await contracts.fleato.allowance(physicalSwapContractAddress);
    } catch(err) {
      console.log("error calculating allowance", err, {sushiSwapContractAddress, physicalSwapContractAddress, holdingTokenSwapAllowance, safeHoldingTokenSwapAllowance, fleatoSettleAllowance, usdcSettleAllowance});
    }

    console.log("params", { holdingSymbol, holdingBalance: balance[holdingSymbol], usdcNeeded, usdcSettleAllowance, holdingTokenSwapAllowance, safeHoldingTokenSwapAllowance, fleatoSettleAllowance, fleatoNeeded, buyFleato, buyUsdc })

    if (holdingTokenSwapAllowance < safeHoldingTokenSwapAllowance || (holdingTokenSwapAllowance > 0 && extraInfo.isMultiItemCheckout)) {
      console.log("unlockHoldingToken needed", holdingTokenSwapAllowance, safeHoldingTokenSwapAllowance)
      step = createStep(callbacks);
      step.name = `Unlock ${holdingSymbol} ${suffix}`;
      step.promptMessage = `Authorize fleato.com to convert no more than ${safeHoldingTokenSwapAllowance.toFixed(8)} ${holdingSymbol} [$${twoDecimalsFixed(holdingAmountNeededForSwap)}] to usdc and fleato coins`;
      step.execute = async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => {
        callbacks.markInError(stepId, "");
        callbacks.markProgress(stepId, 20, `Unlocking ${holdingSymbol}...`);
        try {
          const tx = await contracts[holdingSymbol].approve(sushiSwapContractAddress, ethers.utils.parseUnits(safeHoldingTokenSwapAllowance.toFixed(8), await contracts[holdingSymbol].decimals()));
          if ("wait" in tx) {
            //Connected wallet transaction
            if (callbacks?.setTransactionHash) callbacks.setTransactionHash(stepId, tx.hash);
            console.log("tx", tx);
            const response = await tx.wait();
            const receipt = formatReceipt(response);
            console.log("receipt", receipt);
            if (receipt == null) {
              callbacks.markInError(stepId, "Transaction not mined");
              return false;
            } else if (receipt.status == 0) {
              callbacks.markInError(stepId, "Transaction failed");
              return false;
            } else {
              console.log("txn successful");
            }
            callbacks.markProgress(stepId, 100, `${holdingSymbol} Coin unlocked.`);
          } else {
            //Fleato wallet transaction
            console.log("pooled nonce", tx);
            //Submit to backend
            console.log("txn submitted");
            callbacks.markProgress(stepId, 100, `${holdingSymbol} Coin unlock requested.`);
          }
        } catch (err: any) {
          const keyMessage = err?.message ?? err?.error?.reason ?? err.error ?? err.toString();
          callbacks.markInError(stepId, keyMessage);
          return false;
        };
        return true;
      }
      steps.push(step);
    }
    //Buy needed tokens from sushi swap
    if (buyFleato) {
      step = createStep(callbacks);
      step.name = `Convert ${holdingTokenNeededForFleatoSwap} ${holdingSymbol} ${coin(holdingSymbol)} [$${twoDecimalsFixed(holdingAmountNeededForFleatoSwap)}]  to Fleato coins ${suffix}`;
      step.promptMessage = `Convert ${holdingTokenNeededForFleatoSwap} ${holdingSymbol} ${coin(holdingSymbol)} [$${twoDecimalsFixed(holdingAmountNeededForFleatoSwap)}] to Fleato coins`;
      step.execute = async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => {
        callbacks.markInError(stepId, "");
        const trade = await swapFleato(holdingSymbol, TOKENS.fleato, parseFloat(holdingTokenNeededForFleatoSwap));
        if (callbacks?.markProgress)
          callbacks.markProgress(stepId, 20, `Exchange ${holdingTokenNeededForFleatoSwap} ${holdingSymbol} for approx ${parseFloat(trade.expectedConvertQuote).toFixed(2)} fleato tokens  [$${twoDecimalsFixed(holdingAmountNeededForFleatoSwap)}] `);
        // if (trade.hasEnoughAllowance) {
          const txn = trade.transaction;
          console.log("txn to buy fleato", txn);
          try {
            const tx = await sendTransactionNoWait(txn);
            if ("wait" in tx) {
              if (callbacks?.setTransactionHash) callbacks.setTransactionHash(stepId, tx.hash);
              const response = await tx.wait();
              const receipt = formatReceipt(response);
              console.log("receipt", receipt);
              if (receipt == null) {
                callbacks.markInError(stepId, "Transaction not mined");
                return false;
              } else if (receipt.status == 0) {
                callbacks.markInError(stepId, "Transaction failed");
                return false;
              } else {
                console.log("txn successful");
              }
              callbacks.markProgress(stepId, 100, `Converted ${holdingTokenNeededForFleatoSwap} ${holdingSymbol} ${coin(holdingSymbol)} to Fleato coins  [$${twoDecimalsFixed(holdingAmountNeededForFleatoSwap)}] `);
            } else {
              //Fleato wallet transaction
              console.log("pooled nonce", tx);
              console.log("txn submitted");
              callbacks.markProgress(stepId, 100, `Requested conversion of ${holdingTokenNeededForFleatoSwap} ${holdingSymbol} ${coin(holdingSymbol)} [$${twoDecimalsFixed(holdingAmountNeededForFleatoSwap)}]  to Fleato coins`);
            }
          } catch (err: any) {
            const keyMessage = err?.message ?? err?.error?.reason ?? err.error ?? err.toString();
            callbacks.markInError(stepId, keyMessage);
            return false;
          }
          return true;
        // } else {
        //   const errorMessage = `Insufficient allowance for ${holdingSymbol}. Needed ${parseFloat(holdingTokenNeededForFleatoSwap)}`
        //   if (callbacks?.markInError)
        //     callbacks.markInError(stepId, errorMessage);
        //   else
        //     throw new Error(errorMessage);
        // }
      };
      steps.push(step);
    }

    if (buyUsdc) {
      step = createStep(callbacks);
      step.name = `Convert ${holdingTokenNeededForUsdcSwap} ${holdingSymbol} ${coin(holdingSymbol)} [$${twoDecimalsFixed(holdingAmountNeededForUsdcSwap)}] to USDC coins ${suffix}`;
      step.promptMessage = `Convert ${holdingTokenNeededForUsdcSwap} ${holdingSymbol} ${coin(holdingSymbol)} [$${twoDecimalsFixed(holdingAmountNeededForUsdcSwap)}] to USDC coins`;
      step.execute = async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => {
        callbacks.markInError(stepId, "");
        const trade = await swapUsdc(holdingSymbol, TOKENS.usdc, parseFloat(holdingTokenNeededForUsdcSwap));
        if (callbacks?.markProgress)
          callbacks.markProgress(stepId, 20, `Exchange ${holdingTokenNeededForUsdcSwap} ${holdingSymbol} ${coin(holdingSymbol)} for approx ${parseFloat(trade.expectedConvertQuote).toFixed(2)} usdc tokens [$${twoDecimalsFixed(holdingAmountNeededForUsdcSwap)}]`);
        // if (trade.hasEnoughAllowance) {
          const txn = trade.transaction;
          console.log("txn to buy usdc", txn);
          try {
            const tx = await sendTransactionNoWait(txn);
            if ("wait" in tx) {
              if (callbacks?.setTransactionHash) callbacks.setTransactionHash(stepId, tx.hash);
              const response = await tx.wait();
              const receipt = formatReceipt(response);
              console.log("receipt", receipt);
              if (receipt == null) {
                callbacks.markInError(stepId, "Transaction not mined");
                return false;
              } else if (receipt.status == 0) {
                callbacks.markInError(stepId, "Transaction failed");
                return false;
              } else {
                console.log("txn successful");
              }
              callbacks.markProgress(stepId, 100, `Converted ${holdingTokenNeededForUsdcSwap} ${holdingSymbol} ${coin(holdingSymbol)} to USDC coins [$${twoDecimalsFixed(holdingAmountNeededForUsdcSwap)}]`);
            } else {
              //Fleato wallet transaction
              console.log("pooled nonce", tx);
              console.log("txn submitted");
              callbacks.markProgress(stepId, 100, `Requested conversion of ${holdingTokenNeededForUsdcSwap} ${holdingSymbol} ${coin(holdingSymbol)} to USDC coins [$${twoDecimalsFixed(holdingAmountNeededForUsdcSwap)}]`);
            }
          } catch (err: any) {
            const keyMessage = err?.message ?? err?.error?.reason ?? err.error ?? err.toString();
            callbacks.markInError(stepId, keyMessage);
            return false;
          }
          return true;
        // } else {
        //   const errorMessage = `Insufficient allowance for ${holdingSymbol}. Needed ${parseFloat(holdingTokenNeededForUsdcSwap)}`
        //   if (callbacks?.markInError)
        //     callbacks.markInError(stepId, errorMessage);
        //   else
        //     throw new Error(errorMessage);
        // }
      };
      steps.push(step);
    }

    if (usdcSettleAllowance < usdcNeeded * PAY_USDC_TOL_FACTOR  || (usdcNeeded > 0 && extraInfo.isMultiItemCheckout)) {
      console.log("unlockPaymentToken needed", usdcNeeded * PAY_USDC_TOL_FACTOR, usdcSettleAllowance);
      const safePaymentTokenAllowance = (usdcNeeded * PAY_USDC_TOL_FACTOR).toFixed(8);
      step = createStep(callbacks);
      step.name = `Unlock USDC Tokens for Payment ${suffix}`;
      step.promptMessage = `Authorize fleato.com to debit approx ${usdcNeeded.toFixed(2)} ${TOKENS.usdc} but no more than ${fourDecimals(safePaymentTokenAllowance)} ${TOKENS.usdc} [$${twoDecimalsFixed(usdcNeeded)}]`;
      step.execute = async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => {
        callbacks.markInError(stepId, "");
        callbacks.markProgress(stepId, 20, `Unlocking USDC Tokens for Payment...`);
        try {
          const tx = await contracts.usdc.approve(physicalSwapContractAddress, ethers.utils.parseUnits(safePaymentTokenAllowance, await contracts.usdc.decimals()));
          if ("wait" in tx) {
            if (callbacks?.setTransactionHash) callbacks.setTransactionHash(stepId, tx.hash);
            console.log("tx", tx);
            const response = await tx.wait();
            const receipt = formatReceipt(response);
            console.log("receipt", receipt);
            if (receipt == null) {
              callbacks.markInError(stepId, "Transaction not mined");
              return false;
            } else if (receipt.status == 0) {
              callbacks.markInError(stepId, "Transaction failed");
              return false;
            } else {
              console.log("txn successful");
            }
            callbacks.markProgress(stepId, 100, `USDC Tokens unlocked.`);
          } else {
            //Fleato wallet transaction
            console.log("pooled nonce", tx);
            //Submit to backend
            console.log("txn submitted");
            callbacks.markProgress(stepId, 100, `USDC Tokens unlock requested.`);
          }
        } catch (err: any) {
          const keyMessage = err?.message ?? err?.error?.reason ?? err.error ?? err.toString();
          callbacks.markInError(stepId, keyMessage);
          return false;
        }
        return true;
      };
      steps.push(step);
    }

    if (fleatoSettleAllowance < fleatoNeeded * PAY_FLEATO_TOL_FACTOR  || (fleatoNeeded > 0 && extraInfo.isMultiItemCheckout)) {
      console.log("unlockFleato needed", fleatoNeeded * PAY_FLEATO_TOL_FACTOR, fleatoSettleAllowance)
      const safeFleatoTokenAllowance = (fleatoNeeded * PAY_FLEATO_TOL_FACTOR).toFixed(8);
      step = createStep(callbacks);
      step.name = `Unlock Fleato Tokens for Payment ${suffix}`;
      step.promptMessage = `Authorize fleato.com to debit approx ${fleatoNeeded.toFixed(2)} ${TOKENS.fleato} [$${twoDecimalsFixed(fleatoNeeded * charge.exchangeRate.fleato.usd)}] but no more than ${fourDecimals(safeFleatoTokenAllowance)} ${TOKENS.fleato} [$${twoDecimalsFixed(fleatoNeeded * PAY_FLEATO_TOL_FACTOR * charge.exchangeRate.fleato.usd)}]`;
      step.execute = async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => {
        callbacks.markInError(stepId, "");
        callbacks.markProgress(stepId, 20, `Unlocking Fleato Tokens for Payment...`);
        try {
          const tx = await contracts.fleato.approve(physicalSwapContractAddress, ethers.utils.parseUnits(safeFleatoTokenAllowance, await contracts.fleato.decimals()));
          if ("wait" in tx) {
            if (callbacks?.setTransactionHash) callbacks.setTransactionHash(stepId, tx.hash);
            console.log("tx", tx);
            const response = await tx.wait();
            const receipt = formatReceipt(response);
            console.log("receipt", receipt);
            if (receipt == null) {
              callbacks.markInError(stepId, "Transaction not mined");
              return false;
            } else if (receipt.status == 0) {
              callbacks.markInError(stepId, "Transaction failed");
              return false;
            } else {
              console.log("txn successful");
            }
            callbacks.markProgress(stepId, 100, `Fleato Tokens unlocked.`);
          } else {
            //Fleato wallet transaction
            console.log("pooled nonce", tx);
            //Submit to backend
            console.log("txn submitted");
            callbacks.markProgress(stepId, 100, `Fleato Tokens unlock requested.`);
          }
        } catch (err: any) {
          console.log("Error in Unlock Fleato", err);
          const keyMessage = err?.message ?? err?.error?.reason ?? err.error ?? err.toString();
          callbacks.markInError(stepId, keyMessage);
          return false;
        }
        return true;
      }
      steps.push(step);
    }
    //Pay
    let description = `Pay $${paymentAmount.toFixed(2)} with `;
    if (paymentToken == TOKENS.fleato)
      description += `${fleatoNeeded.toFixed(2)} fleato coins [$${twoDecimalsFixed(fleatoNeeded * charge.exchangeRate.fleato.usd)}]`;
    else
      description += `${paymentNetFeeAndWithholdingAmountInPaymentTokens.toFixed(6)} ${paymentToken} [$${twoDecimalsFixed(paymentNetFeeAndWithholdingAmountInUsd)}] and ${feeAndWithholdingAmountInFleato.toFixed(2)} fleato coins [$${twoDecimalsFixed(feeAndWithholdingAmountInFleato * charge.exchangeRate.fleato.usd)}]`;

    step = createStep(callbacks);
    step.name = `Make Payment ${suffix}`;
    step.promptMessage = `Make Payment`;
    step.execute = async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => {
      callbacks.markProgress(stepId, 20, `Making a Payment of ${fourDecimals(paymentNetFeeAndWithholdingAmountInPaymentTokens)} ${paymentToken} ${coin(holdingSymbol)} [$${twoDecimalsFixed(paymentNetFeeAndWithholdingAmountInUsd)}] towards Merchant Payment and ${fourDecimals(feeAndWithholdingAmountInFleato)} Fleato coins [$${twoDecimalsFixed(feeAndWithholdingAmountInFleato * charge.exchangeRate.fleato.usd)}] towards Tax Withholding and Platform fees...`);
      let tx: EthersTxn | Nonce;
      try {
        tx = await pay({ chargeCode: charge.code, productCode: charge.metadata.stake_code, receiver: charge.payee, paymentTokenContract: TOKEN_CONTRACTS[paymentToken as keyof typeof TOKEN_CONTRACTS], paymentNetFeeAndWithholdingAmount: paymentNetFeeAndWithholdingAmountInPaymentTokens, feeAndWithholdingAmount: feeAndWithholdingAmountInFleato, nftContract, nftTokenId });
        if ("wait" in tx) {
          if (callbacks?.setTransactionHash) callbacks.setTransactionHash(stepId, tx.hash);
          console.log("tx", tx);
          const response = await tx.wait();
          const receipt = formatReceipt(response);
          console.log("receipt", receipt);
          if (receipt == null) {
            callbacks.markInError(stepId, "Transaction not mined");
            return false;
          } else if (receipt.status == 0) {
            callbacks.markInError(stepId, "Transaction failed");
            return false;
          } else {
            console.log("txn successful");
          }
          callbacks.markProgress(stepId, 100, `Paid ${fourDecimals(paymentNetFeeAndWithholdingAmountInPaymentTokens)} ${paymentToken} ${coin(holdingSymbol)} [$${twoDecimalsFixed(paymentNetFeeAndWithholdingAmountInUsd)}] towards Merchant Payment and ${fourDecimals(feeAndWithholdingAmountInFleato)} Fleato coins [$${twoDecimalsFixed(feeAndWithholdingAmountInFleato * charge.exchangeRate.fleato.usd)}] towards Tax Withholding and Platform fees`);
        } else {
          //Fleato wallet transaction
          console.log("pooled nonce", tx);
          //Submit to backend
          console.log("txn submitted");
          callbacks.markProgress(stepId, 100, `Initiated payment of ${fourDecimals(paymentNetFeeAndWithholdingAmountInPaymentTokens)} ${paymentToken} ${coin(holdingSymbol)} [$${twoDecimalsFixed(paymentNetFeeAndWithholdingAmountInUsd)}] towards Merchant Payment and ${fourDecimals(feeAndWithholdingAmountInFleato)} Fleato coins [$${twoDecimalsFixed(feeAndWithholdingAmountInFleato * charge.exchangeRate.fleato.usd)}] towards Tax Withholding and Platform fees`);
        }
      } catch (err: any) {
        callbacks.markInError(stepId, err.toString());
        return false;
      }
      return true;
    };
    steps.push(step);

    return steps;
  }

  const gasBalance = async () => {
    //ensure matic exists for gas fees
    if (!account)
      return 0;
    const maticBalance = parseFloat(utils.formatEther(await (await networkProvider()).getBalance(account)));
    return maticBalance;
  }

  return { payForOrder, isDeposited, depositNFT, depositNFTSteps, paymentProgressPercent, gasBalance, submitInprogressPayment };
}
