import { Children, createContext, useState } from 'react';

interface ICategoriesContext {
  recentDrop: boolean;
  isRerender: boolean;
  setRecentDrop: (recentDrop: boolean) => void;
  currentArtist: string;
  setCurrentArtist: (currentArtist: string) => void;
  isEmpty: boolean,
  setIsRerender: (isRenderer: boolean) => void;
  setIsEmpty: (isEmpty: boolean) => void,
}

export const CategoriesContext = createContext<ICategoriesContext>({} as ICategoriesContext);
export const CategoriesProvider = ({ children }) => {
  const [recentDrop, setRecentDrop] = useState(true);
  const [currentArtist, setCurrentArtist] = useState(undefined);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isRerender, setIsRerender] = useState(false);

  return (
    <CategoriesContext.Provider
      value={{ recentDrop, setRecentDrop, currentArtist, setCurrentArtist, isEmpty, setIsEmpty, isRerender, setIsRerender }} >
      {children}
    </CategoriesContext.Provider>
  )
}