import styled from "styled-components";

export const ChatWrapper = styled.div`
  position: fixed;
  right: 0px;
  bottom: 50px;
  width: 40%;
  z-index: 1;
  height: calc(100% - 177px);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 232, 235);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
  visibility: visible;
  transform: translate3d(100%, 0px, 0px);
  opacity: 0;
  &.visible {
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    visibility: visible;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  .overflow-auto {
    overflow: auto;
    height: calc(100% - 130px);
  }
  @media (min-width: 601px) and (max-width: 1199px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }

  .close {
    position: absolute;
    left: 15px;
    top: 14px;
    svg path {
      stroke: #757575;
    }
  }
  .rce-mbox{border: 1px solid #707070;
    box-shadow: none;border-radius: 8px;}
    .rce-mbox-title{color:#656565;font-weight:700;font-size: 1rem;cursor:auto}
    .rce-mbox-left-notch,.rce-mbox-right-notch{display:none;}
    .rce-container-mbox{margin: 15px 0;}
    .rce-mbox.rce-mbox-right {
   background: #e4ffe2;
}
.rce-mbox-title:hover{text-decoration:none}
  .rce-mbox-reply-border {
    border-color: #29ac49 !important;
  }
  .rce-mbox-reply-border {
    border-color: #29ac49 !important;
  }
  .rce-mbox-reply-owner {
    color: #29ac49 !important;
    font-size: 1rem;
  }
  .rce-mbox-reply-message {
    color: #000000;

    font-size: 0.9rem;
  }
  .rce-mbox-text {
    font-size: 0.9rem;
    color:#656565;
}

  .rce-container-input {
    border: 1px solid #dfdfdf;
    border-radius: 2px;
  }

  @media (max-width: 991px) {
  }
`;
