import styled, { StyledComponent } from "styled-components";
import React from "react";

const GetBold: React.CSSProperties = {
  fontWeight: "bold",
};
const CheckoutNav: React.CSSProperties = {
  boxShadow: "1px 2px 3px #c3c3c3",
};
const PaymentNavigation = styled.div`
  p {
    cursor: pointer;
  }
`;
const DeliveryAddressDetails: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "bold",
};
const AddressModal: React.CSSProperties = {
  fontWeight: "bold",
  fontSize: "18px",
};
const DeliveryAddress = styled.div`
  padding: 20px 0;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  div {
    p {
      font-weight: 500;
    }
  }
`;

export const AddressModalWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #00000052;

  h3 {
  }
  border-radius: 13px;
  padding: 20px 40px;
  .close {
    right: 10px;
    cursor: pointer;
    top: 10px;
  }
  table {
    border-color: #707070;
  }
`;

export const KycWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #00000052;
  overflow-x:hidden;

  h3 {
  }
  border-radius: 0px;
  padding: 20px ;
  .close {
    right: 10px;
    cursor: pointer;
    top: 10px;
  }
  table {
    border-color: #707070;
  }
  .datepicker {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

export const ReviewModalWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #00000052;
  border-radius: 0px;
  padding: 20px 40px;
  .close {
    right: 10px;
    cursor: pointer;
    top: 10px;
  }
  .overflow-wrapper {
    height: calc(50vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
  .text-success {
    color: #1478DD;
    font-size: 14px;
  }
  .btn-success {
    background-color: #1478DD;
  }
  .btn-sucess:focus{
   box-shadow:0 0 0 0.25rem #1478dd4a;
  }
`;
export const CircleWrapper = styled.div`
  z-index:100 !important;
  height:100%;
  width:100%;
  overflow:hidden;
  background-color: #fff;
  border-radius: 50% !important;
  padding: 13px 0px;
  .close {
    position:absolute;
    right: 100px;
    cursor: pointer;
    top: 70px;
    z-index:10;
  }
  .bottom-container{
    bottom:50px;
    left:50%;
    transform:translate(-50%,0%);
    width:100%;
  }
  .center-container{
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:100%;
  }
  .icon{
    width:100px;
    height:100px;
    position:absolute;
    top:-150px;
    left:50%;
    transform:translate(-50%,0);
  }
  @media(min-width:768px){
  .title {font-size: 26px} 
  .bottom-container{
    bottom:100px
  }
  .icon{
    width:150px;
    height:150px;
  }
  }
 
`;
export const ModalWrapper = styled.div`
.badge {
  cursor: pointer;
  background: #d4d9de;
  border-radius: 50%;
  padding: 5px;
  line-height: 0;
  margin-left: 3px;
  svg {
    fill: #ffffff;
  }
}
z-index:100 !important;
  background-color: #fff;
  border-radius: 13px;
  padding: 13px 0px;
  .close {
    right: 10px;
    cursor: pointer;
    top: 10px;
  }
  th {
    text-align:left;
  }
  thead {
    text-align:left;
  }
`;
export const AddVariationsModalWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #00000052;
  border-radius: 0px;
  padding: 0px 0px;
  .close {
    right: 10px;
    cursor: pointer;
    top: 45px;
  }
  @media screen and(min-width:768px) {
    padding: 0px 40px;
    .close {
      right: 10px;
      cursor: pointer;
      top: 10px;
    }
  }
  .image-variations-container {
    .dropdown {
      .btn-secondary {
        border: none;
        outline: none;
        background-color: #fff;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #d2d2d2;
        width: 100%;
      }
    }
    .btn-secondary .dropdown-toggle {
      background-color: #fff;
      border: none;
      outline: none;
      color: #d2d2d2;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .btn-secondary .dropdown-toggle:focus {
      background-color: #fff;
      color: #d2d2d2;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  .input-group-append {
    .dropdown {
      .btn-secondary {
        border: 1px solid #d2d2d2;
        color: #d2d2d2;
      }
    }
    .btn-secondary .dropdown-toggle {
      background-color: #fff;
      border: none;
      outline: none;
      color: #d2d2d2;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .btn-secondary .dropdown-toggle:focus {
      background-color: #fff;
      color: #d2d2d2;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`;
const WizardProgressBar = styled.div`
.progress{
  position: relative;
  left: 56px;
  top: 25px;
  height: 3px;
  }
  .progressText{
    font-size:14px;
  }
  .progressBar {
    display: flex;
    justify-content: space-around;
    margin:10px 0;
    div {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: #707070;
    }
    #checked{
      background-color: #1478DD;

    }
    }
    .progressText {
      margin:10px 0;

    }
    .edit-seller-progress{
      position: relative;
      left: 0px;
      top: 25px;
      height: 3px;
      
    }
    .edit-progressBar {
      justify-content: space-between; 
    }
    .edit-progressText{
      p{
      font-size:10px;
      }
    }

  }
  @media screen and (min-width:720px){
    .progress{
      position: relative;
      left: 120px;
      top: 25px;
      height: 3px;
    }
    .progressText{
      font-size:16px;
    }
    .edit-seller-progress{
      position: relative;
      left: 0px;
      top: 25px;
      height: 3px;
    }
    .edit-progressText{
      p{
      font-size:16px;
      }
    }
  }
  @media screen and (min-width: 920px){
    .progress{
      position: relative;
      left: 165px;
      top: 25px;
      height: 3px;
    }
    .edit-seller-progress{
      position: relative;
      left: 0px;
      top: 25px;
      height: 3px;
    }

  }
  @media screen and (min-width: 1024px){
    .progress{
      position: relative;
      left: 145px;
      top: 25px;
      height: 3px;
    }
    .edit-seller-progress{
      position: relative;
      left: 0px;
      top: 25px;
      height: 3px;
    }
  }
  @media screen and (min-width: 1280px){
    .progress{
      position: relative;
      left: 145px;
      top: 25px;
      height: 3px;
    }
    .edit-seller-progress{
      position: relative;
      left: 0px;
      top: 25px;
      height: 3px;
    }
  }
  @media screen and (min-width: 1400px){
    .progress{
      position: relative;
      left: 155px;
      top: 25px;
      height: 3px;
    }
    .edit-seller-progress{
      position: relative;
      left: 0px;
      top: 25px;
      height: 3px;
    }
  }
  
  @media screen and (min-width: 1920px){
    .progress{
      position: relative;
      left: 150px;
      top: 25px;
      height: 3px;
    }
  }
  @media screen and (min-width: 3840px){
    .progress{
      position: relative;
      left: 150px;
      top: 25px;
      height: 3px;
    }
  }
  }
`;
const WizardProgressText: React.CSSProperties = {
  // fontSize: 14,
  fontWeight: 600,
};
const Hide: React.CSSProperties = {
  display: "none",
};
const Show: React.CSSProperties = {
  display: "block",
};
const DeliveryOption = styled.div`
  justify-content: space-around;
  border:1px solid #d2d2d2;
  .blue-bg {
    background-color: #1478DD !important;
    p {
      color: #fff;
    }
  }
  .grey-bg {
    background: #d2d2d2 !important;
    p {
      color: #000;
    }
  }

  .delivery-title {
    display: none;
  }
  .deliveryModeContainer {
    display: flex;
    

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      border-right: 1px solid #fff;

      text-align: center;
      background-color: #d2d2d2;
      i {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-top: 15px;
        background-color: #dbdbdb;
      }

      p {
        margin-top: 2px;
        font-size: 11px;
      }
    }
    div:nth-child(3) {
      border-right: none;
    }
    div:hover {
      background-color: #1478DD;
      i {
        background-color: #f1f1f1;
      }
      p {
        color: #fff;
      }
    }
  }
  @media screen and (min-width: 720px) {
    background-color: #fff;
    padding: 30px;
    .delivery-title {
      font-size: 17px;
      display: block;
    }
    .deliveryModeContainer {
      div {
        border: none;
        padding: 20px 0;
        margin-right: 5px;
        margin-left: 5px;
        .taxi,
        .bag,
        .truck {
          margin-top: 10px;
        }

        i {
          height: 50px;
          width: 50px;
          margin-top: 0px;
        }
        p {
          margin-top: 2px;
          font-size: 16px;
        }
      }
    }
  }
  // @media screen and (min-width: 920px) {
  //   background-color: #fff;
  //   padding: 30px;
  //   .delivery-title {
  //     display: block;
  //   }
  //   .deliveryModeContainer {
  //     div {
  //       border: none;
  //       padding: 20px 0;
  //       margin-right: 5px;
  //       margin-left: 5px;
  //       .taxi,
  //       .bag,
  //       .truck {
  //         margin-top: 10px;
  //       }

  //       i {
  //         height: 50px;
  //         width: 50px;
  //         margin-top: 0px;
  //       }
  //       p {
  //         margin-top: 10px;
  //         font-size: 16px;
  //       }
  //     }
  //   }
  // }
`;
const DeliveryForm = styled.div`
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 15px;
  p {
    font-size: 14px;
  }
  .bottom-options {
    font-weight: 500;
    color: #8a8a8a;
  }
  form {
    .input-group {
      input {
        font-size: 15px;
      }
      input::placeholder {
        font-size: 15px;
      }
    }
    .form-group {
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          font-weight: 700;
          font-size: 13px;
        }
        .address-option {
          ul {
            list-style: none;
            li {
              background-color: #d2d2d2;
              padding: 5px;
            }
            li:hover {
              background-color: #fff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 720px) {
    p {
      font-size: 17px;
    }
    label {
      font-size: 14px;
    }
    form {
      .input-group {
        input {
          font-size: 16px;
        }
        input::placeholder {
          font-size: 16px;
        }
      }
  }
`;
const NextButton = styled.button`
  background-color: #1478DD !important;
  border-radius: 0;
`;
const PlaceOrderButton = styled.button`
  background-color: #1478DD !important;
  border-radius: 0;
`;
const AddressInformationWrapper = styled.div`
.btn-light{
  background-color: #dbdbdb;
  border: 2px solid #d2d2d2;
  font-size:14px;
}
padding:20px 0;
background-color:#fff;
border:1px solid #d2d2d2;
p {
  font-size:14px;
  font-weight: 500;

}
div {
    div{
    padding:0 !important;
    margin:0;
    height:auto;    
    }
    .bag{
      margin-left:7px;
    }
    i {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: #d2d2d2;
      margin-right: 10px;
    }

  }
}
@media screen and (min-width:720px){
  p {
    font-size:16px;
  }
  .btn-light{
    font-size:16px;
  }
}
`;
const PaymentContainer = styled.div`
  background-color: #fff;
  .shadow{
  box-shadow: 0px 3px 6px #00000052;
  }
  .disabled{
    opacity:0.5;
  }

  p {
    font-size: 10px;
    // text-align: center;
    // font-weight: 600;
  }
  @media screen and (min-width: 720px) {
    p {
      text-align: left;
    }
  }
`;
const OtherPayment = styled.div`
.quantity-select:focus{
  box-shadow:none
}
.small-text{
  font-size:14px;
}
.light-bold{
  font-weight:500;
}
.grey-text{
  color:#7e839d;
}
  input,
  input::placeholder {
    font-size: 13px;
  }

  div {
    div {
      .btn-success {
        background-color: #1478DD;
        border: 2px solid #1478DD;
      }
      .btn-sucess:focus{
        box-shadow:0 0 0 0.25rem #1478dd4a;
       }
      .btn-light {
        background-color: #dbdbdb;
        border: 2px solid #d2d2d2;
      }
    }
  }
  border: 1px solid #d2d2d2;

  background-color: #fff;
  margin: 10px 0;
  // padding: 30px 0;

  p {
    font-size: 14px;
  }
  @media screen and (min-width: 720px) {
  
    input,
    input::placeholder {
      font-size: 16px;
    }
  }
`;
const PaymentConfirmation = styled.div`
  .payment-title {

    text-align: center;
  }

  p{
    font-size:16px;
  }
  .orderDetails {
    background-color: #fff;
    border: 1px solid #d2d2d2;
    padding-top: 10px;
    padding-bottom: 10px;

    div {
      ul:nth-child(1) {
        padding: 5px 5px;
      }
      ul {
        list-style: none;
        li {
          margin:5px 0;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    .items-container {
      ul {
        border: 2px solid #d2d2d2;
        padding: 10px 5px;
        li {
          margin:5px 0;
          font-size: 15px;
          color: #1478DD;
        }
      }
    }
  }
  @media screen and (min-width: 720px) {
    p{
      font-size:18px;
    }
    .payment-title {
      font-size:18px;
      text-align: left;
    }
    .orderDetails {
      div {
        ul:nth-child(1) {
        }
        ul {

          li {
            font-size: 16px;
          }
        }
      }
      .items-container {
        ul {
          li {
            font-size: 16px;
          }
        }
      }
  }
`;
const CartContainer = styled.div`
  border: 2px solid #d2d2d2;
  padding: 10px 0;
  // margin: 0 20px;
  :hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  }
  .total {
    font-weight: 600;
  }
  .cart-title {
    font-weight: 520;
    color: #1478DD;
  }
  .float-right {
    position: relative;
    left: 100px;
    font-weight: 520;
    color: #1478DD;
  }

  .item-container {
    border: 2px solid #d2d2d2;
    width: 100%;
  }
  .cart-items {
    p {
      color: #000;
    }

    .text-success {
      color: #1478DD;
    }
  }
`;
export {
  Show,
  Hide,
  GetBold,
  CheckoutNav,
  WizardProgressBar,
  WizardProgressText,
  DeliveryOption,
  DeliveryForm,
  NextButton,
  PlaceOrderButton,
  AddressInformationWrapper,
  PaymentContainer,
  AddressModal,
  OtherPayment,
  PaymentNavigation,
  PaymentConfirmation,
  CartContainer,
  DeliveryAddress,
  DeliveryAddressDetails,
};
