import { ethers, Signer, BigNumber } from "ethers";
import IERC20 from "../../smartcontracts/IERC20.json";
import { TOKEN_CONTRACTS } from "../../smartcontracts/constants";
import { fleatoConfig } from "../../fleato-config";
import { gasFee, gasFeeOptions } from "../../util/smartcontracts";
import { EthersTxn, Nonce } from "../../models/wallet";
import useFleatoPool from "./useFleatoPool";
import { WalletType } from "../wallet/models";

export default function useERC20({walletType, account, symbol, getSigner, getProvider} : {walletType: WalletType, account?: string, symbol: string, getProvider?: () => Promise<any>, getSigner?: () => Promise<Signer>}) {

  const {getNonce, submitTransaction} = useFleatoPool();
  //getProvider will be sent for connected wallets
  //getSigner will be sent for fleato wallets

  //Web3 Provider for connected providers, default provider for fleato wallet.
  const networkProvider = async (): Promise<any> => {
    if(walletType == WalletType.ConnectedWallet)
      return new ethers.providers.Web3Provider(await getProvider());
    else
      return ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);
  }

  const tokenContractInstance = async () => {
    if(walletType == WalletType.FleatoWallet) {
      const c = new ethers.Contract(TOKEN_CONTRACTS[symbol as keyof typeof TOKEN_CONTRACTS], IERC20.abi, await networkProvider());
      return c.connect(await getSigner());
    } else {
      const c = new ethers.Contract(TOKEN_CONTRACTS[symbol as keyof typeof TOKEN_CONTRACTS], IERC20.abi, (await networkProvider()).getSigner());
      return c;
    }
  }

  const readonlyContractInstance = async () => {
    return new ethers.Contract(TOKEN_CONTRACTS[symbol as keyof typeof TOKEN_CONTRACTS], IERC20.abi, ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint));
  }


  const tokenBalance = async () => {
    if(!account)
      return 0;
    const contract = await readonlyContractInstance();
    const units = parseFloat(ethers.utils.formatUnits(await contract.balanceOf(account), await contract.decimals()));
    return units;
  };

  const decimals = async () => {
    const contract = await tokenContractInstance();
    return await contract.decimals();
  };

  const approve = async (grantForContractAddress: string, amountWei: BigNumber): Promise<EthersTxn | Nonce> => {
    // console.log("Approving withdrawal", {symbol, grantForContractAddress, amountWei: ethers.utils.formatEther(amountWei), account});
    const contract = await tokenContractInstance();
    if(walletType !== WalletType.FleatoWallet) {
        const gasEstimate = await gasFee();
        // console.log("gasEstimate", gasEstimate);
        const tx = await contract.approve(grantForContractAddress, amountWei, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await contract.signer.getAddress()))?.pooled ?? 0, await contract.provider.getTransactionCount(await contract.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await contract.populateTransaction.approve(grantForContractAddress, amountWei, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await contract.populateTransaction.approve(grantForContractAddress, amountWei, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await contract.populateTransaction.approve(grantForContractAddress, amountWei, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await contract.signer.signTransaction({...await contract.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const allowance = async (spender: string): Promise<number> => {
    const contract = await tokenContractInstance();
    return parseFloat(ethers.utils.formatUnits(await contract.allowance(account, spender), await contract.decimals()));
  }

  return {allowance, approve, tokenBalance, decimals};
}
