import { createContext } from 'react'
import { Category, SelectableCategory } from '../models/product';
import useCategories from '../hooks/product/useCategories';

interface ICategoryContext {
  categories: Category[];
  artistCategories: Category[];
  selectableCategories: SelectableCategory[];
}

export const CategoryContext = createContext<ICategoryContext>({} as ICategoryContext);

export const CategoryProvider = ({ children }) => {

  const { selectableCategories, categories, artistCategories } = useCategories();

  return (
    <CategoryContext.Provider value={{ 
      categories, selectableCategories, artistCategories}}>
      {children}
    </CategoryContext.Provider>
  )
}