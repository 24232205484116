import { createContext } from 'react'
import useCredits from '../hooks/wallet/useCredits';
import { CreditsBalance, CreditsTransaction } from '../models/credits';

interface ICreditsContext {
  balance: CreditsBalance;
  usdBalance: number;
  activities: CreditsTransaction[];
  transferToFleatoWallet: () => Promise<void>;
}

const initProps: ICreditsContext = {
  balance: {fleato: 0},
  usdBalance: 0,
  activities: [],
  transferToFleatoWallet: async () => {}
}

export const CreditsContext = createContext<ICreditsContext>(initProps);

export const CreditsProvider = ({children}) => {
  const {balance, usdBalance, activities, transferToFleatoWallet} = useCredits();
  return (
    <CreditsContext.Provider value={{balance, usdBalance, activities, transferToFleatoWallet}}>
      {children}
    </CreditsContext.Provider>
  )
}