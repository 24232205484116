import { createContext } from 'react'
import useConnectedWallet from '../hooks/wallet/useConnectedWallet';
import useTokenBalance from '../hooks/wallet/useTokenBalance';
import { EthersTxn, Nonce, RecentBalance } from '../models/wallet';
import usePay, { PayExtraInfo } from '../hooks/smartcontracts/usePay';
import useQuote from '../hooks/wallet/useQuote';
import { Step, StepCallbacks } from '../components/progressbar/multi-step-process';
import useFleatoNFT from '../hooks/smartcontracts/useFleatoNFT';
import { WalletType } from '../hooks/wallet/models';


interface IConnectedWalletContext {
  walletConnectionActive: boolean;
  accountConnected?: string;
  chainIdConnected?: number;
  connectorsByName?: any;
  connectorError?: Error;
  connector?: any;
  activatingConnector?: any;
  recentBalance?: RecentBalance;
  balanceString?: string;
  shortAddress?: string;
  loadingBalance?: boolean;
  connectedWalletName?: string;
  activateWalletConnection: (connector: any, onError?: (error: Error) => void, throwErrors?: boolean) => Promise<void>;
  deactivateWalletConnection: () => void;
  payForOrder: (prefix: string, chargeId: string, paymentAmount: number, nftContract: string, nftTokenId: string, royaltyBasisPoints: number, extraInfo: PayExtraInfo, callbacks: StepCallbacks) => Promise<Step[]>;
  fetchTokenBalances: () => Promise<RecentBalance>;
  gasBalance: () => Promise<number>;
  submitInprogressPayment: (orderHeaderId: string, orderNumber: string, chargeCode: string, paymentAmount: number) => Promise<void>;
  approveAll: (grantForContractAddress: string) => Promise<EthersTxn | Nonce>;
  isApproved: (grantForContractAddress: string) => Promise<boolean>;
}

export const ConnectedWalletContext = createContext<IConnectedWalletContext>({} as IConnectedWalletContext);

export const ConnectedWalletProvider = ({children}) => {
  const {walletConnectionActive, activateWalletConnection, deactivateWalletConnection, accountConnected, chainIdConnected, library, connectorError, connector, setError, getLockBoxAddress, connectorsByName, getErrorMessage, activatingConnector, connectedWalletName, getProvider} = useConnectedWallet();
  const {quote} = useQuote();
  const {recentBalance, balanceString, shortAddress, loading: loadingBalance, fetchTokenBalances} = useTokenBalance(accountConnected, quote);
  const {payForOrder, gasBalance, submitInprogressPayment} = usePay({walletType: WalletType.ConnectedWallet, getProvider, account: accountConnected, fetchTokenBalances});

  const {isApproved, approveAll} = useFleatoNFT({walletType: WalletType.ConnectedWallet, account: accountConnected, getProvider});
  return (
    <ConnectedWalletContext.Provider value={{payForOrder, gasBalance, walletConnectionActive, activateWalletConnection, connectorError, connector, deactivateWalletConnection, accountConnected, chainIdConnected, connectorsByName, activatingConnector, recentBalance, balanceString, shortAddress, loadingBalance, fetchTokenBalances, connectedWalletName, submitInprogressPayment, isApproved, approveAll}}>
      {children}
    </ConnectedWalletContext.Provider>
  )
}