import { useContext} from 'react';
import "firebase/auth";
import {Event} from '../../models/user';
import { db, auth,functions } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'
import { httpsCallable } from 'firebase/functions';
import slug from "slug";
import { UserContext } from '../../contexts/UserContext';

const UPSERTEVENT = "upsertevent";


export default function useEvent() {

  const{user}=useContext(UserContext);

  const upsertEvent = async (event: Event): Promise<boolean> => {
    try {
      const userCallable = httpsCallable(functions, 'event-eventCall');
    const idSlug = slug(`${event?.title}-${new Date().getTime()}`);
    const eventInput={
      ...event,
      id:event?.id??idSlug
    }
    const result = await userCallable({requestType: UPSERTEVENT, input:eventInput});
      const response = result.data as Event;
      return true;
    } catch (err) { 
      Sentry.captureException(err); 
      console.log(err,"err") 
      return false;
    }
    
  }

  return {upsertEvent};
}