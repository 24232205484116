import { createContext } from 'react'
import { DownloadDetails, Resource } from '../models/user';
import useResources from '../hooks/user/useResources';

interface IResourceContext {
    resources: Resource[];
    isBusySavingResource: boolean;
    upsertResource: (add: Resource) => Promise<Resource | string>;
    fetchResource: (id: string) => Promise<Resource>;
    downloadResource: (userDetails: DownloadDetails) => Promise<Resource>;
    requestedResource: Resource;
}

export const ResourceContext = createContext<IResourceContext>({} as IResourceContext);

export const ResourceProvider = ({ children }) => {

    const { resources, isBusySavingResource, upsertResource, fetchResource, downloadResource, requestedResource } = useResources();

    return (
        <ResourceContext.Provider value={{
            resources, isBusySavingResource, upsertResource, fetchResource, downloadResource, requestedResource
        }}>
            {children}
        </ResourceContext.Provider>
    )
}