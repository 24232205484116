export interface Log {
  removed: boolean;
  transactionLogIndex: number;
  address: string;
  data: string;
  topics: string[];
  logIndex: number;
}

export interface Meta {
  type: string;
  symbol?: string;
  units?: number;
  data: string;
}

export interface Receipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionHash: string;
  transactionIndex: number;
  type: number;
  gasUsed: string;
  logsBloom: string;
  blockHash: string;
  blockNumber: number;
  confirmations: number;
  cumulativeGasUsed: string;
  logs: Log[],
  status: 0 | 1;
  meta?: Meta;
}

export enum SwapContractApprovedState {
  uninitialized = "uninitialized",
  suspense = "suspense",
  okToDeliver = "okToDeliver",
  okToPayout = "okToPayout",
  okToRefund = "okToRefund",
  scavenging = "scavenging"
}

export const SwapContractApprovedStates = [
  SwapContractApprovedState.uninitialized,
  SwapContractApprovedState.suspense,
  SwapContractApprovedState.okToDeliver,
  SwapContractApprovedState.okToPayout,
  SwapContractApprovedState.okToRefund,
  SwapContractApprovedState.scavenging,
];