import { Event } from "./event";
import { Address } from "./user";

export interface Category {
  id: string;
  name: string;
  order?: number;
  data?: CategoryData[];
}

export interface SelectableCategory {
  label: string;
  value: string;
  children?: SelectableCategory[];
}

export interface CategoryData {
  name: string;
  data?: CategoryData[];
}

export interface Collection {
  id: string;
  name: string;
  description: string;
  userId: string;
}

export enum ShippingMethod {
  shipping = "shipping",
  local_pickup = "local_pickup",
  local_delivery = "local_delivery",
  e_delivery = "e_delivery",
  other_1 = "other_1",
  other_2= "other_2",
  other_3 = "other_3",
}

export interface Shipping {
  method: ShippingMethod;
  originZip?: string;
  shipper?: string;
  leadTimeDaysMin?: number;
  leadTimeDaysMax?: number;
  price?: number;
  deliveryRadius?: number;
  sellerAddress?: Address;
  name?: string;
  description?: string;
}

export interface Gallery {
  url: string;
  thumbnail: string;
  type?: 'video' | 'image';
}

export interface Listing {
  buyNowPrice: number;
  msrpPrice?: number;
  minimumPrice?:number;
  allowPriceRange?: boolean;
  reservePrice?: number;
  lastBid?: number;
  listingEndsOn: string;
  autoRelist: boolean;
  locationName?: string;
  locationLatLng?: number[];
  quantity: number;
  soldQuantity?: number;
  reservedQuantity?: number;
  isAuction: boolean;
  raiseMinimum?: number;
  biddingStartsOn?: string;
  minBidAmount?: number;
  taxExempt?: boolean;
}

export interface NFT {
  contractAddress: string;
  tokenId: string;
  mintToAddress: string;
  royaltyPercentage?: number;
}

export interface BidEntry {
  bid: number;
  bidAt: string;
  user: string;
  phoneNumber?: string;
  email?: string;
  name?: string;
}

export interface ProductStats {
  productId: string;
  views?: number;
  likes?: number;
  maxBid: number;
  maxBidder: string;
  maxBidAt: string;
  bidders: string[];
  bids: Bid[];
  winningBid?: BidEntry;
  reserveMet: boolean;
  auctionId?: string;
  agencyIds?: string[];
}

export interface Seller {
  id: string;
  handle: string;
  verified: boolean;
}

export interface VariationOption {
  name: string;
  sku: string;
  price: number;
  msrpPrice?:number;
  image: string;
  size?: string;
  color?: string;
  colorCode?: string;
  inStock?: boolean;
  gallery?: Gallery[];
  externalId?: string;
  shippingPrice?: number;
  type: "art" | "merchandise";
  eventBenefits?: EventBenefits;
}

export interface Variation {
  name: string;
  displayName?: string;
  sku: string;
  price: number;
  msrpPrice?:number;
  image: string;
  gallery?: Gallery[];
  externalId?: string;
  options?: VariationOption[];
  shippingPrice?: number;
  type: "art" | "merchandise";
  facetTags?: string[];
  nft?: NFT;
  isNFT?: boolean;
  eventBenefits?: EventBenefits;
  shipper?: string;
}

export interface MetaData {
  n: string;
  v: string;
}

export interface CustomizationChoice {
  customizationName?: string;
  optionName: string;
  image?: string;
  video?: string;
  gallery?: Gallery[];
  description?: string;
  price?: number;
}

export interface Customization {
  questions: CustomizationQuestion[];
  presets: CustomizationPreset[];
}

export interface CustomizationPreset {
  name: string;
  additionalQuestions?: string[];
  image?: string;
  video?: string;
  description?: string;
  selectedOptions?: CustomizationChoice[];
}

export interface CustomizationQuestion {
  name: string;
  required: boolean;
  index: number;
  description?: string;
  image?: string;
  video?: string;
  gallery?: Gallery[];
  options: CustomizationChoice[] | 'CarSelector';
}

export interface ProductCustomizationRequest {
  buyer: string;
}

export interface Product {
  id: string;
  stakeCode?: string;
  name: string;
  sku: string;
  condition: "new" | "used";
  categories?: string[];
  image: string;
  shippingMethods?: Shipping[];
  listing: Listing;
  nft?: NFT;
  listed: string;
  gallery: Gallery[];
  description: string;
  seller?: Seller;
  taxRate?: number;
  createdAt: string;
  lastUpdated: string;
  status: string;
  variations?: Variation[];
  zmeta?: MetaData[];
  collection: string;
  commission?: Commission;
  artist?: string;
  isNFT?: boolean;
  isPhysical?: boolean;
  isCustomizable?: boolean;
  customizationOptions?: Customization;
  categoryHierarchy?: CategoryHierarchy[];
  returnPolicy: ReturnPolicy;
  productTags:string[];
  flags?: string[];
  stake: number;
  allowMerchandise?: boolean;
  confirmationMessageId?: string;
  agencyIds?: string[];
  type?: "product" | "ticket" | "event";
  rating?: number[];
  eventDetails?: Event;
  eventBenefits?: EventBenefits;
  auctions?: string[];
  currentBid?: CurrentBid;
  externalIds?: {
    wooCommerceProductId?: string;
    auctionId?: number;
  }
}

export interface CurrentBid {
  bid: number;
  bidder: string;
}

export interface EventBenefits {
  benefits?: EventBenefit[];
  scans?: EventScan[];
}

export interface EventScan {
  name: string;
  usedScans?: number;
  remainingScans?: number;
  ticketNumber: number;
  scanningTimeStamps?: EventScanTimeStamp[];
}

export interface EventScanTimeStamp {
  time: string;
  agent: string;
}
export interface EventBenefit {
  availableScans: number;
  name: string;
  allowedAgents?: string[];
}

export interface ProductViewModel {
  name: string;
  description: string;
  sku: string;
  condition: "new" | "used";
  category: string;
  buyNowPrice: number;
  msrpPrice?:number;
  minimumPrice?:number;
  allowPriceRange?: boolean;
  localDelivery?: boolean;
  localPickup?: boolean;
  shipping?: boolean;
  originZip?: string;
  shipper?: string;
  shippingLeadTimeDaysMin?: number;
  shippingLeadTimeDaysMax?: number;
  localPickupLeadTimeDaysMin?: number;
  localPickupLeadTimeDaysMax?: number;
  localDeliveryLeadTimeDaysMin?: number;
  localDeliveryLeadTimeDaysMax?: number;
  locationName?: string;
  locationLatLng?: number[];
  shippingPrice?: number;
  localDeliveryPrice?: number;
  deliveryRadius?: number;
  autoRelist?: boolean;
  quantity: number;

  isAuction?: boolean;
  auctionLengthDays?: number;
  reservePrice?: number;

  autoPriceDrop?: boolean;
  autoPriceDropPercent?: number;
  autoPriceDropDays?: number;

  termsAcknowledged: boolean;
  addVariations?: boolean;
  zmeta?: MetaData[];
  variations?: Variation[];
  eventBenefits?: EventBenefits;
  collection: string;
  commissionCompletionDuration?: number;
  commissionTerminationFee?: number;
  isNFT?: boolean;
  artist: string;
  isPhysical?: boolean;
  isCustomizable?: boolean;
  customizationOptions?: Customization;
  isRoyalty?: boolean;
  categoryHierarchy?: CategoryHierarchy[];
  returnPolicy: ReturnPolicy;
  productTags:string[];
  allowMerchandise:boolean;
  type?: "product" | "ticket"| "event";
  status?: string;
  minBidAmount?: number;
  taxExempt?: boolean;
}

export interface Commission {
  commissionCompletionDuration: number;
  commissionTerminationFee: number;
}
export interface PublicProfile {
  id: string;
  photoUrl?: string;
  handle?: string;
}

export interface PublicProfileByHandle {
  id?: string;
  photoUrl?: string;
  handle?: string;
  wallets?: string[];
}


export interface Bid {
  productId: string;
  bid: number;
  user: string;
  bidAt: string;
  phoneNumber?: string;
  email?: string;
  name?: string;
}

export interface PrivateBid extends Bid {
  maxBid: number;
}

export interface PrivateBidWithCache extends PrivateBid {
  userCacheId: string;
}

export interface GuestBid extends PrivateBid {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface GuestBidWithCache extends GuestBid {
  userCacheId: string;
}

export interface UserCacheMaxBid {
  auctionId: string;
  productId: string;
  maxBid: number;
  bidAt: string;
}

export interface Auction {
  product: Product;
  sellerProfile: PublicProfile;
  stats?: ProductStats;
  quote?: string;
}

export interface ProductWithStats {
  product: Product;
  stats?: ProductStats;
}

export interface SellerStrategy {
  reservePrice: number;
  autoRelist: boolean;
  autoPriceDropPercent: number;
  auctionLengthDays: number;
}

export interface NFTStrategy {
  owner: string;
  quantity: number;
  royaltyBasisPoints: number;
}

export interface ListingRequest {
  product: Product,
  sellerStrategy: SellerStrategy,
  nftStrategy: NFTStrategy,
  sellerSecretMedia: string[];
}


export interface Reward {
  basePercent: number;
  signUpUSD: number;
  referrerUSD: number;
}

export interface TypesenseProduct {
  id: string;
  productSku: string;
  sku: string;
  name: string;
  artist: string;
  description: string;
  condition: string;
  collection: string;
  categories: string[];
  buyNowPrice: number;
  msrpPrice:number;
  minimumPrice:number;
  sellingPrice: number;
  seller: string;
  shippingMethods: string[];
  status: string;
  location: number[];
  listed: number;
  listingEndsOn: number;
  views: number;
  likes: number;
  bids: number;
  maxBid: number;
  isAuction: boolean;
  isNFT: boolean;
  isPhysical: boolean;
  isMerchandise: boolean;
  isCustomizable: boolean;
  reserveMet: boolean;
  lastBidAt: number;
  locationName: string;
  image: string;
  quantity: number;
  availableQuantity: number;
  rating?: number[];
  type?: "product" | "event" | "ticket";
}

export interface TypesenseProductEvent {
  objectID: string;
  id: string;
  productSku: string;
  sku: string;
  name: string;
  description: string;
  categories: string[];
  buyNowPrice: number;
  msrpPrice: number;
  minimumPrice: number;
  sellingPrice: number;
  seller: string;
  artist: string;
  collection: string;
  status: string;
  location: number[];
  listed: number;
  listingEndsOn: number;
  views: number;
  likes: number;
  venue: string;
  startDate: string;
  startTime: string;
  priceCurrency: string;
  image: string;
  quantity: number;
  availableQuantity:number;
  isNFT?: boolean;
  isPhysical?: boolean;
  stake: number;
  "categoryHierarchy.lvl0": string[];
  "categoryHierarchy.lvl1": string[];
  "categoryHierarchy.lvl2": string[];
  "categoryHierarchy.lvl3": string[];
  filterTags: string[];
  facetTags: string[];
  rating?: number[];
}

export enum PRODUCT_STATUS {
  LISTED = "listed",
  EXPIRED = "expired",
  BOUGHT = "bought",
  NOTFORSALE = 'not-for-sale'
}

export interface ProductReview {
  productId?: string;
  userId?: string;
  rating: number;
  stakeCode?: string;
  stakeAmount?: number;
  verifiedBuyer?: boolean;
  lastReviewedOn: string;
  comment: string;
  userPhotoUrl?: string;
  userHandle?: string;
  externalId?: number;
  images?: string[];
  id: string;
}

export interface ProductReviewRequest {
  productId: string;
  rating: number;
  comment: string;
}

export interface CategoryHierarchy {
  lvl0?: string,
  lvl1?: string;
  lvl2?: string;
  lvl3?: string;
}

export interface ReturnPolicy {
  isReturnable: boolean;
  returnDays?: number;
}

export interface CampaignData {
  handle?: string;
  userId?: string;
  utm_campaign: string;
  utm_content: string;
  utm_id: string;
  utm_source: string;
  utm_term: string;
}

export interface ShortUrlDoc {
  urlEmail: string;
  shortUrl: string;
  handle: string;
  productId: string;
  urlFacebook: string;
  urlTwitter: string;
  urlQrCode: string;
  userId: string;
}

export interface OrderConfirmationMessage {
  id: string;
  message: string;
  ctaLink: string;
}

export interface PrintfulProductDescriptionRequest {
  id: string;
  description: string;
}

export interface PrintfulProductRating {
  rating: number[];
  id: string;
}