import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import ERC20 from "../../smartcontracts/IERC20.json";
import { ExchangeRate } from '../../models/payment';
import useQuote from "./useQuote";
import { sixDecimals, twoDecimals, zeroOrMoreDecimals } from '../../util/util';
import { Quote, RecentBalance } from '../../models/wallet';
import {fleatoConfig} from "../../fleato-config";
import { auth } from "../../firebaseutil";

export const shortHex = (hex?: string) => {
  return hex ? `${hex.slice(0,6)}...${hex.slice(-4)}` : "";
}


const networkProvider = () => ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);

const tokenContractReader = async (address: string) => {
  const tokenContract = new ethers.Contract(address, (ERC20 as any).abi, networkProvider());
  return tokenContract;
};

const tokenBalance = async (walletAddress: string, contractAddress: string) => {
  let retries = 0;
  while(retries < 1)
    try {
    const tokenContract = await tokenContractReader(contractAddress);
    const units = parseFloat(ethers.utils.formatUnits(await tokenContract.balanceOf(walletAddress), await tokenContract.decimals()));
    return units;
    } catch(err) {
      console.log("useTokenBalance tokenBalance Error", err, "attempt", retries);
      retries++;
    }
    return 0.0;
};

//Native token balance - will be 18 decimals.
const balanceOf = async (walletAddress: string) => parseFloat(parseFloat(ethers.utils.formatEther(await networkProvider().getBalance(walletAddress))).toFixed(4));

const balanceString = (balance: RecentBalance | undefined) => {
  if(!balance)
    return undefined;
  const b = `${balance.fleato.v ? `fleato $${zeroOrMoreDecimals(balance.fleato.v)}, ` : ""}${balance.usdc?.v ? `usd coin $${zeroOrMoreDecimals(balance.usdc.v)}, ` : ""}${balance.bitcoin?.v ? `bitcoin $${zeroOrMoreDecimals(balance.bitcoin.v)}, ` : ""}${balance.ethereum?.v ? `ethereum $${zeroOrMoreDecimals(balance.ethereum.v)}, ` : ""}${(balance.matic?.v ?? 0) > 1 ? `polygon $${zeroOrMoreDecimals(balance.matic?.v)}, ` : ""}`
  return b ? b.slice(0,-2) : b;
}

export default function useTokenBalance(walletAddress: string | undefined, fleatoQuote: Quote) {
  const [loading, setLoading] = useState(true);
  const [unitBalance, setUnitBalance] = useState<RecentBalance>();
  const [recentBalance, setRecentBalance] = useState<RecentBalance>();

  useEffect(() => {
    if(!!!auth?.currentUser?.uid){
        setRecentBalance(undefined);
        setUnitBalance(undefined);
    }
}, [auth?.currentUser?.uid])


  const [exchangeRate, setExchangeRate] = useState<ExchangeRate>({
    bitcoin: {usd: 60000}, bitcoincash: {usd: 600}, dogecoin: {usd: 0.25}, ethereum: {usd: 4500}, fleato: {usd: 0.01}, litecoin: {usd: 250}, matic: {usd: 1.5}, usdc: {usd: 1.0}
  });

  useEffect(()=> {
    // console.log("useTokenBalance initialized with wallet", walletAddress, "quote", fleatoQuote);
    fetchExchangeRate();
  }, [])

  useEffect(() => {
    if(fleatoQuote) {
      // console.log("useTokenBalance fleatoQuote changed after exchangeRate set, updating exchangeRate", {walletAddress, exchangeRate, fleatoQuote})
      const newExchangeRate = {...exchangeRate, fleato: {usd: fleatoQuote.fleatoToUsdc}};
      setExchangeRate(newExchangeRate);
    }
  }, [fleatoQuote])

  useEffect(() => {
    if(walletAddress) {
      // console.log("useTokenBalance wallet address set", walletAddress, "fetching unit balance");
      fetchUnitBalances();
    }
  }, [walletAddress])

  useEffect(()=> {
    if(unitBalance && exchangeRate) {
      // console.log("useTokenBalance exchangeRate changed after recentBalance fetch, updating balance", {walletAddress, exchangeRate, fleatoQuote})
      fetchRecentBalances(exchangeRate, unitBalance);
    }
  }, [exchangeRate, unitBalance])
  


  const fetchUnitBalances = async () : Promise<RecentBalance> => {
    if(!walletAddress)
      return {
        fleato: {u: 0, v: 0, p: "0"},
        bitcoin: {u: 0, v: 0, p: "0"},
        ethereum: {u: 0, v: 0, p: "0"},
        usdc: {u: 0, v: 0, p: "0"},
        matic: {u: 0, v: 0, p: "0"},
        total: {u: 0, v: 0, p: "0"}
      };

    const unitBalance = {
      fleato: {u: await tokenBalance(walletAddress, fleatoConfig.contractFleatoToken), v: 0, p: "0"},
      bitcoin:{u: await tokenBalance(walletAddress, fleatoConfig.contractWbtcToken), v: 0, p: "0"},
      ethereum:{u: await tokenBalance(walletAddress, fleatoConfig.contractWethToken), v: 0, p: "0"},
      usdc: {u: await tokenBalance(walletAddress, fleatoConfig.contractUsdcToken), v: 0, p: "0"},
      matic: {u: await balanceOf(walletAddress), v: 0, p: "0"},
      total: {u: 0, v: 0, p: "0"}
    }
    setUnitBalance(unitBalance);
    return unitBalance;
  }

  const fetchRecentBalances = async (exchangeRate: ExchangeRate, unitBalance: RecentBalance) : Promise<RecentBalance> => {
    let recentBalance = {...unitBalance};
    recentBalance.fleato.v = parseFloat(twoDecimals(recentBalance.fleato.u * exchangeRate.fleato.usd));
    recentBalance.bitcoin ? recentBalance.bitcoin.v = parseFloat(sixDecimals(recentBalance.bitcoin.u * exchangeRate.bitcoin.usd)) : null;
    recentBalance.usdc ? recentBalance.usdc.v = recentBalance.usdc.u * exchangeRate.usdc.usd : null;
    recentBalance.ethereum ? recentBalance.ethereum.v = parseFloat(twoDecimals(recentBalance.ethereum.u * exchangeRate.ethereum.usd)) : null;
    recentBalance.matic ? recentBalance.matic.v = parseFloat(twoDecimals(recentBalance.matic.u * exchangeRate.matic.usd)) : null;
    recentBalance.total.v = recentBalance.fleato.v + (recentBalance.bitcoin?.v ?? 0) + (recentBalance.ethereum?.v ?? 0) + ((recentBalance.matic?.v ?? 0)  > 1 ? (recentBalance.matic?.v ?? 0) : 0) + (recentBalance.usdc?.v ?? 0);
    recentBalance.total.u = recentBalance.total.v;
    recentBalance.fleato.p = zeroOrMoreDecimals(recentBalance.fleato.v * 100 / recentBalance.total.v);
    recentBalance.bitcoin ? recentBalance.bitcoin.p = zeroOrMoreDecimals(recentBalance.bitcoin.v * 100 / recentBalance.total.v) : null;
    recentBalance.ethereum ? recentBalance.ethereum.p = zeroOrMoreDecimals(recentBalance.ethereum.v * 100 / recentBalance.total.v) : null;
    recentBalance.usdc ? recentBalance.usdc.p = zeroOrMoreDecimals(recentBalance.usdc.v * 100 / recentBalance.total.v) : null;
    recentBalance.matic ? recentBalance.matic.p = zeroOrMoreDecimals(recentBalance.matic.v * 100 / recentBalance.total.v) : null;

    setRecentBalance(recentBalance);
    // console.log("useTokenBalance Recent balance", recentBalance, {walletAddress, exchangeRate, fleatoQuote});
    setLoading(false);
    return recentBalance;
  }

  const fetchTokenBalances = async (): Promise<RecentBalance> => {
    const unitBalance = await fetchUnitBalances();
    // if(exchangeRate && unitBalance)
    return await fetchRecentBalances(exchangeRate, unitBalance);
    // else
    //   return undefined;
  }

  const fetchExchangeRate = useCallback(async () => { 
    let result;
    try {
      const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Cdogecoin%2Clitecoin%2Cbitcoin-cash%2Cmatic-network%2Cusd-coin&vs_currencies=usd");
      result = await response.json();
    } catch(err) {
      result = {"ethereum":{"usd":1500.00},"litecoin":{"usd":50.00},"dogecoin":{"usd":0.06},"matic-network":{"usd":0.75},"bitcoin":{"usd":20000.00},"usd-coin":{"usd":1.00},"bitcoin-cash":{"usd":110.00}}
    }
    const exchRate: ExchangeRate = result;
    exchRate.bitcoincash = result["bitcoin-cash"]; // hack, since - not allowed in property names
    exchRate.matic = result["matic-network"];
    exchRate.fleato = {usd: fleatoQuote.fleatoToUsdc}; // Until token price is established independently
    exchRate.usdc = result["usd-coin"];
    // console.log("useTokenBalance exchRate", exchRate);
    setExchangeRate(exchRate);
  }, []);

  const refreshBalance = async () => {
    if(walletAddress) {
      console.log("refreshing balance");
      
      await fetchExchangeRate();
      await fetchUnitBalances();
    }
  }

  return {shortAddress: shortHex(walletAddress), fetchTokenBalances, recentBalance, balanceString: balanceString(recentBalance), loading, refreshBalance };
}