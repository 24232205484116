import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import useDebounce from '../../hooks/helper/useDebounce'
import { FaSearch } from 'react-icons/fa'
import { SearchContext } from '../../contexts/typesense/SearchContext'
import { tagSearch } from '../../lib/events'
import { CategoriesContext } from '../../contexts/CategoriesContext'
import { queryWithoutStopWords } from '../../util/util'
import { logDOM } from '@testing-library/dom'


/**
 * https://www.algolia.com/doc/api-reference/widgets/search-box/react/
 * https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 */ 

const SearchBox = ({ currentRefinement, isSearchStalled, refine,  setClearSearchBox, clearSearchBox}) => {
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);
    const router = useRouter();
    const { searchScope } = useContext(SearchContext);
    const { isRerender } = useContext(CategoriesContext);
    const marketPlace = (router?.asPath?.split("/")?.[1] === "m" && router?.asPath?.split("/")?.[2] === "marketplace") ? router?.asPath?.split("/")?.[3] : "fleato";    

    useEffect(() => {
        if(clearSearchBox) {
            setSearchTerm("");
            setClearSearchBox(false);
        }
    }, [clearSearchBox])

    useEffect(() => {
        if (debouncedSearchTerm) {
            // console.log(searchScope, "$refine - debouncedSearch debouncedSearchTerm hook, search term = ", searchTerm)
            refine(queryWithoutStopWords(searchTerm));
            tagSearch(queryWithoutStopWords(searchTerm));
        } else {
            // console.log(searchScope, "$refine - debouncedSearch debouncedSearchTerm hook, clear filter")
            refine('')
        }
    }, [debouncedSearchTerm])

    const startSearching = async () => {
        if(marketPlace === "fleato") {
            router.push(`/categories/all`);
        }
        else {
            router.push(`/categories/all?agencyId=${marketPlace}`);
        }
        document.body.style.overflow = "auto";

    }
    useEffect(() => {
        setSearchTerm('');
        refine('');
    }, [isRerender])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }} noValidate role="search" className="d-flex align-items-center ais-SearchBox-form ">
            <button type="button" title="Search for your treasure" className="ais-SearchBox-submit" onClick={() => startSearching()}>
                <FaSearch size={12} className="mb-1" />
            </button>
            <input
                type="search" placeholder="Search items, collections, and accounts" autoComplete="on" className="ais-SearchBox-input py-2"
                value={searchTerm}
                style={{ width: "100%", paddingRight: "4px" }}
                onChange={e => { setSearchTerm(e.target.value) }}
                onKeyPress={event => {
                    if (router.pathname !== "/categories/all") { //  event.key === 'Enter' && router.pathname === "/"
                        startSearching();
                    }
                }}
            />
        </form>
    )
}

const DebouncedSearchBox = connectSearchBox(SearchBox)

export default DebouncedSearchBox