import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { fleatoConfig } from "../../fleato-config";
import { DbUser } from "../../models/user";
import Blockie from "./Blockie";
import Wallet from "./wallet";

const handleLookup = (handle: string, address: string, dbUser: DbUser) => {
  if (fleatoConfig.contractDaoMembershipBot === handle)
    return "Fleato Membership Bot";
  else if (dbUser && dbUser.handle == handle)
    return `Your ${dbUser?.fleatoWallets?.find(w => w.address == address) ? "Fleato" : ""} Wallet`;
  else
    return handle;
}

const WalletCard = ({ address, handle }: { address: string, handle: string }) => {
  const { dbUser } = useContext(UserContext);
  const [copied, setCopied] = useState(false);
  return (
    <div className='d-flex align-items-center'>
      <Blockie address={address} scale={8} />
      <div>
        <p className='ms-3 mb-0'>{handleLookup(handle, address, dbUser)}</p>
        <p className='ms-3 mb-0'><Wallet address={address} /></p>
      </div>
    </div>
  );
};

export default WalletCard;