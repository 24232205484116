import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from "./firebase-config";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import localforage from "localforage";

let firebaseApp: FirebaseApp;

if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  // console.log("get apps length", getApps());
  firebaseApp = getApps()[0];
}

let db = initializeFirestore(firebaseApp, {experimentalForceLongPolling: true, ignoreUndefinedProperties: true})


const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();
// auth.settings.appVerificationDisabledForTesting = true;
const functions = getFunctions(firebaseApp);

// const firebaseCloudMessaging = {
//     //checking whether token is available in indexed DB
//     tokenInlocalforage: async () => {
//         return localforage.getItem("fcm_token");
//     },
//     //initializing firebase app
//     init: async function () {
//         if (!firebaseApp) {
//             initializeApp(firebaseConfig)
//             try {
//                 const tokenInLocalForage = await this.tokenInlocalforage();
//                 //if FCM token is already there just return the token
//                 if (tokenInLocalForage !== null) {
//                     return tokenInLocalForage;
//                 }
//                 //requesting notification permission from browser
//                 const status = await Notification.requestPermission();
//                 const messaging = getMessaging(firebaseApp);
//                 if (status && status === "granted") {
//                     return getToken(messaging, { vapidKey: 'BL9YMn4yF6XvNXt9SXRtfnt67d0fW99nVmRK3CysM9fjadW9nzFakSl1k0_8eNnHzAVIhlEcVIWFuy2-blYCPZ4'}).then((currentToken) => {
//                         if (currentToken) {
//                           console.log('current token for client: ', currentToken);
//                           localforage.setItem("fcm_token", currentToken);

//                           let device = {
//                             id: "web",
//                             deviceName: "web",
//                             deviceYearClass: "web",
//                             platform: "web",
//                             systemVersion: "web",
//                             appVersion: "web",
//                             notificationId: currentToken,
//                             isDevice: "web",
//                             modelName: "web",
//                             lastUpdated: new Date().toISOString(),
//                             status: "active"
//                           };             

//                           const registerDevice = async () => {
//                             const userCallable = httpsCallable(functions, 'user-userCall');
//                             try {
//                               const result = await userCallable({requestType: "registerDevice", input: device});
//                             } catch(err) {
//                               console.log("Error while registering",err);
//                               throw(err);
//                             }
//                           }                        
//                         } else {
//                           console.log('No registration token available. Request permission to generate one.');
//                         }
//                       }).catch((err) => {
//                         console.log('An error occurred while retrieving token. ', err);
//                       });
//                 }
//             } catch (error) {
//                 console.error(error);
//                 return null;
//             }
//         }
//     },
// };

// const firebaseCloudMessaging = async(setTokenFound) => {

//   const tokenInlocalforage = async () => {
//     return localforage.getItem("fcm_token");
//   }

//   const tokenInLocalForage = await tokenInlocalforage();
//   //if FCM token is already there just return the token
//   if (tokenInLocalForage !== null) {
//       return tokenInLocalForage;
//   }
//   //requesting notification permission from browser
//   const status = await Notification.requestPermission();
//   const messaging = await getMessaging(firebaseApp);

//   console.log("getMessaging(firebaseApp) = ", messaging);
  

//   const currentToken = await getToken(messaging, { vapidKey: firebaseConfig.webPushKeyPair});
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       setTokenFound(true);
//       localforage.setItem("fcm_token", currentToken);

//       let device = {
//         id: "web",
//         deviceName: "web",
//         deviceYearClass: "web",
//         platform: "web",
//         systemVersion: "web",
//         appVersion: "web",
//         notificationId: currentToken,
//         isDevice: "web",
//         modelName: "web",
//         lastUpdated: new Date().toISOString(),
//         status: "active"
//       };       
      
//         const userCallable = httpsCallable(functions, 'user-userCall');
//         try {
//           const result = userCallable({requestType: "registerDevice", input: device});
//         } catch(err) {
//           console.log("Error while registering",err);
//           throw(err);
//         }

//       // Send the token to your server and update the UI if necessary
//       // ...
//     } else {
//       // Show permission request UI
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false);
//     // ...
//     }
// }



// const onMessageListener = () => 
//   new Promise((resolve) => {
//     onMessage(getMessaging(firebaseApp), (payload) => {
//       resolve(payload);
//     });
//   });


export {db, auth, functions, firebaseApp};
