import "firebase/auth";
import {useState, useEffect} from "react";
import { httpsCallable } from "firebase/functions";
import { useWeb3React } from '@web3-react/core'
import { useEagerConnect } from "./useEagerConnect";
import { injected, ledger, trezor, walletconnect, walletlink } from "./connectors";
import { UnsupportedChainIdError } from '@web3-react/core'
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect, WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { getAuth } from 'firebase/auth';
import { db, auth, functions } from '../../firebaseutil';
import { fleatoConfig } from "../../fleato-config";
import { ethers } from "ethers";
import * as Sentry from "@sentry/nextjs";

declare global {
  interface Window {
    ethereum: any;
  }
}

export enum ConnectorNames {
  injected = 'injected',
  walletConnect = 'walletConnect',
  walletLink = 'walletLink',
  ledger = 'ledger',
  trezor = 'trezor',
}

enum RequestTypes {
  updateProfile = "updateProfile",
  addWallet = "addWallet"
}

const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.injected]: injected,
  [ConnectorNames.walletConnect]: walletconnect,
  [ConnectorNames.walletLink]: walletlink,
  [ConnectorNames.ledger]: ledger,
  [ConnectorNames.trezor]: trezor,
}

const getConnectorName = (connector) => {
  if(connector === connectorsByName.injected)
    return "Metamask";
  else if(connector === connectorsByName.walletConnect)
    return ConnectorNames.walletConnect;
  else if(connector === connectorsByName.walletLink)
    return "Coinbase";
  else if(connector === connectorsByName.ledger)
    return ConnectorNames.ledger;
  else if(connector === connectorsByName.trezor)
    return ConnectorNames.trezor;
  else
    return "";
}


export default function useConnectedWallet() {
  const [nativeBalance, setNativeBalance] = useState(0.0);
  const {active, activate, deactivate, account, chainId, library, error, connector, setError} = useWeb3React();
  const triedEager = useEagerConnect();
  const [activatingConnector, setActivatingConnector] = useState<any>();

  // console.log("connected wallet", {active, account, chainId, library, error});
  
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  useEffect(()=> {
    if (!!account && !!library) {
      let stale = false
      library.getBalance(account).then((balance: any) => {
        if (!stale) {
          setNativeBalance(balance)
        }
      }).catch(() => {
        if (!stale) {
          setNativeBalance(null)
        }
      })
      return () => {
        stale = true
        setNativeBalance(undefined)
      }
    }
  }, [account, chainId, library])

  useEffect(()=> {
    // console.log("new wallet connection", connector);
    if (!!connector && !!account && !!chainId && !!auth?.currentUser?.uid) {
      (async () => {
        await saveWalletAddress(account, chainId, getConnectorName(connector))
      })();
    }
  }, [connector, account, chainId, auth?.currentUser?.uid])

  useEffect(()=> {
    (async () => {
      if(connector) {
        const {provider} = await connector.activate();
        // console.log("provider in activateWalletConnection", provider);
      }
    })();
  }, [connector])

  const getLockBoxAddress = async (orderNumber: string): Promise<string> => {
    // const lockBox = httpsCallable(functions, 'payment-lockBoxAddress');
    // const paymentAddress = await lockBox({ orderNumber }) as {data: string};
    // return paymentAddress.data;
    throw new Error("getLockBoxAddress Service deprecated. Please revisit the logic")
  }

  const saveWalletAddress = async (walletAddress: string, chainId: number, connectorName: string) => {
    if(!auth?.currentUser?.uid) {
      // console.log("Wallet address can't be saved before login")
      return;
    }
    // console.log("Saving wallet", {walletAddress, chainId, connectorName});
    try{
    const addWallet = httpsCallable(functions, 'user-userCall');
    await addWallet({requestType: RequestTypes.addWallet, input: {address: walletAddress, source: connectorName, chainId, handle: connectorName}});
  }catch(err){
    Sentry.captureException(err);
    console.log(err);
  }
  }

  const getErrorMessage = (error: Error) => {
    if (error instanceof NoEthereumProviderError) {
      return 'No Ethereum browser extension detected, install MetaMask on desktop to use this payment method'
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network. Only Polygon (matic) and Ethereum are supported"
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect
    ) {
      return 'Please authorize fleato.com to access your Ethereum account.'
    } else {
      console.error(error)
      return 'An unknown error occurred. Check the console for more details.'
    }
  }

  const connectedWalletName = getConnectorName(connector);

  const switchToPolygonNetwork = async (): Promise<boolean> => {
    if(window.ethereum) {
      if(window.ethereum.networkVersion !== fleatoConfig.maticChainId) {
        try {
          await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
            params: [{ chainId: ethers.utils.hexValue(fleatoConfig.maticChainId)}],
          });
          return true;
        } catch (error) {
          console.error("Switch network error", error);
          if ((error as any)?.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: fleatoConfig.polygonNetworkName,
                    chainId: ethers.utils.hexValue(fleatoConfig.maticChainId),
                    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                    rpcUrls: [fleatoConfig.polygonRpcUrl],
                    blockExplorerUrls: [fleatoConfig.blockExplorer]
                  },
                ],
              });
              return true;
            } catch(addError) {
              console.error("add network error:", addError);
              return false;
            }
          }
        }
      } else {
        return true;
      }
    } else {
      alert("Please install Metamask Plugin to proceed");
      return false;
    }


  }

  const activateWalletConnection = async (connectorInput) => {
    const swithStatus = await switchToPolygonNetwork();
    if(!swithStatus) {
      alert("You need to be connected to the Polygon Network to proceed");
      return;
    }
    activate(connectorInput);
  }

  const deactivateWalletConnection = async () => {
    deactivate();
    // console.log("deactivate called");
    // console.log(connector)
    if (connector instanceof WalletConnectConnector && !!connector.walletConnectProvider) { 
      // console.log("removing uri");
      connector.walletConnectProvider = undefined 
    }
  }

  const getProvider = async () => await connector?.getProvider();

  // console.log("account", account, "chain", chainId);
  return {walletConnectionActive: active, activateWalletConnection, deactivateWalletConnection, activatingConnector, accountConnected: account, chainIdConnected: chainId, library, connectorError: error, connector, nativeBalance, setError, getLockBoxAddress, getErrorMessage, connectorsByName, connectedWalletName, getProvider};
}