import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { LoginWrapper } from "../../styles/login-style";
import { ReactSVG } from "react-svg";
import logo from "../../public/images/whiteLogo.svg";
import { AiOutlineClose } from "react-icons/ai";
import { UserContext } from "../../contexts/UserContext";
import { getAuth } from "firebase/auth";
import { e164PhoneNumber } from "../../util/util";
import animeLoading from "../../public/lottie/14117-crypto-loader.json";
import Lottie from "react-lottie";
import useUpdateProfile from "../../hooks/user/useUpdateProfile";
import SignInForm from "./SignInForm";
import VerificationCodeForm from "./VerificationCodeForm";
import SignUpForm from "./SignUpForm";
import WalletSetupForm from "./WalletSetupForm";
import { ProfileViewModel } from "./model";
import ProfileUpdateForm from "./ProfileUpdateForm";
import useSignupReward from "./useSignupReward";
import UseWindowSize from "../../hooks/helper/useWindowSize";
import { tagLogin, tagSignUp, tagVisitLogin, tagVisitSignUp } from "../../lib/events";
import { useRouter } from "next/router";

interface Props {
  qrButtonTheme?: boolean;
  referralCode?: string;
  signUp?: boolean;
  setIsSigningUp?: Dispatch<SetStateAction<boolean>>;
  closeModal?: () => void;
  setStep1?: Dispatch<SetStateAction<"signin" | "signup" | "verification" | "profileupdate" | "walletsetup" | "redeem">>;
  setSigningUp1?: Dispatch<SetStateAction<boolean>>;
  referralName?: string;
  referralEmail?: string;
  referralPhoneNumber?: string;
}

export default function LoginModal(props: Props) {
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const { dbUser, markSignUpComplete, fetchDbUser, user } = useContext(UserContext);
  const { updateProfile } = useUpdateProfile();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [profileVM, setProfileVM] = useState<ProfileViewModel>();
  const { referralCode, setReferralCode, reward, rewardStatus, markRedeemed, loading: rewardsLoading, alreadyAwarded } = useSignupReward();
  const [signingUp, setSigningUp] = useState(props.signUp ?? false);
  const [step, setStep] = useState<"signin" | "signup" | "verification" | "profileupdate" | "walletsetup" | "redeem">(props.signUp ? "signup" : "signin");
  const [workflowState, setWorkflowState] = useState<"notloggedin" | "loggedin" | "profileupdated" | "settingupwallet" | "userconfirmation" | "complete">("notloggedin");
  const router = useRouter();
  
  useEffect(() => {
    if (props?.referralCode)
      setReferralCode(props?.referralCode);
  }, [props?.referralCode])

  useEffect(() => {
    if (props?.signUp)
      tagVisitSignUp(props?.referralCode);
    else
      tagVisitLogin(props?.referralCode);
  }, [])  

  //Flows
  // newuser - signup - (login), save profile - A
  // newuser - signin - (login), signup - save profile - A
  // existing user - signin - A
  // A - haswallet and redeemed - close
  // A - haswallet not redeemed - redeem signin bonus - close
  // A - no wallet - create fleato wallet - redeem - close
  // A - no wallet - connect wc wallet - redeem - close
  // login - enter phone - recaptcha - enter verif code - submit

  // user before sign in = !user
  // user before profile setup = !dbUser.email
  // user after signin, profile setup = dbUser.email
  // user before wallet setup = !hasWallet
  // user before redeem = !redeemed
  // user after redeem = redeemd, hasWallet
  // user in the process of signing up = signingUp
  // user in the process of wallet creation = ?
  // user in the process of saving profile = signingUp && !dbUser.email && !dbUser.handle
  // user in the process of login = !user
  // user in the process of redeeming = rewardStatus == "loading"

  // guest = cant say for sure (!user atleast)
  // existinguser = dbUser.email
  // user just signed in = user
  // new user just signed up = user && !dbUser.email
  // user, wallet not setup = user && !hasWallet
  // user, not redeemed = user && !redeemed
  // user, all set = user && dbUser.email && redeemed

  //Login function calls
  //  <no user, no dbuser> => setuprecaptcha
  // <dbuser, no email> => signingUp = true
  // <buttonclick, guest signup, no verification code> => copy profile fields, phoneauth step 1 
  // <buttonclick, guest signup, verification code> => copy profile fields, phoneauth step 2 => user => hook to saveProfile 
  // <buttonclick, guest signin, no verification code> => phoneauth step 1 
  // <buttonclick, guest signin, verification code> => phoneauth step 2, next steps  
  // <buttonclick, guest wrongly signin, no verification code> => phoneauth step 1 
  // <buttonclick, guest wrongly signin, verification code> => phoneauth step 2 => user => hook to mark as signingup, and force formfill  
  // <buttonclick, user signup> => copy profile fields, saveProfile 

  useEffect(() => {
    if (!!props?.setSigningUp1) {
      props?.setSigningUp1(signingUp);
    }
    if (!!props?.setStep1) {
      props?.setStep1(step);
    }
  }, [step, signingUp])

  const closeModal = () => {
    markSignUpComplete();
    if (!!props?.setIsSigningUp) {
      props?.setIsSigningUp(false);
    } if (!!props?.closeModal) {
      props?.closeModal();
    }
  };
  const signIn = (phoneNumber: string) => {
    tagLogin();
    setPhoneNumber(e164PhoneNumber(phoneNumber));
    setStep("verification");
  }

  const signUp = (data: ProfileViewModel) => {
    tagSignUp();
    setPhoneNumber(e164PhoneNumber(data.phoneNumber));
    setProfileVM({ ...data });
    if (data.referralCode)
      setReferralCode(data.referralCode);
    setStep("verification");
  }

  const signedIn = async () => {
    setWorkflowState("loggedin");
  }

  //TODO: Issues that only surface for non-standard scenarios. Handle at a later point:
  // If fleato wallet already exists, even if it was disconnected from drive, the login popup closes without fully signing in the user
  // If user had a prior wallet, that balance shows up in the login page even before setting up a wallet.
  useEffect(() => {
    if (workflowState == "notloggedin") {
      //Nothing to do here. Other triggers will take it forward.
    } else if (workflowState == "loggedin") {
      setLoading(true);
      if (dbUser && !!dbUser.email && !!dbUser.name && !!dbUser.handle && dbUser?.wallets?.length && rewardStatus == "Redeemed") {
        setWorkflowState("complete");
        setLoading(false);
      } else if (dbUser && !!dbUser.email && !!dbUser.name && !!dbUser.handle && dbUser?.wallets?.length) {
        // setWorkflowState("settingupwallet");
        // setStep("redeem");
        setWorkflowState('complete');
        setLoading(false);
      } else if (dbUser && !!dbUser.email && !!dbUser.name && !!dbUser.handle) {
        // setWorkflowState("profileupdated");
        // setStep("walletsetup");
        setWorkflowState('complete');
        setLoading(false);
      } else if (auth?.currentUser?.uid && profileVM) {
        //save profile VM
        (async () => {
          const { userFound, handle, ...rest } = profileVM;
          await updateProfile(userFound ? { ...rest, agencyId: router?.query?.id?.[0] ?? '' } : { ...rest, handle, agencyId: router?.query?.id?.[0] ?? '' });
          setWorkflowState("profileupdated");
          setLoading(false);
        })();
      } else if (auth?.currentUser?.uid && !dbUser) {
        //save profile VM
        (async () => {
          const localDBUser = await fetchDbUser(auth.currentUser.uid);
          if (!localDBUser || !localDBUser.email || !localDBUser.name || !localDBUser.handle) {
            setStep("profileupdate");
            setLoading(false);
          }
        })();
      } else if (auth?.currentUser?.uid && (dbUser && (!dbUser.email || !dbUser.name || !dbUser.handle) || !dbUser)) { // && !profileVM
        //User logged in, but no profile.
        setStep("profileupdate");
        setLoading(false);
      } else {
        console.log("unknown condition 1?", workflowState, step, dbUser, profileVM)
        setLoading(false);
      }
    } else if (workflowState == "profileupdated") {
      setLoading(true);
      if (dbUser && dbUser?.wallets?.length && rewardStatus == "Redeemed") {
        setWorkflowState("userconfirmation");
        setLoading(false);
      } else if (dbUser && dbUser?.wallets?.length) {
        // setWorkflowState("settingupwallet");
        // setStep("redeem");
        setWorkflowState('complete');
        setLoading(false);
      } else if (dbUser) {
        // setWorkflowState("settingupwallet");
        // setStep("walletsetup");
        setWorkflowState('complete');
        setLoading(false);
      } else {
        console.log("unknown condition 2?", workflowState, step, dbUser, profileVM)
        setLoading(false);
      }
    } else if (workflowState == "settingupwallet") {
      setLoading(true);
      if (dbUser && dbUser?.wallets?.length && rewardStatus == "Redeemed") {
        setWorkflowState("userconfirmation");
        setLoading(false);
      } else if (dbUser && dbUser?.wallets?.length && !referralCode) {
        setWorkflowState("userconfirmation");
        setLoading(false);
      } else if (dbUser && dbUser?.wallets?.length && referralCode) {
        // setWorkflowState("settingupwallet");
        // setStep("redeem");
        setWorkflowState('complete');
        setLoading(false);
      } else if (dbUser) {
        // setWorkflowState("settingupwallet");
        // setStep("walletsetup");
        setWorkflowState('complete');
        setLoading(false);
      } else {
        console.log("unknown condition?", workflowState, step)
        setLoading(false);
      }
    } else if (workflowState == "complete") {
      closeModal();
      setLoading(false); //just in case.
    } else if (workflowState == "userconfirmation") {
      if (alreadyAwarded) {
        setWorkflowState("complete");
      }
      //User will have to manually click :lets go shopping at this stage
      setLoading(false); //just in case.
    } else {
      console.log("unknown condition 3?", workflowState, step, dbUser, profileVM)

    }
  }, [auth?.currentUser?.uid, dbUser, profileVM, rewardStatus, referralCode, workflowState, alreadyAwarded]);


  const updateProfileLocal = (data: ProfileViewModel) => {
    if (data.referralCode)
      setReferralCode(data.referralCode);
    setProfileVM(data);
  }

  const title = () => workflowState == "notloggedin" ? (signingUp ? "Sign Up" : "Sign In")
    : workflowState == "loggedin" ? "Loading your profile"
      : workflowState == "profileupdated" ? "Calculating your rewards"
        : "Welcome to Fleato";

  console.log({ workflowState, step, referralCode, reward, rewardStatus, rewardsLoading, dbUser, profileVM, alreadyAwarded })
  const screenProps = UseWindowSize();
  const { windowSize } = screenProps;

  return (
    <div className={`form-layer`}>
      <div >
        {loading &&
          <div className="px-3 mb-5">
            <h3 className="fw-normal text-center text-blue mb-4">{title()}</h3>
            <Lottie height={100} width={100} options={{ loop: true, autoplay: true, animationData: animeLoading }} />
          </div>
        }
        {!loading && step == "signin" && (
          <SignInForm signUp={() => { setSigningUp(true); setStep("signup") }} signIn={signIn} qrButtonTheme={props?.qrButtonTheme} />
        )}
        {!loading && step == "signup" && (
          <SignUpForm
            referralCode={props?.referralCode ? props?.referralCode : referralCode}
            signIn={() => { setSigningUp(false); setStep("signin") }}
            signUp={signUp} qrButtonTheme={props?.qrButtonTheme}
            referralPhoneNumber={props?.referralPhoneNumber}
            referralEmail={props?.referralEmail}
            referralName={props?.referralName}
          />
        )}
        {!loading && step == "verification" &&
          <VerificationCodeForm phoneNumber={phoneNumber} signingUp={signingUp} signedIn={signedIn} closeModal={closeModal} qrButtonTheme={props?.qrButtonTheme} />
        }
        {!loading && step == "profileupdate" &&
          <ProfileUpdateForm
            referralCode={props?.referralCode ? props?.referralCode : referralCode}
            updateProfile={updateProfileLocal}
            qrButtonTheme={props?.qrButtonTheme}
            referralPhoneNumber={props?.referralPhoneNumber}
            referralEmail={props?.referralEmail}
            referralName={props?.referralName}
          />
        }
        {/* {!loading && (step == "walletsetup" || step == "redeem") &&
          <WalletSetupForm referralCode={props.referralCode ? props.referralCode : (referralCode ?? dbUser?.referralCode)} setReferralCode={setReferralCode} reward={reward} rewardStatus={rewardStatus} markRedeemed={markRedeemed} rewardsLoading={rewardsLoading} closeModal={closeModal} />
        } */}
      </div>
    </div>
  );
}
