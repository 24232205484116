import { useEffect, useState } from 'react';
import "firebase/auth";
import { collection, query, onSnapshot, limit } from "firebase/firestore";
import { Category, SelectableCategory } from '../../models/product';
import { db } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'

const CATEGORY = "category";

export default function useCategories() {

  const [categories, setCategories] = useState<Category[]>([]);
  const [artistCategories, setArtistCategories] = useState<Category[]>([]);
  const [selectableCategories, setSelectableCategories] = useState<SelectableCategory[]>([]);
  const data = (item: any, item1: any) => {
    return {
      label: item?.name,
      value: item?.name,
      children: item?.data?.length > 0 ? item?.data?.map((i, item) => data(i, item)) : [],
    }
  }

  useEffect(() => {
    const setData = categories.map((item) => (data(item, null)));
    setSelectableCategories(setData);
  }, [categories])

  const appendCategory = (cat: Category) => {
    setCategories(prev => [...prev.filter(pk => pk.name !== cat.name), cat]) 
  }

  const appendArtistCategory = (cat: Category) => {
    setArtistCategories(prev => [...prev.filter(pk => pk.name !== cat.name), cat]) 
  }

  useEffect(()=> {
    const q = query(collection(db, CATEGORY, "products", CATEGORY), limit(50));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if(change.type == "added") {
          appendCategory({id: change.doc.id, ...change.doc.data() as Category});
        } else if(change.type == "modified") {
          appendCategory({id: change.doc.id, ...change.doc.data() as Category});
        } else if(change.type == "removed") {
          setCategories(prev => prev.filter(p => p.name !== change.doc.data().name))
        }
      })
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
  }, [])

  useEffect(()=> {
    const q = query(collection(db, CATEGORY, "artists", CATEGORY), limit(50));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if(change.type == "added") {
          appendArtistCategory({id: change.doc.id, ...change.doc.data() as Category});
        } else if(change.type == "modified") {
          appendArtistCategory({id: change.doc.id, ...change.doc.data() as Category});
        } else if(change.type == "removed") {
          setArtistCategories(prev => prev.filter(p => p.name !== change.doc.data().name))
        }
      })
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
  }, [])

  return {categories, artistCategories, selectableCategories};
}