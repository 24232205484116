import styled from "styled-components";
export const LoginWrapper = styled.div`
.form-control{
  padding:0;
}
  .blue-bg {
    background: #1478DD;
  }
  .text-blue {
    color: #1478DD;
  }
  .close {
    right: 10px;
    cursor: pointer;
    top: 10px;
  }
  color: #000000;
  h1 {
    font-size: 2rem;
    font-weight: 500;
    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  }
  .logo-wrapper {
    height: calc(100% - 64px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 600px) {
      span {
        font-size: 0.7rem;
      }
    }
  }
  background: #000;
  border-radius: 24px;
  padding: 20px 40px;
  .lt-sec {
    svg {
      width: 143px;
      height: 165px;
      @media (max-width: 600px) {
        width: 103px;
        height: 125px;
      }
    }
  }
  .rt-sec {
    input {
      border: 0;
      border-bottom: 1px solid #ccc;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      ::placeholder {
        color: #8b8a8a;
        font-size: 0.9rem;
      }
    }
    .form-control {
     
      ::placeholder {
        padding:0 !important;
      }
    }
    h3 {
      font-weight: 500;
    }
    background-color: rgb(255, 255, 255);
    border-radius: 32px;
    padding: 0;
    padding-top: 30px;

    h6 {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #6177b9;
      line-height: 0.1em;
      margin: 10px 0 20px;
      font-size: 0.8rem;
    }

    h6 span {
      background: #fff;
      padding: 0 10px;
    }
    .red-bg {
      background: #ea321c;
    }
    .form-check {
      font-size: 0.9rem;
    }
    .form-label {
      font-size: 0.9rem;
    }
    .btn-success {
      background-color: #1478DD;
    }
    .btn-sucess:focus{
      box-shadow:0 0 0 0.25rem #1478dd4a;
     }
    .form-control {
     border-radius:0px;
    }
    // .text-blue {
    //   color: blue;
    //   position: relative;
    //   float: right;
    // }
    .pickWallet {
      border-top: 1px solid #ccc;
    }
    .pickWalletFooter {
      font-size: 0.9rem;
    }
    .terms {
      border-top: 1px solid #ccc;
      font-size: 0.9rem;
    }
    input[type="checkbox"] {
      border: 1px solid #ccc;
    }
    .errorMessage {
      font-size: 0.9rem;
      font-weight: 400;
      margin-left: 0px;
      color: #ea321c;
      font-size: 0.8rem;
    }
    .user-info {
      input[type="text"] {
        border: 0;
        background: linear-gradient(180deg, #eaeaea, #dddddd);
        margin-bottom: 12px;
        border-radius: 8px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
`;
export const LoginModalStyle = styled.div`
position:relative;
.close {
  right: 10px;
  cursor: pointer;
  top: 10px;
  z-index:1;
}
.form-layer{
  box-sizing: border-box;
  margin: 0;
  max-width: 500px;
  border: 1px solid;
  border-color:#eaecf0;
  border-radius: 8px;
  background-color:#fff;
  -webkit-filter: drop-shadow(0px 14px 24px rgba(0,0,0,0.25));
  filter: drop-shadow(0px 14px 24px rgba(0,0,0,0.25));
  overflow: hidden;
}
@media(max-width:600px){
  width: 100%;
}
`;
