import { useEffect, useState } from 'react';
import { Branding } from '../../models/common';

export default function useBranding() {
  const [branding, setBranding] = useState<Branding>({brandName: "fleato"});

    useEffect(() => {
        let {host} = window?.location;
        let isDev = host.includes('localhost');
        let splitHost = host.split('.');
        let brandName = "fleato";
        if((splitHost.length === 3 || (isDev && splitHost.length == 2)) && splitHost[0].toLowerCase() !== "www") {
          brandName = splitHost[0];
        }
        setBranding({...branding, brandName})
    }, [])

   const facetFiltersWithBranding = (filters: string[]) => {
    // console.log("calculating facetFilters for ", filters);
     if(branding.brandName == "fleato")
      return filters;
     else 
      return [...filters, `seller:${branding.brandName}`]
   }

  return {
        branding, facetFiltersWithBranding
    }
}
