import { createContext, useEffect, useState } from 'react'
import { User, UserCredential } from "firebase/auth";
import { PublicProfile } from '../models/product';
import useGapi from '../hooks/user/useGapi';
import useFirebase from '../hooks/user/useFirebase';
import { ArtistProfile, DbUser, GapiUser } from '../models/user';

interface IUserContext {
  user: User | null;
  gapiUser?: GapiUser;
  myArtistProfile?: ArtistProfile;
  dbUser?: DbUser;
  userToken: string | null;
  isAuthenticated: boolean;
  isVerifiedUser: boolean;
  isNewUser: boolean;
  signInSignUpInProgress: boolean;
  isLoading: boolean;
  publicProfile: PublicProfile,
  isGapiAuthReady: boolean;
  isGapiSignedIn: boolean;
  hasFleatoWallet: boolean;
  hasConnectedWallet: boolean;
  userExists: (phoneNumber: string) => Promise<boolean>;
  myReferrals: (handle: string) => Promise<{ handle?: string, joinedOn?: string, redeemedAt?: string }[]>;
  canRefer: (handle: string) => Promise<{ canRefer: boolean, reason: string }>;
  openLoginPopup: (referralCode?: string, isSigningUp?: boolean, redirectUrl?: string) => void;
  firebaseSignIn: () => Promise<void>;
  firebaseSignOut: () => Promise<void>;
  firebaseGuestSignIn: () => Promise<UserCredential>;
  gapiSignInAs: () => Promise<GapiUser | undefined>;
  gapiSignOut: () => Promise<void>;
  markSignUpComplete: () => void;
  updateUserEmail: (email: string) => Promise<string>;
  updateUserProfile: ({ displayName, photoURL }: { displayName: string | null, photoURL: string | null }) => Promise<string>;
  fetchDbUser: (uid: string) => Promise<DbUser | undefined>;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider = ({ children, openLoginPopup }) => {

  const { firebaseSignIn, firebaseSignOut, firebaseGuestSignIn, dbUser, user, 
    userToken, isAuthenticated, isNewUser, signInSignUpInProgress, 
    isLoading, publicProfile, isVerifiedUser, markSignUpComplete, 
    updateUserEmail, updateUserProfile, fetchDbUser, userExists, 
    canRefer, myReferrals, myArtistProfile } = useFirebase();
  
  const { gapiSignInAs, gapiSignOut, isGapiAuthReady, isGapiSignedIn, gapiUser } = useGapi(dbUser?.fleatoWallets?.find(w => !!w.googleAccount)?.googleAccount);
  // const { gapiSignInAs, gapiSignOut, isGapiAuthReady, isGapiSignedIn, gapiUser } = { gapiSignInAs: async () => undefined , gapiSignOut: async () => {},  isGapiAuthReady: false, isGapiSignedIn: false, gapiUser: undefined };
  
  const hasFleatoWallet = (dbUser?.wallets?.length ?? 0) > 0 ?? false;
  const hasConnectedWallet = (dbUser?.connectedWallets?.length ?? 0) > 0 ?? false;
  useEffect(() => {
    if (user?.uid) {
      window?.dataLayer?.push({ userId: user.uid });
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ 
      user, dbUser, gapiUser, userToken, 
      isAuthenticated, isVerifiedUser, isNewUser, 
      signInSignUpInProgress, isLoading, 
      publicProfile, isGapiAuthReady, isGapiSignedIn, 
      hasConnectedWallet, myArtistProfile,
      firebaseSignIn, firebaseSignOut, firebaseGuestSignIn, 
      markSignUpComplete, updateUserEmail, updateUserProfile, 
      openLoginPopup, gapiSignInAs, gapiSignOut, 
      hasFleatoWallet, fetchDbUser, userExists, 
      canRefer, myReferrals }}>
      {children}
    </UserContext.Provider>
  )
}