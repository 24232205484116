import { createContext } from 'react'
import { Event } from '../models/user';
import useEvent from '../hooks/user/useEvent';

interface IEventContext {
    upsertEvent: (add: Event) => Promise<boolean>;
}

export const EventContext = createContext<IEventContext>({} as IEventContext);

export const EventProvider = ({ children }) => {

    const { upsertEvent } = useEvent();

    return (
        <EventContext.Provider value={{
            upsertEvent
        }}>
            {children}
        </EventContext.Provider>
    )
}