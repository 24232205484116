import { useEffect, useState } from 'react';
import "firebase/auth";
import { doc, onSnapshot, collection, getDocs, where, query } from "firebase/firestore";
import moment from 'moment';
import { recentEntry } from '../../util/util';
import { db, auth, functions } from '../../firebaseutil';
import { CreditsBalance, CreditsTransaction } from '../../models/credits';
import useQuote from './useQuote';
import { httpsCallable } from 'firebase/functions';
import * as Sentry from "@sentry/nextjs";

const CREDITS = "credits";
const ENTRIES = "entries";

enum RequestType {
  creditsToWalletTransfer = "creditsToWalletTransfer"
}

export default function useCredits() {
  const {usdForFleato} = useQuote();
  const [activities, setActivities] = useState<CreditsTransaction[]>([]);
  const [balance, setBalance] = useState<CreditsBalance>({fleato: 0});
  const [usdBalance, setUsdBalance] = useState<number>(0);

  useEffect(() => {
    if(!!!auth?.currentUser?.uid){
      setActivities([]);
      setBalance({fleato: 0});
    }
  },[auth?.currentUser?.uid]);

  useEffect(()=> {
    if(!auth.currentUser?.uid)
      return;
    const unsubscribe = onSnapshot(doc(db, CREDITS, auth.currentUser.uid), (snapshot) => {
    const summary = snapshot.data();
    if(summary)
      setBalance(summary as CreditsBalance);
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
  }, [auth.currentUser?.uid])


  useEffect(() => {
    if(!auth.currentUser?.uid)
      return;
    (async () => {
      await fetchEntries();
    })();
  }, [auth.currentUser?.uid])

  useEffect(() => {
    if(balance?.fleato && usdForFleato)
      setUsdBalance(usdForFleato(balance.fleato));
  }, [balance, usdForFleato])

  const fetchEntries = async () : Promise<CreditsTransaction[]> => {
    const q = collection(db, CREDITS, auth!.currentUser!.uid, ENTRIES);
    const txns = (await getDocs(q)).docs.map(doc => ({id: doc.id, ...doc.data()})) as unknown as CreditsTransaction[];
    if(txns?.length) {
      const sorted = txns.sort((a,b) => moment(b.createdAt).diff(moment(a.createdAt), "seconds"))
      setActivities(sorted);
    }
    return txns;
   }

   const transferToFleatoWallet = async () => {
    try {
    const transferCallable = httpsCallable(functions, 'user-userCall');
      const result = await transferCallable({requestType: RequestType.creditsToWalletTransfer, input: {units: 0} });
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error while redeeming",err);
      throw(err);
    }
  }


  return {balance, usdBalance, transferToFleatoWallet, activities: activities.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt), "seconds")) };
}