import { useContext, useEffect, useState } from 'react';
import "firebase/auth";
import { onSnapshot, query, doc, setDoc, collection, serverTimestamp, limit, getDoc } from "firebase/firestore";
import { errorMessage, uuidv4 } from '../../util/util';
import {DownloadDetails, Resource } from '../../models/user';
import { db, auth,functions } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'
import { httpsCallable } from 'firebase/functions';
import slug from "slug";
import { UserContext } from '../../contexts/UserContext';

const RESOURCE = "resource";
const UPSERTRESOURCE = "upsertResource";
const DOWNLOADRESOURCE="downloadResource";


export default function useResource() {

  const [isBusySavingResource, setIsBusySavingResource] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [requestedResource,setRequestedResource]=useState<Resource>();
  const{user}=useContext(UserContext);

//   useEffect(() => {
//     if (!!!auth?.currentUser?.uid) {
//       setResources([]);
//     }
//   }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!auth.currentUser?.uid)
      return;
    const q = query(collection(db, `${RESOURCE}`), limit(20));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type == "added") {
          // console.log("category added", change.doc.id);
          appendResource(change.doc.data() as Resource);
        } else if (change.type == "modified") {
          // console.log("category modified", change.doc.id);
          appendResource(change.doc.data() as Resource);
        } else if (change.type == "removed") {
          // console.log("category removed", change.doc.id);
          setResources(prev => prev.filter(p => p.id !== change.doc.data().id))
        }
      })
    }, (error) => { Sentry.captureException(error); console.log(error) });
    return unsubscribe;
  }, [user])


  const appendResource = (add:Resource) => {
    setResources(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  }

  const upsertResource = async (resource: Resource): Promise<Resource | string> => {
    setIsBusySavingResource(true);
    try {
      const userCallable = httpsCallable(functions, 'resource-resourceCall');
    const idSlug = slug(`${resource?.name}-${new Date().getTime()}`);
    const resourceInput={
      ...resource,
      id:resource?.id??idSlug
    }
    const result = await userCallable({requestType: UPSERTRESOURCE, input:resourceInput});
      const response = result.data as Resource;
     setIsBusySavingResource(false);
      return response;
    } catch (err) { 
      setIsBusySavingResource(false);
      Sentry.captureException(err); 
      console.log(err,"err") 
      return errorMessage(err);
    }
    
  }

  const fetchResource = async (id: string): Promise<Resource> => {
    const resourceRef = doc(db, `${RESOURCE}/${id}`);
    const shortUrlSnapshot = await getDoc(resourceRef);
    return shortUrlSnapshot.data() as Resource;
  }

  const downloadResource=async(userDetails:DownloadDetails):Promise<Resource>=>{
    setIsBusySavingResource(true);
    try {
      const userCallable = httpsCallable(functions, 'resource-resourceCall');
      // console.log(userDetails,"UD")
      const result = await userCallable({requestType:DOWNLOADRESOURCE, input: userDetails});
      const response = result.data as Resource;
      setRequestedResource(response);
    } catch (err) { Sentry.captureException(err); console.log(err,"err") }
    setIsBusySavingResource(false);
    return;
  }
 

  return { resources, upsertResource, isBusySavingResource,fetchResource ,downloadResource,requestedResource};
}