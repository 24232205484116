import { Children, createContext, useEffect, useState } from 'react';

interface ISettingsContext {
  mode: 'kiosk' | 'personal';
  setMode: (mode: 'kiosk' | 'personal') => void;
  brand: string;
  brandLink: string;
  setBrand: (brand: string) => void;
}

const initProps: ISettingsContext = {
  mode: 'personal',
  brand: "",
  brandLink: "",
  setBrand: (brand: string) => {},
  setMode: (mode: 'kiosk' | 'personal') => {},
}

interface Settings {
  mode: 'kiosk' | 'personal';
}

export const SettingsContext = createContext<ISettingsContext>(initProps);
export const SettingsProvider = ({ children }) => {
  const [mode, setMode] = useState<'kiosk' | 'personal' >('personal');
  const [brand, setBrand] = useState<string>('');
  const [brandLink, setBrandLink] = useState('');
  useEffect(() => {
    // const settings: Settings = JSON.parse(localStorage.getItem("settings"));
    // if (settings?.mode) { 
    //   setMode(settings.mode);
    // } else {
      setMode('personal');
    // }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      if(window?.location?.href?.includes("catalog")) {
        setBrandLink(window?.location?.href);
        setBrand(window?.location?.href?.split("/")?.[5]);
      }
    }      
}, [])

  // useEffect(() => {
  //   let settings: Settings = JSON.parse(localStorage.getItem("settings"));
  //   if(mode !== settings?.mode) {
  //     if(!settings)
  //       settings = {mode: 'personal'};
  //     localStorage.setItem("settings", JSON.stringify({...settings, mode})); 
  //   }
  // }, [mode])
  
  return (
    <SettingsContext.Provider
      value={{ mode, setMode, brand, setBrand, brandLink }} >
      {children}
    </SettingsContext.Provider>
  )
}