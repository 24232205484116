import Link from "next/link";
import { Component, ErrorInfo, ReactNode } from "react";
import Lottie from 'react-lottie';
import error from '../public/39161-network-error.json';
import { ModalWrapper } from "../styles/checkout-page-style";
import * as Sentry from '@sentry/nextjs'
interface ErrorBoundaryProps {
  children?: ReactNode;
  error?: string | Error | null | { message: string };
}
interface ErrorBoundaryState {
  hasError: boolean;
}
/**
* A mock validation method to illustrate how you may want to differentiate the type of error messaging you provide.
*/
const hasValidError = (
  _error: string | Error | null | { message: string }
): boolean => true;

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: !!props.error };
  }
  /**
  * This gets called when an uncaught error occurs when executing React lifecycle methods, like render.
  */
  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> | null {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Throw errors when running the unit tests so they don't get swallowed by the error boundary
    if (process.env.NODE_ENV === "development") {
      throw error;
    }
    else if (process.env.NODE_ENV === "test") {
      throw error;
    }
    else if (process.env.NODE_ENV === "production") {
      Sentry.captureException(errorInfo);
      console.log(error.message, { error, errorInfo });
    }
    // Not all errors are equal, sometime sa warning is enough.
    if (hasValidError(error)) {
      console.log(error.message, { error, errorInfo });
    } else {
      console.log(error.message, { error, errorInfo });
    }
    // SessionRecorder.track("ErrorBoundaryReached", { error: error.toString() });
  }

  goHome = (): void => {
    window.location.pathname = "/";
  };
  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="px-5 pb-5 bg-white overflow-hidden" >
          <ModalWrapper>
            <div className='mx-auto '>
              <div className='position-relative'>
                <div className='position-absolute' style={{ width: "100%", top: 30 }}>
                  <h3 className='text-center' style={{ textAlign: 'center', fontWeight: "normal" }}>OOPS! Something went wrong</h3>
                </div>
              </div>
              <div style={{ marginTop: -10 }}>
                <p>Oops, we apologize, an error occured. Fleato support team has been notified, please try again later.</p>
                <Lottie heigth={0} width={450} options={{ loop: true, autoplay: true, animationData: error }} />
              </div>
              <div className='col-4 mx-auto'>
                <Link href="/" passHref>
                  <a>
                    <button className=' btn-green '>Go Home</button>
                  </a>
                </Link>
              </div>

            </div>
          </ModalWrapper>
        </div>
      );
    }

    return children;
  }
}
