import { useEffect, useState } from "react";
import { Mnemonic, TOKENS, WalletStorage } from "../../models/wallet";
import { ethers, Wallet } from "ethers";
import {  httpsCallable } from "firebase/functions";
import { GapiUser, WalletSource } from "../../models/user";
import { db, auth, functions } from '../../firebaseutil';
import { fleatoConfig } from "../../fleato-config";
import * as Sentry from "@sentry/nextjs";


enum RequestTypes {
  updateProfile = "updateProfile",
  addWallet = "addWallet"
}

export default function useSelfCustodyWallet(storage: WalletStorage, gapiUser?: GapiUser) {
  const [isLoading, setIsLoading] = useState(true);
  const [walletExists, setWalletExists] = useState(false);
  const [account, setAccount] = useState<string>();
  // console.log("useSelfCustodyWallet storage.isReady", storage.isReady, walletExists, account);
  useEffect(()=>{
    if(storage.isReady) {
      checkIfWalletExists();
    }
  }, [storage.isReady])

  useEffect(() => {
    if(!!!auth?.currentUser?.uid){
        setAccount(undefined);
    }
  }, [auth?.currentUser?.uid])

  useEffect(()=> {
    if(walletExists) {
      (async () => {
        const address = await walletAddress();
        setAccount(address);
        //If for any reason our reference to wallet address is lost...
        if(address) await saveWalletAddress(address);
      })();
    }
  }, [walletExists])

  const checkIfWalletExists = async () => {
    const exists = await storage.exists();
    if(exists) {
      setWalletExists(true);
      setIsLoading(false);
    } else {
      setWalletExists(false);
      setIsLoading(false);
    }
  }

  const networkProvider = () => ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);

  const readWallet = async (): Promise<Wallet | undefined> => {
    setIsLoading(true);
    const mnemonic = await storage.getValue() as Mnemonic;
    if(mnemonic && mnemonic.phrase && mnemonic.path) {
      const wallet = ethers.Wallet.fromMnemonic(mnemonic.phrase, mnemonic.path).connect(networkProvider());
      //dont save it, load it and use it just-in-time
      setAccount(wallet.address);
      setIsLoading(false);
      return wallet;
    } else {
      // console.log("mnemonic or mnemonic phrase or menonic path is missing", mnemonic?.phrase?.length ?? 0, mnemonic?.path?.length ?? 0 );
      setIsLoading(false);
      return undefined;
    }
  }

  const mnemonic = async (): Promise<Mnemonic | undefined> => {
    const wallet = await readWallet();
    return wallet?.mnemonic ?? undefined;
  }

  const walletAddress = async (): Promise<string | undefined> => {
    const wallet = await readWallet();
    return wallet?.address ?? undefined;
  }

  const generateMnemonicWallet = () : Wallet => {
    const wallet = ethers.Wallet.createRandom();
    
    return wallet;
  }

  const saveWalletAddress = async (walletAddress: string) => {
    if(!auth?.currentUser?.uid) {
      // console.log("Wallet address can't be saved before login")
      return;
    }
    try{
    const addWallet = httpsCallable(functions, 'user-userCall');
    await addWallet({requestType: RequestTypes.addWallet, input: {address: walletAddress, source: WalletSource.fleato, handle: "Fleato Wallet", googleAccount: gapiUser?.email, chainId: fleatoConfig.maticChainId}});
  }
  catch(err){
    Sentry.captureException(err);
    console.log(err);
  }
  }

  const createWallet = async (force: boolean=false) => {
    setIsLoading(true);
    if(!force && await storage.exists()) {
      // console.log("skipping, wallet already exists, updating ");
      const wallet = await readWallet();
      await saveWalletAddress(wallet.address);
      setIsLoading(false);
      return;
    } else {
      const wallet = generateMnemonicWallet(); 
      await storage.create(wallet.mnemonic);
      await checkIfWalletExists();
      await saveWalletAddress(wallet.address);
      setIsLoading(false);
    }
  }

  return {createWallet, walletExists, isLoading, account, mnemonic, getSigner: readWallet};
}
