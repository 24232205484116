import { fleatoConfig } from "../fleato-config";

export const TOKEN_CONTRACTS = {
  fleato: fleatoConfig.contractFleatoToken,
  bitcoin: fleatoConfig.contractWbtcToken,
  ethereum: fleatoConfig.contractWethToken,
  usdc: fleatoConfig.contractUsdcToken,
  matic: fleatoConfig.contractWmaticToken
}

export const TOKEN_DECIMALS = {
  fleato: 18,
  bitcoin: 8,
  ethereum: 18,
  usdc: 6,
  matic: 18
}