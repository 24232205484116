import {Footer} from "./footer";
import { FloatingFooterWrapper } from "./footer-style";

export const FloatingFooter = (props: any) => {
  const { openRedeemModal, openModal } = props;

  return (
    <FloatingFooterWrapper>
      <Footer openRedeemModal={openRedeemModal} openModal={openModal} />
    </FloatingFooterWrapper>
  )
}