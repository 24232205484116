import { createContext, useContext } from 'react'
import { UserContext } from './UserContext';
import useSilaWallet from '../hooks/wallet/useSilaWallet';
import { SilaBankAccount, SilaKycStatus, SilaLiteUserRequest, SilaRegistrationValidationDetails, SilaUser, SilaWalletWithBalance } from '../models/sila';

interface ISilaWalletContext {
  paymentProgressPercent: number;  
  bankAccounts?: SilaBankAccount[], 
  // paymentSteps: PaymentSteps;
  // recentBalance?: RecentBalance;
  balanceString?: string;
  shortAddress?: string;
  isLoading?: boolean;
  isKycPending: boolean;
  verificationResult: SilaRegistrationValidationDetails;
  // payForOrder: (chargeId: string, paymentAmount: number) => Promise<boolean>;
  getPlaidLinkToken: () => Promise<any>;
  registerSilaLiteUser: (silaUserRequest: SilaLiteUserRequest) => Promise<SilaUser>;
  linkSilaBankAccount: (accessToken: string, accountId: string, accountName: string) => Promise<SilaBankAccount[]>;
  createReceiptsWallet: () => Promise<boolean>; 
  getSilaWalletsWithBalance: () => Promise<SilaWalletWithBalance[]>;
  redeemSila: (accountName: string, amount:number) => Promise<boolean>;
  isRegistered: boolean;
  didKycPass: boolean;
  kycStatus: SilaKycStatus;
}

export const SilaWalletContext = createContext<ISilaWalletContext>({} as ISilaWalletContext);

export const SilaWalletProvider = ({children}) => {
  const {dbUser, isGapiSignedIn} = useContext(UserContext);
  const {isLoading, isRegistered, didKycPass, bankAccounts, kycStatus, isKycPending, getPlaidLinkToken, registerSilaLiteUser, linkSilaBankAccount, createReceiptsWallet, getSilaWalletsWithBalance, verificationResult, redeemSila} = useSilaWallet(dbUser);
  return (
    //paymentProgressPercent, payForOrder, paymentSteps, recentBalance, balanceString, shortAddress, loadingBalance, fetchTokenBalances, reset
    <SilaWalletContext.Provider value={{paymentProgressPercent: 0, isRegistered, isKycPending, didKycPass, bankAccounts, kycStatus, getPlaidLinkToken, registerSilaLiteUser, linkSilaBankAccount, createReceiptsWallet, getSilaWalletsWithBalance, isLoading, verificationResult, redeemSila
    //payForOrder, paymentSteps, recentBalance
    }}>
      {children}
    </SilaWalletContext.Provider>
  )
}