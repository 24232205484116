import { createSelector } from "reselect";
import { sliceName } from "../slices/common.slice";
import { CommonSliceType } from "../../types/common.types";

const commonSliceSelector = (state: {
  [sliceName]: CommonSliceType;
}): CommonSliceType => state[sliceName];

export const togglePdtFilterSelector = createSelector(
  commonSliceSelector,
  (state: CommonSliceType) => state.togglePdtFilter
);

export const toggleMenuSelector = createSelector(
  commonSliceSelector,
  (state: CommonSliceType) => state.toggleMenu
);

export const toggleWalletSelector = createSelector(
  commonSliceSelector,
  (state: CommonSliceType) => state.toggleWallet
);

export const toggleChatSelector = createSelector(
  commonSliceSelector,
  (state: CommonSliceType) =>  state.toggleChat
);

export const toggleChatTopicsSelector = createSelector(
  commonSliceSelector,
  (state: CommonSliceType) =>  state.toggleChatTopics
);

export const sellerChatSelector = createSelector(
  commonSliceSelector,
  (state: CommonSliceType) => state.sellerChat
);