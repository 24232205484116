import { collection, limit, onSnapshot, query } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { auth, db, functions } from "../../firebaseutil";
import { AddArtistToAuctionRequest, AddProductToAuctionRequest, Agency, AgencyCreateRequest, AgencyUpdateRequest, AuctionCreateRequest, AuctionUpdateRequest, ChangeAuctionThemeRequest } from "../../models/agency";
import * as Sentry from '@sentry/nextjs'
import { UserContext } from "../../contexts/UserContext";
import { httpsCallable } from "firebase/functions";

export const AGENCY = "agency";

export enum RequestTypes {
  createAgency = "createAgency",
  createAuction = "createAuction",
  updateAgency = "updateAgency",
  updateAuction = "updateAuction",
  changeAuctionTheme = "changeAuctionTheme",
  addArtisToAuction = "addArtisToAuction",
  addProductsToAuction = "addProductsToAuction",
  removeProductsFromAuction = "removeProductsFromAuction",
  removeArtistsFromAuction = "removeArtistsFromAuction",
}

export default function useAgencies() {

    const {user} = useContext(UserContext);
    const [agencies, setAgencies] = useState<Agency[]>([]);

    const appendAgency = (cat: Agency) => {
        setAgencies(prev => [...prev.filter(pk => pk.id !== cat.id), cat]) 
      }
    
    useEffect(() => {
        const q = query(collection(db, `${AGENCY}`), limit(100));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if(change.type == "added") {
              // console.log("artist added", change.doc.id);
                appendAgency(change.doc.data() as Agency);
            } else if(change.type == "modified") {
              // console.log("artist modified", change.doc.id);
                appendAgency(change.doc.data() as Agency);
            } else if(change.type == "removed") {
              // console.log("artist removed", change.doc.id);
              setAgencies(prev => prev.filter(p => p.id !== change.doc.data().id))
            }
          })
        },(err)=>{Sentry.captureException(err);console.log(err)});
        return unsubscribe;
    }, [user])      


    const createAgency = async(data: AgencyCreateRequest) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.createAgency, input: data});

      const response = result.data as any;
      return response;
    }

    const createAuction = async(data: AuctionCreateRequest) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.createAuction, input: data});

      const response = result.data as any;
      return response;
    }


    const updateAgency = async(data: AgencyUpdateRequest) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.updateAgency, input: data});

      const response = result.data as any;
      return response;
    }


    const updateAuction = async(data: AuctionUpdateRequest) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.updateAuction, input: data});

      const response = result.data as any;
      return response;
    }

    const changeAuctionTheme = async(data: ChangeAuctionThemeRequest) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.changeAuctionTheme, input: data});

      const response = result.data as any;
      return response;
    }

    const addArtistsToAuction = async(data: AddArtistToAuctionRequest[]) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.addArtisToAuction, input: data});

      const response = result.data as any;
      return response;
    }
    const removeArtistsFromAuction = async(data: AddArtistToAuctionRequest[]) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.removeArtistsFromAuction, input: data});

      const response = result.data as any;
      return response;
    }


    const addProductsToAuction = async(data: AddProductToAuctionRequest[]) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.addProductsToAuction, input: data});

      const response = result.data as any;
      return response;
    }


    const removeProductsFromAuction = async(data: AddProductToAuctionRequest[]) => {
      const userCallable = httpsCallable(functions, 'agency-agencyCall');
      const result = await userCallable({requestType: RequestTypes.removeProductsFromAuction, input: data});

      const response = result.data as any;
      return response;
    }

 
      
    return {
      agencies,
      createAgency, 
      createAuction,
      updateAgency,
      updateAuction,
      changeAuctionTheme,
      addArtistsToAuction,
      removeArtistsFromAuction,
      addProductsToAuction,
      removeProductsFromAuction,
    }

}