import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { getAuth, ConfirmationResult } from "firebase/auth";
import { isValidPhoneNumber, isValidEmailAddress, uniqueName } from "../../util/util";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ProfileViewModel } from "./model";
import usePublicProfile from "../../hooks/usePublicProfile";
import lineLoading from "../../public/lottie/890-loading-animation.json";
import Lottie from "react-lottie";
import { UserContext } from "../../contexts/UserContext";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().required()
      .test("Invalid email", value => isValidEmailAddress(value)),
    handle: yup.string().matches(/^[a-zA-Z0-9-_]+$/, 'Only letters, numbers, hypen and underscore allowed').required(),
    referralCode: yup.string(),
    consentForUpdates: yup.boolean(),
  })
  .required();

interface Props {
  qrButtonTheme?: boolean;
  referralCode?: string;
  updateProfile: (data: ProfileViewModel) => void;
  referralName?: string;
  referralEmail?: string;
  referralPhoneNumber?: string;
}

export default function ProfileUpdateForm(props: Props) {

  const { dbUser } = useContext(UserContext);
  const { fetchPublicProfileByHandle } = usePublicProfile();
  const [handleError, setHandleError] = useState<boolean>(false);
  const [handleFormatError, setHandleFormatError] = useState<boolean>(false);
  const [checkingHandle, setCheckingHandle] = useState<boolean>(false);
  const [currentHandleInput, setCurrentHandleInput] = useState<string>('');

  const { register, handleSubmit, formState: { isDirty, errors, isValid } } = useForm<ProfileViewModel>({ mode: "onSubmit", resolver: yupResolver(schema), defaultValues: { handle: uniqueName(), consentForUpdates: true, name: props?.referralName, email: props?.referralEmail, phoneNumber: props?.referralPhoneNumber  } });

  useEffect(() => {
    (async () => {
      setCheckingHandle(true);
      if (currentHandleInput && !/^[a-zA-Z0-9-_]+$/.test(currentHandleInput)) {
        setHandleFormatError(true);
        setCheckingHandle(false);
        return;
      } else {
        setHandleFormatError(false);
      }
      const userByHandle = await fetchPublicProfileByHandle(currentHandleInput);
      if (userByHandle?.handle) {
        //handle it taken, check if it is us
        if (userByHandle?.id && userByHandle?.id == dbUser?.id)
          setHandleError(false);
        else
          setHandleError(true);
      } else {
        setHandleError(false);
      }
      setCheckingHandle(false);
    })()
  }, [currentHandleInput]);


  const onSubmit = (data: ProfileViewModel) => {
    props.updateProfile({ ...data }); //, handle: data.handle?.toLowerCase()
  }
  return (
    <div className="gtm-loginpopup">
      <div className="px-3 mb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-center text-blue mb-4">Sign In / Sign Up</h3>
          <input id="nameInput" name="nameInput" type="text" {...register("name")} className={`form-control w-100 my-4`} placeholder="Your name" />
          {errors.name && <p className={`errorMessage my-2`}>{errors.name.message}</p>}
          <input id="emailInput" name="emailInput" type="text"  {...register("email")} className="form-control w-100 my-4" placeholder="Email address*" />
          {errors.email && <p className={`errorMessage my-2`}>{errors.email.message}</p>}
          <label className="form-label mx-0 text-secondary pt-0 mb-1">Choose a Handle (username)*</label>
          <input
            id="handleInput"
            name="handleInput"
            type="text"
            {...register("handle")}
            className="form-control w-100"
            placeholder="Choose an unique Handle"
            onChange={(e) => setCurrentHandleInput(e?.target?.value)}
          />

          {errors.handle && <p className={`errorMessage my-2`}>{errors.handle.message}</p>}
          {handleFormatError && <p className="small-text mb-0 text-error">Only letters, numbers, hypen and underscore allowed</p>}
          {handleError && <p className="small-text mb-0 text-error">This handle already exists!</p>}
          {!checkingHandle && !handleFormatError && !handleError && currentHandleInput?.length != 0 && currentHandleInput.toLowerCase() != dbUser?.handle?.toLowerCase() && <p className="small-text mb-0 text-blue">This handle is available!</p>}
          {checkingHandle && <div style={{}}><Lottie height={30} width={110} options={{ loop: true, autoplay: true, animationData: lineLoading }} /></div>}

          <label className="form-label mx-0 text-secondary pt-0 mb-1">Referral Code</label>
          <input id="referralInput" name="referralInput" defaultValue={props.referralCode} type="text" {...register("referralCode")} className={`form-control w-100`} placeholder="Do you have a referral code?" />
          {errors.referralCode && <p className={`errorMessage my-2`}>{errors.referralCode.message}</p>}
          <div className="form-group form-check pt-3">
            <label className="form-check-label pt-3">
              <input className="form-check-input" type="checkbox"  {...register("consentForUpdates")} /> Sign up for email/mobile updates
            </label>
          </div>
          <button type="submit" className={`btn text-white text-uppercase w-100 my-2 ${props.qrButtonTheme ? 'black-bg' : 'blue-bg'}`} >
            Save Profile
          </button>
        </form>
      </div>
      <div className="text-center terms" style={{ fontSize: "10px" }}>
        <p className="px-2 pt-2">
          By clicking SIGN IN or SIGN UP, you acknowledge that you have read and accepted the <Link href="/terms" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Terms of Service{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>{" "}
          and{" "}
          <Link href="/privacy" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Privacy Policy{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>
        </p>
      </div>
    </div>
  );
}
