import { createContext } from 'react';
import isEqual from 'react-fast-compare';
import { InstantSearch } from "react-instantsearch-dom";
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { fleatoConfig } from '../../fleato-config';
import { tagSearch } from '../../lib/events';
import { SearchClient as TypesenseSearchClient } from "typesense";
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

interface ISearchContext {
  searchScope: string;
  cache: {read: (any) => any, write: (any) => void, clear: () => void}
  getRelatedHits?: ({ q, query_by, filter_by, sort_by }: {
      q?: string;
      query_by?: string;
      filter_by: string;
      sort_by?: string;
    }) => Promise<SearchResponse<{}>>
}

const initProps: ISearchContext = {
  searchScope: "default",
  cache: getInMemoryCache()
}

function getStateWithoutPage(state) {
  const { page, ...rest } = state || {};
  return rest;
}

function getInMemoryCache() {
  let cachedHits = undefined;
  let cachedState = undefined;
  return {
    read({ state }) {
      console.log("product-list read cache query '", state?.query, "' cache match", isEqual(cachedState, getStateWithoutPage(state)), "cache hits", cachedHits?.[0]?.length);
      return isEqual(cachedState, getStateWithoutPage(state))
        ? cachedHits
        : null;
    },
    write({ state, hits }) {
      console.log("product-list write cache '", state?.query, "' hits", hits?.[0]?.length, "cached hits", cachedHits?.[0]?.length);
      cachedState = getStateWithoutPage(state);
      cachedHits = hits;
    },
    clear() {
      console.log("product-list clear cache");
      cachedHits = undefined;
      cachedState = undefined;
    }
  };
}

export const client = new TypesenseSearchClient({
  apiKey: "3Pj2cXbiov5rzkkyhMgH9RTHnYmoJ0gW", // Be sure to use an API key that only allows search operations
  nodes: [
    {
    host: "bjf5zdl7mg1c9wh8p-1.a1.typesense.net",
    port: 443,
    protocol: "https",
    },
  ],
  connectionTimeoutSeconds: 2
})

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    apiKey: "3Pj2cXbiov5rzkkyhMgH9RTHnYmoJ0gW", // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: "bjf5zdl7mg1c9wh8p-1.a1.typesense.net",
        port: 443,
        protocol: "https",
      },
    ],
  },
  // cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "objectID,name,description,artist,categoryHierarchy.lvl0,categoryHierarchy.lvl1,categoryHierarchy.lvl2,categoryHierarchy.lvl3,seller,facetTags,agencyIds",
    sort_by: "_text_match(buckets: 10):desc,stake:desc",
    per_page: 20,
  },
} as any);

const searchClient = typesenseInstantsearchAdapter.searchClient;

export const SearchContext = createContext<ISearchContext>(initProps);
export const SearchProvider = ({ searchScope, children, indexName }: { searchScope: any, children: any, indexName?: string }) => {
  const cache = getInMemoryCache();

  const getRelatedHits = async({q, query_by, filter_by, sort_by}: {q?: string, query_by?: string, filter_by: string, sort_by?: string}) => {

    let searchParameters = {
      'q'         : '',
      'query_by'  : 'objectID',
      'filter_by' : filter_by,
    }
    const result = await client.collections(indexName ?? fleatoConfig.indexName).documents().search(searchParameters, {cacheSearchResultsForSeconds: 2 * 60});
    return result;
  }


  return (
    <SearchContext.Provider
      value={{ cache, searchScope, getRelatedHits }} >
      <InstantSearch indexName={indexName ?? fleatoConfig.indexName} searchClient={searchClient}
        onSearchStateChange={searchState => {
          if (searchState.query?.length)
            tagSearch(searchState.query);
        }}
      >
      {children}
      </InstantSearch>
   </SearchContext.Provider>
  )
}