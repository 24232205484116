import { useEffect, useState } from "react";
import "firebase/auth";
import { getAuth, signInWithRedirect, signInWithCredential, onAuthStateChanged, User, getRedirectResult, getAdditionalUserInfo, updateProfile, updateEmail, sendEmailVerification, signInAnonymously, UserCredential } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { MESSAGES } from '../../util/messages';
import { getFunctions, httpsCallable } from '@firebase/functions';
import { PublicProfile } from '../../models/product';
import { ArtistProfile, DbUser } from '../../models/user';
import { getFirestore, onSnapshot } from '@firebase/firestore';
import { collection, doc, getDoc, getDocs, limit, query, where } from 'firebase/firestore';
import * as Sentry from "@sentry/nextjs";
import { db, auth, functions } from '../../firebaseutil';
import {deviceDetect} from "react-device-detect";
import { ARTISTS, profileTemplate } from "./useArtists";
const provider = new GoogleAuthProvider();

const USER = "user";

enum RequestType {
  subscribeToNewsletter = "subscribeToNewsletter",
  publicProfile = "publicProfile",
  updateProfile = "updateProfile",
  addWallet = "addWallet",
  registerDevice = "registerDevice",
  walletBalance = "walletBalance",
  signedIn = "signedIn",
  userExists = "userExists",
  canRefer = "canRefer",
  myReferrals = "myReferrals"
}

export default function useFirebase() {
  const [user, setUser] = useState<User | null>(null);
  const [dbUser, setDbUser] = useState<DbUser | undefined | null>();
  const [userToken, setUserToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [publicProfile, setPublicProfile] = useState<PublicProfile>();
  const [signInSignUpInProgress, setSignInSignUpInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [myArtistProfile, setMyArtistProfile] = useState<ArtistProfile>(profileTemplate);

  useEffect(() => {
    const authObserverUnsubscribe = onAuthStateChanged(auth,
      async (firebaseUser) => {
        //console.log("Auth changed fired", auth, firebaseUser);
        if (!!firebaseUser) {
          const token = await firebaseUser.getIdToken();
          setUserToken(token);
          setIsAuthenticated(true);
          setUser(firebaseUser);
          Sentry.setUser({ id: firebaseUser.uid });
          const userCredential = await getRedirectResult(auth);
          // console.log("Continuing with signin/signup process");
          if (userCredential) {
            const additionalInfo = getAdditionalUserInfo(userCredential);
            if (additionalInfo?.isNewUser) {
              console.log("New user!");
              setIsNewUser(true);
              setSignInSignUpInProgress(true);
            } else {
              setIsNewUser(false);
            }
          } else {
            setIsNewUser(false);
          }
          await recordSignedIn(firebaseUser.uid);
          await fetchProfile(firebaseUser.uid);
          // if(window)
          //   window.user_id = firebaseUser.uid;
          // console.log("user found", firebaseUser?.uid);
        } else {
          //user not here, empty token?
          setUserToken(null);
          setIsAuthenticated(false);
          setUser(null);
          // console.log("user logged off probably?", firebaseUser);
        }
      },
      (error) => {
        console.log("in useFirebase, auth error", error);
      }
    );
    return () => {
      // ...
      authObserverUnsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!user?.uid) return;

    const unsubscribe = onSnapshot(doc(db, USER, user.uid), (snapshot) => {
      const userRecord = snapshot.data() as DbUser;
      localStorage?.setItem("userName", userRecord?.name);
      localStorage?.setItem("email", userRecord?.email);
      localStorage?.setItem("phone", userRecord?.phoneNumber);
      setDbUser(userRecord as DbUser);
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if(!user?.uid)
      return;
    const q = query(collection(db, `${ARTISTS}`), where("userId", "==", user?.uid), limit(1));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const localMessages = [];
      snapshot.docChanges().forEach((change) => {
        if(change.type == "added") {
            setMyArtistProfile(change.doc.data() as ArtistProfile);
        } else if(change.type == "modified") {
            setMyArtistProfile(change.doc.data() as ArtistProfile);
        } else if(change.type == "removed") {
          setMyArtistProfile(profileTemplate)
        }
      })
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
}, [user])

  const firebaseSignIn = async () => {
    setSignInSignUpInProgress(true);
    await signInWithRedirect(auth, provider);
  }

  const firebaseGuestSignIn = async (): Promise<UserCredential> => {
    const result = await signInAnonymously(auth);
    return result;
  }

  const gapiSignIn = async (credentials: {id_token: string, access_token: string}) => {
    setSignInSignUpInProgress(true);
    if(!credentials || !credentials?.access_token || !credentials?.id_token)
      return;
    const credential = GoogleAuthProvider.credential(
      credentials.id_token,
      credentials.access_token
    )
    await signInWithCredential(auth, credential);
  }

  const markSignUpComplete = () => {
    setSignInSignUpInProgress(false);
  }

  const firebaseSignOut = async () => {
    await auth.signOut();
    setDbUser(undefined);
    setIsAuthenticated(false);
    setPublicProfile(undefined);
    setUserToken(null); 
  }

  const updateUserProfile = async ({ displayName, photoURL }: { displayName: string | null, photoURL: string | null }): Promise<string> => {
    if (user)
      await updateProfile(user, { displayName: displayName ?? user.displayName, photoURL: photoURL ?? user.photoURL });
    return "";
  }

  const recordSignedIn = async (uid: string | undefined) => {
    try{
    const signedIn = httpsCallable(functions, 'user-userCall');
    let deviceData: any = null;
    if(typeof window !== undefined)
      deviceData = deviceDetect(window.navigator.userAgent);
    await signedIn({requestType: RequestType.signedIn, input: {uid, deviceData} });
    }catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const fetchProfile = async (uid: string | undefined) => {
    // console.log("fetching profile...");
    try{
    const publicProfile = httpsCallable(functions, 'user-userCall');
    const userProfile = (await publicProfile({requestType: RequestType.publicProfile, input: {uid} })).data as PublicProfile;
    // console.log("public profile fetched", userProfile);
    setPublicProfile(userProfile);
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const userExists = async (phoneNumber: string) => {
    console.log("checking user...", phoneNumber);
    try{
    const checkUser = httpsCallable(functions, 'user-userCall');
    const result = (await checkUser({requestType: RequestType.userExists, input: {phoneNumber} })).data as {userFound: boolean};
    return result.userFound;
  }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const canRefer = async (handle: string) => {
    console.log("checking referral id...", handle);
    try{
    const checkUser = httpsCallable(functions, 'user-userCall');
    const result = (await checkUser({requestType: RequestType.canRefer, input: {handle} })).data as {canRefer: boolean, reason: string};
    return result;
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);

    }
  }

  const myReferrals = async (handle: string) => {
    console.log("checking referral id...", handle);
    try{
    const referrals = httpsCallable(functions, 'user-userCall');
    const result = (await referrals({requestType: RequestType.myReferrals, input: {handle} })).data as {handle?: string, joinedOn?: string, redeemedAt?: string}[];
    return result;
  }
  catch(err){
    Sentry.captureException(err);
    console.log(err);
  }
  }

  const updateUserEmail = async (email: string): Promise<string> => {
    if (user && email) {
      try {
        await updateEmail(user, email);
        await sendEmailVerification(user, {
          url: 'https://www.fleato.com/my-account',
          iOS: {
            bundleId: 'com.fleato.marketplace'
          },
          android: {
            packageName: 'com.fleato.marketplace',
            installApp: true,
            minimumVersion: '12'
          },
          handleCodeInApp: true
        });
      } catch (err: any) {
        // console.log(err.toString());
        if (err.toString().includes("auth/requires-recent-login"))
          return MESSAGES.LOGIN.REQUIRE_RECENT_LOGIN_FOR_PRIMARY_EMAIL;
      }
      return "";
    } else {
      return "user not logged in";
    }
  }

  const fetchDbUser = async(uid: string): Promise<DbUser | undefined> => {
    const ref = doc(db, `${USER}/${uid}`);
    const dbUser = (await getDoc(ref)).data() as DbUser;
    // console.log("returned dbUser = ", dbUser);
    return dbUser;
  }

  const isVerifiedUser = dbUser?.signInProviders?.length > 0;

  // console.log({isAuthenticated, isNewUser, signInSignUpInProgress, name: user?.displayName, photo: user?.photoURL, phone: user?.phoneNumber});
  return {
    user, dbUser, userToken, isAuthenticated, isNewUser, signInSignUpInProgress, isLoading, publicProfile,
    myArtistProfile,
    updateUserProfile, updateUserEmail, firebaseSignIn, firebaseSignOut, markSignUpComplete, gapiSignIn, fetchProfile, fetchDbUser, firebaseGuestSignIn, isVerifiedUser, userExists, canRefer, myReferrals
  }
}
