import { createContext, useState } from 'react'
import useFirebase from '../hooks/user/useFirebase';
import { ChatTopic } from '../models/chat';
import { Order } from '../models/order';
import { Product } from '../models/product';

interface IChatContext {
  selectedProduct: Product;
  selectedTopic: ChatTopic;
  isSeller: boolean;
  selectedOrder: Order; 
  setSelectedOrder: (order: Order) => void;
  setSelectedProduct: (product: Product) => void;
  setSelectedTopic: (topic: ChatTopic) => void;
}

const initProps: IChatContext = {
  selectedProduct: undefined,
  isSeller: false,
  selectedTopic: undefined,
  selectedOrder: undefined,
  setSelectedOrder: (order: Order) => {},
  setSelectedProduct: (product: Product) => {},
  setSelectedTopic: (topic: ChatTopic) => {}
}

export const ChatContext = createContext<IChatContext>(initProps);

export const ChatProvider = ({children}) => {
  const {user} = useFirebase();
  const [selectedTopic, setSelectedTopic] = useState<ChatTopic>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const isSeller = selectedProduct?.seller?.id === user?.uid;
  return (
    <ChatContext.Provider value={{selectedProduct, setSelectedProduct, isSeller, selectedTopic, setSelectedTopic, selectedOrder, setSelectedOrder}}>
      {children}
    </ChatContext.Provider>
  )
}