import { useEffect, useState } from 'react';
import "firebase/auth";
import useSushiSwapTrader from '../smartcontracts/useSushiSwapTrader';
import { Quote, TOKENS } from '../../models/wallet';

export default function useQuote() {
  //TODO: What to do when huge arbitrage exists between pairs? Which price will be used? 
  //Get continuous quotes
  //Use this as price?
  const {quoteTokenForToken} = useSushiSwapTrader({account: "0x9df3D16f301660eb2ff86FE91F32A84AC44c5c71"}); 
  const [quote, setQuote] = useState<Quote>({usdcToFleato: 100, fleatoToUsdc: 0.01, usdcToFleatoString: "100", fleatoToUsdcString: "0.01", asOf: new Date(2000, 1, 1).toISOString()});

  useEffect(()=> {
    (async () => {
      try {
      const fleato2UsdcTrade = await quoteTokenForToken(TOKENS.fleato, TOKENS.usdc, 100);
      // fleato2UsdcTrade.quoteChanged$.subscribe((value: TradeContext) => setQuote(prev => ({...prev, fleatoToUsdcString: value.expectedConvertQuote, fleatoToUsdc: parseFloat(value.expectedConvertQuote), asOf: new Date().toISOString()})));

      const usdc2FleatoTrade = await quoteTokenForToken(TOKENS.usdc, TOKENS.fleato, 1);
      // fleato2UsdcTrade.quoteChanged$.subscribe((value: TradeContext) => setQuote(prev => ({...prev, usdcToFleatoString: value.expectedConvertQuote, usdcToFleato: parseFloat(value.expectedConvertQuote), asOf: new Date().toISOString()})));

      //Base is 100 fleatos, so re-baseline
      const fleato2UsdcExpectedConvertQuote = (parseFloat(fleato2UsdcTrade.expectedConvertQuote)/100).toFixed(4);
      
      const quote = {fleatoToUsdcString: fleato2UsdcExpectedConvertQuote, usdcToFleatoString: usdc2FleatoTrade.expectedConvertQuote, fleatoToUsdc: parseFloat(fleato2UsdcExpectedConvertQuote), usdcToFleato: parseFloat(usdc2FleatoTrade.expectedConvertQuote), asOf: new Date().toISOString()};
      setQuote(quote);
      return () => {
        fleato2UsdcTrade.destroy();
        usdc2FleatoTrade.destroy();
      }
      } catch(err) {
        console.log("Error getting quote.")
      }
    })();
  }, [])

  const fleatoForUSD = (usd: number, precision: number): string => {
    return (usd/quote.fleatoToUsdc).toFixed(precision);
  }

  const usdForFleato = (fleato: number) => {
    return parseFloat((fleato * quote.fleatoToUsdc).toFixed(2));
  }

  // console.log("quote ", quote, "as of ", asOf);
  return {quote, fleatoForUSD, usdForFleato };
}