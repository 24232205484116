import { createContext, useState } from 'react'
import useFirebase from '../hooks/user/useFirebase';
import { ArtistProfile } from '../models/user';
import useArtists from '../hooks/user/useArtists';

interface IArtistContext {
  allArtists: ArtistProfile[];
  featuredArtists: ArtistProfile[];
  selectedArtist: ArtistProfile[];
  setSelectedArtist: (artist: ArtistProfile[]) => void;
  myArtistProfile: ArtistProfile;
  isSeller: boolean;
}

export const ArtistContext = createContext<IArtistContext>({} as IArtistContext);

export const ArtistProvider = ({ children }) => {

  const { user, myArtistProfile } = useFirebase();
  const { allArtists, featuredArtists } = useArtists(user);
  const [selectedArtist, setSelectedArtist] = useState([]);
  const isSeller = !!myArtistProfile?.id && myArtistProfile.id !== "template";
  

  return (
    <ArtistContext.Provider value={{ 
        featuredArtists, 
        selectedArtist, setSelectedArtist, 
        allArtists, isSeller, myArtistProfile }}
    >
      {children}
    </ArtistContext.Provider>
  )
}