import { useEffect, useState } from "react";

declare global {
  interface Window {
    screenHeight: any
  }
}

export default function UseWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    screenHeight: undefined
  });
  const [scrollPosition,setScrollPosition]=useState(
   undefined,
  )
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      screenHeight: window.screenHeight
    });
  }

  // This causes excessive rerenders. Dont use.
  // function handleScroll(){
  //   console.log("setting scroll pos", window.scrollY)
  //   setScrollPosition(
  //     window.scrollY
  //   )
  // }

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleResize();
      // handleScroll();
      // window.addEventListener('scroll',handleScroll);
      window.addEventListener("resize", handleResize);
      handleResize();
      // handleScroll();

      return () => {window.removeEventListener("resize", handleResize)
      // window.removeEventListener('scroll',handleScroll)
    };
    }
  }, []);
  return {scrollPosition, windowSize};
}
