import { useEffect, useState } from "react";
import { firebaseConfig } from "../../firebase-config";
import useInterval from "../helper/useInterval";
import { GapiUser } from "../../models/user";
import { fleatoConfig } from "../../fleato-config";
import { tagWalletSignIn, tagWalletSignOut } from "../../lib/events";

const AUTH_SCOPES = [
  'email',
  'profile',
  'https://www.googleapis.com/auth/drive.appdata'
]
const CLIENT_ID = fleatoConfig.gapiClientId;

export default function useGapi(signInAsEmail: string | undefined) {
  const [isGapiReady, setIsGapiReady] = useState(false);
  const [isGapiAuthReady, setIsGapiAuthReady] = useState(false);
  const [isGapiSignedIn, setIsGapiSignedIn] = useState(false);
  const [gapiUser, setGapiUser] = useState<GapiUser>();

  const isReady = () => !!window.gapi;

  const checkGapiReadiness = () => {
    // console.log("checking gapi readiness");
    if (isReady()) {
      // console.log("gapi ready!")
      setIsGapiReady(true);
    } else {
      // console.log("gapi not ready yet...")
    }
  }
  useInterval(checkGapiReadiness, !isGapiReady ? 1000 : null);

  useEffect(() => {
    if (!isGapiReady)
      return;
    (async () => {
      // console.log("gapi loading auth library...")
      await loadAuthLibrary();
      // console.log("gapi auth library loaded!")
    })();
  }, [isGapiReady])

  useEffect(() => {
    if (!isGapiAuthReady)
      return;
    (async () => {
      const auth2 = gapi.auth2.getAuthInstance();
      if (auth2.isSignedIn.get()) {
        const response = auth2.currentUser.get().getBasicProfile();
        const currentUser: GapiUser = {
          id: response.getId(), name: response.getName(), email: response.getEmail(), familyName: response.getFamilyName(), givenName: response.getGivenName(), imageUrl: response.getImageUrl()
        }
        // console.log("gapi user already logged in", currentUser.email);
        if (signInAsEmail && currentUser.email !== signInAsEmail) {
          // console.log("Gapi signed in as a different user, signing out", currentUser.email, "and signing in as ", signInAsEmail);
          await gapiSignOut();
        } else {
          setIsGapiSignedIn(true);
        }        
      }
      // console.log("gapi subscribing to isSignedIn...")
      auth2.isSignedIn.listen(handleIsSignedIn);
      // console.log("gapi isSignedIn subscribed to!")
    })();
  }, [isGapiAuthReady, signInAsEmail])

  useEffect(() => {
    if (!isGapiSignedIn)
      return;
    const auth2 = gapi.auth2.getAuthInstance();
    const response = auth2.currentUser.get().getBasicProfile();
    const currentUser: GapiUser = {
      id: response.getId(), name: response.getName(), email: response.getEmail(), familyName: response.getFamilyName(), givenName: response.getGivenName(), imageUrl: response.getImageUrl()
    }
    if (signInAsEmail && currentUser.email !== signInAsEmail) {
      // console.log("Gapi signed in as a different user, signing out", currentUser.email, "and signing in as ", signInAsEmail);
      gapiSignOut();
    } else {
      setGapiUser(currentUser);
    }
  }, [isGapiSignedIn, signInAsEmail])

  const handleIsSignedIn = async () => {
    // console.log("gapi handleIsSignedIn fired");
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2.isSignedIn.get()) {
      // console.log("gapi signed in!")
      const response = auth2.currentUser.get().getBasicProfile();
      const currentUser: GapiUser = {
        id: response.getId(), name: response.getName(), email: response.getEmail(), familyName: response.getFamilyName(), givenName: response.getGivenName(), imageUrl: response.getImageUrl()
      }
      if (signInAsEmail && currentUser.email !== signInAsEmail) {
        // console.log("Gapi signed in as a different user, signing out", currentUser.email, "and signing in as ", signInAsEmail);
        await gapiSignOut();
      } else {
        setIsGapiSignedIn(true);
      }
    } else {
      // console.log("gapi signed off!")
      setIsGapiSignedIn(false);
    }
  }

  const loadAuthLibrary = async () => {
    await new Promise((resolve, reject) => window.gapi.load('client:auth2', (err) => {
      !err ? resolve("success") : reject(err)
    }));
    await gapi.client.init({
      apiKey: firebaseConfig.apiKey, clientId: CLIENT_ID, scope: AUTH_SCOPES.join(' '),
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"]
    })
    setIsGapiAuthReady(true);
  }

  const signIn = async (): Promise<GapiUser | undefined> => {
    if (isGapiSignedIn) {
      // console.log("Gapi already signed in.");
      return gapiUser;
    } else {
      try {
        if (!isGapiAuthReady)
          await loadAuthLibrary();
        const auth2 = gapi.auth2.getAuthInstance();
        const result = await auth2.signIn();
        const response = auth2.currentUser.get().getBasicProfile();
        const currentUser: GapiUser = {
          id: response.getId(), name: response.getName(), email: response.getEmail(), familyName: response.getFamilyName(), givenName: response.getGivenName(), imageUrl: response.getImageUrl()
        }
        return currentUser;
      } catch (error) {
        console.log({ error });
        return undefined;
      }
    }
  }

  const signInAs = async (): Promise<GapiUser | undefined> => {
    if (isGapiSignedIn) {
      if (signInAsEmail && gapiUser?.email !== signInAsEmail) {
        // console.log("Gapi already signed in as a different user, signing out", gapiUser?.email, "and signing in as ", signInAsEmail);
        await gapiSignOut();
        tagWalletSignOut("fleato", signInAsEmail, fleatoConfig.maticChainId as any);
        return undefined;
      } else {
        tagWalletSignIn("fleato", signInAsEmail, fleatoConfig.maticChainId as any);
        return gapiUser;
      }
    } else {
      try {
        if (!isGapiAuthReady)
          await loadAuthLibrary();
        const auth2 = gapi.auth2.getAuthInstance();
        const result = await auth2.signIn();
        const response = auth2.currentUser.get().getBasicProfile();
        const currentUser: GapiUser = {
          id: response.getId(), name: response.getName(), email: response.getEmail(), familyName: response.getFamilyName(), givenName: response.getGivenName(), imageUrl: response.getImageUrl()
        }
        if (signInAsEmail && currentUser.email !== signInAsEmail) {
          // console.log("Gapi just signed in as a different user, signing out", currentUser.email, "and signing in as ", signInAsEmail);
          await gapiSignOut();
          tagWalletSignOut("fleato", signInAsEmail, fleatoConfig.maticChainId as any);
          return undefined;
        } else {
          tagWalletSignIn("fleato", signInAsEmail, fleatoConfig.maticChainId as any);
          return currentUser;
        }
      } catch (error) {
        console.log({ error });
        return undefined;
      }
    }
  }

  const gapiSignOut = async () => {
    setGapiUser(undefined);
    if (!isGapiSignedIn) {
      // console.log("Gapi already signed off.");
      return;
    } else {
      if (!isGapiAuthReady)
        await loadAuthLibrary();
      const auth2 = gapi.auth2.getAuthInstance()
      await auth2.signOut();
    }
  }

  return {
    gapiSignInAs: signInAs, gapiSignOut, isGapiSignedIn, isGapiAuthReady, gapiUser
  };
}
