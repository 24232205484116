import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Tile } from '../../models/bluetile';
import { httpsCallable } from "firebase/functions";
import { functions } from '../../firebaseutil';
import { ethers } from 'ethers';
import { UserContext } from '../../contexts/UserContext';
import { Product } from '../../models/product';
import useProduct from '../product/useProduct';

enum RequestType {
    mint = "mint",
    mintWords = "mint-words",
    transfer = "transfer",
    exists = "exists",
    allowance = "allowance",
    titleExists = "titleExists",
    totalSupply = "totalSupply"
}

export default function useTiles() {
    const { dbUser } = useContext(UserContext);
    const {fetchProduct} = useProduct();
    const SwalAlert = withReactContent(Swal);
    const [templateProduct, setTemplateProduct] = useState<Product>();
    const resetTiles = (showAlert: boolean) => {
        if (showAlert) {
            SwalAlert.fire({
                title: <p>Are you sure do you want to reset?</p>,
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#1478DD"
            }).then(async (alert) => {
                if (alert.isConfirmed) {
                    localStorage.removeItem('tiles');
                    localStorage.removeItem('tile-words');
                    setTiles(initialValue());
                    setTileWords(initialTileWords());
                }
            });
        }
        else {
            localStorage.removeItem('tiles');
            localStorage.removeItem('tile-words');
            setTiles(initialValue());
            setTileWords(initialTileWords());
        }
    }

    useEffect(() => {
        if(!templateProduct){
            (async() => {
                const product = await fetchProduct("blue-tile-project-merchandise-1670535097265");
                if(product?.id){
                    setTemplateProduct(product);
                }
            })()    
        }
    }, [])

    const initialValue = () => {
        const tilesArr = [] as Tile[];
        let isTiles;
        if (typeof window !== 'undefined') {
            isTiles = JSON.parse(localStorage.getItem('tiles'));
            if (isTiles?.length) {
                return [...isTiles];
            }
            else {
                for (let i = 1; i <= 49; i++) {
                    tilesArr.push({ kind: 0 });
                }
                return [...tilesArr];
            }
        }
    };
    const [tiles, setTiles] = useState<Tile[]>(() => {
        return initialValue();
    });

    const initialTileWords = () => {
        let isTileWords;
        if (typeof window !== 'undefined') {
            isTileWords = JSON.parse(localStorage.getItem('tile-words'));
            if (isTileWords?.length) {
                return [...isTileWords];
            }
            else {
                return [];
            }
        }
    };
    const [tileWords, setTileWords] = useState(() => {
        return initialTileWords();
    });

    const [name, setName] = useState<string>("");
    const [tokenId, setTokenId] = useState<string>("0x00");

    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('tiles', JSON.stringify(tiles));
        }
        setTokenId(deriveTokenId())
    }, [tiles]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('tile-words', JSON.stringify(tileWords));
        }
    }, [tileWords]);


    const formattedTileArray = (): number[] => ([7, ...tiles.map(t => t.kind)]);
    const deriveTokenId = (): string => {
        const dataHexString = ethers.utils.hexlify(formattedTileArray());
        const id = ethers.utils.keccak256(dataHexString);
        console.log("Token Id", formattedTileArray(), id);
        return id;
    }

    const exists = async (): Promise<[boolean, boolean]> => {
        const blueTileCall = httpsCallable(functions, "bluetile-bluetileCall");
        // console.log("Listing product", product);

        const result = await blueTileCall({ requestType: RequestType.exists, input: { tokenId, title: name } });
        return result.data as [boolean, boolean];
    }

    const allowance = async (): Promise<number> => {
        const blueTileCall = httpsCallable(functions, "bluetile-bluetileCall");
        // console.log("Listing product", product);

        const result = await blueTileCall({ requestType: RequestType.allowance });
        return result.data as number;
    }

    const mint = async (title: string, wallet: string, totalSupply: number, imageUrl: string, selectedTileType: string, merchImageUrl?: string,) => {
        const blueTileCall = httpsCallable(functions, "bluetile-bluetileCall");
        // console.log("Listing product", product);
        if (!wallet)
            throw new Error("Please choose a wallet first");
        if (selectedTileType == 'shapes') {
            await blueTileCall({ requestType: RequestType.mint, input: { mintToAddress: wallet, tiles: [9, ...tiles.map(t => t.kind)], title, totalSupply, imageUrl } });
        }
        else {
            await blueTileCall({ requestType: RequestType.mintWords, input: { mintToAddress: wallet, tiles: [...tileWords], title, totalSupply, imageUrl, merchImageUrl } });
        }
    }


    return { tiles, setTiles, tileWords, setTileWords, resetTiles, exists, mint, tokenId, allowance, name, setName, templateProduct };

}