import "firebase/auth";
import { functions } from '../../firebaseutil';
import { httpsCallable } from 'firebase/functions';
import { Reward } from '../../models/product';
import * as Sentry from "@sentry/nextjs";


enum RequestTypes {
  getReward = "getReward"
}

export default function useReward() {

  const getReward = async (code: string): Promise<Reward | undefined> => {
    try {
    const rewardCallable = httpsCallable(functions, 'user-userCall');
      const result = await rewardCallable({requestType: RequestTypes.getReward, input: {code}});
      return result.data as Reward;
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error while redeeming",err);
      throw(err);
    }
  }

  return {getReward};
}