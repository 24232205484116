import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { getAuth, ConfirmationResult } from "firebase/auth";
import { isValidPhoneNumber, isValidEmailAddress, uniqueName, e164PhoneNumber } from "../../util/util";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ProfileViewModel } from "./model";
import usePublicProfile from "../../hooks/usePublicProfile";
import lineLoading from "../../public/lottie/890-loading-animation.json";
import Lottie from "react-lottie";
import { UserContext } from "../../contexts/UserContext";
import animeLoading from "../../public/lottie/14117-crypto-loader.json";
import ReactPhoneInput from 'react-phone-input-2'
import UseWindowSize from "../../hooks/helper/useWindowSize";
import { useRouter } from "next/router";

const schema = yup
  .object({
    phoneNumber: yup.string().required("phone number is a required field")
      .test("Invalid phone number", value => isValidPhoneNumber(value ? '+' + value : '')),
    name: yup.string().required(),
    email: yup.string().required()
      .test("Invalid email", value => isValidEmailAddress(value)),
    handle: yup.string().matches(/^[a-zA-Z0-9-_]+$/, 'Only letters, numbers, hypen and underscore allowed').required(),
    // referralCode: yup.string().notRequired(),
    consentForUpdates: yup.boolean(),
  })
  .required();
declare const window: Window &
  typeof globalThis & {
    confirmationResult: ConfirmationResult
  }

interface Props {
  referralCode?: string;
  qrButtonTheme?: boolean;
  signIn: () => void;
  signUp: (data: ProfileViewModel) => void;
  referralName?: string;
  referralEmail?: string;
  referralPhoneNumber?: string;
}

export default function SignUpForm(props: Props) {
  const auth = getAuth();
  const { dbUser, canRefer, userExists } = useContext(UserContext);
  // const { fetchPublicProfileByHandle } = usePublicProfile();
  // const [handleError, setHandleError] = useState<boolean>(false);
  // const [handleFormatError, setHandleFormatError] = useState<boolean>(false);
  // const [checkingHandle, setCheckingHandle] = useState<boolean>(false);
  // const [currentHandleInput, setCurrentHandleInput] = useState<string>('');
  const [welcomeMessageShown, setWelcomeMessageShown] = useState(true);
  const [loading, setLoading] = useState(false);
  const router = useRouter();


  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors } }
    = useForm<ProfileViewModel>({
      mode: "onSubmit",
      resolver: yupResolver(schema),
      defaultValues: { handle: uniqueName(), consentForUpdates: true, referralCode: props?.referralCode, name: props?.referralName, email: props?.referralEmail, phoneNumber: props?.referralPhoneNumber }
    });

  // useEffect(() => {
  //   (async () => {
  //     setCheckingHandle(true);
  //     if (currentHandleInput && !/^[a-zA-Z0-9-_]+$/.test(currentHandleInput)) {
  //       setHandleFormatError(true);
  //       setCheckingHandle(false);
  //       return;
  //     } else {
  //       setHandleFormatError(false);
  //     }
  //     const userByHandle = await fetchPublicProfileByHandle(currentHandleInput);
  //     if (userByHandle?.handle) {
  //       //handle it taken, check if it is us
  //       if (userByHandle?.id && userByHandle?.id == dbUser?.id)
  //         setHandleError(false);
  //       else
  //         setHandleError(true);
  //     } else {
  //       setHandleError(false);
  //     }
  //     setCheckingHandle(false);
  //   })()
  // }, [currentHandleInput]);

  const screenProps = UseWindowSize();
  const onSubmit = async (data: ProfileViewModel) => {
    setLoading(true);
    const formattedPhone = '+' + data.phoneNumber!;
    const userFound = await userExists(formattedPhone);
    // console.log("user found", userFound);
    // if (userFound)
    //   setError("phoneNumber", { message: "Account already exists. Please sign in." });
    // else {
    // const referralValidity = await canRefer(data.referralCode);
    // console.log(referralValidity);

    // if (referralValidity.canRefer) {
    props.signUp({ ...data, phoneNumber: formattedPhone, userFound });
    // } else {
    //   props.signUp({ ...data, phoneNumber: formattedPhone, userFound });
    // setError("referralCode", { message: referralValidity.reason });
    // }
    // }

    setLoading(false);
  }

  return (
    <div className="gtm-loginpopup my-4">
      <div className="px-3 mb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          {router?.asPath.slice(0, 3) != '/sv' && <h3 className="fw-normal text-center text-blue mb-4">Sign Up</h3>}
          {router?.asPath.slice(0, 3) == '/sv' && <><h6 className="my-2 text-center">
            Already have an account? <b onClick={props.signIn} className='cursor-pointer'> Sign In</b>
          </h6>
            <p className="text-center mb-0 fs-22 ">or</p></>}

          {welcomeMessageShown && !loading &&
            <>
              <input id="nameInput" name="nameInput" type="text" {...register("name")} className={`form-control w-100 ${!props.qrButtonTheme ? 'my-2' : 'mt-2 mb-2'}`} placeholder="Your name" />
              {errors.name && <p className={`errorMessage my-2 text-error`}>{errors.name.message}</p>}
              <input id="emailInput" name="emailInput" type="text"  {...register("email")} className="form-control w-100 my-2" placeholder="Email address*" />
              {errors.email && <p className={`errorMessage my-2 text-error`}>{errors.email.message}</p>}
              <Controller control={control} name="phoneNumber" rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <ReactPhoneInput {...field} country={"us"} containerClass={`w-100 ${props?.qrButtonTheme ? 'mt-2' : 'my-2'}`} inputStyle={router.pathname.slice(1, 3) == 'sv' && screenProps?.windowSize?.width < 500 ? { width: '100%' } : { paddingLeft: 50, width: '100%' }} key="phoneNumber"
                    inputProps={{ ref, required: true }} autocompleteSearch />
                )}
              />
              {/* <input id="phoneInput" name="phoneInput" type="tel"  {...register("phoneNumber")} className={`form-control w-100 my-4`} placeholder={`Mobile number*`} /> */}
              {errors.phoneNumber && <p className={`errorMessage my-2 text-error`}>{errors.phoneNumber.message}</p>}
              {/* <label className="form-label mx-0 text-secondary pt-0 mb-1">Choose a Handle (username)*</label>

              <input
                id="handleInput"
                name="handleInput"
                type="text"
                {...register("handle")}
                className="form-control w-100"
                placeholder="Choose an unique Handle"
                onChange={(e) => setCurrentHandleInput(e?.target?.value)}
              />

              {errors.handle && <p className={`errorMessage my-2 text-error`}>{errors.handle.message}</p>}
              {handleFormatError && <p className="small-text mb-0 text-error">Only letters, numbers, hypen and underscore allowed</p>}
              {handleError && <p className="small-text mb-0 text-error">This handle already exists!</p>}
              {!checkingHandle && !handleFormatError && !handleError && currentHandleInput?.length != 0 && currentHandleInput != dbUser?.handle && <p className="small-text mb-0 text-blue">This handle is available!</p>}
              {checkingHandle && <div style={{}}><Lottie height={60} width={110} options={{ loop: true, autoplay: true, animationData: lineLoading }} /></div>} */}

              {!props.qrButtonTheme && <> 
                {props.referralCode && <label className="form-label mx-0 text-secondary pt-0 mt-2">Referral Code</label>}
                <input id="referralInput" name="referralInput" defaultValue={props.referralCode} type="text" {...register("referralCode")} className={`form-control w-100`} placeholder="Do you have a referral code?" required={false} />
                {errors.referralCode && <p className={`errorMessage my-2`}>{errors.referralCode.message}</p>}</>}
              <div className="form-group form-check pt-2">
                <label className="form-check-label pt-2">
                  <input className="form-check-input" type="checkbox"  {...register("consentForUpdates")} /> Sign up for email/mobile updates
                </label>
              </div>
              <button type="submit" className={`btn text-white text-uppercase w-100 my-2 ${props.qrButtonTheme ? 'black-bg' : 'blue-bg'}`} >
                Sign up
              </button>
            </>
          }
          {loading &&
            <Lottie height={100} width={100} options={{ loop: true, autoplay: true, animationData: animeLoading }} />
          }
          {/* {!welcomeMessageShown &&
            <>
              <p>Howdy! Thank you for your interest in Fleato. Currently, we accept signups by invitation only, you need an invite code from an existing Fleato user to sign up.</p>
              <button onClick={() => setWelcomeMessageShown(true)} className="btn blue-bg text-white text-uppercase w-100 my-2" >
                I have the invite code
              </button>
            </>
          } */}
          <>
            <button onClick={(e) =>  {e?.preventDefault(); props.signIn();}} className={`btn btn-ghost-sharp text-uppercase w-100 my-2 ${props.qrButtonTheme ? 'black-ghost-btn' : 'btn-ghost-sharp'}`} >
              Return to Sign in
            </button>
          </>
        </form>
      </div>
      <div className="text-center terms" style={{ fontSize: "10px" }}>
        <p className="px-2">
          By clicking SIGN IN or SIGN UP, you acknowledge that you have read and accepted the <Link href="/terms" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Terms of Service{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>{" "}
          and{" "}
          <Link href="/privacy" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Privacy Policy{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>
        </p>
      </div>
    </div>
  );
}
