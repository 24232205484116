import { useContext, useEffect, useState } from "react";
import Link from "next/link";
import React from "react";
import { UserContext } from "../../../contexts/UserContext";
import { toggleChatSelector, toggleChatTopicsSelector } from "../../../store/selectors/common.selectors";
import { useDispatch, useSelector } from "react-redux";
import { sellerChatAction, toggleChatAction, toggleChatTopicsAction } from "../../../store/slices/common.slice";
import { PublicProfile } from "../../../models/product";
import { GetBold } from "../../../styles/checkout-page-style";
import { ConnectedWalletContext } from "../../../contexts/ConnectedWalletContext";
import lineLoading from "../../../public/lottie/890-loading-animation.json";
import Lottie from "react-lottie";
import { Agency } from "../../../models/agency";

export const DropdownMenu = ({ openRedeemModal, isVerifiedUser, signInSignUp, isSeller, myAgency }: { openRedeemModal: () => void, isVerifiedUser: boolean, signInSignUp: (isSigningUp: boolean) => void, isSeller: boolean, myAgency?: Agency }, {
  user, productId, productName }: { user: PublicProfile; productName: string; productId: string; }) => {
  const { firebaseSignOut, gapiSignOut, dbUser } = useContext(UserContext);
  const { } = useContext(ConnectedWalletContext);
  const toggleChat = useSelector(toggleChatSelector);
  const toggleChatTopics = useSelector(toggleChatTopicsSelector);
  const dispatch = useDispatch();
  const toggleChatTopicsHandler = (e) => {
    e.preventDefault();
    if (!toggleChatTopics)
      dispatch(toggleChatTopicsAction(!toggleChatTopics));
    if (toggleChat) { dispatch(toggleChatAction(!toggleChat)); }
  };

  const [localUser, setLocalUser] = useState({
    userName: "",
    email: "",
    phone: "",
  })

  useEffect(() => {
    (async () => {
      const userName = await localStorage.getItem("userName");
      const email = await localStorage.getItem("email");
      const phone = await localStorage.getItem("phone");
      await setLocalUser({
        userName: (userName != undefined && userName != "undefined") ? userName : "",
        email: (email != undefined && email != "undefined") ? email : "",
        phone: (phone != undefined && phone != "undefined") ? phone : "",
      })
    })()
  }, [dbUser])

  const signOut = async () => {
    await localStorage.setItem("userName", "");
    await localStorage.setItem("email", "");
    await localStorage.setItem("phone", "");
    try {
      await firebaseSignOut();
    } catch(err) {console.log("Error logging out of firebase", err);}
    try {
      await gapiSignOut();
    } catch (err) {console.log("Error logging out of gapi", err);}
  }

  console.log({myAgency});
  

  return (
    <ul className="list-unstyled">
      <li>
        <p style={GetBold}>{localUser?.phone?.length > 0 ? isVerifiedUser ? dbUser?.emailVerified ? dbUser?.email : dbUser?.phoneNumber : localUser?.phone : "Guest"} </p>
      </li>
      {isVerifiedUser &&
        <li>
          <Link href="/my-account" passHref>
            <a className="font-normal">My Account</a>
          </Link>
        </li>
      }
      {isVerifiedUser && !isSeller &&
        <li>
          <Link href={myAgency?.preferences?.sellPageLink ? myAgency?.preferences?.sellPageLink : "/edit-seller-profile"} passHref>
            <a className="font-normal">Sell on {myAgency?.name ? `${myAgency?.name}` : "Fleato"}</a>
          </Link>
        </li>
      }
      {isVerifiedUser &&
        <li>
          <Link href="/" passHref>
            <a className="font-normal" onClick={e => { e.preventDefault(); openRedeemModal(); }}>Redeem Coupon</a>
          </Link>
        </li>
      }
      {/* {isVerifiedUser &&
        <li>
          <Link href="/my-wallet">
            <a className="font-normal">My Wallet</a>
          </Link>
        </li>
      } */}
      {/* {isVerifiedUser &&
        <li>
          <Link href={`/gallery/${dbUser?.handle}`}>
            <a className="font-normal">My NFT Gallery</a>
          </Link>
        </li>
      } */}
      {/* {isVerifiedUser &&
        <li>
          <Link href="/my-cart">
            <a className="font-normal">My Cart</a>
          </Link>
        </li>
      } */}
      {/* {dbUser &&
        <li>
          <Link href="/my-orders">
            <a className="font-normal">My Orders</a>
          </Link>
        </li>
      } */}
      {/* {isVerifiedUser &&
        <li>
          <Link href="/my-listings">
            <a className="font-normal">My Listings</a>
          </Link>
        </li>
      } */}
      {/* {isVerifiedUser &&
        <li>
          <Link href="/my-channel">
            <a className="font-normal">My Channel</a>
          </Link>
        </li>
      } */}
      {/* {isVerifiedUser &&
        <li>
          <Link href="/my-sales">
            <a className="font-normal">My Sales</a>
          </Link>
        </li>
      } */}
      {/* {isVerifiedUser &&
        <li>
          <Link href="/my-bids">
            <a className="font-normal">My Bids</a>
          </Link>
        </li>
      } */}
      {isVerifiedUser &&
        <li>
          <Link href="/refer-a-friend" passHref>
            <a className="font-normal">Refer a Friend</a>
          </Link>
        </li>
      }
      {/* {isVerifiedUser &&
        <li>
          <Link href="/" passHref><a className="font-normal" onClick={(e) => toggleChatTopicsHandler(e)}>Chat</a></Link>
        </li>
      } */}
      {!isVerifiedUser && !dbUser?.id && (localUser?.phone && localUser?.phone?.length > 0) &&
        <li>
          <Lottie height={60} width={110} options={{ loop: true, autoplay: true, animationData: lineLoading }} />
        </li>
      }
      <li>
        <Link href="/help" passHref>
          <a className="font-normal">Help</a>
        </Link>
      </li>
      {!isVerifiedUser && (!localUser?.phone || localUser?.phone?.length === 0) &&
        <li>
          <Link href="/" passHref>
            <a className="font-normal" onClick={(e) => {e?.preventDefault(); signInSignUp(true)}}>Sign up</a>
          </Link>
        </li>
      }
      {!isVerifiedUser && dbUser?.id && (!localUser?.phone || localUser?.phone?.length > 0) &&
        <li>
          <Link href="/" passHref>
            <a className="font-normal" onClick={(e) => {e?.preventDefault(); signInSignUp(false)}}>Sign in</a>
          </Link>
        </li>
      }
      {(!!dbUser || !!localUser?.phone || !!localUser?.userName || !!localUser?.email) &&
        <li>
          <Link href="/" passHref>
            <a className="font-normal" onClick={(e) => {e?.preventDefault(); signOut()}}>Sign Out</a>
          </Link>
        </li>
      }
    </ul>
  );
};
