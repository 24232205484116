import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SellerChat } from "../../models/chat";
import { CommonSliceType } from "../../types/common.types";
export const sliceName = "common";

export const initialState: CommonSliceType = {
  toggleMenu: false,
  togglePdtFilter: false,
  toggleWallet:false,
  toggleChat:false,
  toggleChatTopics:false,
  sellerChat: {sellerId: "", productId: "", sellerName: "", productSlug: "", productName: ""},
};

export const commonSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    toggleMenuAction: (
      state: CommonSliceType,
      action: PayloadAction<boolean>
    ): void => {
      state.toggleMenu = action.payload;
    },
    togglePdtFilterAction: (
      state: CommonSliceType,
      action: PayloadAction<boolean>
    ): void => {
      state.togglePdtFilter = action.payload;
    },
    toggleWalletAction: (
      state: CommonSliceType,
      action: PayloadAction<boolean>
    ): void => {
      state.toggleWallet = action.payload;
    },
    toggleChatAction: (
      state: CommonSliceType,
      action: PayloadAction<boolean>
    ): void => {
     state.toggleChat = action.payload;
    },
    toggleChatTopicsAction: (
      state: CommonSliceType,
      action: PayloadAction<boolean>
    ): void => {
     state.toggleChatTopics = action.payload;
    },
    sellerChatAction: (
      state: CommonSliceType,
      action: PayloadAction<SellerChat>
    ): void => {
     state.sellerChat = action.payload;
    },
  },
});

export const { toggleMenuAction, togglePdtFilterAction,toggleWalletAction,toggleChatAction, toggleChatTopicsAction, sellerChatAction } = commonSlice.actions;
