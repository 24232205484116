import { createContext, useState } from 'react'
import useQuote from '../hooks/wallet/useQuote';
import { Quote } from '../models/wallet';

interface IFleatoQuoteContext {
  quote: Quote;
  fleatoForUSD: (usd: number, precision: number) => string;
}

const initProps: IFleatoQuoteContext = {
  quote: undefined,
  fleatoForUSD: (usd: number, precision:number) => undefined
}

export const FleatoQuoteContext = createContext<IFleatoQuoteContext>(initProps);

export const FleatoQuoteProvider = ({children}) => {
  const {quote, fleatoForUSD} = useQuote();
  return (
    <FleatoQuoteContext.Provider value={{quote, fleatoForUSD}}>
      {children}
    </FleatoQuoteContext.Provider>
  )
}