import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { LoginModalStyle } from "../../styles/login-style";
import { ReactSVG } from "react-svg";
import logo from "../../public/images/whiteLogo.svg";
import { AiOutlineClose } from "react-icons/ai";
import { UserContext } from "../../contexts/UserContext";
import UseWindowSize from "../../hooks/helper/useWindowSize";
import LoginModal from "./login-modal";
import { MdClose } from "react-icons/md";

interface Props {
  referralCode?: string;
  signUp?: boolean;
  setIsSigningUp: Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
  referralName?: string;
  referralEmail?: string;
  referralPhoneNumber?: string;
}

export default function LoginModalWrapper(props: Props) {
  const { user, isNewUser, markSignUpComplete } = useContext(UserContext);
  const [signingUp, setSigningUp] = useState(props.signUp ?? false);
  const [step, setStep] = useState<"signin" | "signup" | "verification" | "profileupdate" | "walletsetup" | "redeem">(props.signUp ? "signup" : "signin");

  const closeModal = () => {
    markSignUpComplete();
    props.setIsSigningUp(false);
    props.closeModal();
  };

  const screenProps = UseWindowSize();
  const { windowSize } = screenProps;
  return (
    <LoginModalStyle>
      <div className="position-absolute close">
        <MdClose size={20} color="#000" onClick={closeModal} />
      </div>
      <div className="row" >
        {/* {(!!dbUser?.email && !!dbUser?.handle && !signingUp && !!rewardStatus) || (step == "walletsetup") ? null : } */}
        {/* <div className="col-sm-6 col-md-6 lt-sec text-center">
          <h1 className="text-blue mt-4">{user === null ? "Welcome to Fleato" : isNewUser ? "Almost There !" : "Fleato"}</h1>
          {(windowSize.width > 600 || (!signingUp && step !== "signup")) && <div className="logo-wrapper d-flex pb-5 mb-sm-0">
            <ReactSVG src={logo.src} alt="Logo" fill="#fff" />
            <span className="text-white pt-2">The Global Art Marketplace</span>
          </div>}
        </div> */}
        {/* ${(!!dbUser?.email && !!dbUser?.handle && !signingUp && !!rewardStatus) || (step == "walletsetup") ? 'col-sm-12' : 'col-sm-6'} */}
        <LoginModal
          setIsSigningUp={props?.setIsSigningUp}
          referralCode={props?.referralCode}
          signUp={props.signUp}
          closeModal={props?.closeModal}
          setStep1={setStep}
          setSigningUp1={setSigningUp}
          referralPhoneNumber={props?.referralPhoneNumber}
          referralEmail={props?.referralEmail}
          referralName={props?.referralName}
        />
      </div>
    </LoginModalStyle>
  );
}