import { useEffect, useState } from 'react';
import "firebase/auth";
import { onSnapshot, query, doc, setDoc, collection, serverTimestamp, limit } from "firebase/firestore";
import { uuidv4 } from '../../util/util';
import { Address } from '../../models/user';
import { db, auth } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'

const USER = "user";
const ADDRESS = "address";

export default function useAddress() {

  const [isBusySavingAddress, setIsBusySavingAddress] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);

  useEffect(() => {
    if (!!!auth?.currentUser?.uid) {
      setAddresses([]);
    }
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!auth.currentUser?.uid)
      return;
    const q = query(collection(db, `${USER}/${auth.currentUser?.uid}/${ADDRESS}`), limit(20));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const localAddresses = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type == "added") {
          // console.log("category added", change.doc.id);
          appendAddress(change.doc.data() as Address);
        } else if (change.type == "modified") {
          // console.log("category modified", change.doc.id);
          appendAddress(change.doc.data() as Address);
        } else if (change.type == "removed") {
          // console.log("category removed", change.doc.id);
          setAddresses(prev => prev.filter(p => p.id !== change.doc.data().id))
        }
      })
    }, (error) => { Sentry.captureException(error); console.log(error) });
    return unsubscribe;
  }, [auth.currentUser?.uid])


  const appendAddress = (add: Address) => {
    setAddresses(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  }

  const upsertAddress = async (address: Address): Promise<Address> => {
    setIsBusySavingAddress(true);
    address.lastUpdated = serverTimestamp();
    try {
      if (address.id) {
        const addressRef = doc(db, `${USER}/${auth.currentUser?.uid}/${ADDRESS}/${address.id}`);
        await setDoc(addressRef, {
          fullName: address?.fullName,
          phoneNumber: address?.phoneNumber,
          state: address?.state,
          zip: address?.zip,
          address1: address?.address1,
          address2: address?.address2,
          city: address?.city,
          isDefault: false,
          lastUpdated: serverTimestamp()
        }, { merge: true })
      }
      else if (!address.id)
        address.id = uuidv4();
      const addressRef = doc(db, `${USER}/${auth.currentUser?.uid}/${ADDRESS}/${address.id}`);
      if (address.isDefault) {
        const prevDefaultAddresses = addresses.filter(a => a.isDefault);
        if (prevDefaultAddresses.length == 1 && prevDefaultAddresses[0].id === address.id) {
          //this is alright
        } else if (prevDefaultAddresses.length > 0) {
          //change all previous ones to not default
          prevDefaultAddresses.forEach(async (add) => {
            const prevRef = doc(db, `${USER}/${auth.currentUser?.uid}/${ADDRESS}/${add.id}`);
            await setDoc(prevRef, { isDefault: false, lastUpdated: serverTimestamp() }, { merge: true });
          });
        }
      } else if (!addresses.some(a => a.isDefault)) {
        address.isDefault = true;
      }
      await setDoc(addressRef, address, { merge: true });
    } catch (err) { Sentry.captureException(err); console.log(err) }
    appendAddress(address);
    setIsBusySavingAddress(false);
    return address;
  }

  const getDefaultAddress = (): Address | undefined => {
    const def = addresses.find(a => a.isDefault);
    if (def)
      return def;
    else if (addresses.length > 0)
      return addresses[0];
    else
      return undefined;
  }

  const defaultAddress = getDefaultAddress();  

  return { addresses, defaultAddress, upsertAddress, isBusySavingAddress };
}