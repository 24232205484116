import { useState, useEffect, useContext } from "react";
import { IconContext } from "react-icons";
import { Accordion, AccordionContext, Card, useAccordionButton } from "react-bootstrap";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BiCheck, BiChevronDown } from "react-icons/bi";
import { uuidv4 } from "../../util/util";
import Link from "next/link";
import { fleatoConfig } from "../../fleato-config";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BsCheckLg } from 'react-icons/bs';
import WalletCard from "../wallet/WalletCard";

export interface Step {
  id: string;
  name: string;
  promptMessage: string;
  progressMessage?: string;
  confirmationMessage?: string;
  errorMessage?: string;
  percentComplete: number;
  isInError: boolean;
  execute: (stepId: string, callbacks: StepCallbacks) => Promise<boolean>;
  callbacks?: StepCallbacks;
  transactionHash?: string;
  wallet?: string;
  children?: Step[];
}

export interface StepProps {
  name?: string;
  promptMessage?: string;
  progressMessage?: string;
  confirmationMessage?: string;
  errorMessage?: string;
  percentComplete?: number;
  isInError?: boolean;
  transactionHash?: string;
  walletAddress?: string;
}


export const createStep = (callbacks: StepCallbacks): Step => {
  let step: Step = {
    id: uuidv4(), percentComplete: 0, isInError: false, name: "", promptMessage: "",
    execute: async (stepId: string, callbacks: StepCallbacks): Promise<boolean> => { return false; },
    callbacks
  };
  return step;
}

export interface StepCallbacks {
  setTransactionHash?: (stepId: string, transactionHash: string) => void;
  setWalletAddress?: (stepId: string, walletAddress: string) => void;
  setConfirmationMessage?: (stepId: string, confirmationMessage: string) => void;
  markProgress?: (stepId: string, progressPercent: number, message?: string) => void;
  markInError?: (stepId: string, errorMessage: string) => void;
}
interface Props {
  steps: Step[];
}

function StepToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div className="accordion-header" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

export default function MultiStepProcess({ steps }: Props) {
  const [selectedItem, setSelectedItem] = useState<string>();
  useEffect(() => {
    const firstUnfinishedStepIndex = steps.findIndex(s => s.isInError || s.percentComplete < 100);
    setSelectedItem(`item${firstUnfinishedStepIndex}`);
  }, [steps]);
  return (
    <Accordion activeKey={selectedItem} >
      {steps.map((step, index) => (
        <Card key={`item${index}-${step.id}`}>
          <Card.Header>
            <StepToggle eventKey={`item${index}`} callback={(eventKey) => setSelectedItem(eventKey)}>
              <div className="d-flex align-items-center justify-content-between mx-md-3">
                <div className="d-flex align-items-center py-3">
                  <div className="d-flex align-items-center justify-content-center mx-2 " >
                    {
                      step.percentComplete == 0 ?
                        <div style={{ width: 30, height: 30 }}>

                          <CircularProgressbarWithChildren value={step.percentComplete}
                            strokeWidth={10}
                            styles={buildStyles({
                              pathColor: "#1478DD",
                              pathTransition: "stroke-dashoffset 0.5s ease 0s",

                            })}
                          >
                            <div>
                              <p className="mb-0 fw-bold">{index + 1}</p>
                            </div>
                          </CircularProgressbarWithChildren >
                        </div> :
                        <div style={{ width: 30, height: 30 }}>
                          {!step.isInError && step.percentComplete < 100 ?
                            <div className="spinner-border" style={{ color: "#1478DD" }} role="status">
                            </div> :
                            <CircularProgressbarWithChildren value={step.percentComplete < 100 ? 80 : 100}
                              className={(!step.isInError && step.percentComplete < 100) ? "loader" : ""}
                              strokeWidth={10}
                              styles={buildStyles({
                                pathColor: "#1478DD",
                                pathTransition: "stroke-dashoffset 0.5s ease 0s",



                              })}
                            >
                              {/* {step.percentComplete == 100 &&
                                <div className="rounded-circle d-flex align-items-center justify-content-center" style={{ width: 30, height: 30, backgroundColor: "#1478DD" }}>
                                  <BiCheck
                                    size={24}
                                    color="#fff" />
                                </div>
                              } */}
                              <div>
                                <p className="mb-0 fw-bold">{index + 1}</p>
                              </div>
                            </CircularProgressbarWithChildren >}
                        </div>
                    }
                  </div>
                  <div className="d-flex align-items-center ">
                    <p className="mb-0 fw-bold">{step.name}  </p>
                    {step.percentComplete == 100 && <BsCheckLg color="#00cd2c" className="mx-2" />}
                  </div>
                </div>
                <div className="pb-3">

                  <IconContext.Provider value={{ className: "arrow" }}>
                    <i>
                      <BiChevronDown
                        className="taxi"
                        // color="#1478DD"
                        size={28}
                      />
                    </i>
                  </IconContext.Provider>
                </div>
              </div>
            </StepToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={`item${index}`}>
            <div className="accordion-body border-top mx-3">
              <div className="col-12 ">
                {step.percentComplete == 0 && !step.isInError &&
                  <p>{step.promptMessage}</p>
                }
                {step.percentComplete > 0 && step.percentComplete < 100 && !step.isInError &&
                  <p>{step.progressMessage ?? step.promptMessage}</p>
                }
                {step.percentComplete === 100 && !step.isInError &&
                  <p>{step.confirmationMessage ?? step.promptMessage}</p>
                }
                {step.isInError &&
                  <p className="text-danger">{step.errorMessage ?? "Error processing this step. Please try again later."}</p>
                }
              </div>
              {step.percentComplete == 0 &&
                <div className=" col-md-6 ">
                  <button className="btn btn-ghost" onClick={async () => await step.execute(step.id, step.callbacks)}>Submit</button>
                </div>
              }
              <div className="col-12">
                {!!step.wallet && step.percentComplete == 100 &&
                  <WalletCard address={step.wallet} handle="Your Wallet" />
                }
                {step.transactionHash &&
                  <p>View this transaction at <Link href={`${fleatoConfig.blockExplorer}/tx/${step.transactionHash}`} passHref><a target="_blank" rel="noreferrer">Polygon Block Explorer {"   "}<FaExternalLinkAlt />{" "}</a></Link></p>
                }
              </div>
            </div>
          </Accordion.Collapse>
        </Card>
      ))
      }
    </Accordion >
  )
}