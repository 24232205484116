export const fleatoConfig = {
  indexName: "product",
  productEventIndexName: "event",
  userIndexName: "user",
  artistIndex: "artist",
  opportunityIndex: "opportunity",
  freshartsresourcesIndex: "freshartsresources",
  payPalClientId:
    "Aap8IhQtmJjFAH6duRmezxDa6LUxqiBMu969eMc16vAc7XSWjDGXMS3mXP32_TQSFtupuYJOyl06Wa6X",
  gtmId: "G-4YMYW6N0JG",
  facebookPixelId: "460897109290635",
  fleatoNFTGraphUrl:
    "https://api.thegraph.com/subgraphs/name/fleatomarket/fleato-nft",
  bluetileGraphUrl:
    "https://api.thegraph.com/subgraphs/name/fleatomarket/bluetile",
  polygonRpcEndpoint:
    "https://polygon-mainnet.g.alchemy.com/v2/PP3b_D7tQBR7ZEf7AibCPALRS86au92b",
  ethereumRpcEndpoint:
    "https://eth-mainnet.alchemyapi.io/v2/AY49zEV3rOv-hoo3EoP4sSIpd1koNKL0",
  contractPhysicalSwap: "0x7025B8D5A3DB1D8c22f410809ba4684959dFF4Bf",
  contractUsdcToken: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  contractFleatoToken: "0x6804b07d883d0169c05233332ccf17aa956424c5",
  contractWbtcToken: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
  contractWethToken: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
  contractWmaticToken: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
  contractFleatoNFT: "0xEAb0FFDF495F13D8b6Af17b888ABa160cEFAeab1",
  contractGnosisSingleton: "0xd9db270c1b5e3bd161e8c8503c55ceabee709552",
  contractGnosisFallbackLibrary: "0xf48f2b2d2a534e402487b3ee7c18c33aec0fe5e4",
  contractGnosisMultisendLibrary: "0xa238cbeb142c10ef7ad8442c6d1f9e89e07e7761",
  contractDaoMembershipBot: "0xE17c5077b7e1e98A04B04433Cb3FaA5605F6C786",
  contractPoster: "0x000000000000cd17345801aa8147b8D3950260FF",
  contractBaalSummoner: "0x8Fc1b1Fe6D65481157deC19a2FF2ef51819904b1",
  contractLootTemplate: "0x25e105403261Eff4795f9f0D143f73bd3deCEf05",
  contractSharesTemplate: "0xCEac64d515155ab0EBD5Bc2F1206AfC8A7dB0c39",
  addressAdjudicator: "0x5A17e155Fa838EcA3aF1934D6adA7Fac9004062b",
  contractSushV2Router02: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  contractBlueTile: "0xE2d7b606b6b8745095A03726085d24Df5Ba6eA59",
  maticChainId: 137,
  ethereumChainId: 1,
  gapiClientId:
    "410686584435-8kvt2brplv5uvmtsda4r2vvungsgv7he.apps.googleusercontent.com",
  listingBucket: "gs://fleato-listing",
  polygonNetworkName: "Polygon Mainnet",
  polygonRpcUrl: "https://polygon-rpc.com",
  blockExplorer: "https://polygonscan.com",
  websiteBaseUrl: "https://www.fleato.com",
  cloudFunctionBaseUrl: "https://us-central1-fleato.cloudfunctions.net",
  blogBaseUrl: "https://services.fleato.com",
  nftBaseUrl: "https://nft.fleato.org",
  polygonScanApiKey: "GEHIMH1KXQ18YRJAHGIUVVR1JJ58VSBN8M",
  storageBaseUrl: "https://cdn.fleato.org",
  imageOptimizedUrls: [
    "https://firebasestorage.googleapis.com/v0/b/fleato-listing",
    "https://cdn.fleato.com",
    "https://cdn.fleato.org",
  ],
  mapBox:
    "pk.eyJ1Ijoic3BhcmtsZWUiLCJhIjoiY2wwdW1qejFvMG95ZTNwcGZ0ejJpeHd3aSJ9.SFxF-pIVI72x_BbT_xP75A",
  signupReward: 15,
  defaultRandomUUID: "000000000000000000000000000000000000",
  googleMapsApiKey: "AIzaSyArINdHSF-o60ncLOBDdQhJW_2uo2NjAqA",
};
