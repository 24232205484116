import { useEffect, useState } from 'react';
import {  httpsCallable } from "firebase/functions";
import { SilaKycStatus, SilaBankAccount, SilaLiteUserRequest, SilaUser, SilaVerificationStatus, SilaLinkBankAccountRequest, SilaWalletWithBalance, SilaRegistrationResponse, SilaRegistrationValidationDetails, SilaRedemptionRequest } from '../../models/sila';
import {DbUser} from "../../models/user";
import useInterval from "../helper/useInterval";
import { db, auth, functions } from '../../firebaseutil';
import * as Sentry from "@sentry/nextjs";


enum RequestTypes {
  registerSilaLiteUser = "registerSilaLiteUser",
  checkKycStatus = "checkKycStatus",
  getPlaidLinkToken = "getPlaidLinkToken",
  getSilaBankAccounts = "getSilaBankAccounts",
  linkSilaBankAccount = "linkSilaBankAccount",
  createSilaCharge = "createSilaCharge",
  chargeSila = "chargeSila",
  createReceiptsWallet = "createReceiptsWallet",
  getSilaBalance = "getSilaBalance",
  getSilaWalletsWithBalance = "getSilaWalletsWithBalance",
  redeemSila = "redeemSila"
}

export default function useSilaWallet(dbUser: DbUser) {
  const [isLoading, setIsLoading] = useState(true);
  const [kycStatus, setKycStatus] = useState<SilaKycStatus>();
  const [silaHandle, setSilaHandle] = useState<string>();
  const [bankAccounts, setBankAccounts] = useState<SilaBankAccount[]>();
  const [verificationResult, setVerificationResult] = useState<SilaRegistrationValidationDetails>();

  useEffect(() => {
    if(!!!auth?.currentUser?.uid){
        setKycStatus(undefined);
        setSilaHandle(undefined);
        setBankAccounts(undefined);
        setVerificationResult(undefined);
    }
  },[auth?.currentUser?.uid]);

  useEffect(()=> {
      if(dbUser) {
        if(dbUser?.silaHandle) {
          (async () => {
            setSilaHandle(dbUser.silaHandle);
            setKycStatus(dbUser.silaKycStatus);
            if(dbUser.silaKycStatus == "passed")
              await getSilaBankAccounts();
            setIsLoading(false);
          })();
        } else {
          setIsLoading(false);
        }
      } 
  }, [dbUser]);

  const isKycPending = kycStatus === "pending" || kycStatus == "unverified";
  //Not sure if the useInterval will re-evaluate everytime, so adding as a function as well
  const checkIfKycPending = () => kycStatus === "pending" || kycStatus == "unverified";

  const pollKycStatus = async () => {
    console.log("polling kyc status since it is",  kycStatus);
    if(checkIfKycPending()) {
      console.log("kyc pending. Polling to get update");
      await checkKycStatus();
    } else {
      // console.log("kyc moved in somd direction...")
    }
  }
  useInterval(pollKycStatus, kycStatus && kycStatus === "pending" ? 2000: null);

  // useEffect(()=>{
  //   if(kycStatus === "pending") {
  //   }
  // }, [kycStatus])

  const didKycPass = kycStatus === "passed";
  const isRegistered = kycStatus !== undefined;
  const isBankAccountLinked = bankAccounts?.length > 0;
  //Handle is created during registerLite user automatically
  const registerSilaLiteUser = async (silaUserRequest: SilaLiteUserRequest): Promise<SilaUser | undefined> => {
   try{
    const silaCall = httpsCallable(functions, 'sila-silaCall');
    // console.log("registering lite user", dbUser.id, {requestType: RequestTypes.registerSilaLiteUser, input: silaUserRequest});
    const result = await silaCall({requestType: RequestTypes.registerSilaLiteUser, input: silaUserRequest});
    const response = result.data as SilaRegistrationResponse;
    if(!response.success) {
      setVerificationResult(response.validation_details);
      return undefined;
    } else {
      setVerificationResult(undefined);
      // console.log("sila verification result", result);
      const silaUser = result.data as unknown as SilaUser;
      // console.log("sila user", silaUser);
      return silaUser;
    }
  }catch(err){
    Sentry.captureException(err);
    console.log(err);
  }
  }

  const checkKycStatus = async (): Promise<SilaVerificationStatus> => {
    try{
    const silaCall = httpsCallable(functions, 'sila-silaCall');
    // console.log("Checking kyc status for", dbUser.id);
    const result = await silaCall({requestType: RequestTypes.checkKycStatus});
    const silaVerificationStatus = result.data  as unknown as SilaVerificationStatus;
    // console.log("sila verification status", silaVerificationStatus);
    return silaVerificationStatus;
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }

  }

  const getSilaBankAccounts = async (): Promise<SilaBankAccount[]> => {
    try{
    const silaCall = httpsCallable(functions, 'sila-silaCall');
    // console.log("getting bank accounts for", dbUser.id);
    const result = await silaCall({requestType: RequestTypes.getSilaBankAccounts});
    // console.log("useSilaWallet getSilabankAccounts", result);
    const bankAccounts = result.data as unknown as SilaBankAccount[];
    setBankAccounts(bankAccounts);
    // console.log("sila bankAccounts", bankAccounts);
    return bankAccounts;
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const getPlaidLinkToken = async (): Promise<any> => {
    try{
    const silaCall = httpsCallable(functions, 'sila-silaCall');
    // console.log("Getting plaid token for", dbUser.id);
    const result = await silaCall({requestType: RequestTypes.getPlaidLinkToken})
    const plaidLinkToken = result.data as unknown as any;
    // console.log("plaid link token", plaidLinkToken);
    return plaidLinkToken;
  }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const createReceiptsWallet = async (): Promise<boolean> => {
    try{
    const silaCall = httpsCallable(functions, 'sila-silaCall');
    // console.log("Creating Receipts Wallet for", dbUser.id);
    const result = await silaCall({requestType: RequestTypes.createReceiptsWallet})
    const success = result.data as unknown as any;
    // console.log("receipts wallet creation status", success);
    return success;
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const redeemSila = async (accountName: string, amount: number): Promise<boolean> => {
    try{
    const silaCall = httpsCallable(functions, 'sila-silaCall');
    // console.log("Redeeming Sila for", dbUser.id);
    const input: SilaRedemptionRequest = {accountName, amount};
    const result = await silaCall({requestType: RequestTypes.redeemSila, input})
    const success = result.data as unknown as boolean;
    // console.log("redeem sila status", success);
    return success;
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const getSilaWalletsWithBalance = async (): Promise<SilaWalletWithBalance[]> => {
    try{
    const silaCall = httpsCallable(functions, 'sila-silaCall');
    // console.log("Getting balance for", dbUser?.id);
    const result = await silaCall({requestType: RequestTypes.getSilaWalletsWithBalance})
    const wallets = result.data as SilaWalletWithBalance[];
    // console.log("sila balance amounts", wallets);
    return wallets;
    }
    catch(err){
      Sentry.captureException(err);
      console.log(err);
    }
  }

  const linkSilaBankAccount = async (accessToken: string, accountId: string, accountName: string): Promise<SilaBankAccount[]> => {
  
  try{
      const silaCall = httpsCallable(functions, 'sila-silaCall');
    const input: SilaLinkBankAccountRequest = {
      plaidToken: accessToken, accountId, accountName
    };
    // console.log("link bank account", dbUser.id, "data", {requestType: RequestTypes.linkSilaBankAccount, input});
    const result = await silaCall({requestType: RequestTypes.linkSilaBankAccount, input});
    const success = result.data as boolean;
    return await getSilaBankAccounts();
  }
  catch(err){
    Sentry.captureException(err);
    console.log(err);
  }
  }

  // console.log("sila hook", {isLoading, isBankAccountLinked, bankAccounts, silaHandle, didKycPass, isRegistered, kycStatus, verificationResult});
  return {registerSilaLiteUser, getPlaidLinkToken, getSilaBankAccounts, isLoading, isBankAccountLinked, isKycPending, bankAccounts, kycStatus, silaHandle, didKycPass, isRegistered, linkSilaBankAccount, createReceiptsWallet, getSilaWalletsWithBalance, verificationResult, redeemSila};
}