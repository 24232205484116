import {useEffect, useState} from "react";
import { Mnemonic, WalletStorage } from "../../models/wallet";

export default function useGoogleDrive(isGapiSignedIn: boolean) {
  const [isReady, setIsReady] = useState(false);
  const [reLoginGapi, setReloginGapi] = useState(false);
  // console.log("useGoogleDrive isGapiSignedIn", isGapiSignedIn);
  useEffect(()=>{
    if(!isGapiSignedIn)
      return;
    (async () => {
      //drive is loaded during gapi init itself via discovery docs, so this should never run.
      if(!gapi.client.drive)
        await loadLibrary();

      setIsReady(true);
    })();
  }, [isGapiSignedIn])


  var createFileWithJSONContent = async (folderId, name, data) => {
    return new Promise((resolve, reject) => {
      const boundary = '-------314159265358979323846';
      const delimiter = "\r\n--" + boundary + "\r\n";
      const close_delim = "\r\n--" + boundary + "--";

      const contentType = 'application/json';

      var metadata = {
        'name': name,
        'mimeType': contentType,
        'parents': [folderId]
      };

      var multipartRequestBody =
        delimiter +
        'Content-Type: application/json\r\n\r\n' +
        JSON.stringify(metadata) +
        delimiter +
        'Content-Type: ' + contentType + '\r\n\r\n' +
        data +
        close_delim;

      const msg = {
        'path': `/upload/drive/v3/files`,
        'method': 'POST',
        'params': { 'uploadType': 'multipart' },
        'headers': {
          'Content-Type': 'multipart/related; boundary="' + boundary + '"'
        },
        'body': multipartRequestBody
      };
      // console.log("firing message", msg);
      var request = gapi.client.request(msg);
      const callback = function (file) {
        if(!!file?.result?.error)
          reject(new Error(file?.result?.error?.message))
        else
          resolve(file);
      };
      request.execute(callback);
    });
  }

  const loadLibrary = async () => {
    // console.log("google drive load library called");
    const result =  await gapi.client.load('drive', 'v3');
    
  }

  const listFiles = async () => {
    if(!gapi.client.drive)
      await loadLibrary();
    try{
      const response = await gapi.client.drive.files.list({ spaces: 'appDataFolder' }); 
      // console.log("response", response);
      const returnData = response.result.files;
      // console.log("list files = ", files[0]);
    return returnData;
    }catch(err) {
      if((err as any)?.result?.error?.message === "Insufficient Permission: Request had insufficient authentication scopes.") { //.result?
        setReloginGapi(true);
      }else {        
        console.log("Error while listing files in gdrive: ",err);
      }
      return [];
    }
  }

  const readFile = async (fileId: string) => {
    return new Promise((resolve, reject) => {
      const request = gapi.client.drive.files.get({
        fileId: fileId,
        alt: 'media'
      });
      request.execute((response) => {        
        resolve(response.result);
      });
    });
  }

  const latestFileId = async (): Promise<string | undefined> => {
    const files = await listFiles();
    const filtered = files?.filter(f => f.name !== "testwallet.json") ?? [];
    const sorted = filtered.sort((a,b) => parseInt(b.name.split(".")[0]) - parseInt(a.name.split(".")[0]));
    // console.log("sorted", sorted.map(f => f.name));
    const fileId = sorted?.[0]?.id;
    return fileId;
  }

  const generateFileName = () => `${new Date().getTime()}.json`;

  const exists = async (): Promise<boolean> => {
    const fileId = await latestFileId();
    return !!fileId;
  }

  const getValue = async(): Promise<Mnemonic | undefined> => {
    // console.log("getting stored value..."); 
    const fileId = await latestFileId();
    // console.log("fileId", fileId);
    if(fileId) {
      const content =  await readFile(fileId);
      // console.log("content", content);
      return content as Mnemonic;
    } else {
      return undefined;
    }
  }

  const create = async (content: Mnemonic) => {
    try {
      await createFileWithJSONContent('appDataFolder', generateFileName(), JSON.stringify(content));
    } catch (err) {
      console.log("Error while creating wallet in gdrive: ",err);
    }
  }

  const storage: WalletStorage  = {
    isReady, exists, getValue, create
  };

  return { storage, reLoginGapi, setReloginGapi };
}