import styled from "styled-components";

export const ChatWrapper = styled.div`
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 90%;
  z-index: 7;
  margin-bottom: 18px;
  height: calc(100% - 123px);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 232, 235);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
  visibility: visible;
  transform: translate3d(100%, 0px, 0px);
  opacity: 0;
  &.visible {
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    visibility: visible;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  .overflow-auto {
    overflow: auto;
    height: calc(100% - 130px);
    position: absolute;
  }
  .main-wrap {
    height: calc(100% - 63px);
    @media(min-width:992px){
   .w-1{width:30% !important}
   .w-2{width:40% !important}
  }
  }
  .left {
    width:100%;
    height: calc(100% - 0px);
    top: 0px;
    right: 0px;
    h3 {
      font-size: 1rem;
    }
    font-size: 0.9rem;
    overflow-x: hidden !important;
    ul li {
      border-bottom: 1px solid #fff;
      margin-bottom: 10px;
    }
  }
  .text-grey {
    color: #656565;
  }
  .text-blue{color:#4a91e6}
  .middle {
    height: calc(100% - 54px);
    top: 0px;
  }
  .right {
    height: calc(100% - 98px);
  overflow-x: hidden !important;
  .img-wrap{width:auto; height:auto;min-height:120px; position:relative;margin-bottom:5px}
  @media(max-width:991px){
    right:10px;
    left: 10px;
  }
  }
  &.grey-bg {
    background: #d4d4d4;
  }
  .input-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media (min-width: 601px) and (max-width: 1199px) {
    width: 98%;
  }
  @media (max-width: 600px) {
    width: 98%;
  }

  .close {
    position: absolute;
    z-index: 5;
    left: 5px;
    top: 12px;
    svg path {
      stroke: #757575;
    }
  }
  .rce-mbox {
    border: 1px solid #707070;
    box-shadow: none;
    border-radius: 8px;
  }
  .rce-mbox-title {
    color: #656565;
    font-weight: 700;
    font-size: 1rem;
    cursor: auto;
  }
  .rce-mbox-left-notch,
  .rce-mbox-right-notch {
    display: none;
  }
  .rce-container-mbox {
    margin: 15px 0;
    min-width: initial;
  }
  .rce-mbox.rce-mbox-right {
    background: #e4ffe2;
    margin: 0;
  }
  .rce-mbox-title:hover {
    text-decoration: none;
  }
  .rce-mbox-reply-border {
    border-color: #29ac49 !important;
  }
  .rce-mbox-reply-border {
    border-color: #29ac49 !important;
  }
  .rce-mbox-reply-owner {
    color: #29ac49 !important;
    font-size: 1rem;
  }
  .rce-mbox-reply-message {
    color: #000000;

    font-size: 0.9rem;
  }
  .rce-mbox-text {
    font-size: 0.9rem;
    color: #656565;
  }

  .rce-container-input {
    border: 1px solid #dfdfdf;
    border-radius: 2px;
  }

  .chat-topics {
    border-right: 3px solid #1478DD;
  }
  @media (max-width: 991px) {
  }
`;
