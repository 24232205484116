import { ethers, Signer } from "ethers";
import { formatReceipt, gasFee, gasFeeOptions } from "../../util/smartcontracts";
import { Receipt } from "../../models/blockchain";
import useFleatoPool from "./useFleatoPool";
import { EthersTxn, Nonce } from "../../models/wallet";
import { WalletType } from "../wallet/models";

export default function useRawTransaction({walletType, getSigner, getProvider} : {walletType: WalletType, getProvider?: () => Promise<any>, getSigner?: () => Promise<Signer>}) {

  const {getNonce, submitTransaction} = useFleatoPool();
  //getProvider will be sent for connected wallets
  //getSigner will be sent for fleato wallets

  //Web3 Provider for connected providers, default provider for fleato wallet.
  const networkProvider = async (): Promise<any> => {
    if(walletType == WalletType.ConnectedWallet) {
      const web3 =  new ethers.providers.Web3Provider(await getProvider());
      console.log("using connected web3 provider", web3);
      return web3.getSigner();
    } else {
      const signer = await getSigner();
      console.log("using signer", signer);
      return signer;
    }
  }

  const sendTransactionNoWait = async (transaction: any): Promise<EthersTxn | Nonce> => {
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      delete transaction.gasPrice;
      transaction.maxFeePerGas = gasEstimate.maxFeePerGas;
      transaction.maxPriorityFeePerGas = gasEstimate.maxPriorityFeePerGas;
      transaction.type = 2;
      const provider = await networkProvider();
      const tx = await provider.sendTransaction(transaction);
      console.log("tx", tx);
      return tx as EthersTxn;
    } else {
      const signer = await networkProvider();
      const nonce = Math.max((await getNonce(transaction.from))?.pooled ?? 0, await signer.provider.getTransactionCount(transaction.from, "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = {...transaction, maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 1500000};
      const fastTxn = {...transaction, maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 1500000};
      const fastestTxn = {...transaction, maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 1500000};
      const signedTx = await signer.signTransaction({...await signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await signer.signTransaction({...await signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await signer.signTransaction({...await signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(transaction.from);

    }
  }

  const sendTransaction = async (transaction: any): Promise<Receipt | void> => {
    const tx = await sendTransactionNoWait(transaction);
    if("wait" in tx) {
      const response = await tx.wait();
      const receipt = formatReceipt(response);
      console.log("receipt", receipt);
      if (receipt == null) {
        throw new Error("txn not mined");
      } else if (receipt.status == 0) {
        throw new Error("txn failed");
      } else {
        console.log("txn successful");
        return receipt;
      }
    } else {
      return;
    }
  }

  return {sendTransaction, sendTransactionNoWait};
}
