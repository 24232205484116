import { ShippingMethod } from "./product";
import { Address } from "./user";

export enum Shipper {
  USPS = "USPS",
  UPS = "UPS",
  FEDEX = "FEDEX",
  DHL = "DHL",
  OTHER = "OTHER"
}

export enum ShippingStatus {
  NOT_SHIPPED = "not-shipped",
  LABEL_CREATED = "label-created",
  PICKED_UP_BY_SHIPPER = "picked-up-by-shipper",
  IN_TRANSIT = "in-transit",
  OUT_FOR_DELIVERY = "out-for-delivery",
  DELIVERED = "delivered",
  DELIVERY_FAILED = "delivery-failed"
}

export interface ShippingInfo {
  shipper: Shipper;
  trackingNumber: string;
  trackingUrl: string;
  shipDate: string;
  estimatedDeliveryDate?: string;
  buyerConfirmation?: ShippingConfirmation;
  sellerConfirmation?: ShippingConfirmation;
  status: ShippingStatus;
}

export enum PickupDeliveryStatus {
  NOT_STARTED = "not-started",
  ARRANGING = "arranging",
  SCHEDULED = "scheduled",
  IN_TRANSIT = "in-transit",
  DELIVERED = "delivered",
  BUYER_NOSHOW = "buyer-noshow",
  SELLER_NOSHOW = "seller-noshow",
  FAILED = "failed"
}

export interface ShippingConfirmation {
  proof?: string[];
  notes?: string;
  status: ShippingStatus;
}

export interface PickupDeliveryConfirmation {
  arrivedAt: string;
  deliveredAt: string;
  proof?: string[];
  status: PickupDeliveryStatus.BUYER_NOSHOW | PickupDeliveryStatus.SELLER_NOSHOW | PickupDeliveryStatus.DELIVERED | PickupDeliveryStatus.FAILED;
  notes: string;
}

export interface Location {
  lat: number;
  lng: number;
  address?: string;
}

export interface PickupDeliveryInfo {
  location: Location;
  scheduledTime: string;
  buyerConfirmation?: PickupDeliveryConfirmation;
  sellerConfirmation?: PickupDeliveryConfirmation;
  status: PickupDeliveryStatus;
}

export interface Delivery {
  orderNumber: string;
  deliveryMode: ShippingMethod;
  shipping?: ShippingInfo;
  localPickup?: PickupDeliveryInfo;
  localDelivery?: PickupDeliveryInfo;
  buyerReceiptConfirmation?: boolean;
}

export interface ShippingLabelUpdate {
  shipper: Shipper;
  trackingNumber: string;
  trackingUrl?: string;
  shipDate: string;
}

export interface PickupDeliveryScheduleUpdate {
  location: Location;
  scheduledTime: string;
}

export interface SellerDeliveryUpdate {
  orderNumber: string;
  deliveryMode: ShippingMethod;
  shippingLabelUpdate?: ShippingLabelUpdate;
  estimatedDeliveryDateUpdate?: string;
  shippingProofUpdate?: string[]
  shippingStatusUpdate?: ShippingStatus;
  pickupScheduleUpdate?: PickupDeliveryScheduleUpdate;
  pickupConfirmationUpdate?: PickupDeliveryConfirmation;
  deliveryConfirmationUpdate?: PickupDeliveryConfirmation;
}


export interface BuyerDeliveryUpdate {
  orderNumber: string;
  deliveryMode: ShippingMethod;
  deliveryScheduleUpdate?: PickupDeliveryScheduleUpdate;
  pickupConfirmationUpdate?: PickupDeliveryConfirmation;
  deliveryConfirmationUpdate?: PickupDeliveryConfirmation;
  buyerReceiptConfirmation?: ShippingConfirmation;
}
