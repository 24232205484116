import { createContext, useContext } from 'react'
import { UserContext } from './UserContext';
import useGoogleDrive from '../hooks/helper/useGoogleDrive';
import useSelfCustodyWallet from '../hooks/wallet/useSelfCustodyWallet';
import usePay, { PayExtraInfo } from '../hooks/smartcontracts/usePay';
import useTokenBalance from '../hooks/wallet/useTokenBalance';
import { EthersTxn, Nonce, RecentBalance } from '../models/wallet';
import useQuote from '../hooks/wallet/useQuote';
import { recentFleatoWallet } from '../util/util';
import { Step, StepCallbacks } from '../components/progressbar/multi-step-process';
import useFleatoNFT from '../hooks/smartcontracts/useFleatoNFT';
import { WalletType } from '../hooks/wallet/models';
import { BigNumber, ContractTransaction, ethers, Wallet } from 'ethers';
import useBaal from '../hooks/smartcontracts/useBaal';
import { Dao, DaoMember } from '../models/dao';

interface IFleatoWalletContext {
  paymentProgressPercent: number;
  account?: string,
  recentBalance?: RecentBalance;
  balanceString?: string;
  shortAddress?: string;
  loadingBalance?: boolean;
  payForOrder: (prefix: string, chargeId: string, paymentAmount: number, nftContract: string, nftTokenId: string, royaltyBasisPoints: number, extraInfo: PayExtraInfo, callbacks: StepCallbacks) => Promise<Step[]>;
  isDeposited: (chargeCode: string) => Promise<boolean>, 
  depositNFT: (chargeCode: string) => Promise<EthersTxn | Nonce>,
  depositNFTSteps: (chargeCode: string, isDeposited: (chargeCode: string) => Promise<boolean>, depositNFT: (chargeCode: string) => Promise<EthersTxn | Nonce>, onComplete: () => Promise<void>, callbacks: StepCallbacks) => Promise<Step[]>,
  fetchTokenBalances: () => Promise<RecentBalance>;
  gasBalance: () => Promise<number>;
  submitInprogressPayment: (orderHeaderId: string, orderNumber: string, chargeCode: string, paymentAmount: number) => Promise<void>;
  approveAll: (grantForContractAddress: string) => Promise<EthersTxn | Nonce>;
  isApproved: (grantForContractAddress: string) => Promise<boolean>;
  getSigner: () => Promise<Wallet>;
  refreshBalance: () => Promise<void>;
  summonBaalAndSafe: (dao: Dao, members: DaoMember[]) => Promise<ContractTransaction | Nonce>;
  mintVotes: (baalAddress: string, memberAddress: string[], votes: BigNumber[]) => Promise<ContractTransaction | Nonce>;
  mintLoot: (baalAddress: string, memberAddress: string[], loot: BigNumber[]) => Promise<ContractTransaction | Nonce>;
  burnVotes: (baalAddress: string, memberAddress: string[], votes: BigNumber[]) => Promise<ContractTransaction | Nonce>;
  burnLoot: (baalAddress: string, memberAddress: string[], loot: BigNumber[]) => Promise<ContractTransaction | Nonce>;
  submitProposal: (baalAddress: string, name: string, description: string) => Promise<ContractTransaction | Nonce>;
  sponsorProposal: (baalAddress: string, proposalId: number) => Promise<ContractTransaction | Nonce>;
  processProposal: (baalAddress: string, proposalId: number) => Promise<ContractTransaction | Nonce>;
  submitVote: (baalAddress: string, proposalId: number, vote: boolean) => Promise<ContractTransaction | Nonce>;
  daoBalanceOf: (baalAddress: string, memberAddress: string) => Promise<{lootBalance: number, votesBalance: number}>;
  giveMembersRoles:  (baalAddress: string, memberAddress: string[], roles: BigNumber[]) => Promise<ContractTransaction | Nonce>;
}

export const FleatoWalletContext = createContext<IFleatoWalletContext>({} as IFleatoWalletContext);

export const FleatoWalletProvider = ({children}) => {
  const {dbUser, isGapiSignedIn, gapiUser} = useContext(UserContext);
  const {storage} = useGoogleDrive(isGapiSignedIn);
  const {account, getSigner} = useSelfCustodyWallet(storage, gapiUser);
  const {quote} = useQuote();
  const {recentBalance, balanceString, shortAddress, loading: loadingBalance, fetchTokenBalances, refreshBalance} = useTokenBalance(account ?? recentFleatoWallet(dbUser?.wallets), quote);
  const {payForOrder, isDeposited, depositNFT, depositNFTSteps, gasBalance,  paymentProgressPercent,  submitInprogressPayment} = usePay({walletType: WalletType.FleatoWallet, account: account ?? dbUser?.wallets?.[dbUser.wallets.length -1], getSigner, fetchTokenBalances});
  const {isApproved, approveAll} = useFleatoNFT({walletType: WalletType.FleatoWallet, account, getSigner});
  const {summonBaalAndSafe, mintVotes, mintLoot, burnVotes, burnLoot, submitProposal,sponsorProposal, processProposal, submitVote, daoBalanceOf, giveMembersRoles} = useBaal({walletType: WalletType.FleatoWallet, account, getSigner})
  return (
    <FleatoWalletContext.Provider value={{account : account ?? recentFleatoWallet(dbUser?.wallets), paymentProgressPercent, payForOrder, isDeposited, depositNFT, depositNFTSteps, gasBalance, recentBalance, balanceString, shortAddress, loadingBalance, fetchTokenBalances, submitInprogressPayment, isApproved, approveAll, getSigner, refreshBalance, summonBaalAndSafe, mintVotes, mintLoot, burnVotes, burnLoot, submitProposal, sponsorProposal, processProposal, submitVote, daoBalanceOf, giveMembersRoles}}>
      {children}
    </FleatoWalletContext.Provider>
  )
}