import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { LedgerConnector } from '@web3-react/ledger-connector'
import { TrezorConnector } from '@web3-react/trezor-connector'
import {fleatoConfig} from "../../fleato-config";

const POLLING_INTERVAL = 12000

export const injected = new InjectedConnector({ supportedChainIds: [fleatoConfig.maticChainId, fleatoConfig.ethereumChainId] })

export const walletconnect = new WalletConnectConnector({
  rpc: { 
    [fleatoConfig.maticChainId]: fleatoConfig.polygonRpcEndpoint,
    [fleatoConfig.ethereumChainId]: fleatoConfig.ethereumRpcEndpoint
  },
  qrcode: true
})

export const walletlink = new WalletLinkConnector({
  url: fleatoConfig.polygonRpcEndpoint,
  appName: 'Fleato.com',
  appLogoUrl: "https://www.fleato.com/images/flea120.png",
  darkMode: false,
  supportedChainIds: [fleatoConfig.maticChainId, fleatoConfig.ethereumChainId]
})

export const ledger = new LedgerConnector({ chainId: fleatoConfig.maticChainId, url: fleatoConfig.polygonRpcEndpoint, pollingInterval: POLLING_INTERVAL })

export const trezor = new TrezorConnector({
  chainId: fleatoConfig.maticChainId,
  url: fleatoConfig.polygonRpcEndpoint,
  pollingInterval: POLLING_INTERVAL,
  manifestEmail: 'support@fleato.com',
  manifestAppUrl: 'https://www.fleato.com'
})