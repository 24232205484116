import { useContext, useEffect, useState } from 'react';
import "firebase/auth";
import { onSnapshot, query, doc, setDoc, collection, serverTimestamp, limit, getDoc, where } from "firebase/firestore";
import { uuidv4 } from '../../util/util';
import {Opportunity, UpdateOpportunityStatus} from '../../models/user';
import { db, auth,functions } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'
import { httpsCallable } from 'firebase/functions';
import slug from "slug";
import { UserContext } from '../../contexts/UserContext';

const OPPORTUNITY = "opportunity";
const UPSERTOPPORTUNITY = "upsertOpportunity";
const UPDATESTATUS='updateStatus';
const DELETEOPPORTUNITY='deleteOpportunity';

interface Filter{
  startDate:string;
  endDate:string;
}
export default function useOpportunity() {

  const [opportunitys, setOpportunitys] = useState<Opportunity[]>([]);
  const [filter,setFilter]=useState<Filter>();
  const [prevFilter, setPrevFilter] = useState<Filter>();
  const{user}=useContext(UserContext);
  

  useEffect(() => {
    if(filter != prevFilter){
      setOpportunitys([]);
      setPrevFilter(filter);
    }
  }, [filter])

  useEffect(() => {
    if (!!!auth?.currentUser?.uid) {
      setOpportunitys([]);
    }
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!auth.currentUser?.uid)
      return;
    let q;
    if(filter?.startDate&&filter?.endDate){
      q=query(collection(db, `${OPPORTUNITY}`),where('date',">=",`${filter?.startDate}`),where('date',"<=",`${filter?.endDate}`), limit(20));
    }
    else{
      q=query(collection(db, `${OPPORTUNITY}`),where('date',">=",`'${filter?.startDate}'`), limit(20));
    }
   
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type == "added") {
          // console.log("category added", change.doc.id);
          appendOpportunity(change.doc.data() as Opportunity);
        } else if (change.type == "modified") {
          // console.log("category modified", change.doc.id);
          appendOpportunity(change.doc.data() as Opportunity);
        } else if (change.type == "removed") {
          // console.log("category removed", change.doc.id);
          setOpportunitys(prev => prev.filter(p => p.id !== change.doc.data().id))
        }
      })
    }, (error) => { Sentry.captureException(error); console.log(error) });
    return unsubscribe;
  }, [user, filter])


  const appendOpportunity = (add:Opportunity) => {
    setOpportunitys(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  }

  const upsertOpportunity = async (opportunity: Opportunity): Promise<boolean> => {
    try {
      const userCallable = httpsCallable(functions, 'opportunity-opportunityCall');
    const idSlug = slug(`${opportunity?.name}-${new Date().getTime()}`);
    const opportunityInput={
      ...opportunity,
      id:opportunity?.id??idSlug
    }
    // console.log(opportunityInput,"sdfds")
    const result = await userCallable({requestType: UPSERTOPPORTUNITY, input:opportunityInput});
      const response = result.data as Opportunity;
      return true;
    } catch (err) { 
      Sentry.captureException(err); 
      console.log(err,"err") 
      return false;
    }
    
  }

  const fetchOpportunity = async (id: string): Promise<Opportunity> => {
    const opportunityRef = doc(db, `${OPPORTUNITY}/${id}`);
    const shortUrlSnapshot = await getDoc(opportunityRef);
    return shortUrlSnapshot.data() as Opportunity;
  }
  const deleteOpportunity=async(id:string):Promise<boolean>=>{
    try{
    const userCallable = httpsCallable(functions, 'opportunity-opportunityCall');
    const result = await userCallable({requestType: DELETEOPPORTUNITY, input:id});
    return true;
    }
     catch (err) { 
      Sentry.captureException(err); 
      console.log(err,"err") 
      return false;
    }
  }
  const updateOpportunityStatus=async(opportunityStatus:UpdateOpportunityStatus):Promise<boolean>=>{
    try{
    const userCallable = httpsCallable(functions, 'opportunity-opportunityCall');
    const result = await userCallable({requestType: UPDATESTATUS, input:opportunityStatus});
    return true;
    }
     catch (err) { 
      Sentry.captureException(err); 
      console.log(err,"err") 
      return false;
    }
  }
  return { opportunitys, upsertOpportunity,fetchOpportunity,deleteOpportunity,updateOpportunityStatus,setFilter, filter};
}