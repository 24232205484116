import { useEffect, useState } from 'react';
import "firebase/auth";
import { collection, query, onSnapshot, orderBy, where, getDoc, doc, limit } from "firebase/firestore";
import { OrderStatus, OrderWithDelivery, OrderWithPayment } from '../../models/order';
import { auth, db, functions} from '../../firebaseutil';
import { ShippingMethod } from '../../models/product';
import { PickupDeliveryStatus, ShippingStatus } from '../../models/delivery';
import { httpsCallable } from 'firebase/functions';
import * as Sentry from '@sentry/nextjs';


const ORDER = "order";

enum RequestTypes {
  updateDeliveryInfoAsSeller = "updateDeliveryInfoAsSeller",
  updateDeliveryInfoAsBuyer = "updateDeliveryInfoAsBuyer",
  requestPayment = "requestPayment",
  cancelOrder = "cancelOrder",
  cancelPartiallyPaidOrder = "cancelPartiallyPaidOrder",
  fetchOrderForQrCode = "fetchOrderForQrCode",
  updateOrderForEventScan = "updateOrderForEventScan",
}

export const fullyPaid = (s: OrderWithDelivery) => [OrderStatus.processing, OrderStatus.paid, OrderStatus.complete].includes(s.status) &&
// ((!!s.cryptoPayment || !!s.payPalPayment || !!s.silaPayment) &&
    s.amount - ((s.creditsPayment?.totalUsd?.v ?? 0) + (s.cryptoPayment?.totalUsd?.v ?? 0) + (s.silaPayment?.amount ?? 0) + (s.payPalPayment?.amount ?? 0)) < 0.1;

export const draft = (s: OrderWithDelivery) => s.status === OrderStatus.draft;

export const draftOrPaymentStarted = (s: OrderWithDelivery) => s.status === OrderStatus.draft ||  (s.status === OrderStatus.processing && !fullyPaid(s));

export const paymentStarted = (s: OrderWithDelivery) => [OrderStatus.processing].includes(s.status) &&
  ((!!s.chargeCodesFleato?.length || !!s.chargeCodesCredits?.length || !!s.chargeCodesPayPal?.length || !!s.chargeCodesSila?.length) && !fullyPaid(s));

export const partiallyPaid = (s: OrderWithDelivery) => [OrderStatus.processing, OrderStatus.draft].includes(s.status) &&
  // ((!!s.cryptoPayment || !!s.payPalPayment || !!s.silaPayment) &&
    s.amount - ((s.creditsPayment?.totalUsd?.v ?? 0) + (s.cryptoPayment?.totalUsd?.v ?? 0) + (s.silaPayment?.amount ?? 0) + (s.payPalPayment?.amount ?? 0)) >= 0.1;


export default function useOrders(uid?: string) {

  const [purchases, setPurchases] = useState<OrderWithDelivery[]>([]);
  const [loadingPurchases, setLoadingPurchases] = useState(true);
  // console.log("purchases in useOrders", purchases, "for user", uid);
  const appendPurchase = (order: OrderWithDelivery) => {
    setPurchases(prev => [...prev.filter(pk => pk.orderNumber !== order.orderNumber), order])
  }

  useEffect(() => {
    if(!!!auth?.currentUser?.uid){
      setPurchases([]);
    }
  },[auth?.currentUser?.uid]);


  useEffect(() => {
    // console.log("in hook, auth user ", uid);
    if (!uid)
      return;
    setLoadingPurchases(true);
    const q = query(collection(db, `${ORDER}`), where("buyerId", "==", uid), orderBy("orderNumber", "desc"), limit(20));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type == "added") {
          // console.log("order added", change.doc.id, change.doc.data());
          appendPurchase(change.doc.data() as OrderWithDelivery);
        } else if (change.type == "modified") {
          // console.log("order modified", change.doc.id);
          appendPurchase(change.doc.data() as OrderWithDelivery);
        } else if (change.type == "removed") {
          // console.log("order removed", change.doc.id);
          setPurchases(prev => prev.filter(p => p.orderNumber !== change.doc.data().orderNumber))
        }
      })
      setLoadingPurchases(false);
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
  }, [uid])

  const sortedPurchases = [...purchases].sort((a, b) => a.orderNumber > b.orderNumber ? -1 : 1);
  const paidOrProcessingPurchases = sortedPurchases.filter(o => o.status !== "draft" && (partiallyPaid(o) || fullyPaid(o)));

  const processingOrders = sortedPurchases.filter(o => o.status === "processing");

  const waitingOnBuyer = sortedPurchases.filter((s: OrderWithDelivery) => partiallyPaid(s) || ([OrderStatus.processing, OrderStatus.paid].includes(s.status) && (
    (s.shippingMethod === ShippingMethod.local_delivery && !s.shippingAddress) ||
    (s.shippingMethod === ShippingMethod.local_pickup && s.delivery?.localPickup?.status != PickupDeliveryStatus.DELIVERED)))) 

  const waitingOnPlatform = sortedPurchases.filter((s: OrderWithDelivery) => (s.status === OrderStatus.processing || s.status === OrderStatus.paid) && fullyPaid(s) && (
    (s.shippingMethod === ShippingMethod.shipping && !s.delivery) ||
    (s.shippingMethod === ShippingMethod.local_pickup && !s.delivery) ||
    (s.delivery?.deliveryMode === ShippingMethod.local_delivery && s.delivery?.localDelivery?.status != PickupDeliveryStatus.DELIVERED)));
  
  const inTransit = sortedPurchases.filter((s: OrderWithDelivery) => (s.shippingMethod === ShippingMethod.shipping && !!s.delivery?.shipping?.status && [ShippingStatus.IN_TRANSIT, ShippingStatus.LABEL_CREATED, ShippingStatus.OUT_FOR_DELIVERY, ShippingStatus.PICKED_UP_BY_SHIPPER].includes(s.delivery.shipping.status)) ||
  (s.shippingMethod === ShippingMethod.local_delivery && s.delivery?.localDelivery?.location && s.delivery?.localDelivery?.status != PickupDeliveryStatus.DELIVERED));
  
  //Ignore orders without header ids, they are old.
  const inCart = (sortedPurchases?.filter(draftOrPaymentStarted) ?? []).filter(o => !!o.orderHeaderId).filter(order => (order.inprogressPayments?.reduce((acc, p) => acc + p.paymentAmount, 0) ?? 0) < (order.amount ?? order.productPrice));

  const cancelOrder = async(orderNumber: string) => {
    try{
    const cancel = httpsCallable(functions, 'payment-paymentCall1');
    const result = await cancel({requestType: RequestTypes.cancelOrder, orderNumber: orderNumber});
    // console.log({result}); 
    return result;
    }
    catch(err){
        Sentry.captureException(err);
        console.log(err);
    }
  }

  const cancelPartiallyPaidOrder = async(orderNumber: string) => {
    try{
    const cancel = httpsCallable(functions, 'payment-paymentCall1');
    const result = await cancel({requestType: RequestTypes.cancelPartiallyPaidOrder, orderNumber: orderNumber});
    // console.log({result}); 
    return result;
    }
    catch(err){
        Sentry.captureException(err);
        console.log(err);
        return err;
    }
  }

  const fetchOrderByOrderNumber = async(orderNumber: string) => {
    const docRef = doc(db, ORDER, orderNumber);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data() as OrderWithDelivery;
      // console.log("Document data:", docSnap.data());
    } else {
      return undefined;
    }
  }

  const fetchOrderForQrCode = async(qrCodeData: string, benefitName: string) => {
    try{
      const cancel = httpsCallable(functions, 'payment-paymentCall1');
      const result = await cancel({requestType: RequestTypes.fetchOrderForQrCode, qrCodeData, benefitName});
      return result.data;
      }
      catch(err){
          Sentry.captureException(err);
          console.log(err);
          return err;
      }   
  }

  const updateOrderForEventScan = async(order: OrderWithPayment) => {
    try{
      const cancel = httpsCallable(functions, 'payment-paymentCall1');
      const result = await cancel({requestType: RequestTypes.updateOrderForEventScan, order});
      return result;
      }
      catch(err){
          Sentry.captureException(err);
          console.log(err);
          return err;
      }   
  }


  // console.log("useOrders", {purchases, paidOrProcessingPurchases, inCart, processingOrders})

  return { purchases: paidOrProcessingPurchases, 
    loadingPurchases, inCart, waitingOnBuyer, 
    waitingOnPlatform, inTransit, cancelOrder, 
    processingOrders, fetchOrderByOrderNumber, 
    cancelPartiallyPaidOrder, fetchOrderForQrCode, updateOrderForEventScan };
}