import { useContext, useEffect, useState } from 'react';
import "firebase/auth";
import { onSnapshot, doc, query, collection, getDocs, where, getDoc } from "firebase/firestore";
import { db, auth, functions } from '../../firebaseutil';
import { fleatoConfig } from '../../fleato-config';
import { httpsCallable } from 'firebase/functions';
import { Dao, DaoByHandle, DaoDeleteMember, DaoDetailsRequest, DaoGovernenceRequest, DaoMember, DaoPermissionsRequest, DaoReserveRequest, DaoSocialRequest } from '../../models/dao';
import { UserContext } from '../../contexts/UserContext';
import * as Sentry from '@sentry/nextjs'

const DAO = "dao";
const DAO_MEMBERS = "members";

export default function useDaos() {

  const [daos, setDaos] = useState<Dao[]>([]);
  const [userAsMember, setUserAsMember] = useState<DaoMember[]>([]);
  const {dbUser} = useContext(UserContext);

  const appendDao = async (add: Dao) => {
    setDaos(prev => [...prev.filter(pk => pk.sysHandle !== add.sysHandle), add]);
    if(auth?.currentUser?.uid)
      await loadDaoMember(add?.sysHandle);
  }

  const appendDaoMember = (add: DaoMember) => {
    setUserAsMember(prev => [...prev.filter(pk => pk.handle !== add.handle), add]);
  }

  useEffect(() => {
    // if (!auth.currentUser?.uid)
    //   return;
    const q = query(collection(db, `${DAO}`));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type == "added") {
          // console.log("category added", change.doc.id);
          await appendDao(change.doc.data() as Dao);
        } else if (change.type == "modified") {
          // console.log("category modified", change.doc.id);
          await appendDao(change.doc.data() as Dao);
        } else if (change.type == "removed") {
          // console.log("category removed", change.doc.id);
          setDaos(prev => prev.filter(p => p.sysHandle !== change.doc.data().id))
        }
      })
    },(err)=>{Sentry.captureException(err);console.log(err)});
    return unsubscribe;
  }, [])

  // useEffect(() => {
  //   if (!auth.currentUser?.uid && !dbUser?.handle)
  //     return;
  //   const q = query(collection(db, `${DAO}/{document=**}/${DAO_MEMBERS}`), where("handle", "==", dbUser?.handle));
  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     snapshot.docChanges().forEach((change) => {
  //       if (change.type == "added") {
  //         console.log("category added", change.doc.id);
  //         appendDaoMember(change.doc.data() as DaoMember);
  //       } else if (change.type == "modified") {
  //         console.log("category modified", change.doc.id);
  //         appendDaoMember(change.doc.data() as DaoMember);
  //       } else if (change.type == "removed") {
  //         console.log("category removed", change.doc.id);
  //         setUserAsMember(prev => prev.filter(p => p.handle !== change.doc.data().id))
  //       }
  //     })
  //   });
  //   return unsubscribe;
  // }, [auth.currentUser?.uid, dbUser])

  useEffect(() => {
    if(!auth?.currentUser?.uid  || !dbUser?.handle){
      return;
    }else {
      for(let dao of daos){
        (async() => {
          await loadDaoMember(dao?.sysHandle);
        })()
      }
    }
  }, [auth?.currentUser?.uid, daos, dbUser?.handle])

  const loadDaoMember = async(daoSysHandle: string) => {
    if(!dbUser?.handle || !daoSysHandle)
      return;
    const q = doc(db, DAO, daoSysHandle, DAO_MEMBERS, dbUser?.handle);
    const querySnapshot = await getDoc(q);
    if(querySnapshot?.data()){
      appendDaoMember(querySnapshot?.data() as DaoMember);
    }
  }

  const getDaoMembers = async(sysHandle: string) => {
    const q = query(collection(db, DAO, sysHandle, DAO_MEMBERS));
    const querySnapshot = await getDocs(q);
    const returnArray: DaoMember[] = [];
    await querySnapshot.forEach( async(doc) => {
      await returnArray.push(doc.data() as DaoMember);
    });
    return returnArray;
  }

  const daosIManage = daos?.filter(d => d.adminId == auth?.currentUser?.uid);
  const daosIJoined = daosIManage;
  const featuredDaos = daos?.filter(d => d.stake > 10).sort((a,b) => b.stake - a.stake);
  return {daos, getDaoMembers, userAsMember, daosIManage, daosIJoined, featuredDaos };
}