import { createContext, useState } from 'react';
import useTiles from '../hooks/bluetile/useTiles';
import { Tile } from '../models/bluetile';
import { Product } from '../models/product';


interface ITileContext {
    tiles: Tile[];
    tileWords: string[];
    name: string;
    setName: (name: string) => void;
    setTiles: (value: React.SetStateAction<Tile[]>) => void;
    setTileWords: (value: React.SetStateAction<string[]>) => void;
    resetTiles: (showAlert: boolean) => void;
    exists: () => Promise<[boolean, boolean]>;
    allowance: () => Promise<number>;
    tokenId: string;
    templateProduct: Product;
    mint: (title: string, wallet: string, totalSupply: number, imageUrl: string, selectedTileType: string, merchImageUrl?: string) => Promise<void>;
}

export const TilesContext = createContext<ITileContext>({} as ITileContext);

export const TilesProvider = ({ children }) => {
    const { tiles, setTiles, resetTiles, exists, mint, tokenId, allowance, name, setName, tileWords, setTileWords, templateProduct } = useTiles();
    return (
        <TilesContext.Provider value={{ tiles, setTiles, tileWords, setTileWords, resetTiles, exists, mint, tokenId, allowance, name, setName, templateProduct }}>
            {children}
        </TilesContext.Provider>
    )
}