import { ethers, Signer, BigNumber, ContractFactory, utils, ContractTransaction } from "ethers";
import IBaal from "../../smartcontracts/Baal.json";
import IBaalSummoner from "../../smartcontracts/BaalSummoner.json";
import ILoot from "../../smartcontracts/Loot.json";
import IShares from "../../smartcontracts/Shares.json";
import IMultiSend from "../../smartcontracts/MultiSend.json";
import IPoster from "../../smartcontracts/Poster.json";
import { fleatoConfig } from "../../fleato-config";
import { gasFee, gasFeeOptions } from "../../util/smartcontracts";
import { EthersTxn, Nonce } from "../../models/wallet";
import useFleatoPool from "./useFleatoPool";
import { WalletType } from "../wallet/models";
import { Baal, BaalSummoner, Loot, MultiSend, Poster, Shares } from "../../smartcontracts/types";
import { Dao, DaoGovernanceParams, DaoMember, DaoMemberRequest } from "../../models/dao";
import { encodeMultiAction } from "./multi-send";

export interface SharesLootMintBurnRequest {
  address: string;
  sharesToMint: number;
  lootToMint: number;
  sharesToBurn: number;
  lootToBurn: number;
}

export default function useBaal({walletType, account, getSigner, getProvider} : {walletType: WalletType, account?: string, getProvider?: () => Promise<any>, getSigner?: () => Promise<Signer>}) {

  const {getNonce, submitTransaction} = useFleatoPool();
  //getProvider will be sent for connected wallets
  //getSigner will be sent for fleato wallets

  //Web3 Provider for connected providers, default provider for fleato wallet.
  const networkProvider = async (): Promise<any> => {
    if(walletType == WalletType.ConnectedWallet)
      return new ethers.providers.Web3Provider(await getProvider());
    else
      return ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint);
  }

  const baalInstance = async (baalAddress: string): Promise<Baal> => {
    if(walletType == WalletType.FleatoWallet) {
      const c = new ethers.Contract(baalAddress, IBaal.abi, await networkProvider());
      return c.connect(await getSigner()) as Baal;
    } else {
      const c = new ethers.Contract(baalAddress, IBaal.abi, (await networkProvider()).getSigner());
      return c as Baal;
    }
  }

  const multiSendInstance = async (): Promise<MultiSend> => {
    if(walletType == WalletType.FleatoWallet) {
      const c = new ethers.Contract(fleatoConfig.contractGnosisMultisendLibrary, IMultiSend.abi, await networkProvider());
      return c.connect(await getSigner()) as MultiSend;
    } else {
      const c = new ethers.Contract(fleatoConfig.contractGnosisMultisendLibrary, IMultiSend.abi, (await networkProvider()).getSigner());
      return c as MultiSend;
    }
  }

  const connectFactory = async (factory: ContractFactory) => {
    if(walletType == WalletType.FleatoWallet) {
      return factory.connect(await getSigner());
    } else {
      return factory.connect((await networkProvider()).getSigner());
    }  
  }

  const readonlyBaalInstance = async (baalAddress: string): Promise<Baal> => {
    return new ethers.Contract(baalAddress, IBaal.abi, ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint)) as Baal;
  }

  const readonlyLootInstance = async (lootAddress: string): Promise<Loot> => {
    return new ethers.Contract(lootAddress, ILoot.abi, ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint)) as Loot;
  }
  
  const readonlySharesInstance = async (sharesAddress: string): Promise<Shares> => {
    return new ethers.Contract(sharesAddress, IShares.abi, ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint)) as Shares;
  }

  const abiCoder = utils.defaultAbiCoder;


  const getBaalParams = async function (
    baal: Baal,
    multisend: MultiSend,
    lootSingleton: Loot,
    sharesSingleton: Shares,
    poster: Poster,
    config: {
      PROPOSAL_OFFERING: any;
      GRACE_PERIOD_IN_SECONDS: any;
      VOTING_PERIOD_IN_SECONDS: any;
      QUORUM_PERCENT: any;
      SPONSOR_THRESHOLD: any;
      MIN_RETENTION_PERCENT: any;
      MIN_STAKING_PERCENT: any;
      TOKEN_NAME: any;
      TOKEN_SYMBOL: any;
    },
    metadata: [string, string],
    adminConfig: [boolean, boolean],
    shamans: [string[], number[]],
    shares: [string[], BigNumber[]],
    loots: [string[], BigNumber[]]
  ) {
    const governanceConfig = abiCoder.encode(
      ["uint32", "uint32", "uint256", "uint256", "uint256", "uint256"],
      [
        config.VOTING_PERIOD_IN_SECONDS,
        config.GRACE_PERIOD_IN_SECONDS,
        config.PROPOSAL_OFFERING,
        config.QUORUM_PERCENT,
        config.SPONSOR_THRESHOLD,
        config.MIN_RETENTION_PERCENT,
      ]
    );

    const setAdminConfig = await baal.interface.encodeFunctionData(
      "setAdminConfig",
      adminConfig
    );
    const setGovernanceConfig = await baal.interface.encodeFunctionData(
      "setGovernanceConfig",
      [governanceConfig]
    );
    const setShaman = await baal.interface.encodeFunctionData(
      "setShamans",
      shamans
    );
    const mintShares = await baal.interface.encodeFunctionData(
      "mintShares",
      shares
    );
    const mintLoot = await baal.interface.encodeFunctionData(
      "mintLoot",
      loots
    );
    const postMetaData = await poster.interface.encodeFunctionData("post", metadata);
    const posterFromBaal = await baal.interface.encodeFunctionData(
      "executeAsBaal",
      [poster.address, 0, postMetaData]
    );

    const initalizationActions = [
      setAdminConfig,
      setGovernanceConfig,
      setShaman,
      mintShares,
      mintLoot,
      posterFromBaal,
    ];

    // const initalizationActionsMulti = encodeMultiAction(
    //   multisend,
    //   [setAdminConfig, setGovernanceConfig, setGuildTokens, setShaman, mintShares, mintLoot],
    //   [baal.address, baal.address, baal.address, baal.address, baal.address, baal.address],
    //   [BigNumber.from(0), BigNumber.from(0), BigNumber.from(0), BigNumber.from(0), BigNumber.from(0), BigNumber.from(0)],
    //   [0, 0, 0, 0, 0, 0]
    // )
    return {
      initParams: abiCoder.encode(
        ["string", "string", "address", "address", "address"],
        [
          config.TOKEN_NAME,
          config.TOKEN_SYMBOL,
          lootSingleton.address,
          sharesSingleton.address,
          multisend.address,
        ]
      ),
      initalizationActions,
    };
  };


  const summonBaalAndSafe = async (dao: Dao, members: DaoMember[]): Promise<ContractTransaction | Nonce> => {
    const baalSummonerFactory = await connectFactory(new ContractFactory(IBaalSummoner.abi, IBaalSummoner.bytecode));
    const summoner = baalSummonerFactory.attach(fleatoConfig.contractBaalSummoner) as BaalSummoner;
    // console.log("summonBaalAndSafe", fleatoConfig.contractBaalSummoner, dao, members);
    const baalTemplateAddr = await summoner.template();

    const lootFactory = await connectFactory(new ContractFactory(ILoot.abi, ILoot.bytecode));
    const loot = await lootFactory.attach(fleatoConfig.contractLootTemplate) as Loot;

    const sharesFactory = await connectFactory(new ContractFactory(IShares.abi, IShares.bytecode));
    const shares = await sharesFactory.attach(fleatoConfig.contractSharesTemplate) as Shares;

    const baalFactory = await connectFactory(new ContractFactory(IBaal.abi, IBaal.bytecode));
    const baal = await baalFactory.attach(baalTemplateAddr) as Baal;

    const posterFactory = await connectFactory(new ContractFactory(IPoster.abi, IPoster.bytecode));
    const poster = await posterFactory.attach(fleatoConfig.contractPoster) as Poster;

    const multiSendFactory = await connectFactory(new ContractFactory(IMultiSend.abi, IMultiSend.bytecode));
    const multiSend = await multiSendFactory.attach(fleatoConfig.contractGnosisMultisendLibrary) as MultiSend;

    const {handle, name} = dao;
    const metadataConfig = {
      CONTENT: JSON.stringify({handle, name}),
      TAG: "fleatodao.summon.metadata",
    };

    const g = dao.governanceParams;
    const deploymentConfig = {
      VOTING_PERIOD_IN_SECONDS: g.votingPeriodDays * 24 * 3600,
      GRACE_PERIOD_IN_SECONDS: g.gracePeriodHours * 3600,
      SPONSOR_THRESHOLD: utils.parseEther(g.votesForProposalSponsorship.toFixed(0)),
      PROPOSAL_OFFERING: utils.parseEther(g.proposalFee.toFixed(0)),
      MIN_RETENTION_PERCENT: (100 - g.rageQuitPercentForAutoFailProposal),
      MIN_STAKING_PERCENT: 0,
      QUORUM_PERCENT: g.yesPercentForProposalApproval,
      TOKEN_NAME: dao.votingToken.name,
      TOKEN_SYMBOL: dao.votingToken.symbol
    }

    const sharesPaused = false, lootPaused = false;
    const shamans = members.filter(m => m.isAdmin || m.isGovernor || m.isManager).map(m => ({address: m.address, rewards: m.rewards, votes: m.votes, role: 0 + (m.isAdmin ? 1 : 0) + (m.isManager ? 2 : 0) + (m.isGovernor ? 4 : 0)}));
    shamans.push({address: fleatoConfig.contractDaoMembershipBot, rewards: 0, votes: 0, role: 2});

    // console.log("getBaalParams", {baal,
    //   multiSend,
    //   loot,
    //   sharesSingleton: shares,
    //   poster,
    //   deploymentConfig,
    //   metadata: [metadataConfig.CONTENT, metadataConfig.TAG],
    //   adminConfig: [sharesPaused, lootPaused],
    //   shamans: [shamans.map(s => s.address), shamans.map(s => s.role)],
    //   shares: [members.filter(m => m.votes > 0).map(m => m.address), members.filter(m => m.votes > 0).map(m => utils.parseEther(m.votes.toFixed(6)))],
    //   loots: [members.filter(m => m.rewards > 0).map(m => m.address), members.filter(m => m.rewards > 0).map(m => utils.parseEther(m.rewards.toFixed(6)))]
    // });

    const encodedInitParams = await getBaalParams(
      baal,
      multiSend,
      loot,
      shares,
      poster,
      deploymentConfig,
      [metadataConfig.CONTENT, metadataConfig.TAG],
      [sharesPaused, lootPaused],
      [shamans.map(s => s.address), shamans.map(s => s.role)],
      [members.map(m => m.address), members.map(m => utils.parseEther(m.votes.toFixed(6)))],
      [members.map(m => m.address), members.map(m => utils.parseEther(m.rewards.toFixed(6)))]
    );

    const randomSeed = Math.floor(Math.random() * 10000000);

    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await summoner.summonBaalAndSafe(
        encodedInitParams.initParams,
        encodedInitParams.initalizationActions,
        randomSeed, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas}
      );
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await summoner.signer.getAddress()))?.pooled ?? 0, await summoner.provider.getTransactionCount(await summoner.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await summoner.populateTransaction.summonBaalAndSafe(
        encodedInitParams.initParams,
        encodedInitParams.initalizationActions,
        randomSeed, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 1500000});
      const fastTxn = await summoner.populateTransaction.summonBaalAndSafe(
        encodedInitParams.initParams,
        encodedInitParams.initalizationActions,
        randomSeed, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 1500000});
      const fastestTxn = await summoner.populateTransaction.summonBaalAndSafe(
        encodedInitParams.initParams,
        encodedInitParams.initalizationActions,
        randomSeed, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 1500000});
      const signedTx = await summoner.signer.signTransaction({...await summoner.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await summoner.signer.signTransaction({...await summoner.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await summoner.signer.signTransaction({...await summoner.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const getBaalAddressesFromCreateTxnHash = async (
    txHash: string
  ): Promise<{ baal: string; loot: string; shares: string; safe: string }> => {
    const receipt = await (await ethers.getDefaultProvider(fleatoConfig.polygonRpcEndpoint)).getTransactionReceipt(txHash);
    let baalSummonAbi = [
      "event SummonBaal(address indexed baal, address indexed loot, address indexed shares, address safe)",
    ];
    let iface = new ethers.utils.Interface(baalSummonAbi);
    let log = iface.parseLog(receipt.logs[receipt.logs.length - 1]);
    const { baal, loot, shares, safe } = log.args;
    return { baal, loot, shares, safe };
  };

  const getDaoInfo = async (baalAddress: string) => {
    if(!account)
      return 0;
    const baal = await readonlyBaalInstance(baalAddress);
    const g = {
      votingPeriodDays: await baal.votingPeriod() / (24 * 3600),
      gracePeriodHours: await baal.gracePeriod() / 3600,
      proposalFee: parseFloat(utils.formatEther(await baal.proposalOffering())),
      totalLoot: parseFloat(utils.formatEther(await baal.totalLoot())),
    }
    return g;
  };

  const mintVotes = async (baalAddress: string, memberAddress: string[], votes: BigNumber[]): Promise<ContractTransaction | Nonce> => {
    const baal = await baalInstance(baalAddress);
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.mintShares(memberAddress, votes, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.mintShares(memberAddress, votes, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.mintShares(memberAddress, votes, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.mintShares(memberAddress, votes, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const burnVotes = async (baalAddress: string, memberAddress: string[], votes: BigNumber[]): Promise<ContractTransaction | Nonce> => {
    const baal = await baalInstance(baalAddress);
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.burnShares(memberAddress, votes, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.burnShares(memberAddress, votes, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.burnShares(memberAddress, votes, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.burnShares(memberAddress, votes, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const mintLoot = async (baalAddress: string, memberAddress: string[], loot: BigNumber[]): Promise<ContractTransaction | Nonce> => {
    const baal = await baalInstance(baalAddress);
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.mintLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.mintLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.mintLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.mintLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const burnLoot = async (baalAddress: string, memberAddress: string[], loot: BigNumber[]): Promise<ContractTransaction | Nonce> => {
    const baal = await baalInstance(baalAddress);
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.burnLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.burnLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.burnLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.burnLoot(memberAddress, loot, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const daoBalanceOf = async (baalAddress: string, memberAddress: string) => {
    const baal = await readonlyBaalInstance(baalAddress);
    const loot = await readonlyLootInstance(await baal.lootToken());
    const votes = await readonlySharesInstance(await baal.sharesToken());
    const lootBalance = parseFloat(ethers.utils.formatUnits(await loot.balanceOf(memberAddress), await loot.decimals()));
    const votesBalance = parseFloat(ethers.utils.formatUnits(await votes.balanceOf(memberAddress), await votes.decimals()));
    return {lootBalance, votesBalance};
  }

  const submitProposal = async (baalAddress: string, name: string, description: string) => {
    const multisendInstance = await multiSendInstance();
    const selfTransferAction = encodeMultiAction(
      multisendInstance,
      ["0x"],
      [fleatoConfig.contractGnosisSingleton],
      [BigNumber.from(0)],
      [0]
    );
    const proposal = {
      details: JSON.stringify({name, description}),
      expiration: 0,
      baalGas: 0,
    };
    const baal = await baalInstance(baalAddress);

    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.submitProposal(
        selfTransferAction,
        proposal.expiration,
        proposal.baalGas,
        ethers.utils.id(proposal.details), {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.submitProposal(
        selfTransferAction,
        proposal.expiration,
        proposal.baalGas,
        ethers.utils.id(proposal.details), {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.submitProposal(
        selfTransferAction,
        proposal.expiration,
        proposal.baalGas,
        ethers.utils.id(proposal.details), {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.submitProposal(
        selfTransferAction,
        proposal.expiration,
        proposal.baalGas,
        ethers.utils.id(proposal.details), {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }
  const sponsorProposal = async (baalAddress: string, proposalId: number) => {
    const baal = await baalInstance(baalAddress);
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.sponsorProposal(proposalId, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.sponsorProposal(proposalId, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.sponsorProposal(proposalId, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.sponsorProposal(proposalId, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }
  const submitVote = async (baalAddress: string, proposalId: number, vote: boolean) => {
    const baal = await baalInstance(baalAddress);
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.submitVote(proposalId, vote, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.submitVote(proposalId, vote, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.submitVote(proposalId, vote, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.submitVote(proposalId, vote, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }
  const processProposal = async (baalAddress: string, proposalId: number) => {
    const multisendInstance = await multiSendInstance();
    const selfTransferAction = encodeMultiAction(
      multisendInstance,
      ["0x"],
      [fleatoConfig.contractGnosisSingleton],
      [BigNumber.from(0)],
      [0]
    );

    const baal = await baalInstance(baalAddress);
    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.processProposal(proposalId, selfTransferAction, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.processProposal(proposalId, selfTransferAction, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.processProposal(proposalId, selfTransferAction, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.processProposal(proposalId, selfTransferAction, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }
  }

  const giveMembersRoles = async (baalAddress: string, memberAddress: string[], roles: BigNumber[]): Promise<ContractTransaction | Nonce> => {
    const baal = await baalInstance(baalAddress);

    if(walletType !== WalletType.FleatoWallet) {
      const gasEstimate = await gasFee();
      const tx = await baal.setShamans(memberAddress, roles, {maxFeePerGas: gasEstimate.maxFeePerGas, maxPriorityFeePerGas: gasEstimate.maxPriorityFeePerGas});
      return tx;
    } else {
      //TODO: Get latest from fleato pool instead
      const nonce = Math.max((await getNonce(await baal.signer.getAddress()))?.pooled ?? 0, await baal.provider.getTransactionCount(await baal.signer.getAddress(), "latest"));
      const gasEstimate = await gasFeeOptions();
      const slowTxn = await baal.populateTransaction.setShamans(memberAddress, roles, {maxFeePerGas: gasEstimate.slow.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.slow.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastTxn = await baal.populateTransaction.setShamans(memberAddress, roles, {maxFeePerGas: gasEstimate.fast.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fast.maxPriorityFeePerGas!, gasLimit: 500000});
      const fastestTxn = await baal.populateTransaction.setShamans(memberAddress, roles, {maxFeePerGas: gasEstimate.fastest.maxFeePerGas!, maxPriorityFeePerGas: gasEstimate.fastest.maxPriorityFeePerGas!, gasLimit: 500000});
      const signedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(slowTxn), nonce});
      const fastFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastTxn), nonce});
      const fastestFeeSignedTx = await baal.signer.signTransaction({...await baal.signer.populateTransaction(fastestTxn), nonce});
      await submitTransaction({signedTx, fastFeeSignedTx, fastestFeeSignedTx});
      return await getNonce(account);
    }

  }

  return {summonBaalAndSafe, getBaalAddressesFromCreateTxnHash, 
    getDaoInfo, mintVotes, burnVotes, mintLoot, 
    burnLoot, daoBalanceOf, submitProposal, 
    sponsorProposal, submitVote, processProposal,
    giveMembersRoles};
}
