import { useState, useEffect } from "react";
import Script from "next/script";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/globals.scss";
import "../styles/DatePicker.css";
import "../styles/Calendar.css";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { Analytics } from "@vercel/analytics/react";
import { Provider } from "react-redux";
import store from "../store/slices/index.slice";
import { UserProvider } from "../contexts/UserContext";
import { getAuth } from "firebase/auth";
import Header from "../layout/header/header";
import { useRouter } from "next/router";
import Modal from "react-modal";
import MobileNavigation from "../components/menu/mobile-navigation";
import ChatElements from "../components/chat-elements/chat-elements";
import * as gtag from "../lib/analytics";
import RedeemModal from "../components/modal/redeem-modal/redeem-modal";
import { ethers } from "ethers";
import { Web3ReactProvider } from "@web3-react/core";
import { ChatProvider } from "../contexts/ChatContext";
import TopicsChatElements from "../components/topics-chat-elements/topics-chat-elements";
import { FleatoQuoteProvider } from "../contexts/FleatoQuoteContext";
import { FleatoWalletProvider } from "../contexts/FleatoWalletContext";
import { ConnectedWalletProvider } from "../contexts/ConnectedWalletContext";
import { SilaWalletProvider } from "../contexts/SilaWalletContext";
import { fleatoConfig } from "../fleato-config";
import ErrorBoundary from "../components/error-boundary";
import { CategoriesProvider } from "../contexts/CategoriesContext";
import { FixedFooter } from "../layout/footer/footer/FixedFooter";
import { FloatingFooter } from "../layout/footer/footer/FloatingFooter";
import { SearchProvider } from "../contexts/typesense/SearchContext";
import { SSRProvider } from "react-bootstrap";
import { SettingsProvider } from "../contexts/SettingsContext";
import useBranding from "../hooks/store/useBranding";
import { CreditsProvider } from "../contexts/CreditsContext";
import UseWindowSize from "../hooks/helper/useWindowSize";
import { DaoProvider } from "../contexts/DaoContext";
import LoginModalWrapper from "../components/login-modal/login-modal-wrapper";
import { CategoryProvider } from "../contexts/CategoryContext";
import { AddressProvider } from "../contexts/AddressContext";
import { ArtistProvider } from "../contexts/ArtistContext";
import { TilesProvider } from "../contexts/TilesContext";
import { OrderProvider } from "../contexts/OrdersContext";
import { ResourceProvider } from "../contexts/ResourceContext";
import { OpportunityProvider } from "../contexts/OpportunityContext";
import { EventProvider } from "../contexts/EventContext";
import { AgenciesProvider } from "../contexts/AgenciesContext";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const GTM_ID = fleatoConfig.gtmId || "";

export interface FleatoBaseProps {
  openModal: (
    referralCode?: string,
    isSigningUp?: boolean,
    redirectUrl?: string,
    referralName?: string,
    referralEmail?: string,
    referralPhoneNumber?: string
  ) => void;
}

const getLibrary = (provider, connector) => {
  let p = new ethers.providers.Web3Provider(provider);
  console.log("provider", { provider, connector, p });
  return p;
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  gtag.webVital(metric);
}

function MyApp({ Component, pageProps }: AppProps) {
  const { pathname, query } = useRouter();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [redeemModalIsOpen, setRedeemModalIsOpen] = useState(false);
  const [chat, setChat] = useState(false);
  const [referralCode, setReferralCode] = useState<string>();
  const [referralName, setReferralName] = useState<string>();
  const [referralEmail, setReferralEmail] = useState<string>();
  const [referralPhoneNumber, setReferralPhoneNumber] = useState<string>();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [headless, setHeadless] = useState(false);
  const router = useRouter();
  const [redirectUrl, setRedirectUrl] = useState("/");
  const [clearSearchBox, setClearSearchBox] = useState(false);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(fleatoConfig.facebookPixelId); // facebookPixelId
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      setTimeout(() => {
        gtag.pageview(url, getAuth()?.currentUser?.uid);
      }, 0);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const { branding } = useBranding();

  const openRedeemModal = () => {
    console.log("Redeem modal is now open");
    setRedeemModalIsOpen(true);
  };
  const closeRedeemModal = () => {
    setRedeemModalIsOpen(false);
  };

  const currentArtist =
    pageProps?.artist ??
    pageProps?.featuredArtists?.find(
      (a) => a?.artist?.id === branding?.brandName
    )?.artist;

  const openModal = (
    referralCode?: string,
    isSigningUp?: boolean,
    redirectUrl?: string,
    referralName?: string,
    referralEmail?: string,
    referralPhoneNumber?: string
  ) => {
    if (redirectUrl) setRedirectUrl(redirectUrl);
    if (referralCode) setReferralCode(referralCode);
    if (isSigningUp) setIsSigningUp(isSigningUp);
    if (referralName) setReferralName(referralName);
    if (referralEmail) setReferralEmail(referralEmail);
    if (referralPhoneNumber) setReferralPhoneNumber(referralPhoneNumber);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    if (redirectUrl !== "/") router.push(redirectUrl);
  };

  useEffect(() => {
    if (
      ["products", "my-orders", "my-sales", "p"].includes(
        pathname.split("/")?.[1]
      )
    ) {
      setChat(true);
    } else {
      setChat(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (query.headless) setHeadless(true);
  }, [query]);

  useEffect(() => {
    if (getAuth()?.currentUser?.uid) {
      setTimeout(() => gtag.trackUserId(getAuth()?.currentUser?.uid), 0);
    }
  }, [getAuth()?.currentUser?.uid]);

  useEffect(() => {
    if (typeof window !== "undefined" && router.asPath != "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [router]);
  const screenProps = UseWindowSize();
  // console.log(screenProps?.windowSize?.height, screenProps?.windowSize?.screenHeight, "height");
  const needFixedFooter = false;

  if (
    pathname.startsWith("/payv2") ||
    pathname.startsWith("/mobilehelp") ||
    pathname.startsWith("/u") ||
    pathname.startsWith("/playlist") ||
    pathname.startsWith("/guest-auction") ||
    pathname.startsWith("/sv") ||
    pathname.startsWith("/bluetile/") ||
    pathname.startsWith("/marketplace") ||
    pathname.startsWith("/auction")
  ) {
    return (
      <SSRProvider>
        <Script src="https://apis.google.com/js/api.js"></Script>
        <Script src="https://www.google.com/recaptcha/api.js?render=6LcWWQ0bAAAAAIOJFphzWkFaVY7QQvOcrhJBtBs1" />
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`}
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              window['ga-disable-${GTM_ID}'] = ${"false"};

              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              // gtag('config', '${GTM_ID}', { page_path: window.location.href });
            `,
          }}
        />
        <Web3ReactProvider getLibrary={getLibrary}>
          <UserProvider openLoginPopup={openModal}>
            <CategoryProvider>
              <Provider store={store}>
                <FleatoWalletProvider>
                  <ConnectedWalletProvider>
                    <TilesProvider>
                      <AddressProvider>
                        <EventProvider>
                          <OpportunityProvider>
                            <ResourceProvider>
                              <ArtistProvider>
                                <OrderProvider>
                                  <AgenciesProvider>
                                    <SettingsProvider>
                                      <Web3ReactProvider
                                        getLibrary={getLibrary}
                                      >
                                        <ErrorBoundary>
                                          <Component
                                            {...pageProps}
                                            openModal={openModal}
                                          />
                                          <Modal
                                            isOpen={modalIsOpen}
                                            className="loginModal"
                                          >
                                            <LoginModalWrapper
                                              closeModal={closeModal}
                                              signUp={isSigningUp}
                                              setIsSigningUp={setIsSigningUp}
                                              referralCode={referralCode}
                                            />
                                          </Modal>
                                          <Analytics />
                                        </ErrorBoundary>
                                      </Web3ReactProvider>
                                    </SettingsProvider>
                                  </AgenciesProvider>
                                </OrderProvider>
                              </ArtistProvider>
                            </ResourceProvider>
                          </OpportunityProvider>
                        </EventProvider>
                      </AddressProvider>
                    </TilesProvider>
                  </ConnectedWalletProvider>
                </FleatoWalletProvider>
              </Provider>
            </CategoryProvider>
          </UserProvider>
        </Web3ReactProvider>
      </SSRProvider>
    );
  } else {
    return (
      <SSRProvider>
        <Script src="https://apis.google.com/js/api.js"></Script>
        <Script src="https://www.google.com/recaptcha/api.js?render=6LcWWQ0bAAAAAIOJFphzWkFaVY7QQvOcrhJBtBs1" />
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`}
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              window['ga-disable-${GTM_ID}'] = ${"false"};

              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              // gtag('config', '${GTM_ID}', { page_path: window.location.href });
            `,
          }}
        />
        <SettingsProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
            <UserProvider openLoginPopup={openModal}>
              <CategoryProvider>
                <AddressProvider>
                  <EventProvider>
                    <OpportunityProvider>
                      <ResourceProvider>
                        <ArtistProvider>
                          <OrderProvider>
                            <ErrorBoundary>
                              <CreditsProvider>
                                <FleatoWalletProvider>
                                  <ConnectedWalletProvider>
                                    <SilaWalletProvider>
                                      <CategoriesProvider>
                                        <Provider store={store}>
                                          <DaoProvider>
                                            <SearchProvider
                                              searchScope={"app-default"}
                                            >
                                              <FleatoQuoteProvider>
                                                <ChatProvider>
                                                  <TilesProvider>
                                                    <AgenciesProvider>
                                                      <>
                                                        {!pathname.startsWith(
                                                          "/catalog"
                                                        ) &&
                                                          !headless && (
                                                            <Header
                                                              // artist={
                                                              //   currentArtist
                                                              // }
                                                              // branding={
                                                              //   branding
                                                              // }
                                                              // openModal={
                                                              //   openModal
                                                              // }
                                                              // openRedeemModal={
                                                              //   openRedeemModal
                                                              // }
                                                              // clearSearchBox={
                                                              //   clearSearchBox
                                                              // }
                                                              // setClearSearchBox={
                                                              //   setClearSearchBox
                                                              // }
                                                            />
                                                          )}
                                                        {!pathname.startsWith(
                                                          "/catalog"
                                                        ) &&
                                                          !headless && (
                                                            <MobileNavigation />
                                                          )}
                                                        <div
                                                          style={{
                                                            minHeight:
                                                              (screenProps
                                                                ?.windowSize
                                                                ?.height ??
                                                                768) - 80,
                                                          }}
                                                        >
                                                          <Component
                                                            {...pageProps}
                                                            openModal={
                                                              openModal
                                                            }
                                                            clearSearchBox={
                                                              clearSearchBox
                                                            }
                                                            setClearSearchBox={
                                                              setClearSearchBox
                                                            }
                                                          />
                                                          <Analytics />
                                                        </div>
                                                        {chat && (
                                                          <ChatElements />
                                                        )}
                                                        <TopicsChatElements />
                                                        <Modal
                                                          isOpen={modalIsOpen}
                                                          className="loginModal"
                                                        >
                                                          <LoginModalWrapper
                                                            closeModal={
                                                              closeModal
                                                            }
                                                            signUp={isSigningUp}
                                                            setIsSigningUp={
                                                              setIsSigningUp
                                                            }
                                                            referralCode={
                                                              referralCode
                                                            }
                                                            referralPhoneNumber={
                                                              referralPhoneNumber
                                                            }
                                                            referralEmail={
                                                              referralEmail
                                                            }
                                                            referralName={
                                                              referralName
                                                            }
                                                          />
                                                        </Modal>
                                                        <Modal
                                                          isOpen={
                                                            redeemModalIsOpen
                                                          }
                                                          className="redeemModal"
                                                        >
                                                          <RedeemModal
                                                            closeModal={
                                                              closeRedeemModal
                                                            }
                                                          />
                                                        </Modal>
                                                        {!pathname.startsWith(
                                                          "/catalog"
                                                        ) &&
                                                          !headless && (
                                                            <>
                                                              {needFixedFooter ? (
                                                                <FixedFooter
                                                                  openModal={
                                                                    openModal
                                                                  }
                                                                  openRedeemModal={
                                                                    openRedeemModal
                                                                  }
                                                                />
                                                              ) : (
                                                                <FloatingFooter
                                                                  openModal={
                                                                    openModal
                                                                  }
                                                                  openRedeemModal={
                                                                    openRedeemModal
                                                                  }
                                                                />
                                                              )}
                                                            </>
                                                          )}
                                                      </>
                                                    </AgenciesProvider>
                                                  </TilesProvider>
                                                </ChatProvider>
                                              </FleatoQuoteProvider>
                                            </SearchProvider>
                                          </DaoProvider>
                                        </Provider>
                                      </CategoriesProvider>
                                    </SilaWalletProvider>
                                  </ConnectedWalletProvider>
                                </FleatoWalletProvider>
                              </CreditsProvider>
                            </ErrorBoundary>
                          </OrderProvider>
                        </ArtistProvider>
                      </ResourceProvider>
                    </OpportunityProvider>
                  </EventProvider>
                </AddressProvider>
              </CategoryProvider>
            </UserProvider>
          </Web3ReactProvider>
        </SettingsProvider>
      </SSRProvider>
    );
  }
}
export default MyApp;
