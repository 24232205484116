import React, { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RedeemModalWrapper } from "../../../styles/redeem-style";
import { ProductStats, PublicProfile } from "../../../models/product";
import animeLoading from "../../../public/lottie/14117-crypto-loader.json";
import Lottie from "react-lottie";
import useRedeem from "../../../hooks/user/useRedeem";

export default function RedeemModal({ closeModal }: {
  closeModal: () => void;
}) {
  const codeRef = useRef<HTMLInputElement>(null);
  const [isBusy, setIsBusy] = useState(false);
  const { redeem: redeemLogic } = useRedeem();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const redeem = async () => {
    setIsBusy(true);
    setMessage("");
    setError("");
    const code = codeRef.current.value;
    try {
      await redeemLogic({ code });
      setMessage(`Code ${code} successfully redeemed`);
      codeRef.current.value = "";
    } catch (err) {
      console.log("Error while redeeming", err);
      setError(`Unable to redeem code ${code}. Please try again or try another code`);
    }
    setIsBusy(false);
  }
  return (
    <RedeemModalWrapper>
      <div className="position-absolute close">
        <AiOutlineClose size={18} color="#000" onClick={closeModal} />
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3>Redeem Coupon(s)</h3>
          <p className="font-md-bold mt-1 mb-2 text-blue">
            {message}
          </p>
          <p className="font-md-bold mt-1 mb-2 text-error">
            {error}
          </p>
          {/* <p className="font-md-bold mt-1 mb-2">
            Enter your code below.
          </p> */}
          <div className="table-responsive">

            {/* <table className="table table-bordered">
            <thead>
              <tr>
                <th>Code</th>
                <th>Reward</th>
                <th>Redeemed on</th>
              </tr>
            </thead>
            <tbody>
            <tr>
             <td ></td>
              <td ></td>
              <td ></td>
            </tr>
            </tbody>
          </table> */}

            {isBusy &&
              <Lottie
                height={400}
                width={400}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animeLoading,
                }}
              />
            }
          </div>
          <div className="form-group row">
            <>
              <div className="col-sm-2">
                <p>{message.length > 0 ? "Redeem another code" : "Enter your code"}</p>
              </div>
              <div className="col-sm-3">
                <input type="input" ref={codeRef} className="form-control" />
              </div>
            </>
            <div className="col-sm-3 mt-3 mt-sm-0">
              {!isBusy &&
                <button className="btn blue-bg w-100 text-white px-4" onClick={redeem}>
                  Redeem
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </RedeemModalWrapper>
  );
}
