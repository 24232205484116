export const MESSAGES = {
  LOGIN : {
    NOTHING_ENTERED: "Please enter a valid phone number",
    INVALID_PHONENUMBER: "Invalid phone number",
    INVALID_EMAIL: "Invalid email address",
    INVALID_HANDLE: "Please select a nickname. This name will be shown when you interact with other users on the platform",
    INVALID_VERIFICATION_CODE: "Invalid Verification code",
    REQUIRE_RECENT_LOGIN_FOR_PRIMARY_EMAIL: "For your safely, we require you to login once again to change the email address",
    ACCOUNT_ALREADY_EXISTS: "A fleato account already exists with that phone number"
  }
}
