import { NextWebVitalsMetric } from "next/app";
import { fleatoConfig } from "../fleato-config";
import { CustomEventNames, CustomEventParams } from "./analytics-model";

export const GA_TRACKING_ID = fleatoConfig.gtmId || "";

export const pageview = (url: URL, userId?: string) => {
  // console.log("recording gtag", 'config', GA_TRACKING_ID, {
  //   event_category: 'test',
  //   event_label: 'test label',
  //   page_path: url
  // }, "gtag is", window.gtag)
  if(!!window?.gtag)
    window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
        user_id: userId
    }); 
  else {
    // alert("skip gtag, no window.gtag yet")
  }
};

export const event = (action: Gtag.EventNames, data: Gtag.EventParams) => {
  // console.log("*********** Sending event", action, data)
  // if(!!window?.gtag)
  try {
    window.gtag('event', action, data);
  } catch(err) {
    console.log("Skip logging event, gtag not available");
  }
  // else
    // console.log("Skip logging event, gtag not available");
};

export const customEvent = (action: CustomEventNames, data: CustomEventParams) => {
  console.log("*********** Sending event", action, data)
  try {
    window.gtag('event', action, data);
  } catch(err) {
    console.log("Skip logging event, gtag not available");
  }
};

export const trackUserId = (userId: string) => {
  try {
  if(userId)
    window.gtag('config', GA_TRACKING_ID, { user_id: userId });
  else 
    console.log("window.gtag.config userId not available yet");
  } catch(err) {
    console.log("Skip logging event, gtag not available");
  }
}

export const webVital = ({name, label, value, id}: NextWebVitalsMetric) => {
  // console.log("*********** Sending webvital", {name, label, value, id})
  try {
    window.gtag('event', name, {
      event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  } catch(err) {
    console.log("Skip logging event, gtag not available");
  }
};