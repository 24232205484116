import { useContext, useEffect, useState } from 'react';
import "firebase/auth";
import { e164PhoneNumber, isValidPhoneNumber, uniqueName } from '../../util/util';
import { ConfirmationResult, getAuth, signInWithPhoneNumber } from 'firebase/auth';
import { MESSAGES } from '../../util/messages';
import useRedeem from '../../hooks/user/useRedeem';
import { UserContext } from '../../contexts/UserContext';
import useReward from '../../hooks/user/useReward';
import { Reward } from '../../models/product';


export default function useSignupReward() {
  const { user, dbUser, hasFleatoWallet } = useContext(UserContext);
  const {getReward} = useReward();
  const { hasRedeemed } = useRedeem();
  const [loading, setLoading] = useState(true);
  const [referralCode, setReferralCode] = useState<string>();
  const [reward, setReward] = useState<Reward>({basePercent: 9, referrerUSD: 5, signUpUSD: 5});
  const [rewardStatus, setRewardStatus] = useState<"Not Redeemed" | "Redeemed">();
  const [alreadyAwarded, setAlreadyAwarded] = useState(false)
  
  useEffect(() => {
    if(dbUser)
      setReferralCode(dbUser.referralCode);
  }, [dbUser]);
  
  const markRedeemed = () => {
    setRewardStatus("Redeemed");
  }

  useEffect(() => {
    if (dbUser && referralCode) {
      (async() => {
        const reward = await getReward(referralCode);
        setReward(reward);
        const alreadyRewardedLocal = await hasRedeemed(user?.uid ?? "", referralCode);
        console.log("already rewarded status", alreadyRewardedLocal, "wallet setup status", hasFleatoWallet);
        alreadyRewardedLocal ? setRewardStatus("Redeemed") : setRewardStatus("Not Redeemed");
        setAlreadyAwarded(alreadyRewardedLocal);  
        setLoading(false);
      })()
    }
  }, [referralCode, dbUser]);

 return {loading, referralCode, setReferralCode, reward, rewardStatus, markRedeemed, alreadyAwarded};
}