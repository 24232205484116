import { createContext } from 'react'
import useOrders from '../hooks/order/useOrders';
import useFirebase from '../hooks/user/useFirebase';
import { OrderWithDelivery } from '../models/order';

interface IOrderContext {
  purchases: OrderWithDelivery[];
  waitingOnBuyer: OrderWithDelivery[];
  waitingOnPlatform: OrderWithDelivery[];
  inTransit: OrderWithDelivery[];
  inCart: OrderWithDelivery[];
  loadingPurchases: boolean;
  cancelOrder: (orderNumber: string) => Promise<any>;
  fetchOrderByOrderNumber: (orderNumber: string) => Promise<OrderWithDelivery>;
  cancelPartiallyPaidOrder: (orderNumber: string) => Promise<unknown>;
}

export const OrderContext = createContext<IOrderContext>({} as IOrderContext);

export const OrderProvider = ({ children }) => {
  const {user} = useFirebase();
  const { purchases, waitingOnBuyer, waitingOnPlatform, inTransit, loadingPurchases, inCart, cancelOrder, fetchOrderByOrderNumber, cancelPartiallyPaidOrder } = useOrders(user?.uid);
  return (
    <OrderContext.Provider value={{ purchases, waitingOnBuyer, waitingOnPlatform, inTransit, loadingPurchases, inCart, cancelOrder, fetchOrderByOrderNumber, cancelPartiallyPaidOrder }}>
      {children}
    </OrderContext.Provider>
  )
}