import React, { useContext, useState } from "react";
import Link from "next/link";
import { getAuth } from "firebase/auth";
import { e164PhoneNumber, isValidPhoneNumber } from "../../util/util";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import { UserContext } from "../../contexts/UserContext";
import animeLoading from "../../public/lottie/14117-crypto-loader.json";
import Lottie from "react-lottie";
import ReactPhoneInput from 'react-phone-input-2'
import { ProductViewModel } from "../../models/product";

const schema = yup
  .object({
    phoneNumber: yup.string().required('Phone number is a required field')
      .test("Invalid phone number", value => isValidPhoneNumber(value ? '+' + value : '')),
  })
  .required();

interface SignInViewModel {
  phoneNumber?: string;
}

interface Props {
  qrButtonTheme?: boolean;
  signUp: () => void;
  signIn: (phoneNumber: string) => void;
}

export default function SignInForm(props: Props) {
  const auth = getAuth();
  const { userExists } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState<any>();

  // const { register, handleSubmit, control, watch, setError, formState: { errors } } = useForm<SignInViewModel>({ mode: "onSubmit", resolver: yupResolver(schema), defaultValues: {} });

  const onSubmit = async (data: SignInViewModel) => {
    setLoading(true);
    const formattedPhone = '+' + (phoneNumber ?? data.phoneNumber)!;
    const userFound = await userExists(formattedPhone);
    if (userFound)
      props.signIn('+' + (phoneNumber ?? data.phoneNumber)!);
    else {
      setPhoneError({ message: "Account does not exist. Please sign up first." })
    }
    setLoading(false);
  }

  return (
    <div className="gtm-loginpopup my-4">
      <div className="px-3 mb-3">
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <h3 className="fw-normal text-center text-blue mb-4">Sign In</h3>
        {/* <Controller
            control={control}
            name="phoneNumber"
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => ( */}
        <ReactPhoneInput
          // {...field}
          country={"us"}
          containerClass="w-100 mb-2"
          inputStyle={{ paddingLeft: "50px", width: "100%" }}
          key="phoneNumber"
          onChange={(data) => { setPhoneNumber(data) }}
          inputProps={{
            // ref,
            required: true,
            autoFocus: true
          }}
          onEnterKeyPress={() => { (onSubmit({ phoneNumber: phoneNumber })) }}
          autocompleteSearch
        />
        {/* )}
          /> */}
        {phoneError && <p className={`errorMessage my-2`}>{phoneError.message}</p>}
        {loading &&
          <Lottie height={100} width={100} options={{ loop: true, autoplay: true, animationData: animeLoading }} />
        }
        <button onClick={(e) => { e.preventDefault(); onSubmit({ phoneNumber: phoneNumber }) }} className={`btn text-white text-uppercase w-100 my-2 ${props.qrButtonTheme ? 'black-bg' : 'blue-bg'} `}>
          Sign in
        </button>
        {/* </form> */}
        <>
          <button onClick={(e) => {e?.preventDefault(); props.signUp();}} className={`btn text-uppercase w-100 my-2 ${props.qrButtonTheme ? 'black-ghost-btn' : 'btn-ghost-sharp'}`} >
          Dont have an account? Sign up
          </button>
        </>
      </div>
      <div className="text-center terms" style={{ fontSize: "10px" }}>
        <p className="px-2 pt-2">
          By clicking SIGN IN or SIGN UP, you acknowledge that you have read and accepted the <Link href="/terms" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Terms of Service{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>{" "}
          and{" "}
          <Link href="/privacy" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Privacy Policy{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>
        </p>
      </div>
    </div >
  );
}
