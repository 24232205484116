export interface USDValue {
  rate: number;
  units: number;
  value: number;
}
export interface Holding extends USDValue {
  allocationPercent: number;
}

export interface WalletBalance {
  usdc?: Holding;
  dogecoin?: Holding;
  ethereum?: Holding;
  litecoin?: Holding;
  bitcoin?: Holding;
  bitcoincash?: Holding; 
  fleato?: Holding;
  matic?: Holding;
  totalUSD?: Holding;
}

export interface WalletBalanceTimeSeries {
  ts: number;
  balance: WalletBalance
}


export interface BlockchainTransaction {
  id: string;
  type: TransactionType;
  from: string;
  to: string;
  receiptFrom: string;
  receiptTo: string;
  symbol: string;
  units: number;
  txnHash: string;
  status: 0 | 1 | "processing";
  createdAt: string;
  value?: number;
  errorCode?: string;
  userId?: string;
  orderNumber?: string;
  description: string;
  tokenTransfers?: Transfer[];
  productId?: string;
  statusAt?: string;
  walletBalance?: string;
}

export enum TransactionType {
  creditTokenFromFaucet = "creditTokenFromFaucet",
  markOkToDeliver = "markOkToDeliver",
  markOkToPayout = "markOkToPayout",
  markOkToRefund = "markOkToRefund",
  pay = "pay",
  refund = "refund",
  withdrawPayment = "withdrawPayment",
  withdrawFee = "withdrawFee",
  withdrawPaymentsAndFees = "withdrawPaymentsAndFees",
  scavengePayment = "scavengePayment",
  scavengeFee = "scavengeFee",
  mintNFT = "mintNFT"
}

export interface Transfer {
  from: string;
  to: string;
  amount: string;
  symbol: string;
}

export interface Entry {
  ts: number;
  u: number;
  v: number;
  p?: number;
}

export interface TokenValueSeries {
  fleato: Entry[];
  bitcoin?: Entry[];
  ethereum?: Entry[];
  usdc?: Entry[];
  matic?: Entry[];
}

export interface Value {
  u: number;
  v: number;
}

export interface TokenValue {
  fleato?: Value;
  bitcoin?: Value;
  ethereum?: Value;
  usdc?: Value;
  matic?: Value;
  totalUsd?: Value;
}

export interface Mnemonic {
  phrase: string;
  locale: string;
  path: string;
}

export interface WalletStorage {
  isReady: boolean;
  exists(): Promise<boolean>;
  getValue(): Promise<Mnemonic>;
  create(content: Mnemonic): Promise<void>;
}

export const TOKENS = {
  usdc: "usdc",
  dogecoin: "dogecoin",
  ethereum: "ethereum",
  litecoin: "litecoin",
  bitcoin: "bitcoin",
  bitcoincash: "bitcoincash",
  fleato: "fleato",
  matic: "matic",
};

export const TOKEN_NAMES = {
  usdc: "USD Coin",
  dogecoin: "Doge Coin",
  ethereum: "Ethereum",
  litecoin: "Lite Coin",
  bitcoin: "Bitcoin",
  bitcoincash: "Bitcoin Cash",
  fleato: "Fleato Coin",
  matic: "Polygon",
};


export interface BalanceEntry {
  u: number;
  v: number;
  p: string;
}

export interface RecentBalance {
  fleato: BalanceEntry,
  bitcoin?: BalanceEntry,
  ethereum?: BalanceEntry,
  matic?: BalanceEntry,
  usdc?: BalanceEntry,
  total: BalanceEntry
}

export interface Quote {
  usdcToFleatoString: string;
  fleatoToUsdcString: string;
  usdcToFleato: number;
  fleatoToUsdc: number;
  asOf: string;
}
export interface GasFeeResponse {
  status: "0" | "1";
  message: string;
  result: GasFee;
}
export interface GasFee {
  LastBlock: string;
  SafeGasPrice: string;
  ProposeGasPrice: string;
  FastGasPrice: string;
  BaseFee?: string;
  suggestBaseFee?: string;
  GasUsedRatio: string;
  UsdPrice: string;
}

export interface SignedTxnRequest {
  signedTx: string;
  fastFeeSignedTx: string;
  fastestFeeSignedTx: string;
}

export interface EthersTxn {
  wait: () => Promise<void>;
  hash: string;
}

export interface Nonce {
  pooled?: number;
  latest?: number;
  pending?: number;
}


export const FLEATO_PAY_LIMIT = 100.00;

export const SWAP_FLEATO_TOL_FACTOR = 1.01; 
export const SWAP_FLEATO_TOL_ROUNDUP = 1;
export const SWAP_USDC_TOL_FACTOR = 1.05;
export const SWAP_USDC_TOL_ROUNDUP = 0.1;
export const PAY_FLEATO_TOL_FACTOR = 1.01;
export const PAY_FLEATO_TOL_ROUNDUP = 1;
export const PAY_USDC_TOL_FACTOR = 1.01;
export const PAY_USDC_TOL_ROUNDUP = 0.1;
export const SAFE_BALANCE_TOL_FACTOR = 1.01;
