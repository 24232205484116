import "firebase/auth";
import {useState} from "react";
import {httpsCallable} from "firebase/functions";
import { ProfileUpdateRequest } from "../../models/user";
import { functions } from '../../firebaseutil';
import * as Sentry from "@sentry/nextjs";

enum RequestTypes {
  updateProfile = "updateProfile",
  addWallet = "addWallet"
}

export default function useUpdateProfile() {
  const [updateStatus, setUpdateStatus] = useState<ProfileUpdateRequest>();
  const updateProfile = async (request: ProfileUpdateRequest): Promise<ProfileUpdateRequest> => {
    try {
    const userCallable = httpsCallable(functions, 'user-userCall');
      setUpdateStatus({});
      const result = await userCallable({requestType: RequestTypes.updateProfile, input: request});
      const response = result.data as ProfileUpdateRequest;
      if(request.handle && !response.handle)
        setUpdateStatus(prev => ({...prev, handle: "Handle already taken"}));
      return response;
    } catch(err) {
      Sentry.captureException(err);
      console.log("Error while updating profile",err);
      throw(err);
    }
  }

  return {updateStatus, updateProfile};
}
