import { MessageList, Input, Button } from "react-chat-elements";
import React, { useContext, useState, useRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import useChatMessages from "../../hooks/chat/useChatMessages";
import { ChatContext } from "../../contexts/ChatContext";
import usePublicProfile from "../../hooks/usePublicProfile";
import { ChatTopic } from "../../models/chat";
import empty from "../../public/lottie/33740-sad-empty-box.json";
import Lottie from "react-lottie";

interface Props {
  selectedTopic?: ChatTopic;
}

export default function MessageListView({ selectedTopic }: Props) {
  const [reply, setReply] = useState({ id: "", text: "" });
  const { user, publicProfile } = useContext(UserContext);
  const { selectedProduct } = useContext(ChatContext);
  const { publicProfile: otherProfile } = usePublicProfile(selectedProduct?.seller?.id);
  const buyerId = selectedTopic?.partyIds?.length ? selectedTopic?.partyIds[0] : "";
  const sellerId = selectedTopic?.partyIds?.length ? selectedTopic?.partyIds[1] : "";
  const { messages, send, markAsRead } = useChatMessages({
    userId: user?.uid,
    buyerId,
    sellerId,
    productId: selectedTopic?.productId,
  });

  const inputRef = useRef(null);
  const replyHandler = (e) => {
    let value = e.target.value;
    setReply({
      id: user?.uid,
      text: value,
    });
  };

  const onSend = () => {
    if (reply?.text) {
      send({
        msg: [reply],
        prevCount: messages?.length,
        buyerName: selectedTopic?.partyIds?.length === 0 ? publicProfile?.handle : selectedProduct?.seller?.id === selectedTopic?.partyIds[0] ? selectedTopic?.partyNames[1] : selectedTopic?.partyNames[0],
        sellerName: selectedProduct?.seller?.handle,
        productImageUrl: selectedProduct?.image,
        productName: selectedProduct?.name,
        partyImageUrls: [publicProfile?.photoUrl ?? "", otherProfile?.photoUrl ?? ""]
      });

      inputRef?.current.clear();
    }
  };

  return (
    <>
      {messages?.length === 0 &&

        <div className="position-absolute text-center">
          <h1 className="position-relative" style={{ top: 60 }}>OOPS! No conversation</h1>
          <Lottie className="mb-0" height={500} width={500} options={{ loop: true, autoplay: true, animationData: empty }} />

        </div>}
      {selectedProduct &&
        <>
          <div className="overflow-auto row middle w-100">
            <div className="px-2 py-2">
              <MessageList
                className="message-list"
                lockable={true}
                toBottomHeight={"100%"}
                dataSource={messages?.map((item) => ({
                  ...item,
                  title:
                    (item?.id === user?.uid
                      ? "You" : item.id === selectedTopic?.partyIds?.[0] ? selectedTopic?.partyNames?.[0] : selectedTopic?.partyNames?.[1]) ?? "",
                  position: item?.id === user?.uid ? "right" : "left",
                  type: "text",
                  date: item.createdAt,
                })).sort((a, b) => (a.date > b.date ? 1 : -1))}
              />
            </div>
          </div>
          <div className="input-wrap mb-3">
            <Input
              ref={inputRef}
              onChange={(e) => replyHandler(e)}
              placeholder="Type here..."
              rightButtons={

                <button
                  className="btn blue-bg text-white"
                  onClick={onSend}
                >
                  Send
                </button>
              }
            />
          </div>
        </>
      }
    </>
  );

}
